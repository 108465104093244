import "./SliderNumberInput.scss";

import { ChangeEvent, FC, FocusEvent, useCallback, useEffect, useState } from "react";

import SliderInput, {
  ISliderInputEvent,
  ISliderMetaData,
  ISliderTick,
} from "components/SliderInput/SliderInput";
import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { useModal, useTranslation } from "modules";
import formatCommas from "utils/formatCommas";

import classNames from "classnames";
import { kebabCase, reduce, truncate } from "lodash-es";

interface IProps {
  id: string;
  idText: string;
  style?: React.CSSProperties;
  className?: ClassName;
  label?: string;
  value: number;
  metadata: ISliderMetaData;
  hasUpToLimit?: boolean;
  dataTestId?: string;
  onChange?(event: ISliderInputEvent): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
  onBlur?(event: ISliderInputEvent): void;
}

type ActiveInput = "slider" | "number";

const SliderNumberInput: FC<IProps> = props => {
  const {
    className,
    style,
    label,
    value,
    onChange,
    onFocus,
    onBlur,
    id,
    idText,
    metadata,
    hasUpToLimit,
    dataTestId,
  } = props;
  const { t } = useTranslation();

  const [numberValue, setNumberValue] = useState(0);
  const [textValue, setTextValue] = useState("");
  const [activeInput, setActiveInput] = useState<ActiveInput>("slider");

  // Metadata
  const ticksList = metadata.ticks || [];
  const valueBase = metadata?.valueBase || 0;
  const characterMax = metadata?.characterMax || 20;
  const { min, max } = reduce(
    ticksList,
    (result: Record<string, number>, tick: ISliderTick) => {
      const newResult = { ...result };
      if (!result.min || result.min > tick.value) newResult.min = tick.value;
      if (!result.max || result.max < tick.value) newResult.max = tick.value;
      return newResult;
    },
    { min: 0, max: 0 }
  );
  const maxErrorMessage = useModal(
    {},
    {
      header: t("coverage_editor.max_coverage_error.title", "Sorry, high-roller..."),
      text: metadata.maxErrorMessage,
    }
  );
  const minErrorMessage = useModal(
    {},
    {
      header: t(
        "coverage_editor.min_coverage_error.title",
        "Hmm, let’s get some meat on those bones"
      ),
      text: metadata.minErrorMessage,
    }
  );

  const handleTextInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = event.target.selectionStart || 1;

    const cleanedValue = event.target.value.replace(/[^0-9]/gi, "").replace(/^0+/, "");
    const truncateValue = truncate(cleanedValue, { length: characterMax, omission: "" });
    const currentTextLength = textValue.length;

    const textLength = await formatNumberToText(truncateValue, false);

    // Adjust cursor position based on addition/removal of commas on textValue
    const newPosition =
      textLength > currentTextLength + 1
        ? cursorPosition + 1
        : textLength < currentTextLength - 1
        ? cursorPosition - 1
        : cursorPosition;

    event.target.selectionStart = newPosition;
    event.target.selectionEnd = newPosition;
  };

  const handleSliderOnChange = (event: ISliderInputEvent) => {
    const value = event.target.value;

    const changeEvent = {
      target: {
        value: +value,
        id: event.target.id,
      },
    };

    onChange && onChange(changeEvent);
  };

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    setActiveInput(inputName as ActiveInput);

    onFocus && onFocus(event);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const inputName = event.target.name as ActiveInput;
    const changeEvent = {
      target: {
        value: event.target.valueAsNumber,
        id,
      },
    };

    if (inputName === "number") {
      const cleanedValue = event.target.value.replace(/[^0-9]/gi, "").replace(/^0+/, "");

      changeEvent.target.value = validateNumberValue(+cleanedValue);

      setActiveInput("slider");

      onChange && onChange(changeEvent);
    }

    onBlur && onBlur(changeEvent);
  };

  const validateNumberValue = (value: number) => {
    let validatedValue = value - valueBase;

    if (!value) validatedValue = numberValue + valueBase;

    if (validatedValue > max) {
      maxErrorMessage("SliderErrorMessageModal");
      validatedValue = max;
    }
    if (validatedValue < min) {
      minErrorMessage("SliderErrorMessageModal");
      validatedValue = min;
    }

    formatNumberToText(validatedValue);
    return validatedValue;
  };

  const formatNumberToText = (value: number | string, addBase = true) => {
    const adjustedValue = addBase ? +value + +valueBase : value;
    const formatWithCommas = `${formatCommas(adjustedValue)}`;

    setTextValue(formatWithCommas);
    return formatWithCommas.length;
  };

  const classes = classNames("slider-input__container", className);
  const inputLabelWrapper = classNames("slider-input__label--wrapper", {
    inactive: activeInput !== "number",
    "flex--center-space-between": !!label,
    "flex--justify-center": !label,
  });

  const textLength = textValue.length;

  const setValues = useCallback(() => {
    formatNumberToText(value);
    setNumberValue(value);

    // // Initial values from metadata
    if (!numberValue && !textValue) {
      setNumberValue(metadata.value);
      formatNumberToText(metadata.value);
    }
  }, [value, metadata.value]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValues();
  }, [setValues]);

  return (
    <div className={classes} style={style}>
      <div className={inputLabelWrapper}>
        {label ? <Text className="slider-input__label" tag="l2" text={label} /> : null}
        <div className="slider-input__number-text--wrapper">
          {hasUpToLimit && (
            <Text
              tag="l6"
              text={t("ad.amount.superscript", "Up to")}
              className="slider-input__number-text--limit"
            />
          )}
          <div className="slider-input__number--wrapper flex--center">
            {metadata?.leftTextLabel && (
              <label className="slider-input__number-unit--left" htmlFor={idText}>
                <Text tag="h6" text={metadata?.leftTextLabel} />
              </label>
            )}
            <input
              id={idText}
              className="slider-input__number h6-tag__text"
              type="text"
              name="number"
              value={textValue || 0}
              onChange={handleTextInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              disabled={!metadata.textEditable}
              role="number-input"
              style={{ width: `${textLength * 15 + 22}px` }}
            />
            {metadata?.rightTextLabel && (
              <Text
                className="slider-input__number-unit--right"
                tag="h6"
                text={metadata?.rightTextLabel}
              />
            )}
          </div>
        </div>
      </div>
      <SliderInput
        id={id}
        value={numberValue}
        metadata={metadata}
        onFocus={handleOnFocus}
        onChange={handleSliderOnChange}
        onBlur={handleOnBlur}
        dataTestId={dataTestId || `slider-number-input__${kebabCase(label)}`}
        inactive={activeInput !== "slider"}
      />
    </div>
  );
};

export default SliderNumberInput;
