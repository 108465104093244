import {
  IFormInput,
  IFormInputs,
  ErrorCodes,
  GqlError,
  GqlValidationError,
  IAppError,
} from "config/types";
import { AppThunk } from "store";

import { PayloadAction } from "@reduxjs/toolkit";
import { camelCase, get, isEqual, isString, kebabCase, mapValues, pick, some } from "lodash-es";

export const buildGraphQlError = (response: Record<string, any>): IAppError => {
  const error = get(response, "graphQLErrors[0]");
  const gqlError: GqlError = pick(error, ["extensions", "message"]);
  gqlError.extensions = pick(gqlError.extensions, ["full_message", "validation_errors"]);

  return {
    code: gqlError.message,
    fullMessage: gqlError.extensions.full_message,
    validationErrors: gqlError.extensions.validation_errors?.errors,
  };
};

const buildAuthError = (response: Record<string, any>): IAppError => {
  return {
    code: get(response, "response.data.error", ""),
    fullMessage: get(response, "response.data.error_description", ""),
  };
};

export const getAppError = (response: Record<string, any>): IAppError => {
  const isGraphQlError = !!get(response, "graphQLErrors");
  return isGraphQlError ? buildGraphQlError(response) : buildAuthError(response);
};

export const getThunkError = <T>(thunkAction: AppThunk<T> | PayloadAction<T>): IAppError | null => {
  const defaultThunkErrorMessage = get(thunkAction, ["error", "message"]);

  if (defaultThunkErrorMessage !== "Rejected") {
    return null;
  } else {
    return get(thunkAction, "payload");
  }
};

// form inputs need to be named to match backend fields for this to work
// we will convert all backend field names to camelCase
// use camelCase for input names/ids
export const processValidationErrors = (
  inputs: IFormInputs,
  errors?: GqlValidationError[]
): IFormInputs => {
  if (!errors || !errors.length) {
    return inputs;
  }

  const processedInputs = mapValues(inputs, input => {
    const newInput: IFormInput = { ...input };
    const error = errors.find(
      error => camelCase(error.field) === input.id || kebabCase(error.field) === input.id
    );

    if (error) {
      newInput.error = [...newInput.error, { code: error.error, message: error.message }];
    }
    return newInput;
  });
  return processedInputs;
};

export const isErrorCodeEqualTo = (
  response: string | Record<string, any>,
  code: ErrorCodes | ErrorCodes[]
): boolean => {
  const errorCode = isString(response) ? response : getAppError(response);

  return isString(code)
    ? isEqual(errorCode, code)
    : some(code, (codeItem: string) => isEqual(errorCode, codeItem));
};
