import { RootState } from "store";
import * as actionType from "store/constants";

import { createAction } from "@reduxjs/toolkit";

import { getDefaultAttrs, getProductSelectedAttr } from "./defaults";

/* ------------------------ EVENT ACTION ------------------------ */
export const beneficiaryPacketModalDisplayedEvent = createAction(
  actionType.BENEFICIARY_PACKET_MODAL_DISPLAYED
);

export const beneficiaryPacketModalExit = createAction(actionType.BENEFICIARY_PACKET_MODAL_EXIT);

export const beneficiaryPacketSentEvent = createAction(actionType.BENEFICIARY_PACKET_SENT);

/* ------------------------ EVENT DEFINITION ------------------------ */

const getDefaultBillingDisplayedEventDefinition = (eventName: string) => {
  return (_: any, prevState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "DASHBOARD");
    const productSelected = getProductSelectedAttr(prevState);

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        productSelected,
      },
    };
  };
};

/* ------------------------ EVENT MAP ------------------------ */
export const beneficiaryEventsMap = {
  [actionType.BENEFICIARY_PACKET_MODAL_DISPLAYED]: getDefaultBillingDisplayedEventDefinition(
    "beneficiary_packet_modal"
  ),
  [actionType.BENEFICIARY_PACKET_MODAL_EXIT]: getDefaultBillingDisplayedEventDefinition(
    "beneficiary_packet_exit"
  ),
  [actionType.BENEFICIARY_PACKET_SENT]: getDefaultBillingDisplayedEventDefinition(
    "beneficiary_packet_sent"
  ),
};
