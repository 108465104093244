import { IWysh, IWyshAllocation } from "config/types";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { getWysh, getWyshes } from "store/actions/wyshes";
import { findWyshIconUrls } from "utils/findIconUrl";

import { createReducer } from "@reduxjs/toolkit";
import { find, map } from "lodash-es";

export interface IWyshState extends IWysh {
  icon: string;
  infoHeader: string;
  isMonitorable?: boolean;
  detailsHeader: string;
  iconSelected: string;
  iconUnselected: string;
}

export interface IWyshesAllocationState extends IWyshAllocation {
  icon: string;
  infoHeader: string;
  detailsHeader: string;
}

export interface IWyshesState {
  wyshesItems: IWyshState[];
}

const defaultState: IWyshesState = {
  wyshesItems: [],
};

const wyshes = createReducer(defaultState, builder => {
  builder
    .addCase(getWyshes.fulfilled, (state, action) => {
      const mappedWyshes = map(action.payload || [], wysh => {
        const wyshIcons = findWyshIconUrls(wysh);
        const isMonitorable = action.meta?.isAccountEligibleForMonitoring && wysh.monitorable;

        return {
          ...wysh,
          ...wyshIcons,
          isMonitorable,
        };
      });

      state.wyshesItems = mappedWyshes;
    })
    .addCase(getWysh.fulfilled, (state, action) => {
      const wyshData = action.payload;

      const wyshIcons = findWyshIconUrls(wyshData);
      const isMonitorable = action.meta?.isAccountEligibleForMonitoring && wyshData.monitorable;

      const wysh: IWyshState = {
        ...wyshData,
        ...wyshIcons,
        isMonitorable,
      };

      const existingWysh = find(state.wyshesItems, ["slug", wysh.slug]);
      const newWysh = existingWysh ? { ...existingWysh, ...wysh } : wysh;

      state.wyshesItems = [...state.wyshesItems, newWysh];
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const mappedWyshes = map(state.wyshesItems, wysh => {
        const isMonitorable = action.payload?.account?.eligibleForMonitoring && wysh.monitorable;

        return { ...wysh, isMonitorable };
      });

      state.wyshesItems = mappedWyshes;
    })
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const mappedWyshes = map(state.wyshesItems, wysh => {
        const wyshIcons = findWyshIconUrls(wysh);
        const isMonitorable = action.payload?.eligibleForMonitoring && wysh.monitorable;

        return { ...wysh, ...wyshIcons, isMonitorable };
      });

      state.wyshesItems = mappedWyshes;
    })
    .addCase(logout, () => defaultState);
});

export default wyshes;
