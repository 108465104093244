import "./HeaderNavigation.scss";

import CustomLink from "components/CustomLink/CustomLink";
import { ClassName } from "config";
import { useTranslation } from "modules";
import { useParamSelector, getFeatureFlagByName } from "store/selectors";

import classNames from "classnames";

interface IProps {
  className?: ClassName;
}

function HeaderNavigation({ className }: IProps) {
  const { t } = useTranslation();
  const savingsEnabled = useParamSelector(getFeatureFlagByName, "savings_enabled");

  return (
    <div className={classNames("header-navigation", className)}>
      <CustomLink
        activeClassName="header-navigation__link--active"
        className="header-navigation__link header-navigation__link--home"
        dataTestId="header__link--dashboard"
        exact
        tag="l4-label"
        to="/"
        text={t("tab_nav.item.1.title", "HOME")}
      />
      <CustomLink
        activeClassName="header-navigation__link--active"
        className="header-navigation__link"
        dataTestId="header__link--insurance"
        tag="l4-label"
        to="/products/insurance"
        text={t("tab_nav.item.3.title", "INSURANCE")}
      />
      {savingsEnabled && (
        <CustomLink
          activeClassName="header-navigation__link--active"
          className="header-navigation__link"
          tag="l4-label"
          to="/products/savings"
          text={t("tab_nav.item.2.title", "SAVINGS+")}
        />
      )}
      <CustomLink
        activeClassName="header-navigation__link--active"
        className="header-navigation__link"
        tag="l4-label"
        to="/wyshes"
        text={t("tab_nav.item.4.title", "WYSHES")}
      />
    </div>
  );
}

export default HeaderNavigation;
