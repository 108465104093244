import "./FlowReview.scss";

import { FormEvent, useCallback, useEffect, useState } from "react";

import Button from "components/Button/Button";
import FlowReviewQuestion from "components/FlowReview/FlowReviewQuestion";
import Text from "components/Text/Text";
import {
  IFlowField,
  IFlowForm,
  IFlowQuestion,
  IForm,
  IFormInputs,
  IInsuranceApplicationDocument,
} from "config";
import { useTranslation } from "modules";
import { useAppDispatch } from "store";
import { getLatestInsuranceApplicationDocuments } from "store/actions/insuranceApplications";
import { buildFormInputs } from "utils/form";
import { poll } from "utils/poll";

import classNames from "classnames";
import { isArray, isEmpty } from "lodash-es";

const APPLICATION_SLUG = "application";

type TLatestInsuranceApplicationDocumentResponse = {
  latestApplicationDocument?: IInsuranceApplicationDocument;
};

interface IProps {
  form: IForm;
  formId: IFlowForm["id"];
  inputs: IFormInputs;
  prompt: string;
  questions: IFlowQuestion[];
  title: string;
  onSubmit(event?: FormEvent<HTMLFormElement>, formInputs?: IFormInputs): void;
}

const FlowReview = (props: IProps) => {
  const { form, formId, inputs, onSubmit, prompt, questions, title } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isGettingApplicationDocumentUrl, setIsGettingApplicationDocumentUrl] = useState(false);

  const getLatestInsuranceApplicationDocumentPolling = useCallback(async () => {
    const latestApplicationDocuments = await dispatch(
      getLatestInsuranceApplicationDocuments()
    ).unwrap();

    const latestApplicationDocument =
      isArray(latestApplicationDocuments) &&
      latestApplicationDocuments.find(({ slug }) => slug === APPLICATION_SLUG);

    return {
      context: {
        latestApplicationDocument,
      },
      resolved: !!latestApplicationDocument,
    };
  }, [dispatch]);

  const handleDownloadApplication = async () => {
    try {
      setIsGettingApplicationDocumentUrl(true);

      const latestInsuranceApplicationDocumentResponse = await poll<
        TLatestInsuranceApplicationDocumentResponse
      >(getLatestInsuranceApplicationDocumentPolling);

      const latestInsuranceApplicationDocumentUrl =
        latestInsuranceApplicationDocumentResponse.context.latestApplicationDocument?.html?.url ||
        latestInsuranceApplicationDocumentResponse.context.latestApplicationDocument?.pdf?.url;

      if (latestInsuranceApplicationDocumentUrl) {
        window.open(latestInsuranceApplicationDocumentUrl, "_blank");
      }
    } finally {
      setIsGettingApplicationDocumentUrl(false);
    }
  };

  const handleEditField = async (fieldId: IFlowField["id"]) => {
    const formInputs = buildFormInputs([
      { id: "review", metadata: { flowInputType: "radio_group" }, value: fieldId },
    ]);

    form.initializeFormState(formInputs);

    onSubmit(undefined, formInputs);
  };

  useEffect(() => {
    const formInputs = buildFormInputs([
      { id: "review", metadata: { flowInputType: "radio_group" }, value: "confirm" },
    ]);
    form.initializeFormState(formInputs);
  }, [formId, questions]); //eslint-disable-line react-hooks/exhaustive-deps

  const downloadCtaClasses = classNames("flow-review__policy-download", {
    "flow-review__policy-download--disabled": isGettingApplicationDocumentUrl,
  });

  if (!questions || isEmpty(inputs)) {
    return null;
  }

  return (
    <form className="flow__question-form-container" noValidate onSubmit={onSubmit}>
      <Text className="flow-review__title" tag="h4" text={title} />

      <div className="flow-review__questions">
        {questions.map(question => {
          return (
            <FlowReviewQuestion
              className="flow-review__question"
              fields={question.fields}
              isEditDisabled={form.loading}
              onEditField={handleEditField}
              key={question.id}
              prompt={question.prompt}
            />
          );
        })}
      </div>

      <Text
        className={downloadCtaClasses}
        onClick={handleDownloadApplication}
        tag="p3"
        text={t("insurance.application_review.button.download", "Download your application")}
      />

      <Text className="flow-review__terms" tag="p3" text={prompt} />

      <Button
        className="flow__submit-button two-col-layout__next"
        dataTestId="flow-review__cta"
        disabled={form.loading}
        isLoading={form.loading}
        subtype="primary"
        text={t("insurance.application_review.cta", "Submit")}
        type="submit"
      />
    </form>
  );
};

export default FlowReview;
