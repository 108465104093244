import { FC } from "react";

import { RootState } from "store";
import { isIdentifiedToken } from "utils/auth";

import { isEqual } from "lodash-es";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";

const locationHelper = locationHelperBuilder({});

const authenticatingSelector = (store: RootState) => {
  const { authentication } = store;
  return authentication.authenticating;
};

export const authenticated = (store: RootState) => {
  const { authentication } = store;
  const isIdentified = isIdentifiedToken();

  return isEqual(authentication.authenticated, true) || isIdentified;
};
export const notAuthenticated = (store: RootState) => {
  const { authentication } = store;
  return (
    isEqual(authentication.authenticated, false) || isEqual(authentication.authenticated, null)
  );
  // return isEqual(authentication.authenticated, false);
};

const LoadingContainer: FC = () => {
  const text = "LOADING";
  return <h1>{text}</h1>;
};

export const UserIsAuthenticated = connectedRouterRedirect({
  authenticatingSelector,
  wrapperDisplayName: "UserIsAuthenticated",
  AuthenticatingComponent: LoadingContainer,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/products/insurance/welcome",
  authenticatedSelector: authenticated,
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  authenticatingSelector,
  wrapperDisplayName: "UserIsNotAuthenticated",
  AuthenticatingComponent: LoadingContainer,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || "/",
  authenticatedSelector: notAuthenticated,
});
