import "./SidebarMenuLayout.scss";

import { FC } from "react";

import Menu from "components/Menu/Menu";
import { IMenuItem } from "config/types";
import { ILayoutProps } from "modules";

import classNames from "classnames";

type ContentType = {
  menuItems: IMenuItem[];
};

const SidebarMenuLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const menuItems = content.menuItems;

  const classes = classNames("sidebar-layout", className);

  return (
    <div className={classes} data-testid="sidebar-menu">
      <div className="sidebar-layout__menu-container appear-hidden appear-initial@medium">
        <Menu className="sidebar-layout__menu" menuItems={menuItems} active={true} />
      </div>
      <div className="sidebar-layout__content">{children}</div>
    </div>
  );
};

export default SidebarMenuLayout;
