import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { getDevToolsConfig } from "config";

import GoogleTagManager from "@redux-beacon/google-tag-manager";
import { configureStore } from "@reduxjs/toolkit";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { AnyAction } from "redux";
import { createMiddleware } from "redux-beacon";
import { ThunkAction } from "redux-thunk";

import createRootReducer from "./reducers";
import { eventsMap } from "../analytics/eventsMap";

export const history = createBrowserHistory();

const gtm = GoogleTagManager();
const gtmMiddleware = createMiddleware(eventsMap, gtm);

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware()
    .concat(routerMiddleware(history))
    .concat(gtmMiddleware);

const devTools = getDevToolsConfig();

export const reducer = createRootReducer(history);
const store = configureStore({
  reducer,
  middleware,
  devTools,
});

export type RootState = ReturnType<typeof reducer>;
export type AppThunk<T = AnyAction> = ThunkAction<
  Promise<AnyAction> | T,
  RootState,
  null,
  AnyAction
>;
export type AppDispatch = typeof store.dispatch;
export type Store = typeof store;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
