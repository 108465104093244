import "./Loader.scss";

import { CSSProperties, FC } from "react";

import { ClassName } from "config/types";

import classNames from "classnames";
import { get } from "lodash-es";

interface IProps {
  style?: CSSProperties;
  className?: ClassName;
  size?: "sm" | "md" | "lg";
  centered?: boolean;
  inline?: boolean;
  fullScreen?: boolean;
}

const Loader: FC<IProps> = props => {
  const { style, className, centered, inline, fullScreen } = props;
  const size = get(props, "size", "sm");

  const loaderContainerClasses = classNames("loader-container", className, {
    "loader-container--centered": centered,
    "loader-container--fullscreen": fullScreen,
    "loader--inline": inline,
  });

  const loaderClasses = classNames("loader", className, {
    [`loader--${size}`]: size,
  });

  return (
    <div className={loaderContainerClasses}>
      <div style={style} className={loaderClasses} />
    </div>
  );
};

export default Loader;
