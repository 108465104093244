import "./Features.scss";

import BlobExtend from "assets/icons/BlobExtend/BlobExtend.svg";
import BlobPayLess from "assets/icons/BlobPayLess/BlobPayLess.svg";
import BenefitPeace from "assets/images/ads/BenefitPeace.svg";
import FYoung from "assets/images/ads/FYoung.svg";
import Text from "components/Text/Text";
import { useTranslation } from "modules";

const Features = () => {
  const { t } = useTranslation();

  const features = [
    {
      id: "1",
      title: t("ad.about.feature.1.title", "ACCIDENT PROTECTION"),
      info: t(
        "ad.about.feature.1.info",
        "Financial support for your loved ones if you die in a covered accident."
      ),
      icon: BlobPayLess,
    },
    {
      id: "2",
      title: t("ad.about.feature.2.title", "PRE-QUALIFICATION"),
      info: t(
        "ad.about.feature.2.info",
        "You’re already approved! Just don’t let your quote expire:)"
      ),
      icon: BenefitPeace,
    },
    {
      id: "3",
      title: t("ad.about.feature.3.title", "FLEXIBLE COVERAGE UP TO $1,000,000"),
      info: t(
        "ad.about.feature.3.info",
        "Life changes all the time. Wysh coverage can change with it."
      ),
      icon: FYoung,
    },
    {
      id: "4",
      title: t("ad.about.feature.4.title", "LOCKED IN RATE UNTIL 65 Y/O"),
      info: t(
        "ad.about.feature.4.info",
        "Your rate will stay the same as long as you keep your policy active."
      ),
      icon: BlobExtend,
    },
  ];
  return (
    <div className="offer-card__container ad-features-card">
      <div className="ad-features-card__features">
        {features.map(feature => {
          return (
            <div className="ad-features-card__feature" key={feature.id}>
              <img className="ad-features-card__icon" alt="" src={feature.icon} />
              <Text className="ad-features-card__title" tag="c3" text={feature.title} />
              <Text className="ad-features-card__info" tag="p5" text={feature.info} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
