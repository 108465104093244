import { ICase } from "config/types";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getCases, setCases } from "store/actions/cases";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";

/* ------------    TYPES    ------------------ */
export interface ICasesState {
  cases: ICase[];
  latestCase: ICase;
}

const defaultState: ICasesState = {
  cases: [],
  latestCase: {
    id: "",
    result: "",
    externalId: "",
    createdAt: "",
  },
};

/* ------------    ADD MATCHERS     ------------------ */
const setCasesMatcher = isAnyOf(setCases, getCases.fulfilled, getVerifiedInitialData.fulfilled);

/* ------------    REDUCER     ------------------ */
const cases = createReducer(defaultState, builder => {
  builder
    .addCase(setCases, (state, action) => {
      const casesPayload = action.payload.cases;

      state.cases = casesPayload;
    })
    .addCase(getCases.fulfilled, (state, action) => {
      const casesPayload = action.payload || [];

      state.cases = casesPayload;
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const accountPayload = action.payload.account;
      const casesPayload = accountPayload?.cases || [];

      state.cases = casesPayload;
    })
    .addCase(logout, () => defaultState)
    .addMatcher(setCasesMatcher, state => {
      const latestCase = state.cases.reduce(
        (prev, curr) => (prev.createdAt > curr.createdAt ? prev : curr),
        state.latestCase
      );

      state.latestCase = latestCase;
    });
});

export default cases;
