import "./TestModal.scss";

import { FC } from "react";
import { useDispatch } from "react-redux";

import Button from "components/Button/Button";
import Text from "components/Text/Text";
import Modal from "containers/Shared/Modal/Modal";
import { closeModal } from "store/actions/modal";

const TestModal: FC = () => {
  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());

  return (
    <Modal className="test-modal" type="default">
      <div className="test-modal-container">
        <Text tag="h3" text="Do you need a little help?" />
        <Text
          tag="p1"
          text="We’ve noticed you struggling a bit. It’s easy to reset your password!"
        />
        <div className="test-modal__btns">
          <Button subtype="primary" text="What is a active CTA" />
          <Button
            subtype="text-dark"
            text="No thanks, i’ll try again"
            onClick={closeModalWrapper}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TestModal;
