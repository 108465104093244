import { IWyshCategory, WyshLiteType } from "config/types/wysh.types";
import { logout } from "store/actions/authentication";
import { getWyshesCategories } from "store/actions/wyshes";
import { findWyshIconUrls } from "utils/findIconUrl";

import { createReducer } from "@reduxjs/toolkit";
import { map, sortBy } from "lodash-es";

export interface WyshCategoryWyshes extends WyshLiteType {
  icon: string;
  infoHeader: string;
  detailsHeader: string;
  iconSelected: string;
  iconUnselected: string;
}

export interface IWyshCategoryState extends Omit<IWyshCategory, "wyshes"> {
  wyshes: WyshCategoryWyshes[];
}

interface IWyshCategoriesState {
  categories: IWyshCategoryState[];
}

const defaultState: IWyshCategoriesState = {
  categories: [],
};

const wyshCategories = createReducer(defaultState, builder => {
  builder
    .addCase(getWyshesCategories.fulfilled, (state, action) => {
      const mappedCategories = map(action.payload, category => {
        const mappedWyshes = map(category?.wyshes || [], wysh => {
          const wyshIcons = findWyshIconUrls(wysh);

          return { ...wysh, ...wyshIcons };
        });

        return { ...category, wyshes: mappedWyshes };
      });

      const sortCategories = sortBy(mappedCategories, "order");

      state.categories = sortCategories;
    })
    .addCase(logout, () => defaultState);
});

export default wyshCategories;
