import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BeneficiaryResponsibility, BusinessSlugTypes, ContactType } from "config/types";
import { useModal } from "modules";
import { getIsBeneficiaryPacketEmailEnabled } from "store/selectors";

import { replace } from "connected-react-router";

interface IHandleBeneficiaryChangeSuccessData {
  firstName: string;
  id: string;
  isEmailDirty?: boolean;
}

interface IUseBeneficiaryChangeSuccess {
  action: "create" | "edit";
  lineOfBusiness: BusinessSlugTypes;
  responsibility: BeneficiaryResponsibility;
  type: ContactType;
}

export const useBeneficiaryChangeSuccess = (options: IUseBeneficiaryChangeSuccess) => {
  const { action, lineOfBusiness, responsibility, type } = options;
  const isBeneficiaryPacketEmailEnabled = useSelector(getIsBeneficiaryPacketEmailEnabled);

  const dispatch = useDispatch();

  const redirectPath =
    action === "create" && responsibility !== "backup"
      ? `/beneficiary/allocation/${lineOfBusiness}/create`
      : `/beneficiary/${lineOfBusiness}`;

  const openModal = useModal({
    onClose: () => dispatch(replace(redirectPath)),
  });

  const handleBeneficiaryChangeSuccess = useCallback(
    (data: IHandleBeneficiaryChangeSuccessData) => {
      const { firstName, id, isEmailDirty } = data;

      /**
       * Only show the Beneficiary Packet Modal for:
       *   - Users who have the Beneficiary Packet Email feature flag turned on
       *   - Insurance policyholders (i.e. non-Savings+ users)
       *   - Individual, non-minor beneficiaries
       *   - If creating a beneficiary or editing an existing beneficiary's
       *     email address
       */
      const canSendBeneficiaryPacket =
        isBeneficiaryPacketEmailEnabled &&
        lineOfBusiness === "wysh-insurance" &&
        type === "individual" &&
        (action === "create" || (action === "edit" && isEmailDirty));

      if (canSendBeneficiaryPacket) {
        openModal("SendBeneficiaryPacketModal", "", {
          beneficiaryFirstName: firstName,
          beneficiaryId: id,
        });
      } else {
        openModal("Success");
      }
    },
    [action, isBeneficiaryPacketEmailEnabled, lineOfBusiness, openModal, type]
  );

  return { handleBeneficiaryChangeSuccess };
};
