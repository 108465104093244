import { RootState } from "store";
import * as actionType from "store/constants";

import { createAction } from "@reduxjs/toolkit";

import { getDefaultAttrs } from "../defaults";

// Analytic events for Savings+ funding

/* ------------------------    EVENT ACTION     ------------------------------ */
export const initialDepositLinkBankEvent = createAction(
  actionType.OB_INITIALDEPOSIT_LINKBANKACCOUNT_CLICK
);

export const initialDepositPageEvent = createAction(actionType.OB_INITIALDEPOSIT_FUNDING_PAGEVIEW);

export const initialDepositEditBankEvent = createAction(
  actionType.OB_INITIALDEPOSIT_FUNDING_EDIT_BANK
);

export const initialDepositCompleteEvent = createAction<{ depositAmount: number }>(
  actionType.OB_INITIALDEPOSIT_FUNDING_COMPLETED
);

export const initialDepositMaxErrorEvent = createAction(
  actionType.OB_INITIALDEPOSIT_MAX_DEPOSIT_ERROR
);

export const initialDepositFundingMismatchEvent = createAction(
  actionType.OB_INITIALDEPOSIT_FUNDING_MISMATCH
);

export const initialFundingSkippedEvent = createAction(
  actionType.OB_INITIALDEPOSIT_FUNDING_SKIP_CLICKED
);

export const initialDepositSkippedEvent = createAction(
  actionType.OB_INITIALDEPOSIT_DEPOSIT_SKIP_CLICKED
);

export const autoDepositSetupClickEvent = createAction(actionType.OB_AUTODEPOSIT_INTRO_SETUP_CLICK);

export const autoDepositSkipClickEvent = createAction(actionType.OB_AUTODEPOSIT_INTRO_SKIP_CLICK);

export const autoDepositCompletedEvent = createAction<{
  autodepositAmount: number;
  autodepositDate: string;
}>(actionType.OB_AUTODEPOSIT_FUNDING_COMPLETED);

export const autoDepositEditClickEvent = createAction(actionType.OB_AUTODEPOSIT_EDIT_CLICK);

export const autoDepositRemoveEvent = createAction(actionType.OB_AUTODEPOSIT_REMOVE);

export const autoDepositCancelEvent = createAction(actionType.OB_AUTODEPOSIT_SETUP_CANCEL);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const getSavingsFundingEventDefinition = (eventName: string) => {
  return (action: { [key: string]: any }, prevState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "FUNDING");
    const payload = action.payload || {};

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        ...payload,
      },
    };
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const savingsFundingEventsMap = {
  [actionType.OB_INITIALDEPOSIT_LINKBANKACCOUNT_CLICK]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_linkbankaccount_click"
  ),
  [actionType.OB_INITIALDEPOSIT_FUNDING_PAGEVIEW]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_funding_pageview"
  ),
  [actionType.OB_INITIALDEPOSIT_FUNDING_EDIT_BANK]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_funding_edit_bank"
  ),
  [actionType.OB_INITIALDEPOSIT_FUNDING_COMPLETED]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_funding_completed"
  ),
  [actionType.OB_INITIALDEPOSIT_MAX_DEPOSIT_ERROR]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_max_deposit_error"
  ),
  [actionType.OB_INITIALDEPOSIT_FUNDING_MISMATCH]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_funding_mismatch"
  ),
  [actionType.OB_INITIALDEPOSIT_FUNDING_SKIP_CLICKED]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_funding_skip_clicked"
  ),
  [actionType.OB_INITIALDEPOSIT_DEPOSIT_SKIP_CLICKED]: getSavingsFundingEventDefinition(
    "ob_initialdeposit_deposit_skip_clicked"
  ),
  [actionType.OB_AUTODEPOSIT_INTRO_SETUP_CLICK]: getSavingsFundingEventDefinition(
    "ob_autodeposit_intro_setup_click"
  ),
  [actionType.OB_AUTODEPOSIT_INTRO_SKIP_CLICK]: getSavingsFundingEventDefinition(
    "ob_autodeposit_intro_skip_click"
  ),
  [actionType.OB_AUTODEPOSIT_FUNDING_COMPLETED]: getSavingsFundingEventDefinition(
    "ob_autodeposit_funding_completed"
  ),
  [actionType.OB_AUTODEPOSIT_EDIT_CLICK]: getSavingsFundingEventDefinition(
    "ob_autodeposit_edit_click"
  ),
  [actionType.OB_AUTODEPOSIT_REMOVE]: getSavingsFundingEventDefinition("ob_autodeposit_remove"),
  [actionType.OB_AUTODEPOSIT_SETUP_CANCEL]: getSavingsFundingEventDefinition(
    "ob_autodeposit_setup_cancel"
  ),
};
