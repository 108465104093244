import "./HeaderLogo.scss";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PlusSrc from "assets/images/cobrands/plus.svg";
import WyshboxLarge from "assets/logos/wyshbox_logo_lg.svg";
import WyshboxSmall from "assets/logos/wyshbox_logo_sm.svg";
import Icon from "components/Icon/Icon";
import { ClassName, WyshboxURL } from "config";
import { useCoBranding } from "modules";
import { getCanSeeDashboard } from "store/selectors";

import classNames from "classnames";

interface IProps {
  className?: ClassName;
}

function HeaderLogo({ className }: IProps) {
  const canSeeDashboard = useSelector(getCanSeeDashboard);
  const { coBrandMetadata, isCoBranded } = useCoBranding();

  return (
    <div className={classNames("header-logo", className)}>
      <Link
        target={canSeeDashboard ? "_self" : "_blank"}
        to={{ pathname: canSeeDashboard ? "/" : WyshboxURL }}
        rel="noopener noreferrer">
        <Icon className="header-logo__image header-logo__image--sm" src={WyshboxSmall} />
        <Icon className="header-logo__image header-logo__image--lg" src={WyshboxLarge} />
      </Link>

      {isCoBranded && (
        <div className="header-logo__co-brand">
          <img alt="plus" src={PlusSrc} />
          <img
            alt={coBrandMetadata?.name}
            className="header-logo__co-brand-logo"
            src={coBrandMetadata?.logoSrc}
          />
        </div>
      )}
    </div>
  );
}

export default HeaderLogo;
