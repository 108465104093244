import { useSelector } from "react-redux";
import { matchPath } from "react-router";

import { useCondition, useJourney } from "modules";
import { RootState } from "store";
import { getIsIdentified } from "store/selectors";

import { isEqual, some } from "lodash-es";

export const useImmersiveMode = () => {
  const currentRoute = useSelector((state: RootState) => state.router.location.pathname);
  const accountStatus = useSelector((state: RootState) => state.account);
  const { hasActiveJourney } = useCondition({ hasActiveJourney: "journey.hasActiveJourney" });
  const sessionVerified = useSelector((state: RootState) => state.authentication.sessionVerified);
  const isIdentified = useSelector(getIsIdentified);

  const { currentStep } = useJourney();

  const routesToHide = [
    "/create-new-password",
    "/link-expired",
    "/login",
    "/new-device",
    "/wysh-builder/list",
    "/wysh-builder/:category?",
    "/wysh-builder/:wyshId?/info",
    "/wysh-builder/:wyshId?/add/:field?/:allocationId?",
    "/wyshes/:allocationId/edit/:field?",
    "/beneficiary/select-type/:lineOfBusiness/:responsibility",
    "/forgot-password",
    "/disclosure/:slug",
    "/packages-preview",
    "/coverage-preview",
    "/edit-coverage/:type",
    "/flow/:slug",
    "/beneficiary/:lineOfBusiness",
    "/ineligible/:slug",
    "/products/insurance/billing/:edit?",
    "/products/insurance/get-wysh-estimate",
    "/products/insurance/welcome",
    "/products/insurance/wysh-builder/list",
    "/products/savings/welcome",
    "/products/savings/blocked",
    "/products/savings/create-account",
    "/products/savings/close-account/:param?",
    "/products/savings/coming-soon",
    "/products/savings/deposit/:param?",
    "/products/savings/deposit/auto/:param?",
    "/products/savings/deposit/micro/:param?",
    "/products/savings/application-summary",
    "/products/savings/micro-deposits/:param?",
    "/products/savings/verification/:result",
    "/products/savings/verification/:result/:param",
    "/products/savings/transfer/:action?/:param?",
    "/products/savings/available-funds",
    "/products/savings/death-payout",
    "/products/savings/lifetime-interest",
    "/products/savings/linked-account/:param?",
    "/products/savings/incoming-funds",
    "/products/savings/account-details",
    "/products/savings/insurance-payout-details",
    "/products/savings/life-benefit-coverage",
    "/products/savings/transfer/:action?/:scheduledTransferId?/:details?",
    "/products/savings/transactions/:transactionDate?/:details?",
  ];

  const isOnFlow = isEqual(currentStep?.type, "flow");
  const isOnRouteToHide = some(routesToHide, (path: string) =>
    matchPath(currentRoute, { path, exact: true })
  );

  const isSessionVerifyMode = !sessionVerified && !isIdentified;

  const immersiveConditions = [
    isOnRouteToHide,
    accountStatus.locked,
    accountStatus.fraudulentDevice,
    isOnFlow,
    hasActiveJourney,
    isSessionVerifyMode,
  ];
  const conditionsMet = some(immersiveConditions);

  return conditionsMet;
};
