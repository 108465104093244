import { FC, useEffect } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import Modal from "containers/Shared/Modal/Modal";
import { ILayoutProps } from "modules";

import classNames from "classnames";
import { isFunction } from "lodash-es";

type ContentType = {
  header: string;
  icon?: string;
  onClose?: () => void;
  onInitialRender?: () => () => void;
};

const ModalStatusLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const header = content?.header as string;
  const onClose = content?.onClose;
  const icon = content?.icon;
  const onInitialRender = content?.onInitialRender;

  const classes = classNames("modal", className);

  useEffect(() => {
    if (isFunction(onInitialRender)) {
      const callback = onInitialRender();
      return callback;
    }
  });

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      headerProps={{ closeButton: false }}
      className={classes}
      type="status"
      onClose={onClose}>
      <div className="modal__container">
        <Icon className="modal__icon" size="lg" src={icon} />
        <Text className="modal__header" tag="c5" text={header} />
        {children}
      </div>
    </Modal>
  );
};

export default ModalStatusLayout;
