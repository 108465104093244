import Text from "components/Text/Text";
import VariableText from "components/VariableText/VariableText";
import { useTranslation } from "modules";
import formatCommas from "utils/formatCommas";

import { IPaymentAmountData } from "./ReinstateablePolicyCoverages";

interface IReinstateablePolicyLegalese {
  dueToday: IPaymentAmountData["dueToday"];
  monthlyPremium: IPaymentAmountData["monthlyPremium"];
  policyDocHref?: string;
  soghDocHref?: string;
}

function ReinstateablePolicyLegalese(props: IReinstateablePolicyLegalese) {
  const { dueToday, monthlyPremium, policyDocHref, soghDocHref } = props;
  const { t } = useTranslation();

  return (
    <ol>
      <li className="purchase-policy__legalese-item">
        <VariableText
          replacementElement="strong"
          target={/<strong>(.+)<\/strong>/gi}
          text={t(
            "purchase_policy.resinstatement_legalese.bullets.item1.html",
            "Allow Wysh to charge $%<due_today>s today, and $%<premium>s each month for the term of your policy. <strong>You may cancel anytime.</strong>",
            {
              due_today: formatCommas(dueToday),
              premium: formatCommas(monthlyPremium),
            }
          )}
        />
      </li>

      {soghDocHref && (
        <>
          <li className="purchase-policy__legalese-item purchase-policy__legalese-item--sogh">
            <VariableText
              replacementElement="strong"
              target={/<strong>(.+)<\/strong>/gi}
              text={t(
                "purchase_policy.legalese.bullets.item2.html",
                "Accept and electronically sign the <strong>Statement of Good Health</strong>, and all terms and conditions contained therein."
              )}
            />
            <a
              className="purchase-policy__sogh-link link-text"
              href={soghDocHref}
              rel="noreferrer"
              target="_blank">
              <Text
                tag="l4"
                text={t("purchase_policy.document.good_health.cta", "See Statement of Good Health")}
              />
            </a>
          </li>
        </>
      )}

      <li className="purchase-policy__legalese-item">
        <VariableText
          replacementElement="strong"
          target={/<strong>(.+)<\/strong>/gi}
          text={t(
            "purchase_policy.legalese.bullets.item3.html",
            "Accept the <strong>%<product_type>s</strong> and all terms and conditions contained therein.",
            {
              product_type: t("general.product_type.term", "Individual Term Life Insurance Policy"),
            }
          )}
        />
        <a
          className="purchase-policy__policy-link link-text"
          href={policyDocHref}
          rel="noreferrer"
          target="_blank">
          <Text
            tag="l4"
            text={t("purchase_policy.document.policy_packet.cta", "See Policy Packet")}
          />
        </a>
      </li>
    </ol>
  );
}

export default ReinstateablePolicyLegalese;
