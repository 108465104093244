import { IFinanceProgramDetails } from "config";
import { getFinanceProgramDetails } from "store/actions/financeProgramDetails";

import { createReducer } from "@reduxjs/toolkit";

const defaultState: IFinanceProgramDetails = {
  apy: 0,
  apyLastUpdatedAt: "",
  lifeBenefitPercentage: 0,
  lifeBenefitMaximumCoverage: 0,
  nationalAverageMultiplier: 0,
  nationalAverageMultiplierLastUpdatedAt: "",
  updatedAt: "",
};

const financeProgramDetails = createReducer(defaultState, builder => {
  builder.addCase(getFinanceProgramDetails.fulfilled, (state, action) => {
    state.apy = action.payload.apy;
    state.apyLastUpdatedAt = action.payload.apyLastUpdatedAt;
    state.lifeBenefitMaximumCoverage = action.payload.lifeBenefitMaximumCoverage;
    state.lifeBenefitPercentage = action.payload.lifeBenefitPercentage;
    state.nationalAverageMultiplier = action.payload.nationalAverageMultiplier;
    state.nationalAverageMultiplierLastUpdatedAt =
      action.payload.nationalAverageMultiplierLastUpdatedAt;
    state.updatedAt = action.payload.updatedAt;
  });
});

export default financeProgramDetails;
