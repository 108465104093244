import { RefObject } from "react";

import { animate, AnimationOptions } from "framer-motion";

interface Props {
  // The duration of the scroll animation.
  duration?: number;
  // The offset from the top of the target element to scroll to.
  offset?: number;
}

export const useScroll = (props?: Props) => {
  const { duration = 1, offset = 0 } = props || {};

  const scrollToTarget = async (
    targetRef: RefObject<HTMLElement>,
    options?: AnimationOptions<number>
  ) => {
    return new Promise<boolean>(resolve => {
      const target = targetRef.current;
      if (!target) {
        resolve(false);
        return;
      }
      const targetOffsetTop = target.getBoundingClientRect().top + window.scrollY;

      animate(window.scrollY, targetOffsetTop + offset, {
        duration: duration,
        ...options,
        onUpdate: value => window.scrollTo(0, value),
        onComplete: () => resolve(true),
      });
    });
  };

  const scrollToTop = async (options?: AnimationOptions<number>) => {
    const targetRef: RefObject<HTMLElement> = { current: document.body };
    return scrollToTarget(targetRef, options);
  };

  return { scrollToTarget, scrollToTop };
};
