import { RootState } from "store";
import formatCommas from "utils/formatCommas";
import { formatCurrencyCompactAmount } from "utils/formatCurrencyAmount";

import { createSelector } from "@reduxjs/toolkit";

const getFinanceProgramDetails = (state: RootState) => state.financeProgramDetails;

export const selectFinanceProgramDetails = createSelector(
  getFinanceProgramDetails,
  financeProgramDetails => {
    return {
      ...financeProgramDetails,
      formattedLifeBenefitMaximumCoverage: {
        amount: `$${formatCommas(financeProgramDetails.lifeBenefitMaximumCoverage)}`,
        shortAmount: `$${formatCurrencyCompactAmount(
          financeProgramDetails.lifeBenefitMaximumCoverage
        )}`,
      },
    };
  }
);
