import { FC } from "react";

import { IButtonAction } from "config";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { useButtonAction } from "containers/DynamicForm/useButtonAction";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";

const ExitWithoutsavingModal: FC<IModalProps> = ({ closeModal, context, data }) => {
  const { t } = useTranslation();
  const dynamicFormButtonItemActions = (data?.actions || []) as IButtonAction[];
  const executeButtonItemActions = useButtonAction(dynamicFormButtonItemActions);

  const onClickConfirm = () => {
    if (context?.callbacks?.onDiscardChanges) {
      context?.callbacks?.onDiscardChanges();
    }

    executeButtonItemActions();

    closeModal();
  };

  const onClickDecline = () => {
    closeModal();
  };

  const content = {
    header: t("settings.profile.edit.save_modal.title", "Exit without saving changes?"),
    onClose: closeModal,
    button: [
      {
        text: t("settings.profile.edit.save_modal.button.yes", "YES"),
        subType: "primary",
        onClick: onClickConfirm,
      },
      {
        text: t("settings.profile.edit.save_modal.button.no", "NO"),
        subType: "text-dark-border",
        onClick: onClickDecline,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobAlert",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default ExitWithoutsavingModal;
