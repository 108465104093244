import { IDocument, IUser } from "config/types";
import { getDocuments } from "store/actions/acknowledgement";
import { getPreVerifiedInitialData, getVerifiedInitialData } from "store/actions/appLoad";
import { authorizeUnidentifiedUser, logout } from "store/actions/authentication";
import { createIdentifiedUser, getCurrentUser } from "store/actions/user";
import { getActiveAccount } from "utils/account";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { uniqBy } from "lodash-es";

export interface IUserState extends IUser {
  documents: IDocument[];
}

/* ------------    DEFAULT USER STATE     ------------------ */
const defaultState: IUserState = {
  id: "",
  birthdate: "",
  age: 0,
  state: {
    id: "",
    name: "",
    alpha2: "",
  },
  status: "",
  roles: [],
  activeConstellations: [],
  notifications: [],
  documents: [],
};

/* ------------    MATCHERS     ------------------ */
const isMeQueryFulfilled = isAnyOf(
  getCurrentUser.fulfilled,
  getPreVerifiedInitialData.fulfilled,
  getVerifiedInitialData.fulfilled
);

/* ------------    REDUCER     ------------------ */
const user = createReducer(defaultState, builder => {
  builder
    .addCase(authorizeUnidentifiedUser.fulfilled, (state, action) => {
      const userData = action.payload.user;

      state.id = userData?.id || state.id;
      state.birthdate = userData?.birthdate || state.birthdate;
      state.age = userData?.age || state.age;
      state.state = userData?.state || state.state;
      state.status = userData?.status || state.status;
      state.roles = userData?.roles || state.roles;
      state.activeConstellations = userData?.activeConstellations || state.activeConstellations;
      state.notifications = userData?.notifications || state.notifications;
    })
    .addCase(createIdentifiedUser.fulfilled, (state, action) => {
      const userData = action.payload;

      state.id = userData?.id || state.id;
      state.status = userData?.status || state.status;
      state.activeConstellations = userData?.activeConstellations || state.activeConstellations;
    })
    .addCase(getDocuments.fulfilled, (state, action) => {
      const activeAccount = getActiveAccount(action.payload);
      const documents = activeAccount.documents || [];
      const policyDocuments = activeAccount.activePolicy?.documents || [];
      const mergeDocuments = uniqBy([...documents, ...policyDocuments], "slug");

      state.documents = mergeDocuments;
    })
    .addCase(logout, () => defaultState)
    .addMatcher(isMeQueryFulfilled, (state, action) => {
      const userData = action.payload.me;

      state.id = userData?.id || state.id;
      state.birthdate = userData?.birthdate || state.birthdate;
      state.age = userData?.age || state.age;
      state.state = userData?.state || state.state;
      state.status = userData?.status || state.status;
      state.roles = userData?.roles || state.roles;
      state.activeConstellations = userData?.activeConstellations || state.activeConstellations;
      state.notifications = userData?.notifications || state.notifications;
    });
});

export default user;
