import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";
import { find } from "lodash-es";

const selectDisclosures = (state: RootState) => state.disclosures;
const selectQuote = (state: RootState) => state.quote;

// Select documents and disclosures for PurchasePolicy screen acknowledgements
export const selectReviewDisclosures = createSelector(
  selectDisclosures,
  selectQuote,
  (disclosures, quote) => {
    const reviewDisclosures = [];
    const isAD = quote.productDetail.slug === "wysh-accidental-death";
    const adDisclosure = find(disclosures, { slug: "replace-accidental-death" });
    const quoteDocument = find(quote.documents, { slug: "quote" });

    if (quoteDocument) {
      reviewDisclosures.push(quoteDocument);
    }

    // if quote.slug is "wysh-accidental-death", return disclosure "replace-accidental-death"
    if (isAD && adDisclosure) {
      reviewDisclosures.push(adDisclosure);
    }

    return reviewDisclosures;
  }
);
