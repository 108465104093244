import { UnderwritingModalTypes } from "config";
import { RootState } from "store";
import { IEventFiredPayload } from "store/actions/analytics";
import * as actionType from "store/constants";

import { createAction } from "@reduxjs/toolkit";
import { EventDefinition } from "redux-beacon";

import { getDefaultAttrs } from "./defaults";

/* ------------------------    EVENT ACTION     ------------------------------ */

// The initial screen telling the user they fell within manual UW
export const manualUWStartEvent = createAction(actionType.MUW_START);

// Clicking on the FAQ link in the body of the screen (not header)
export const manualUWFAQEvent = createAction(actionType.MUW_FAQ_CLICK);

// Click on link to the dashboard from the body screen
export const manualUWDashboardEvent = createAction(actionType.MUW_DASHBOARD_CLICK);

// Anytime a user comes back and sees one of the modals, this should fire on its display.
// The attribute should be the name of the modal which includes the info, accepted, rejected and any future ones we might add here.
export const manualUWModalDisplayEvent = createAction<UnderwritingModalTypes>(
  actionType.MUW_MODAL_DISPLAY
);

// Once they've been approved, there's a new screen to confirm details. This should fire when that loads.
export const manualUWConfirmDisplayEvent = createAction(actionType.MUW_CONFIRM_DISPLAY);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const manualUWStartEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "MANUALUW");

  return {
    event: "fulfillment_manual_start",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const manualUWFAQEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "MANUALUW");

  return {
    event: "fulfillment_manual_faq_click",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const manualUWDashboardEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "MANUALUW");

  return {
    event: "fulfillment_manual_dash_click",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const manualUWModalDisplayEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: UnderwritingModalTypes },
  RootState
> = (action, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "MANUALUW");
  const modalName = action.payload;

  return {
    event: "fulfillment_manual_modal_display",
    attribute: {
      ...defaultAttrs,
      modalName,
    },
  };
};

const manualUWConfirmDisplayEventDefinition: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "MANUALUW");

  return {
    event: "fulfillment_manual_confirm_display",
    attribute: {
      ...defaultAttrs,
    },
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const underwritingEventsMap = {
  [actionType.MUW_START]: manualUWStartEventDefinition,
  [actionType.MUW_FAQ_CLICK]: manualUWFAQEventDefinition,
  [actionType.MUW_DASHBOARD_CLICK]: manualUWDashboardEventDefinition,
  [actionType.MUW_MODAL_DISPLAY]: manualUWModalDisplayEventDefinition,
  [actionType.MUW_CONFIRM_DISPLAY]: manualUWConfirmDisplayEventDefinition,
};
