import "./ArrowButton.scss";

import { FC, MouseEvent } from "react";

import Icon from "components/Icon/Icon";
import { ClassName } from "config";

import c from "classnames";

interface ICarouselArrowProps {
  className?: ClassName;
  inactive?: boolean;
  onClick?: (event: MouseEvent) => void;
}

export const ArrowButton: FC<ICarouselArrowProps> = props => {
  const { inactive, className, onClick } = props;
  const classes = c("carousel__arrow", className, {
    inactive: inactive,
  });

  return (
    <div className={classes} onClick={onClick}>
      <Icon className="slider-arrow__icon" src="BackArrow" size="xs" />
    </div>
  );
};
