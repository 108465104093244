import { useContext } from "react";

import { TApiButtonCommandFn } from ".";

import { destroyWyshAllocation } from "store/actions/wyshes";
import { useAppSelector, useAppDispatch } from "store/index";

import { find } from "lodash-es";

import { DynamicFormContext } from "./../DynamicFormContext";

export const useCheckWyshAllocations = () => {
  const dispatch = useAppDispatch();
  const { activeCount } = useAppSelector(state => state.allocationSummary);
  const allocated = useAppSelector(state => state.wyshAllocations.allocated);
  const { inputs } = useContext(DynamicFormContext);

  const checkWyshllocationsCommand: TApiButtonCommandFn = async commandArgs => {
    const commandArgument = commandArgs && commandArgs[0];

    const coverageInput = find(inputs, { key: commandArgument?.source });

    if (activeCount > 0 && Number.isFinite(parseInt(coverageInput?.value, 10))) {
      const allocations = allocated.map(allocation =>
        dispatch(destroyWyshAllocation(allocation.id))
      );

      await Promise.all(allocations);
    }
  };

  return checkWyshllocationsCommand;
};
