import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TBeneficiariesContext } from "config";
import { getBeneficiaries } from "store/actions/beneficiaries";
import { selectBeneficiariesByLineOfBusiness, useParamSelector } from "store/selectors";
import { getThunkError } from "utils/error";

import { defaultTo } from "lodash-es";

export const useBeneficiariesData = (
  refresh = false,
  lineOfBusinessOrAssignedEntity: TBeneficiariesContext = "wysh-insurance"
) => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(refresh ? true : false);
  const [error, setError] = useState("");

  const { backup, beneficiaries } = useParamSelector(
    selectBeneficiariesByLineOfBusiness,
    lineOfBusinessOrAssignedEntity
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getBeneficiaries());

        setPending(false);
        const error = getThunkError(response);
        if (error) {
          throw error;
        }
      } catch (error) {
        setError(defaultTo(error.fullMessage, error.code));
      }
    };

    fetchData();
  }, [dispatch, refresh]);

  return {
    beneficiaries,
    backup,
    beneficiariesPending: pending,
    beneficiariesError: error,
  };
};
