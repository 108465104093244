import "./ModalLayout.scss";

import { FC } from "react";

import Button from "components/Button/Button";
import Checkbox, { ICheckboxEvent } from "components/Checkbox/Checkbox";
import Icon from "components/Icon/Icon";
import Text, { IProps as ITextProps } from "components/Text/Text";
import { ButtonSubType, OrString, ValueOrArray } from "config/types";
import Modal from "containers/Shared/Modal/Modal";
import { ILayoutProps } from "modules";

import classNames from "classnames";
import { flatten, map } from "lodash-es";

type ButtonType = {
  subType: ButtonSubType;
  text: string;
  isLoading: boolean;
  disabled: boolean;
  onClick: () => void;
  className?: string;
  dataTestId?: string;
};

type ContentType = {
  header: string;
  subheader: ValueOrArray<ITextProps["text"]>;
  buttonText: string;
  linkButtonTo: string;
  linkButtonText: string;
  onButtonClick?: () => void;
  onClose?: () => void;
  button: ButtonType | ButtonType[];
  icon?: string;
  alwaysShowIcon?: boolean;
  checkbox?: {
    label: OrString<JSX.Element>;
    checked: boolean;
    onChange(event: ICheckboxEvent): void;
  };
};

const ModalLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const header = content?.header as string;
  const subheader = content?.subheader || [];
  const subheaderList = flatten([subheader]);
  const button = content?.button || [];
  const buttonList = flatten([button || []]);
  const onClose = content?.onClose;
  const icon = content?.icon;
  const checkbox = content?.checkbox;
  const alwaysShowIcon = content?.alwaysShowIcon;

  const classes = classNames("modal", className);
  const iconClasses = classNames("modal__icon", {
    "appear-hidden": !alwaysShowIcon,
    "appear-initial@medium": !alwaysShowIcon,
  });

  return (
    <Modal className={classes} type="default" onClose={onClose}>
      <div className="modal__container">
        <Icon className={iconClasses} size="xl" src={icon} />
        <Text className="modal__header" tag="h4" text={header} />
        {map(subheaderList, (item: ITextProps["text"], index: number) => (
          <Text className="modal__subheader" key={index} tag="p3" text={item} />
        ))}

        <div className="modal__btns">
          {checkbox && (
            <Checkbox
              value=""
              className="modal__checkbox"
              onChange={checkbox.onChange}
              checked={checkbox.checked}
              label={checkbox.label}
            />
          )}
          {map(buttonList, (item: ButtonType, index: number) => (
            <Button
              key={index}
              subtype={item.subType || "primary"}
              text={item.text}
              onClick={item.onClick}
              isLoading={item.isLoading}
              disabled={item.disabled}
              className={item.className}
              dataTestId={item.dataTestId}
            />
          ))}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalLayout;
