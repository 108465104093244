import { defaultPageTitleOptions } from "config/constants";
import { IHeader } from "config/types";

import { get } from "lodash-es";
import { AnyAction } from "redux";

import * as t from "../constants";

const defaultState: IHeader = {
  type: "default",
  title: defaultPageTitleOptions,
};

const header = (state: IHeader = defaultState, action: AnyAction) => {
  const payload = get(action, "payload");
  switch (action.type) {
    case t.SET_HEADER:
      return { ...state, ...get(payload, "header") };
    case t.RESET_MODAL:
      return { ...state, ...defaultState };
    default:
      return state;
  }
};

export default header;
