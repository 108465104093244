import "./ProgressBar.scss";
import { FC } from "react";

import classNames from "classnames";

interface IProps {
  percentage: number;
  className?: string;
  showInitialProgress?: boolean;
}

const ProgressBar: FC<IProps> = ({ className = "", percentage, showInitialProgress }) => {
  const barClasses = classNames("progress-bar", className);
  const fillClasses = classNames("progress-bar__fill", {
    "progress-bar__fill--initial-fill": showInitialProgress,
  });

  return (
    <div className={barClasses}>
      <div className={fillClasses} style={{ width: `${percentage}%` }} />
    </div>
  );
};

export default ProgressBar;
