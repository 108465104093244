import { FC, useState } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { getPlaidItems } from "store/actions/bank";
import { closeModal } from "store/actions/modal";
import { updateWyshAllocation } from "store/actions/wyshes";
import { getThunkError } from "utils/error";

const UnmonitorWyshModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wyshAllocationId = props.data.allocationId as string;

  const [loading, setLoading] = useState(false);

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const unmonitorWyshHandler = async () => {
    try {
      setLoading(true);

      const response = await dispatch(
        updateWyshAllocation({
          wyshAllocationId: wyshAllocationId,
          monitored: false,
        })
      );

      const error = getThunkError(response);
      if (error) {
        throw error;
      }

      await dispatch(getPlaidItems());
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
      closeModalWrapper();
    }
  };

  const ctaButtons = [
    {
      text: t("wysh.modal.turn_off_monitoring.button_confirm", "YES, TURN OFF"),
      isLoading: loading,
      disabled: loading,
      onClick: unmonitorWyshHandler,
    },
    {
      text: t("wysh.modal.turn_off_monitoring.button_cancel", "NEVERMIND"),
      subType: "text-dark",
      disabled: loading,
      onClick: closeModalWrapper,
    },
  ];

  const content = {
    header: t(
      "wysh.modal.turn_off_monitoring.title",
      "Are you sure you want to turn off monitoring?"
    ),
    subheader: t(
      "wysh.modal.turn_off_monitoring.body",
      "Heads up: once this is off, we won’t be able to keep an eye on your accounts."
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default UnmonitorWyshModal;
