import { ChangeEvent, FocusEvent } from "react";

import { IChangeEvent } from "components/AddressInput/AddressInput";
import { FlowClickInputTypes, FlowTextInputTypes, IFlowField } from "config";
import { useAppDispatch } from "store";
import { flowFieldByKeySetEvent, flowFieldValidationError } from "store/actions/flow";

import { includes } from "lodash-es";

// Analytic event actions triggered on set
export const useFlowFieldAnalyticEvents = () => {
  const dispatch = useAppDispatch();

  // METHODS
  const flowFieldTextTypeEvent = ({
    event,
    field,
    errorMessages = [],
  }: {
    event: FocusEvent<HTMLInputElement>;
    field: Pick<Partial<IFlowField>, "inputType" | "key">;
    errorMessages?: string[];
  }) => {
    const textInputTypes: FlowTextInputTypes[] = [
      "text",
      "date",
      "numeric",
      "slider",
      "tax_id",
      "unitized",
    ];

    const { inputType = "", key = "" } = field;

    if (errorMessages.length) {
      dispatch(flowFieldValidationError({ [key]: event.target.value }));
    } else if (includes(textInputTypes, inputType)) {
      dispatch(flowFieldByKeySetEvent({ [key]: event.target.value }));
    }
  };

  const flowFieldSelectTypeEvent = ({
    event,
    field,
  }: {
    event: ChangeEvent<HTMLInputElement> | IChangeEvent;
    field: Pick<Partial<IFlowField>, "inputType" | "key">;
  }) => {
    const clickInputTypes: FlowClickInputTypes[] = [
      "dropdown",
      "blobs",
      "multi_select",
      "radio_group",
    ];

    const { inputType = "", key = "" } = field;

    if (includes(clickInputTypes, inputType)) {
      dispatch(flowFieldByKeySetEvent({ [key]: event.target.value }));
    }
  };

  return { flowFieldTextTypeEvent, flowFieldSelectTypeEvent };
};
