import { FC } from "react";

import WyshboxLarge from "assets/logos/wyshbox_logo_lg.svg";
import WyshboxSmall from "assets/logos/wyshbox_logo_sm.svg";
import Icon from "components/Icon/Icon";
import { ClassName } from "config/types";

import classNames from "classnames";
import { defaultTo, get } from "lodash-es";

export const logoTypes = {
  default: WyshboxSmall,
  WyshboxSmall: WyshboxSmall,
  WyshboxLarge: WyshboxLarge,
};

interface IProps {
  type?: keyof typeof logoTypes;
  className?: ClassName;
}

const Logo: FC<IProps> = props => {
  const { className, type } = props;
  const logoFromTypes = get(logoTypes, type || "");
  const logoSm = defaultTo(logoFromTypes, WyshboxSmall);
  const logoLg = defaultTo(logoFromTypes, WyshboxLarge);
  const classSm = classNames("appear-hidden@medium", className);
  const classLg = classNames("appear-hidden appear-initial@medium", className);

  return (
    <>
      <Icon className={classSm} src={logoSm} />
      <Icon className={classLg} src={logoLg} />
    </>
  );
};

export default Logo;
