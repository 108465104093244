import { useContext } from "react";

import { ApiFormGroup, IApiArgument, IFormInput } from "config";
import { useAppSelector } from "store";
import { groupDetailMutationBody } from "store/actions/flow";
import { selectDynamicFormApiGroups } from "store/selectors";
import { encrypt } from "utils/encrypt";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { find, isEmpty, isFinite, isNil, reduce, toString } from "lodash-es";

import { DynamicFormContext } from "./DynamicFormContext";

export const useGroupApi = () => {
  const { inputs } = useContext(DynamicFormContext);
  const dynamicFormApiGroups = useAppSelector(selectDynamicFormApiGroups);

  // Return form input values mapped to GroupDetailApi.mutationVariables
  const mapValueToMutationVariable = (
    mutationVariables: ApiFormGroup["detail"]["mutationVariables"]
  ) => {
    const inputMappingWithFormValues = reduce(
      mutationVariables,
      (inputPayload, mutationVariable) => {
        const { name, sourceType, source } = mutationVariable;
        const formInput = find(inputs, ["key", source]);
        const payloadValue =
          sourceType === "input"
            ? sanitizeInputValue(name, formInput)
            : sanitizeByArgumentType(source, mutationVariable);

        return { ...inputPayload, ...(payloadValue !== null && { [name]: payloadValue }) };
      },
      {}
    );

    return inputMappingWithFormValues;
  };

  // Return mutationBody query wrapped in AsyncThunk function
  const createGroupApiThunk = (key: ApiFormGroup["detail"]["key"]) => {
    const foundApiGroup = dynamicFormApiGroups.find(groupDetail => groupDetail.detail.key === key);

    if (!foundApiGroup) {
      return foundApiGroup;
    }

    const inputPayload = mapValueToMutationVariable(foundApiGroup.detail.mutationVariables);
    const groupMutationThunk = groupDetailMutationBody({
      inputPayload,
      mutationBody: foundApiGroup.detail.mutationBody,
    });

    return groupMutationThunk;
  };

  return createGroupApiThunk;
};

/*
  Sanitize input values on request payload to server
  - empty input values are returned as null
  - default values by MutationPayloadName type
  - transform input values by MutationPayloadName type
 */
const sanitizeInputValue = (
  mutationPayloadName: string,
  input?: IFormInput
): IFormInput["value"] => {
  const isEmptyValue = isNil(input?.value) || (isEmpty(input?.value) && !isFinite(input?.value));

  // Default countryCode to "1" US
  if (mutationPayloadName === "countryCode") {
    return isEmptyValue ? "1" : input?.value;
  }

  // Sanitize phone number country code prefix
  if (!isEmptyValue && mutationPayloadName === "phoneNumber") {
    const parsed = parsePhoneNumberFromString(input?.value, "US");
    return parsed?.nationalNumber || input?.value;
  }

  if (mutationPayloadName === "smsMarketing") {
    return !!(input?.value ?? false);
  }

  if (!input || isEmptyValue) {
    return null;
  } else if (input?.encryptOutput) {
    const isMaskedValue = /^\*{3}-\*{2}-[0-9]{4}/.test(input.value);
    const encryptedValue = encrypt(input.value);

    return isMaskedValue ? null : encryptedValue;
  } else {
    return input.value;
  }
};

export const sanitizeByArgumentType = (value: any, { type }: IApiArgument): IFormInput["value"] => {
  switch (type) {
    case "boolean":
      if (value === "true") {
        return true;
      } else if (value === "false") {
        return false;
      } else {
        return value;
      }

    case "float":
      return parseFloat(toString(value));

    case "int":
      return parseInt(value, 10);

    case "string":
      return toString(value);

    default:
      return value;
  }
};
