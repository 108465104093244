import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ICheckboxEvent } from "components/Checkbox/Checkbox";
import FormError from "components/FormError/FormError";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { IDisclosure } from "config/types";
import { Layout, useTranslation } from "modules";
import { getDisclosure } from "store/actions/disclosure";
import { closeModal } from "store/actions/modal";
import { getThunkError } from "utils/error";

import { defaultTo, get } from "lodash-es";

const defaultDisclosure: IDisclosure = {
  id: "",
  slug: "",
  name: "",
  description: "",
  cta: "",
  version: 1,
  createdAt: "",
};

const slug = "beneficiary-terms";

const ConfirmBeneficiaryChangesModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const [disclosure, setDisclosure] = useState<IDisclosure>(defaultDisclosure);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await dispatch(getDisclosure({ slug }));
        const error = getThunkError(response);
        if (error) {
          throw error;
        }
        setError("");
        setDisclosure(get(response, "payload"));
      } catch (error) {
        setError(defaultTo(error.fullMessage, error.code));
      }
    };

    if (!disclosure.id) {
      getData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const onClickContinue = async () => {
    try {
      // TODO - integrate create acknowledgement when terms are ready
      // const response = await dispatch(
      //   createAcknowledgement({ recordId: disclosure.id, recordType: "disclosure" })
      // );
      // const error = getThunkError(response);
      // if (error) {
      //   throw error;
      // }
      props.context?.callbacks?.onConfirm();
      closeModalWrapper();
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
    }
  };

  const CheckboxLabel = () => {
    return (
      <>
        {/* TODO - render disclosure description and link, remove copy when terms are ready */}
        By checking this box, I acknowledge that I wish to make these changes to my policy
        {/* {disclosure.description}
        <DisclosureLink className="link p3-tag__text" slug="beneficiary-terms">
          {disclosure.name}
        </DisclosureLink> */}
      </>
    );
  };

  const checkbox = !!disclosure.id
    ? {
        label: <CheckboxLabel />,
        onChange: (event: ICheckboxEvent) => {
          setChecked(event.target.value);
        },
        checked,
      }
    : null;

  const content = {
    header: t("beneficiary.modal.confirm_changes.title", "Please confirm your changes"),
    onClose: closeModalWrapper,
    checkbox,
    button: [
      {
        text: t("beneficiary.modal.confirm_changes.cta.primary", "CONTINUE"),
        subType: "primary",
        onClick: onClickContinue,
        disabled: !checked,
      },
      {
        text: t("beneficiary.modal.confirm_changes.cta.secondary", "GO BACK TO EDITING"),
        subType: "text-dark",
        onClick: closeModalWrapper,
      },
    ],
  };
  return (
    <Layout as="ModalLayout" content={content}>
      {error && <FormError error={error} />}
    </Layout>
  );
};

export default ConfirmBeneficiaryChangesModal;
