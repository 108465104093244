import "./Modal.scss";

import { CSSProperties, FC, ReactElement, useCallback, useMemo } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";

import { ClassName } from "config";
import { useAppSelector } from "store";
import { closeModal } from "store/actions/modal";

import classNames from "classnames";
import { MotionConfig, Variant } from "framer-motion";

import { ModalContent } from "./ModalContent";
import { ModalOverlay } from "./ModalOverlay";

/**
 * Modal Component
 *
 * The `Modal` component provides a flexible and customizable modal dialog window
 * that can be integrated into React applications. This component leverages `ReactModal`
 * and `framer-motion` to handle modal display and animations. It is highly configurable
 * with several props for managing appearance, behavior, and transitions.
 *
 * Key Features:
 * - Configurable modal types, see `ModalType` for available options.
 * - Customizable header with optional close button and icon.
 * - Built-in support for overlay click and ESC key close actions.
 * - Framer Motion integration for smooth transition effects.
 * - Redux-based close action dispatching for global state management.
 *
 * Props:
 * - `title` (optional): The title of the modal, which can be a string or an object with `title` and `subtitle`.
 * - `headerProps` (optional): Allows customization of the modal header, such as setting an icon or close button visibility.
 * - `isOpen` (optional, default: `true`): Controls whether the modal is visible or not.
 * - `shouldCloseOnOverlayClick` (optional, default: `true`): Determines if the modal should close when the overlay is clicked.
 * - `shouldCloseOnEsc` (optional, default: `true`): Determines if the modal should close when the ESC key is pressed.
 * - `className` (optional): Adds additional CSS classes for further customization of the modal.
 * - `style` (optional): Custom CSS properties to style the modal.
 * - `type` (optional, default: `"default"`): Defines the visual style or "type" of the modal, which can alter its appearance.
 * - `transition` (optional): Controls the transition effects for showing and hiding the modal using Framer Motion's `variants` and duration.
 * - `onClose` (optional): Callback function that will be called when the modal is closed.
 *
 * Example Usage:
 *
 * ```tsx
 * <Modal
 *   title="Sample Modal"
 *   isOpen={isModalOpen}
 *   onClose={() => setModalOpen(false)}
 *   shouldCloseOnOverlayClick={false}
 *   headerProps={{ icon: "AlertIcon", closeButton: true }}
 *   type="alert"
 * >
 *   <div>Your modal content goes here</div>
 * </Modal>
 * ```
 *
 * In this example, a modal with an alert type, custom icon, and content is displayed.
 * It can be closed via the close button or programmatically via the `onClose` callback.
 */

type ModalType = "default" | "light" | "alert" | "status" | "search" | "fullscreen";

interface IHeaderProps {
  icon: string;
  className: ClassName;
  closeButton: boolean | ReactElement;
}

export interface IModalTransitionVariants {
  [key: string]: Variant;
  visible: Variant;
  hidden: Variant;
}

interface IModalTransitionProps {
  duration: number;
  variants: IModalTransitionVariants;
}

export interface IModalProps {
  title?: string | { title: string; subtitle: string };
  headerProps?: Partial<IHeaderProps>;
  isOpen?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  className?: string;
  style?: CSSProperties;
  type?: ModalType;
  transition?: IModalTransitionProps;
  onClose?(): void;
}

const ModalTransitionDuration = 0.25;

const ModalTransitionVariants: IModalTransitionVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const defaultTransitionProps: IModalTransitionProps = {
  duration: ModalTransitionDuration,
  variants: ModalTransitionVariants,
};

const defaultHeaderProps: IHeaderProps = {
  className: "modal-header",
  closeButton: true,
  icon: "CloseBorder",
};

const Modal: FC<IModalProps> = ({
  className,
  style,
  onClose,
  children,
  headerProps = defaultHeaderProps,
  isOpen: isOpenProp,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  type = "default",
  transition = defaultTransitionProps,
}) => {
  const dispatch = useDispatch();
  const currentModal = useAppSelector(state => state.modal.currentModal);
  const isOpen = useMemo(() => isOpenProp ?? !!currentModal, [currentModal, isOpenProp]);

  const closeModalWrapper = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const confirmExitModal = useCallback(() => {
    if (onClose) onClose();
    closeModalWrapper();
  }, [onClose, closeModalWrapper]);

  const ModalOverlayCallback = useCallback(
    (props, content) => (
      <ModalOverlay {...props} variants={transition.variants}>
        {content}
      </ModalOverlay>
    ),
    [transition.variants]
  );

  const classes = classNames("modal-wrapper", { [`${type}-type`]: type }, className);
  const overlayClasses = classNames("modal-overlay", `${type}-type`);

  return (
    <MotionConfig transition={{ duration: transition.duration }}>
      <ReactModal
        className="modal"
        isOpen={isOpen}
        closeTimeoutMS={transition.duration * 1000}
        bodyOpenClassName="ReactModal__Body--open"
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={confirmExitModal}
        overlayClassName={overlayClasses}
        overlayElement={ModalOverlayCallback}>
        <ModalContent
          className={classes}
          style={style}
          headerProps={headerProps}
          variants={transition.variants}
          confirmExitModal={confirmExitModal}>
          {children}
        </ModalContent>
      </ReactModal>
    </MotionConfig>
  );
};

export default Modal;
