import { IReinstateablePolicy, IUserAccount } from "config";
import {
  ICoverageAdjustmentInput,
  requestCoverageAdjustment,
  requestReinstatement,
} from "graphql/mutations/policy";
import { getCoverageEditorStateQuery, ICoverageEditorStateInput } from "graphql/queries/field";
import {
  fetchCurrentUserPolicyQuery,
  fetchReinstateablePolicies,
  policyRidersQuery,
} from "graphql/queries/policy";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { every } from "lodash-es";

import { getLatestInsuranceApplication } from "./insuranceApplications";

/* ------------       THUNKS      ------------------ */

export const getCoverageEditorState = createAsyncThunk(
  "policy/requestPolicyCoverageAdjustment",
  async (payload: ICoverageEditorStateInput, { rejectWithValue }) => {
    try {
      const { data } = await getCoverageEditorStateQuery(payload);

      return data.coverageEditorState;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  },
  {
    condition: payload => {
      if (every(payload, value => !value)) {
        return false;
      }
    },
  }
);

export const getCurrentUserPolicy = createAsyncThunk(
  "policy/getCurrentUserPolicy",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchCurrentUserPolicyQuery();
      const account = getActiveAccount(data.me) as IUserAccount;
      const activePolicy = account?.activePolicy;
      const latestPayableInvoice = account?.latestPayableInvoice;

      return { activePolicy, latestPayableInvoice };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getReinstateablePolicies = createAsyncThunk(
  "policy/getReinstateablePolicies",
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { policies },
      } = await fetchReinstateablePolicies();

      return policies;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getReinstateablePoliciesPolling = createAsyncThunk(
  "policy/getReinstateablePolicyWithDetailsPolling",
  async (policyId: IReinstateablePolicy["id"], { dispatch, rejectWithValue }) => {
    try {
      const [policies, latestInsuranceApplication] = await Promise.all([
        dispatch(getReinstateablePolicies()).unwrap(),
        dispatch(getLatestInsuranceApplication()).unwrap(),
      ]);

      return {
        caseId: latestInsuranceApplication?.id,
        caseResult: latestInsuranceApplication?.result,
        productsOffering: policies.find(
          ({ id }: { id: IReinstateablePolicy["id"] }) => id === policyId
        )?.reinstatementRequest?.productsOffering,
      };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  },
  { condition: policyId => !!policyId }
);

export const requestPolicyCoverageAdjustment = createAsyncThunk(
  "policy/requestPolicyCoverageAdjustment",
  async (payload: ICoverageAdjustmentInput, { rejectWithValue }) => {
    try {
      const { data } = await requestCoverageAdjustment(payload);
      const response = data.requestCoverageAdjustment.response;
      const underwritingRequired = response.status === "UNDERWRITING_REQUIRED" ? true : false;

      return { ...response, underwritingRequired };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const requestPolicyReinstatement = createAsyncThunk(
  "policy/requestPolicyReinstatement",
  async (policyId: IReinstateablePolicy["id"], { rejectWithValue }) => {
    try {
      const {
        data: {
          requestReinstatement: { response: requestReinstatementResponse },
        },
      } = await requestReinstatement(policyId);

      return requestReinstatementResponse;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getPolicyRiders = createAsyncThunk(
  "policy/getPolicyRiders",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await policyRidersQuery();

      const account = getActiveAccount(data.me) as IUserAccount;
      const activePolicy = account?.activePolicy;

      return activePolicy?.policyRiders || [];
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
