import { createClientMutationId } from "utils/clientMutationId";

import { ApolloLink, RequestHandler } from "@apollo/client";
import { OperationDefinitionNode } from "graphql";
import { first, has } from "lodash-es";

export const callback: RequestHandler = (operation, forward) => {
  const definitions = operation.query.definitions;
  const firstDefinition = first(definitions as OperationDefinitionNode[]);
  const operationType = firstDefinition?.operation;
  const variables = operation.variables;
  const inputVariables = variables?.input;

  if (operationType === "mutation" && !has(inputVariables, "clientMutationId")) {
    const clientMutationId = createClientMutationId();
    operation.variables = { ...variables, input: { ...inputVariables, clientMutationId } };
  }
  return forward(operation);
};

export const mutationIdLink = new ApolloLink(callback);
