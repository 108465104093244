import "./TLProductOfferingLayout.scss";

import { ReactNode } from "react";

import Container from "containers/Container/Container";
import { ILayoutProps } from "modules/layouts";

import classNames from "classnames";

interface IProps extends ILayoutProps<null> {
  children: ReactNode;
}

function TLProductOfferingLayout({ children, className }: IProps) {
  return (
    <Container className={classNames("tl-product-offering-layout", className)}>
      {children}
    </Container>
  );
}

export default TLProductOfferingLayout;
