import "./HeaderAccountButton.scss";

import CustomLink from "components/CustomLink/CustomLink";
import Icon from "components/Icon/Icon";
import { ClassName } from "config";

import classNames from "classnames";

interface IProps {
  className?: ClassName;
}

function HeaderAccountButton({ className }: IProps) {
  return (
    <CustomLink
      activeClassName="header-account-button--active"
      className={classNames("header-account-button", className)}
      to="/account">
      <Icon className="header-account-button__icon" src="Account" size="md" />
    </CustomLink>
  );
}

export default HeaderAccountButton;
