import "./TextAreaInput.scss";

import {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  Ref,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Text from "components/Text/Text";
import { ClassName, ValueOrArray } from "config/types";
import { useTranslation } from "modules";

import classNames from "classnames";
import { defaultTo, flatten, isFunction, isNil, toString, trimStart, truncate } from "lodash-es";

type NativeTextAreaProps = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "disabled" | "required" | "readonly" | "className"
>;

type Limit = {
  min: number;
  max: number;
};

type LimitProps = {
  character: Partial<Limit>;
  line: Partial<Limit>;
};

export interface TextAreaInputProps extends NativeTextAreaProps {
  activeLabel?: string;
  className?: ClassName;
  dataTestId?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  label?: string;
  limit?: Partial<LimitProps>;
  placeholder?: string;
  required?: boolean;
  isFocused?: boolean;
  maxHeight?: CSSProperties["maxHeight"];
  style?: CSSProperties;
  value?: string;
  defaultValue?: string;
  resize?: "both" | "vertical" | "horizontal" | "none";
  /**
   * Enables native spell check on the `textarea` element.
   */
  spellCheck?: boolean;
  onBlur?(event: FocusEvent<HTMLTextAreaElement>): void;
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onFocus?(event: FocusEvent<HTMLTextAreaElement>): void;
}

const TextAreaInputComponent: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaInputProps> = (
  props,
  ref
) => {
  const { t } = useTranslation();
  const {
    activeLabel,
    className,
    dataTestId,
    disabled,
    error,
    errorMessage,
    isFocused,
    label,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    style,
    value: controlledValue,
    maxHeight = "50vh",
    limit = {},
    resize = "none",
    defaultValue = "",
    ...rest
  } = props;
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState<string>(defaultValue);

  const characterLimit = limit.character;
  const maxCharacterLength = characterLimit?.max;
  const lineHeight = 1.7;

  const setInputHeight = (element: HTMLTextAreaElement) => {
    element.style.height = "auto";
    const borderHeight = 2;
    const paddingBoxHeight = element.scrollHeight;
    const borderBoxHeight = paddingBoxHeight + borderHeight * 2;
    element.style.height = `${borderBoxHeight}px`;
  };

  const trimTrailingNewlines = (value: string) => {
    return value.replace(/\n\n\n$/, "\n\n");
  };

  const handleOnInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (!isNil(maxCharacterLength)) {
        const value = trimTrailingNewlines(event.target.value);
        const trimmed = truncate(trimStart(value), { length: maxCharacterLength, omission: "" });
        event.target.value = trimmed;
      }

      !!textareaRef.current && setInputHeight(textareaRef.current);
      setValue(event.target.value);
      onChange && onChange(event);
    },
    [maxCharacterLength, onChange]
  );

  const handleOnFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    setFocused(true);
    onFocus && onFocus(event);
  };

  const handleOnBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    setFocused(false);
    onBlur && onBlur(event);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setValue(controlledValue || "");
  }, [controlledValue]);

  useEffect(() => {
    const el: HTMLTextAreaElement | null = textareaRef.current;
    if (el) {
      setInputHeight(el);
    }
  }, [value]);

  useEffect(() => {
    if (isFocused && !focused && textareaRef.current) {
      textareaRef.current.focus();
      setFocused(true);
    }
  }, [isFocused, focused]);

  const getTextAreaStyle = useMemo(() => {
    const minimumLines = limit.line?.min || 4;
    const minHeight = minimumLines * lineHeight + 2;
    const maxLines = limit.line?.max;
    const defaultMaxHeight = maxLines ? (maxLines || 1) * lineHeight : undefined;
    const maxHeightStyle = maxHeight || (defaultMaxHeight && `${defaultMaxHeight}em`);
    return {
      ...(maxHeightStyle && { maxHeight: maxHeightStyle }),
      minHeight: `${minHeight}em`,
      resize: resize as CSSProperties["resize"],
    };
  }, [limit.line, maxHeight, resize]);

  const getTextAreaRef = (elementRef: HTMLTextAreaElement | null) => {
    textareaRef.current = elementRef;

    if (!!ref) {
      if (isFunction(ref)) {
        ref(elementRef);
      } else {
        ref.current = elementRef;
      }
    }
  };

  const active = useMemo(() => !!(focused || value), [focused, value]);

  const labelText = useMemo(() => {
    const activeLabelText = defaultTo(activeLabel, label);
    const labelValue = activeLabelText ?? label;
    const shouldShowActiveLabel = active && activeLabel;
    return shouldShowActiveLabel ? labelValue : label;
  }, [active, activeLabel, label]);

  const errorMessages = flatten([errorMessage]);

  const conditionalClassNames = {
    "textarea-input--active": active,
    "textarea-input--complete": value,
    "textarea-input--error": error,
    "textarea-input--disabled": disabled,
    "textarea-input--has-label": labelText,
  };

  const classes = classNames(
    className,
    "textarea-input__container form__input",
    conditionalClassNames
  );

  return (
    <div className={classes} style={style}>
      <div className="textarea-input__wrapper">
        <textarea
          data-testid={dataTestId}
          ref={getTextAreaRef}
          value={controlledValue !== undefined ? controlledValue : value}
          onChange={handleOnInputChange}
          placeholder={placeholder}
          required={false}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          disabled={disabled}
          readOnly={false}
          style={getTextAreaStyle}
          {...rest}
          aria-required={props.required}
          aria-invalid={error}
          aria-labelledby={!!labelText ? rest.id : undefined}
        />
      </div>

      <div className="textarea-input__label-wrapper">
        {!isNil(maxCharacterLength) && (
          <Text
            className="textarea-input__max-chars"
            tag="c5"
            text={t("wysh.setup.name.input_limit", "%<length>s/%<max>s", {
              length: toString(value).length,
              max: maxCharacterLength,
            })}
          />
        )}
        {labelText && (
          <label className="textarea-input__label" htmlFor={rest.id}>
            {labelText}
          </label>
        )}
        {error &&
          errorMessages.map((message, index) => (
            <Text
              id={`textarea-input__error-${index}`}
              className="textarea-input__error-text"
              text={message}
              tag="p6"
              key={index}
            />
          ))}
      </div>
    </div>
  );
};

const TextAreaInputWrapper = forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  TextAreaInputComponent
);

export const TextAreaInput = memo(
  forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
    (props: TextAreaInputProps, ref: Ref<HTMLTextAreaElement>) => (
      <TextAreaInputWrapper ref={ref} {...props} />
    )
  )
);

TextAreaInput.displayName = "TextAreaInput";

export default TextAreaInput;
