import "./ADProductOfferingLayout.scss";

import { FC, ReactNode } from "react";

import DefaultHeroImage from "assets/images/fulfillment/ReviewProducts.svg";
import HeroImageHeader from "components/HeroImageHeader/HeroImageHeader";
import Text from "components/Text/Text";
import Container from "containers/Container/Container";
import { ILayoutProps } from "modules";

import classNames from "classnames";

type ContentType = {
  title: string;
  subtitle: ReactNode;
  pretitle?: string;
  heroImage?: string;
};

const ADProductOfferingLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const title = content.title;
  const subtitle = content.subtitle;
  const pretitle = content.pretitle;

  const hero = content.heroImage ? content.heroImage : DefaultHeroImage;

  const classes = classNames("review-offerings", className);

  return (
    <Container className={classes}>
      <HeroImageHeader className="review-offerings__header" image={hero} />
      <div className="review-offerings__content">
        <div className="review-offerings__title-container">
          {pretitle && <Text className="review-offerings__pretitle" tag="c3" text={pretitle} />}
          <Text className="review-offerings__title" tag="h4" text={title} />
          <Text className="review-offerings__subtitle" tag="p4" text={subtitle} />
        </div>
        <div className="review-offerings__offerings">{children}</div>
      </div>
    </Container>
  );
};

export default ADProductOfferingLayout;
