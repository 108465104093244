import React, { FC } from "react";
import { useDispatch } from "react-redux";

import Text from "components/Text/Text";
import VariableText from "components/VariableText/VariableText";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { BusinessSlugTypes } from "config/types";
import { Layout, useTranslation } from "modules";
import { closeModal } from "store/actions/modal";

import { push } from "connected-react-router";

const AddAnotherBeneficiaryModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lineOfBusiness = props.data.lineOfBusiness as BusinessSlugTypes;

  const handleOnClick = () => {
    dispatch(push(`/beneficiary/select-type/${lineOfBusiness}/primary`));
    closeModalWrapper();
  };

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const subHeaderContentStart = t(
    "beneficiary.main.add.modal.body.start",
    "Unfortunately, when you have two or more beneficiaries our"
  );
  const subHeaderContentLink = t("beneficiary.main.add.modal.body.link", "Concierge Service");
  const subHeaderContentEnd = t(
    "beneficiary.main.add.modal.body.end",
    "may not be able to do some of their magic, like paying off your debts directly to your lenders and banks.\nHowever, all your beneficiaries will still be given the personal, friendly attention that we pride ourselves on."
  );
  const subHeaderText = `${subHeaderContentStart} ${subHeaderContentLink} ${subHeaderContentEnd}`;

  const subHeaderWithLink = (
    <VariableText
      target={subHeaderContentLink}
      text={subHeaderText}
      replacementValue={<Text tag="span" text={subHeaderContentLink} />}
    />
  );

  const ctaButtons = [
    {
      text: t("beneficiary.main.add.modal.cta", "GOT IT"),
      onClick: handleOnClick,
    },
  ];

  const content = {
    header: t("beneficiary.main.add.modal.header", "Heads up!"),
    subheader: subHeaderWithLink,
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default AddAnotherBeneficiaryModal;
