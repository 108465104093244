import { useSelector } from "react-redux";

import { FlowTypes, isDF3KSlug } from "config";
import { useModal, useQueryParams } from "modules";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { selectDynamicFormsByLayout } from "store/selectors";

import { goBack, push } from "connected-react-router";

export const useFlowRouteBackNav = (slug: FlowTypes) => {
  // HOOKS
  const dispatch = useAppDispatch();
  const { queryParams } = useQueryParams();
  const isFirstForm = useSelector((state: RootState) => state.formFlow.formIndex <= 1);
  const isFormDirtied = useSelector((state: RootState) => state.formFlow.isFormDirtied);
  const { dynamicForm } = useAppSelector(selectDynamicFormsByLayout);

  // DERIVED VARIABLES
  const isEditFlow = queryParams.get("edit") === "true";
  const flowSlugsToOverride: FlowTypes[] = [
    "evidence-underwriting-aura-review",
    "savings-default-application",
  ];
  const shouldOverride = isFirstForm || flowSlugsToOverride.includes(slug) || isDF3KSlug(slug);
  const shouldHideBackNav = ["savings-cdd"].includes(slug);

  // METHODS
  const openModal = useModal({
    onDiscardChanges: () => dispatch(goBack()),
  });

  const onBackClick = () => {
    switch (true) {
      case isEditFlow && isFormDirtied:
        return openModal("ExitWithoutSavingModal");
      case isEditFlow:
        return dispatch(goBack());
      case slug === "savings-default-application":
        return dispatch(push("/"));
      default:
        return dispatch(goBack());
    }
  };

  const backNavOverride = {
    hasBackNav: !shouldHideBackNav,
    leadingNavigationButton: dynamicForm.leadingNavigationButton,
    onClick: onBackClick,
    replaceExitInJourneyWithBack: false,
  };

  return { backNavOverride: shouldOverride ? backNavOverride : {} };
};
