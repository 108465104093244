import { FC, Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { RouteHistory } from "config/types/general.types";

export interface IScrollProps {
  children?: React.ReactNode;
  history: RouteHistory;
}

const ScrollToTop: FC<IScrollProps> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
