import { FC } from "react";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { openChat } from "utils/customerSupport";

const CantFindBankModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();

  const content = {
    icon: "BlobBank",
    header: t("savings.plaid_fail.modal.title", "Can’t find your bank?"),
    subheader: t(
      "savings.plaid_fail.modal.text",
      "No worries. Quick-linking with Plaid is faster, but we have another option. Our support team can help you get started."
    ),
    button: {
      onClick: () => {
        openChat();
        closeModal();
      },
      text: t("savings.plaid_fail.modal.cta", "CONTACT SUPPORT"),
    },
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default CantFindBankModal;
