import { IDynamicForm, IReducerState } from "config";
import { logout } from "store/actions/authentication";
import { dismissDynamicFormModal, fetchDynamicForm, initFormFlow } from "store/actions/flow";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";

interface IDynamicFormState extends IDynamicForm, IReducerState {}

const defaultState: IDynamicFormState = {
  description: "",
  flow: { slug: "" },
  id: "",
  groups: [],
  hasLoaded: false,
  isLoading: false,
  items: [],
  layout: "vertical",
  leadingNavigationButton: undefined,
  slug: "",
};

/* ------------------    ADD MATCHERS     ------------------ */
const isDynamicFormPending = isAnyOf(fetchDynamicForm.pending, initFormFlow.pending);

const isDynamicFormResolved = isAnyOf(fetchDynamicForm.fulfilled, fetchDynamicForm.rejected);

/* ------------------    REDUCER     ------------------ */
const dynamicForm = createReducer(defaultState, builder => {
  builder
    .addCase(fetchDynamicForm.fulfilled, (state, action) => {
      const dynamicForm = action.payload;
      state.description = dynamicForm.description;
      state.flow = dynamicForm.flow;
      state.id = dynamicForm.id;
      state.groups = dynamicForm.groups || [];
      state.items = dynamicForm.items;
      state.layout = dynamicForm.layout;
      state.leadingNavigationButton = dynamicForm.leadingNavigationButton;
      state.slug = dynamicForm.slug;
    })
    .addCase(dismissDynamicFormModal.fulfilled, (state, action) => {
      const dynamicForm = action.payload as IDynamicForm;

      state.description = dynamicForm.description;
      state.flow = dynamicForm.flow;
      state.id = dynamicForm.id;
      state.groups = dynamicForm.groups || [];
      state.items = dynamicForm.items;
      state.layout = dynamicForm.layout;
      state.leadingNavigationButton = dynamicForm.leadingNavigationButton;
      state.slug = dynamicForm.slug;
    })
    .addCase(initFormFlow.pending, () => defaultState)
    .addCase(logout, () => defaultState)
    .addMatcher(isDynamicFormPending, state => {
      state.hasLoaded = false;
      state.isLoading = true;
    })
    .addMatcher(isDynamicFormResolved, state => {
      state.hasLoaded = true;
      state.isLoading = false;
    });
});

export default dynamicForm;
