import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

export const selectCountryItems = (state: RootState) => state.country.countries;

// Maps countries into array of objects for select dropdown
export const selectCountryOptions = createSelector(selectCountryItems, countries =>
  countries.map(country => ({ value: country.id, label: country.name }))
);
