import "./LeadingNavigationButton.scss";

import { ComponentProps } from "react";
import { Prompt } from "react-router";

import { ButtonFormItem, RouteAction, RouteLocation } from "config";
import { ButtonItem } from "containers/DynamicForm/DynamicFormItems/ButtonItem";
import { useButtonAction } from "containers/DynamicForm/useButtonAction";

import c from "classnames";

type ButtonFormItemContent = ButtonFormItem["content"];

interface IProps extends Partial<ComponentProps<typeof ButtonItem>> {
  content: ButtonFormItemContent;
  hasPortal?: boolean;
  isModalLayout?: boolean;
}

export function LeadingNavigationButton({
  className,
  content,
  hasPortal,
  isModalLayout,
  tag,
}: IProps) {
  const actionCommands = useButtonAction(content.actions);
  const handleHiddenButtonActions = (_location: RouteLocation, action: RouteAction) => {
    try {
      // set delay on button actions to execute after router/LOCATION_CHANGE action (LOCATION_CHANGE action resets modal reducer state)
      setTimeout(() => {
        actionCommands({});
      }, 100);

      return action === "POP" ? false : true;
    } catch (error) {
      return error;
    }
  };

  const buttonItem = {
    content,
    key: "leading-navigation-button",
    type: "button" as const,
  };

  const classes = c(
    "leading-navigation-button",
    { "leading-navigation-button--modal": isModalLayout },
    { "leading-navigation-button--portal": hasPortal },
    className
  );

  const buttonTextByStyle = {
    ...(!!isModalLayout && { back: "", exit: "" }),
  };

  // RENDER
  return (
    <>
      <Prompt when={content.style === "hidden"} message={handleHiddenButtonActions} />
      <ButtonItem
        className={classes}
        buttonTextByStyle={buttonTextByStyle}
        icon={isModalLayout ? "CloseBorder" : undefined}
        item={buttonItem}
        tag={tag}
      />
    </>
  );
}
