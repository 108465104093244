import "./FlowReviewQuestion.scss";

import Text from "components/Text/Text";
import { ClassName, IFlowField, IFlowQuestion } from "config";

import classNames from "classnames";

import FlowReviewField from "./FlowReviewField";

interface IProps {
  fields: IFlowQuestion["fields"];
  isEditDisabled: boolean;
  prompt: IFlowQuestion["prompt"];
  className?: ClassName;
  onEditField(fieldId: IFlowField["id"]): void;
}

const FlowReviewQuestion = (props: IProps) => {
  const { className, fields, isEditDisabled, onEditField, prompt } = props;

  const classes = classNames("flow-review-question", className);

  return (
    <div className={classes}>
      <Text className="flow-review-question__title" tag="l3" text={prompt} />

      {fields.map(field => {
        return (
          <FlowReviewField
            inputType={field.inputType}
            isEditDisabled={isEditDisabled}
            isLegacy
            key={field.id}
            label={field.label}
            onEdit={() => onEditField(field.id)}
            options={field.metadata.options}
            unit={field.metadata.unit}
            value={field.metadata.value}
          />
        );
      })}
    </div>
  );
};

export default FlowReviewQuestion;
