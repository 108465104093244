import { FC, MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useJourney, useTranslation } from "modules";
import { RootState } from "store";
import { closeModal } from "store/actions/modal";
import { destroyWyshAllocation } from "store/actions/wyshes";
import { getThunkError } from "utils/error";

import { push } from "connected-react-router";
import { map } from "lodash-es";

const CancelAddedWyshesModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { anyJourneysActive } = useJourney();
  const allocated = useSelector((state: RootState) => state.wyshAllocations.allocated);

  const [loading, setLoading] = useState(false);

  const removeWyshAllocations = async (event: MouseEvent) => {
    try {
      setLoading(true);

      const allocations = map(allocated, allocation =>
        dispatch(destroyWyshAllocation(allocation.id))
      );

      const responses = await Promise.all(allocations);

      responses.forEach(response => {
        const error = getThunkError(response);
        if (error) {
          throw error;
        }
      });

      closeModalWrapper();
      props.context?.callbacks?.startCoverageCollectionFlow &&
        props.context?.callbacks?.startCoverageCollectionFlow(event);
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const returnToWyshBuilder = () => {
    if (!anyJourneysActive) {
      dispatch(push("/products/insurance/wysh-builder/list"));
    }

    closeModalWrapper();
  };

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const ctaButtons = [
    {
      text: t("wysh.modal.wyshes.cancel.confirm", "CONTINUE"),
      isLoading: loading,
      disabled: loading,
      onClick: removeWyshAllocations,
      dataTestId: "cancel-added-wyshes__continue-cta",
    },
    {
      text: t("wysh.modal.wyshes.cancel.cancel", "RETURN TO WYSH SETUP"),
      subType: "text-dark",
      disabled: loading,
      onClick: returnToWyshBuilder,
      dataTestId: "cancel-added-wyshes__decline-cta",
    },
  ];

  const content = {
    header: t("wysh.modal.wyshes.cancel.title", "You have added Wyshes"),
    subheader: t("wysh.modal.wyshes.cancel.body", "Continuing will discard the Wyshes you created"),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return (
    <Layout as="ModalLayout" content={content} className="cancel-added-wyshes-modal__container" />
  );
};

export default CancelAddedWyshesModal;
