import "./ProductOfferingDetails.scss";

import { ReactNode } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { useTranslation } from "modules";

import classNames from "classnames";

interface IProductOfferingOfferDetails {
  children: ReactNode;
  className?: ClassName;
}

interface IProductOfferingOfferDetailsHeader {
  onEditClick?(): void;
}

interface IProductOfferingOfferDetailsItem {
  description: string;
  value: string | ReactNode;
}

export function ProductOfferingDetails({ children, className }: IProductOfferingOfferDetails) {
  return <div className={classNames("product-offering-details", className)}>{children}</div>;
}

export function ProductOfferingDetailsHeader(props: IProductOfferingOfferDetailsHeader) {
  const { onEditClick } = props;

  const { t } = useTranslation();

  const isEditable = !!onEditClick;

  return (
    <div className="product-offering-details-header product-offering-details__header">
      <Text tag="l4-label">{t("review_quotev2.term.offer.info.header", "Offer details")}</Text>

      {isEditable && (
        <div className="product-offering-details-header__cta" onClick={onEditClick}>
          <Text tag="c5-caption">{t("review_quotev2.term.offer.info.edit.cta", "Edit")}</Text>
          <Icon size="sm" src="Next" color="cta" />
        </div>
      )}
    </div>
  );
}

export function ProductOfferingDetailsItem(props: IProductOfferingOfferDetailsItem) {
  const { description, value } = props;

  return (
    <div className="product-offering-details-item product-offering-details__item">
      <Text tag="l5-label">{description}</Text>
      <Text tag="l5-label">{value}</Text>
    </div>
  );
}
