import { useMemo } from "react";

import BlobPadlock from "assets/icons/BlobPadlock/BlobPadlock.svg";
import Socure from "assets/icons/Socure/Socure.svg";
import TransUnion from "assets/icons/TransUnion/TransUnion.svg";
import SavingsTrustCTA from "components/SavingsApplicationTrustCTA/SavingsApplicationTrustCTA";

import { useTranslation } from "./useTranslation";

export const useFlowAdContent = (fieldKey: string) => {
  const { t } = useTranslation();
  const adFlowContent = useMemo(() => {
    switch (fieldKey) {
      case "application-ssn-tin": {
        const adCardContent = {
          items: [
            {
              title: t("form.ssn.info.1.title", "YOUR DATA IS PROTECTED"),
              description: t(
                "form.ssn.info.1.description",
                "We use industry-best encryption and security technology to keep your personal information safe."
              ),
              imgSrc: BlobPadlock,
              descriptionSize: "p5" as const,
            },
            {
              title: t("form.ssn.info.2.title", "VERIFIED BY A TRUSTED PARTNER"),
              description: t(
                "form.ssn.info.2.description",
                "TransUnion's digital service lets us quickly and effectively check your identity and personal information safely."
              ),
              imgSrc: TransUnion,
              descriptionSize: "p5" as const,
            },
          ],
        };

        return {
          flowAdContentComponent: (
            <SavingsTrustCTA
              className="insurance-application-flow-ad-content"
              content={adCardContent}
            />
          ),
        };
      }

      case "savings-application": {
        const adCardContent = {
          items: [
            {
              title: t("savings.application_form.value_prop.data.title", "YOUR DATA IS PROTECTED"),
              description: t(
                "savings.application_form.value_prop.data.message",
                "We use industry-best encryption and security technology to keep your personal information safe."
              ),
              imgSrc: BlobPadlock,
              descriptionSize: "p5" as const,
            },
            {
              title: t(
                "savings.application_form.value_prop.partner.title",
                "VERIFIED BY A TRUSTED PARTNER"
              ),
              description: t(
                "savings.application_form.value_prop.partner.message",
                "Socure's verification service lets us accurately confirm your identity and personal information."
              ),
              imgSrc: Socure,
              descriptionSize: "p5" as const,
            },
          ],
        };

        return {
          flowAdContentComponent: <SavingsTrustCTA content={adCardContent} />,
        };
      }

      default:
        return {
          flowAdContentComponent: null,
        };
    }
  }, [fieldKey]); // eslint-disable-line react-hooks/exhaustive-deps
  return adFlowContent;
};
