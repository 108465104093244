import queryString from "query-string";

export default function replaceWyshwareUri(text: string) {
  /**
   * RegEx works with the following patterns:
   * 1. wyshware.app://uri/path?query=value
   * 2. <a href="wyshware.app://url/path?query=value"
   */
  return text.replace(/(wyshware.app\:\/\/[^"]+)/g, (_, urlString) => {
    const { query, url } = queryString.parseUrl(urlString);

    switch (url) {
      case "wyshware.app://flow/beneficiary":
        return "/flow/beneficiary";

      case "wyshware.app://flow/beneficiary/wysh-insurance":
        return "/beneficiary/wysh-insurance";

      case "wyshware.app://journey/fulfillment":
        return "/fulfillment/start";

      case "wyshware.app://screen/disclosure":
        return `/disclosure/${query.slug}`;

      case "wyshware.app://screen/documents":
        if (query.category) {
          return `/account/documents/${query.category}`;
        } else if (query.id) {
          return `/account/document/${query.id}`;
        } else {
          return "/account/documents";
        }

      case "wyshware.app://screen/home":
        return `/`;

      case "wyshware.app://screen/insurance":
        return "/products/insurance";

      case "wyshware.app://screen/policy_reinstatement":
        return `/account/policy-reinstatement`;

      case "wyshware.app://screen/settings-billing":
        return "/products/insurance/billing";

      case "wyshware.app://screen/verify-phone":
        return "/account/profile/edit/verify-phone";

      default:
        return "/";
    }
  });
}
