import { FC } from "react";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useAddWyshCategories, useJourney, useNavigate } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { SectionContextValue } from "modules/managers/JourneySectionProvider";
import { closeModal } from "store/actions/modal";

import { find, get, isFunction } from "lodash-es";

const NoWyshesAdded: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { anyJourneysActive, nextStepCallback } = useJourney();
  const journeyContext = get(props.data, "journeySectionContext") as SectionContextValue;
  const navigate = useNavigate({}, { context: { journeySection: journeyContext } });
  const wyshCategories = useAddWyshCategories();

  const closeModalWrapper = () => {
    if (isFunction(props.context?.callbacks?.resetForm)) {
      props.context?.callbacks?.resetForm();
    }
    dispatch(closeModal());
  };

  const firstSlug = find(wyshCategories, ["order", 1])?.slug || "";

  const redirectToCoverageCollection = async () => {
    const next = nextStepCallback(closeModalWrapper, true, true);
    next();
  };

  const redirectToWyshWSetup = async () => {
    await closeModalWrapper();
    const destination = generatePath("/wysh-builder/:category", { category: firstSlug });
    navigate(destination);
  };

  const redirectToDashboard = async () => {
    await closeModalWrapper();
    navigate("/");
  };

  const enterAmountButton = {
    text: t("wysh.modal.no_wyshes.button_cancel_alternate", "Enter amount instead"),
    subType: "text-dark",
    onClick: redirectToCoverageCollection,
  };

  const noWyshesButton = {
    text: t("wysh.modal.no_wyshes.button_cancel", "I have no wyshes"),
    subType: "text-dark",
    onClick: redirectToDashboard,
  };

  const secondaryButton = anyJourneysActive ? enterAmountButton : noWyshesButton;
  const ctaButtons = [
    {
      text: t("wysh.modal.no_wyshes.button_confirm", "Go Back & add wishes"),
      isLoading: false,
      onClick: redirectToWyshWSetup,
    },
    secondaryButton,
  ];

  const content = {
    header: t("wysh.modal.no_wyshes.title", "We noticed you didn't add any Wyshes..."),
    subheader: t(
      "wysh.modal.no_wyshes.body",
      "Wyshes can help you get the exact coverage you need. Already know how much you want? Let us know & we’ll hook it up."
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default NoWyshesAdded;
