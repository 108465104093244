import {
  BusinessSlugTypes,
  IInvoice,
  ILinkTokenResponse,
  PaymentMethodPayload,
} from "config/types";
import {
  createMultiplePaymentsMutation,
  createPaymentMethodMutation,
  exchangePlaidPublicToken,
  getPlaidLinkToken,
  ICreateMultiplePaymentsInput,
} from "graphql/mutations/payment";
import { activePaymentMethodQuery } from "graphql/queries/payment";
import { RootState } from "store";
import { logBranchPurchaseEvent } from "utils/branch";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLinkToken = createAsyncThunk("getLinkToken", async (_, { rejectWithValue }) => {
  try {
    const response: ILinkTokenResponse = await getPlaidLinkToken();
    return response.data.plaidGenerateLinkToken.linkToken;
  } catch (error) {
    const errorCode = getAppError(error);

    return rejectWithValue(errorCode);
  }
});

export const exchangePublicToken = createAsyncThunk(
  "exchangePublicToken",
  async (
    {
      publicToken,
      bankAccountId,
      lineOfBusiness = "wysh-insurance",
    }: { publicToken: string; bankAccountId: string; lineOfBusiness?: BusinessSlugTypes },
    { rejectWithValue }
  ) => {
    try {
      const response = await exchangePlaidPublicToken(publicToken, bankAccountId, lineOfBusiness);
      return response.data.plaidExchangePublicToken.plaidAccount;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);

export const createPaymentMethod = createAsyncThunk(
  "createPaymentMethod",
  async ({ externalId, source, metadata = {} }: PaymentMethodPayload, { rejectWithValue }) => {
    try {
      const response = await createPaymentMethodMutation(externalId, source, metadata);
      return response.data.createPaymentMethod.paymentMethod;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);

export const getActivePaymentMethod = createAsyncThunk(
  "getActivePaymentMethod",
  async (_, { rejectWithValue }) => {
    try {
      const response = await activePaymentMethodQuery();
      return response.data.activePaymentMethod;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);

interface ICreatePayment {
  invoiceId: IInvoice["id"];
  purchasePolicy?: boolean;
}

export const createPayment = createAsyncThunk(
  "createPayment",
  async ({ invoiceId, purchasePolicy = false }: ICreatePayment, { rejectWithValue, getState }) => {
    try {
      const { policy } = getState() as RootState;
      const response = await createMultiplePaymentsMutation({ invoiceIds: [invoiceId] });
      const payments = response.data.createMultiplePayments.payments;
      const {
        processed: [processedInvoice],
      } = payments;

      if (purchasePolicy && processedInvoice.transactionResult !== "failed") {
        const policyId = policy.id;
        const price = policy.activePlan.amount;
        const term = policy.termDuration;
        logBranchPurchaseEvent(policyId, price, term);
      }

      return payments;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);

export const createMultplePayments = createAsyncThunk(
  "createMultiplePayments",
  async (input: ICreateMultiplePaymentsInput, { rejectWithValue }) => {
    try {
      const response = await createMultiplePaymentsMutation(input);

      return response.data.createMultiplePayments.payments;
    } catch (error) {
      const errorCode = getAppError(error);

      return rejectWithValue(errorCode);
    }
  }
);
