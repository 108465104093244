import { FC } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { closeModal } from "store/actions/modal";

const SaveChangesModal: FC<IModalProps> = props => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const onClickConfirm = () => {
    props.context?.callbacks?.handleSave();
    closeModalWrapper();
  };

  const onClickDecline = () => {
    props.context?.callbacks?.redirectToPath();
    closeModalWrapper();
  };

  const content = {
    header: t("save_changes.modal.title", "Save changes before you go?"),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("save_changes.modal.button.confirm", "YES, SAVE"),
        subType: "primary",
        onClick: onClickConfirm,
      },
      {
        text: t("save_changes.modal.button.decline", "NAH, I'M GOOD"),
        subType: "text-dark-border",
        onClick: onClickDecline,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default SaveChangesModal;
