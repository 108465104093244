import { useContext } from "react";

import { useAppDispatch } from "store";
import { updateBeneficiaryAllocations } from "store/actions/beneficiaries";

import { filter } from "lodash-es";

import { DynamicFormContext } from "../DynamicFormContext";

const BENEFICIARY_ALLOCATION_KEY_PREFIX = "bene-allocation-";

export const useUpdateBeneficiaryAllocations = () => {
  const { inputs } = useContext(DynamicFormContext);
  const dispatch = useAppDispatch();

  const updateBeneficiaryAllocationsCommand = async () => {
    const allocationInputs = filter(inputs, ({ key }) =>
      key.startsWith(BENEFICIARY_ALLOCATION_KEY_PREFIX)
    );

    const allocations = allocationInputs
      .map(({ key, value }) => ({
        allocation: parseFloat(value) || 0,
        beneficiaryId: key.replace(new RegExp(`^${BENEFICIARY_ALLOCATION_KEY_PREFIX}`, "gi"), ""),
      }))
      .filter(({ beneficiaryId }) => !!beneficiaryId);

    return await dispatch(updateBeneficiaryAllocations(allocations)).unwrap();
  };

  return updateBeneficiaryAllocationsCommand;
};
