import { DynamicFormLayoutType, EstimateFormSlug } from "config";

/*
..................
~~~ INTERFACES ~~~
..................
*/

export interface IFlow {
  id: string;
  name: string;
  slug: FlowTypes;
  sections: IFlowSection[];
  terminalBehavior: FlowTerminalBehaviorType;
}

export interface IFlowSection {
  flow: Pick<IFlow, "id">;
  forms: IFlowForm[];
  id: string;
  name: string;
}

export interface IFlowForm {
  id: string;
  layout: DynamicFormLayoutType;
  prompt: string;
  questions: IFlowQuestion[];
  section: Pick<IFlowSection, "id">;
  terminal: boolean;
  title: string;
}

export interface IFlowQuestion {
  id: string;
  prompt: string;
  layout?: FlowQuestionLayout;
  description?: string;
  justification: string;
  justificationHeader: string;
  fields: IFlowField[];
}

export interface IFlowField {
  id: string;
  inputType: FlowInputTypes;
  label: string;
  metadata: any;
  required: boolean;
  key: string;
}

export interface IFlowSession {
  id: string;
}

/*
..................
~~~~~ ENUMS ~~~~~
..................
*/

export enum EnumFlowInputTypes {
  dropdown,
  numeric,
  text,
  "radio_group",
  "multi_select",
  "long_text",
  date,
  unitized,
  blobs,
  slider,
  "tax_id",
}

export enum EnumFlowTypes {
  "account-profile",
  "application",
  "aura-interview",
  "beneficiary",
  "cdd-form",
  "coverage-collection",
  "estimate",
  "estimate-v2",
  "estimate-form",
  "evidence-underwriting-aura",
  "evidence-underwriting-aura-review",
  "insurance-profile",
  "marketing",
  "pre-qualification",
  "pre-verified-initial-data",
  "savings-cdd",
  "savings-default-application",
  "savings-default-application-v2",
  "term-life-extension",
  "underwriting-evidence",
}

export enum EnumFieldKeys {
  "prequalification-state",
}

export enum FlowQuestionLayout {
  vertical = "vertical",
  address = "vertical_with_domestic_address_autocomplete",
}

/*
..................
~~~~~ TYPES ~~~~~
..................
*/

export type FlowInputTypes = keyof typeof EnumFlowInputTypes;

export type FlowClickInputTypes = Extract<
  FlowInputTypes,
  "dropdown" | "radio_group" | "multi_select" | "blobs"
>;

export type FlowTextInputTypes = Extract<
  FlowInputTypes,
  "text" | "numeric" | "date" | "unitized" | "slider" | "tax_id"
>;

export type FlowTypes = keyof typeof EnumFlowTypes | typeof EstimateFormSlug;

export type FlowFieldKeys = keyof typeof EnumFieldKeys;

export type FlowTerminalBehaviorType = "before_response" | "after_response";
