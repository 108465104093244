import "./Menu.scss";

import { CSSProperties, FC, MouseEvent, useRef, useState } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName, IMenuItem } from "config/types";

import classNames from "classnames";
import { get } from "lodash-es";

import MenuList from "./MenuList";

type IProps = {
  subMenuItems: IMenuItem[];
  handleCloseMenu?: (event: MouseEvent) => void;
  subMenuText?: string;
  className?: ClassName;
  style?: CSSProperties;
};

const SubMenu: FC<IProps> = (props: IProps) => {
  const { className, subMenuItems, subMenuText, handleCloseMenu } = props;
  const subMenuRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [activeSubMenu, setActiveSubMenu] = useState(false);

  const toggleSubMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const eventTarget = get(event, "target") as Node;
    const currentRef = get(subMenuRef, "current");

    if (currentRef && currentRef.contains(eventTarget)) {
      setActiveSubMenu(!activeSubMenu);
    }
  };

  const onMenuListFocus = () => {
    setActiveSubMenu(true);
  };

  const classes = classNames("sub-menu-container", className, {
    "sub-active": activeSubMenu,
  });

  return (
    <div className={classes}>
      <div className="sub-menu--border" />
      {subMenuText ? (
        <div
          className={`menu-item main-menu flex--center-space-between ${
            activeSubMenu ? "active" : ""
          }`}
          onClick={toggleSubMenu}
          ref={subMenuRef}>
          <Text className="sub-menu__toggle--text" text={subMenuText} tag="p3" />
          <Icon className="menu-item__icon-carat" src="CaratDown" size="xs" />
        </div>
      ) : null}
      <MenuList
        menuItems={subMenuItems}
        handleCloseMenu={handleCloseMenu}
        isSubMenu={true}
        onFocus={onMenuListFocus}
      />
    </div>
  );
};

export default SubMenu;
