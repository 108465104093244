import "./HeaderLogo.scss";

import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PlusSrc from "assets/images/cobrands/plus.svg";
import Logo from "components/Logo/Logo";
import { WyshboxURL } from "config";
import { getCanSeeDashboard } from "store/selectors";

import classNames from "classnames";

interface IProps {
  isCoBranded: boolean;
  className?: string;
  coBrandMetadata?: {
    logoSrc: string;
    name: string;
  };
}

const HeaderLogo: FC<IProps> = ({ className, coBrandMetadata, isCoBranded }) => {
  const classes = classNames("header-logo__container", className);
  const canSeeDashboard = useSelector(getCanSeeDashboard);

  return (
    <div className={classes}>
      <Link
        to={{ pathname: canSeeDashboard ? "/" : WyshboxURL }}
        target={canSeeDashboard ? "_self" : "_blank"}
        rel="noopener noreferrer"
        className="header-logo__link">
        <Logo className="header-logo__logo" />
      </Link>

      {isCoBranded ? (
        <div className="header-logo__co-brand-container">
          <img alt="plus" src={PlusSrc} />
          <img
            alt={coBrandMetadata?.name}
            className="header-logo__co-brand-logo"
            src={coBrandMetadata?.logoSrc}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HeaderLogo;
