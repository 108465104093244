import {
  fetchLatestInsuranceApplication,
  fetchLatestInsuranceApplicationDocuments,
} from "graphql/queries/insuranceApplications";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLatestInsuranceApplication = createAsyncThunk(
  "insuranceApplications/fetchLatestInsuranceApplication",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchLatestInsuranceApplication();
      const { latestInsuranceApplication } = getActiveAccount(response.data.me);

      return latestInsuranceApplication;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getLatestInsuranceApplicationDocuments = createAsyncThunk(
  "insuranceApplications/fetchLatestInsuranceApplicationDocuments",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchLatestInsuranceApplicationDocuments();
      const { latestInsuranceApplication } = getActiveAccount(response.data.me);

      return latestInsuranceApplication?.documents;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
