import { FC } from "react";
import { useDispatch } from "react-redux";

import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { logout } from "store/actions/authentication";
import { closeModal } from "store/actions/modal";

import { push } from "connected-react-router";

const IdentifiedExitModal: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const onClickConfirm = () => {
    dispatch(logout());
    dispatch(push("/"));
    closeModalWrapper();
  };

  const onClickDecline = () => {
    closeModalWrapper();
  };

  const content = {
    header: t("reset.confirm.title", "Are you sure you wanna exit?"),
    subheader: t(
      "reset.confirm.body",
      "If you leave now, you’ll lose all your progress (that means your answers, Wyshes, the whole lot...)."
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("reset.confirm.cta", "YES, EXIT"),
        subType: "primary",
        onClick: onClickConfirm,
        dataTestId: "identified-exit-modal__confirm",
      },
      {
        text: t("reset.confirm.cancel", "NEVERMIND"),
        subType: "text-dark-border",
        onClick: onClickDecline,
        dataTestId: "identified-exit-modal__decline",
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default IdentifiedExitModal;
