import { FC, useEffect } from "react";

import { paymentFailureModalDisplayedEvent } from "analytics/billingEvents";
import Text from "components/Text/Text";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { openChat } from "utils/customerSupport";

import { push } from "connected-react-router";

const PolicyBillingPaymentRetryFailureModal: FC<IModalProps> = props => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(paymentFailureModalDisplayedEvent());
  }, [dispatch]);

  const closeModal = props.closeModal;
  const lapsesAt = props.data.lapsesAt as string;

  const content = {
    icon: "BlobAlert",
    header: t("policy.modal.payment_retry.error.title", "Uh-oh, your payment didn’t go through..."),
    subheader: (
      <>
        {t(
          "policy.modal.payment_retry.error.text_1",
          "To keep your policy, you may need to update your payment method. Unfortunately, we will have to cancel your policy if the past due amount is not received by %<lapsesAt>s. If you have billing questions, please contact",
          { lapsesAt }
        )}{" "}
        <Text
          as="span"
          className="modal__subheader-inline-cta"
          onClick={() => {
            openChat();
            closeModal();
          }}
          tag="p3"
          text={t("policy.modal.payment_retry.error.cs", "Customer Support")}
        />{" "}
        {t("policy.modal.payment_retry.error.text_2", "for help.")}
      </>
    ),
    button: [
      {
        onClick: () => {
          dispatch(push("/products/insurance/billing"));
          closeModal();
        },
        text: t("policy.modal.payment_retry.error.cta_1", "GO TO BILLING"),
      },
      {
        onClick: () => {
          openChat();
          closeModal();
        },
        subType: "text-dark",
        text: t("policy.modal.payment_retry.error.cta_2", "GET HELP"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default PolicyBillingPaymentRetryFailureModal;
