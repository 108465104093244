import { FC, useEffect, useState } from "react";

import FormError from "components/FormError/FormError";
import IneligibleWarning from "components/IneligibleWarning/IneligibleWarning";
import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import { IneligibleCoverageReasonsType } from "config";
import { useJourney } from "modules";
import { useAppDispatch } from "store";
import { eventFired } from "store/actions/analytics";
import { getIneligibleForCoverageReasons } from "store/actions/estimate";

import { get } from "lodash-es";

const ALL_REASONS: IneligibleCoverageReasonsType[] = [
  "age_of_insured",
  "state_of_residence",
  "criminal_history",
];
const AGE_OF_INSURED = ALL_REASONS[0];
const CRIMINAL_HISTORY = ALL_REASONS[2];

const REASON_TO_EVENT_MAP = {
  age_of_insured: "prequal_cantinsure_age_pageview",
  criminal_history: "prequal_cantinsure_criminal_history_pageview",
  general: undefined,
  state_of_residence: "prequal_cantinsure_state_pageview",
};

interface IProps {
  reasonParam?: IneligibleCoverageReasonsType;
}

const IneligibleWarningContainer: FC<IProps> = ({ reasonParam }) => {
  const dispatch = useAppDispatch();
  const { nextStepCallback } = useJourney();
  const [reason, setReason] = useState<IneligibleCoverageReasonsType | "general">(
    reasonParam || "general"
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const checkEligibility = async () => {
      try {
        const reasons = await dispatch(getIneligibleForCoverageReasons()).unwrap();

        // Client logic for handling the array of ineligibility reasons should be as follows:
        // - If there are zero reasons, do not show the screen
        // - If there is one reason, show the screen for that reason
        // - If there are multiple reasons, precedence is as follows:
        //   - age_of_insured (user is completely blocked)
        //   - criminal_history (user is almost definitely going to be rejected)
        //   - location (this user has the best chance of ending up accepted)
        //
        // There is currently no way for there to be multiple reasons and have location take
        // precedence, so it does not need a specific handler. This can change if more reasons
        // are added.
        switch (true) {
          case !reasons.length:
            nextStepCallback()();
            break;
          case reasons.length === 1 && ALL_REASONS.includes(reasons[0]):
            setReason(reasons[0]);
            break;
          case reasons.includes(AGE_OF_INSURED):
            setReason(AGE_OF_INSURED);
            break;
          case reasons.includes(CRIMINAL_HISTORY):
            setReason(CRIMINAL_HISTORY);
            break;
          default:
            // Do nothing here -- "general" is set upon init of `reason` state
            break;
        }
      } catch (err) {
        if (get(err, "name") !== "ConditionError") {
          setError(get(err, "message", "Error occurred while checking eligibility"));
        }
      } finally {
        setLoading(false);
      }
    };

    // setting the ineligible reasons array
    checkEligibility();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading && REASON_TO_EVENT_MAP[reason]) {
      dispatch(
        eventFired({
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          event: REASON_TO_EVENT_MAP[reason]!,
          experienceLocation: "ONBOARDING",
        })
      );
    }
  }, [dispatch, loading, reason]);

  if (error) {
    return <FormError error={error} />;
  }

  return loading ? <ScreenLoader /> : <IneligibleWarning reason={reason} />;
};

export default IneligibleWarningContainer;
