import { FC, useCallback, useContext, useEffect } from "react";

import { ConditionKey } from "config/conditions";
import { Section, ValueOrArray } from "config/types";
import { useCondition } from "modules";
import { JourneySectionContext } from "modules/managers/JourneySectionProvider";

import {
  defaultTo,
  every,
  filter,
  find,
  first,
  flatten,
  flattenDeep,
  get,
  isEmpty,
  isEqual,
  map,
  uniq,
} from "lodash-es";

interface IProps {
  id: string;
  step: string;
  sections: Section[];
  active: boolean;
}

const JourneySection: FC<IProps> = props => {
  const { sections } = props;
  const { setSections, setActive, active, sectionMetadata } = useContext(JourneySectionContext);
  const activeSection = find(
    sections,
    section => !!section?.active || isEqual(section?.key, active)
  );
  const sectionConditions = flattenDeep(
    defaultTo(map(sections, "conditions"), []) as ValueOrArray<string>
  );
  const filteredConditions = filter(sectionConditions, v => !isEmpty(v));
  const uniqConditions = uniq(filteredConditions) as ConditionKey[];
  const conditions = useCondition(uniqConditions);

  const conditionsIterator = (section: Section) => {
    const empty = isEmpty(section.conditions) || every(section.conditions, isEmpty);
    if (empty) return { ...section, conditions: undefined };

    const conditionsList = flatten([section.conditions]);

    const computedCondition = every(conditionsList, value => {
      if (isEmpty(value)) return true;
      return defaultTo(conditions[value as string], false);
    });
    return { ...section, conditions: computedCondition };
  };

  const getCurrentSection = (collection: Section[]) => {
    const sectionsWithCompConditions = map(collection, conditionsIterator);
    const currentSection = find(sectionsWithCompConditions, s => s.conditions === false);
    return currentSection;
  };

  const firstSection = first(sections);
  const defaultSection = defaultTo(getCurrentSection(sections), firstSection);
  const currentSection = defaultTo(activeSection, defaultSection);
  const Element = get(currentSection, "component", null);

  const clearSectionState = useCallback(() => {
    setSections([]);
    setActive("");
  }, [setActive, setSections]);

  useEffect(() => {
    setSections(sections);

    return () => {
      clearSectionState();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (Element === null) return null;

  return (
    <div className="journey-section__container">
      <Element {...sectionMetadata} />
    </div>
  );
};

export default JourneySection;
