import { FC, useEffect } from "react";

import { paymentSuccessModalDisplayedEvent } from "analytics/billingEvents";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { useAppDispatch } from "store";

const PolicyBillingPaymentRetrySuccessModal: FC<IModalProps> = ({ closeModal }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(paymentSuccessModalDisplayedEvent());
  }, [dispatch]);

  const content = {
    icon: "BlobCheck",
    header: t("policy.modal.payment_retry.success.title", "Your payment was successful!"),
    subheader: t(
      "policy.modal.payment_retry.success.text",
      "Thanks, we were able to process your payment! You're all caught up on billing now, so there's no further action required."
    ),
    button: {
      onClick: () => closeModal(),
      text: t("policy.modal.payment_retry.success.cta", "OKAY, GOT IT"),
    },
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default PolicyBillingPaymentRetrySuccessModal;
