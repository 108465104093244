import "./BackNav.scss";

import { CSSProperties, FC, MouseEvent } from "react";
import { useDispatch } from "react-redux";

import Button from "components/Button/Button";
import { ClassName } from "config/types";
import { Link, useCondition, useJourney, useNavigate } from "modules";

import classNames from "classnames";
import { goBack } from "connected-react-router";
import { isFunction } from "lodash-es";

import { BackNavContent } from "./BackNavContent";

interface IProps {
  className?: ClassName;
  hasPortal?: boolean;
  hash?: string;
  icon?: string;
  pathname?: string;
  overrideDefaultBehavior?: boolean;
  replaceExitInJourneyWithBack?: boolean;
  search?: string;
  selfPositionedAboveContent?: boolean;
  state?: any;
  style?: CSSProperties;
  text?: string;
  onClick?(event?: MouseEvent): void;
}

const BackNav: FC<IProps> = props => {
  const {
    className,
    hasPortal,
    hash,
    icon,
    pathname,
    onClick,
    overrideDefaultBehavior,
    replaceExitInJourneyWithBack: replaceExitInJourneyWithBackProp,
    search,
    selfPositionedAboveContent,
    state,
    style,
    text,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = classNames("backnav", className);
  const containerClasses = classNames("backnav__container", {
    "backnav--self-positioned": selfPositionedAboveContent,
  });
  const { hasActiveJourney } = useCondition({ hasActiveJourney: "journey.hasActiveJourney" });
  const { currentStep, previousStepCallback, navigationMetadata } = useJourney();
  const replaceExitInJourneyWithBack =
    navigationMetadata.replaceExitInJourneyWithBack || replaceExitInJourneyWithBackProp;
  const onClickBack = () => {
    if (isFunction(onClick)) {
      onClick();
    } else if (hasActiveJourney) {
      if (
        (!replaceExitInJourneyWithBack && !overrideDefaultBehavior) ||
        currentStep.navigation?.exit
      ) {
        // Trigger Journey Exit Modal
        navigate("/");
      } else {
        const previousAction = previousStepCallback();
        previousAction();
      }
    } else {
      dispatch(goBack());
    }
  };

  const Content = (
    <BackNavContent
      hasPortal={!!hasPortal}
      iconSrc={icon}
      isExit={
        !!(hasActiveJourney && (!replaceExitInJourneyWithBack || currentStep.navigation?.exit))
      }
      text={text}
    />
  );

  const BackNav = (
    <Button
      className={classes + " backnav--btn"}
      onClick={onClickBack}
      style={style}
      subtype="text-dark">
      {Content}
    </Button>
  );

  const BackNavLink = (
    <div style={style} className={classes}>
      <Link
        className="backnav__anchor"
        data-testid="backnav-anchor"
        onClick={onClick}
        to={{
          pathname,
          search,
          hash,
          state,
        }}>
        {Content}
      </Link>
    </div>
  );

  return (
    <div className={containerClasses} data-testid="backnav">
      {pathname ? BackNavLink : BackNav}
    </div>
  );
};

export default BackNav;
