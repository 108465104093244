import "./CoverageEditor.scss";

import { FC } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { useMultipleAdsData, useTranslation } from "modules";

export const CoverageEditorAds: FC = () => {
  const { t } = useTranslation();
  const { coverageEditorAds } = useMultipleAdsData();

  return (
    <div className="coverage-editor-ads">
      <Text tag="h4" text={t("coverage-editor.ad.title", "What you get with Wysh")} />
      <Text
        tag="p2"
        text={t(
          "coverage-editor.ad.description",
          "Wysh is for those who want more from their life insurance. See what sets us apart."
        )}
      />
      <div className="coverage-editor-ads__cards">
        {coverageEditorAds.map((item, index) => (
          <div className="coverage-editor-ad__card" key={index}>
            <Icon size="lg" src={item.imgSrc} />
            <Text tag="c3" text={item.title} />
            <Text className="color-secondary-text" tag="p5" text={item.description} />
          </div>
        ))}
      </div>
    </div>
  );
};
