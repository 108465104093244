import "./OfferCard.scss";
import { FC, ReactNode } from "react";

import Button from "components/Button/Button";
import Currency from "components/Currency/Currency";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { ConditionalWrapper, useTranslation } from "modules";

import c from "classnames";

interface IProps {
  title: string;
  theme: "dark" | "light";
  premium: number;
  ctaText: string;
  secondaryCtaText?: string;
  validUntilDate: string;
  detailsItems: { label: string; value: ReactNode }[];
  bubbleText?: string;
  className?: ClassName;
  ctaLoading?: boolean;
  onClickCta(): void;
  onClickEdit?(): void;
}

const OfferCard: FC<IProps> = props => {
  const { t } = useTranslation();
  const {
    title,
    theme,
    premium,
    ctaText,
    secondaryCtaText,
    validUntilDate,
    detailsItems,
    bubbleText,
    className,
    ctaLoading,
    onClickCta,
    onClickEdit,
  } = props;

  const classes = c(
    "offer-card",
    `offer-card--details-${detailsItems.length}`,
    {
      "offer-card--dark": theme === "dark",
      "offer-card--light": theme === "light",
    },
    className
  );

  return (
    <div className={classes}>
      {bubbleText && (
        <div className="offer-card__bubble">
          <Icon src="WyshStar" size="xs" />
          <Text className="offer-card__bubble-text" tag="l3" text={bubbleText} />
          <Icon src="WyshStar" size="xs" />
        </div>
      )}

      <div className="offer-card__top">
        <Text className="offer-card__title" tag="h5" text={title} />
        <div className="offer-card__premium">
          <Text className="offer-card__premium-amount" tag="n1">
            <Currency amount={premium} fixedPoints={2} />
          </Text>
          <Text
            className="offer-card__premium-suffix"
            tag="c5"
            text={t("offer.card.premium.suffix", "/ MONTH")}
          />
        </div>
        <Button
          className="offer-card__cta"
          text={ctaText}
          onClick={onClickCta}
          isLoading={ctaLoading}
          disabled={ctaLoading}
        />
        <ConditionalWrapper condition={!!(onClickEdit && secondaryCtaText)}>
          <Button
            className={theme === "dark" ? "offer-card__secondary-cta" : "offer-card__cta"}
            text={secondaryCtaText}
            onClick={onClickEdit}
            disabled={ctaLoading}
            subtype={theme === "dark" ? "text-light-border" : "text-dark-border"}
          />
        </ConditionalWrapper>
        <Text
          className="offer-card__valid-until"
          tag="p5"
          text={t("offer.card.valid_until", "Quote valid until: %<date>s", {
            date: validUntilDate,
          })}
        />
      </div>

      <div className="offer-card__bottom">
        <div className="offer-card__details-list">
          <div className="offer-card__details-list-item">
            <Text tag="l1" text={t("offer.card.details.title", "Offer details")} />
          </div>
          {detailsItems.map((item, index) => {
            return (
              <div
                key={index}
                className="offer-card__details-list-item offer-card__details-list-item--border">
                <Text tag="l3" text={item.label} />
                <Text tag="l3" text={item.value} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
