import "./ADOfferLegalese.scss";

import { FC } from "react";

import Text from "components/Text/Text";
import VariableText from "components/VariableText/VariableText";
import { ADDisclosuresURL } from "config";
import { ConditionalWrapper, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { setModal } from "store/actions/modal";

interface IProps {
  payoutDetails?: boolean;
}

const ADOfferLegalese: FC<IProps> = ({ payoutDetails }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openPayoutDetailsModal = () => {
    dispatch(setModal("PayoutDetailsModal"));
  };

  const bodyText = t(
    "offer.ad.card.legalese.body",
    "Coverage and the benefit amount are based on type of accident."
  );
  const payoutText = t("offer.ad.card.legalese.link", "See payout details.");
  const disclosureText = t(
    "offer.ad.card.legalese.state_policy",
    "See state policy disclosures, exclusions and limitations."
  );
  const replacementText = "policy disclosures, exclusions and limitations.";

  return (
    <div className="ad-offer-legalese__container">
      <Text className="ad-offer-legalese__text" tag="p6">
        <span className="ad-offer-legalese__text">{bodyText} </span>
        <ConditionalWrapper condition={!!payoutDetails}>
          <span className="link-text" onClick={openPayoutDetailsModal}>
            {payoutText}{" "}
          </span>
        </ConditionalWrapper>
        <VariableText
          target={replacementText}
          text={disclosureText}
          replacementValue={
            <a href={ADDisclosuresURL} target="_blank" rel="noreferrer" className="link-text">
              {replacementText}
            </a>
          }
        />
      </Text>
    </div>
  );
};

export default ADOfferLegalese;
