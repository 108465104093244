import { FC } from "react";
import { useDispatch } from "react-redux";

import { Layout, useTranslation } from "modules";
import { closeModal } from "store/actions/modal";

const UnderwritingRejectionModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const content = {
    header: t("underwriting.rejection.modal.title", "Do not pass Go"),
    subheader: t(
      "underwriting.rejection.modal.body",
      "We're sorry you did not pass underwriting please try again in 30 days"
    ),
    onClose: closeModalWrapper,
    button: {
      text: t("underwriting.rejection.modal.button", "Gotcha!"),
      subType: "primary",
      onClick: closeModalWrapper,
    },
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default UnderwritingRejectionModal;
