import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

const getInvoicesById = (state: RootState) => state.invoice.byId;
const getLapsesAt = (state: RootState) => state.policy.lapsesAt;
const getPastDueBalance = (state: RootState) => state.invoice.pastDueBalance;
const getPastDueInvoiceIds = (state: RootState) => state.invoice.pastDueIds;
const getProcessingAmount = (state: RootState) => state.invoice.processingAmount;
const getProcessingInvoiceIds = (state: RootState) => state.invoice.processingIds;

export const getPastDueInvoiceData = createSelector(
  getInvoicesById,
  getLapsesAt,
  getPastDueBalance,
  getPastDueInvoiceIds,
  getProcessingAmount,
  getProcessingInvoiceIds,
  (invoices, lapsesAt, pastDueBalance, pastDueIds, processingAmount, processingInvoiceIds) => ({
    firstProcessingInvoiceDueAt: processingInvoiceIds.length
      ? invoices[processingInvoiceIds[0]]?.dueAt
      : undefined,
    lapsesAt,
    pastDueBalance,
    processingAmount,
    isPastDue: pastDueIds.length > 0,
    isProcessing: pastDueIds.length === 0 && processingInvoiceIds.length > 0,
    pastDueInvoiceIds: pastDueIds,
    processingInvoiceIds: processingInvoiceIds,
  })
);
