import "./CurrencyInput.scss";

import { FC, FocusEvent, useRef } from "react";

import TextInput from "components/TextInput/TextInput";
import { ClassName, ValueOrArray } from "config/types";
import { useFormattedInput } from "modules/hooks";
import formatCommas from "utils/formatCommas";

import classNames from "classnames";
import { parseDigit } from "input-format";

interface IProps {
  id: string;
  value: number | string;
  className?: ClassName;
  dataTestId?: string;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  hasCents?: boolean;
  label?: string;
  placeholder?: string;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  onChange(event: INumberInputEvent): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
}

export interface INumberInputEvent {
  target: {
    value: number | string;
    id: string;
  };
}

const CurrencyInput: FC<IProps> = props => {
  const { className, hasCents, id, onChange, value, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCurrencyValueChange = (currencyValue: string) => {
    let value = currencyValue;

    if (value && hasCents) {
      const numberValue = parseInt(value, 10);
      value = numberValue !== 0 ? (numberValue / 100).toFixed(2) : "";
    }

    const changeEvent = {
      target: {
        id,
        value,
      },
    };

    onChange && onChange(changeEvent);
  };

  const formatCurrency = (currencyValue?: string) => {
    const text = currencyValue ? formatCommas(currencyValue) : "";
    const template = text
      .split("")
      .map((character: string) => (/\d/.test(character) ? "x" : character))
      .join("");

    return { template, text };
  };

  const { onChange: handleOnChange, onKeyDown } = useFormattedInput({
    format: formatCurrency,
    onChange: handleCurrencyValueChange,
    parse: parseDigit,
    ref: inputRef,
  });

  const classes = classNames("currency-input", className);

  return (
    <TextInput
      {...rest}
      className={classes}
      disableFloatingLabel
      iconLeft="Dollar"
      id={id}
      inputMode={hasCents ? "decimal" : "numeric"}
      inputRef={inputRef}
      onChange={handleOnChange}
      onKeyDown={onKeyDown}
      type="text"
      value={formatCurrency(`${value}`).text}
    />
  );
};

export default CurrencyInput;
