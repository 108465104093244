import "./HonestyPledgeEligibility.scss";

import { FC } from "react";

import HonestyPledgeAD from "containers/HonestyPledgeAD/HonestyPledgeAD";
import HonestyPledgeTerm from "containers/HonestyPledgeTerm/HonestyPledgeTerm";
import { Layout, useIsADAvailableInState, useJourney } from "modules";

const HonestyPledgeEligibility: FC = () => {
  const { previousStepCallback } = useJourney();
  const adEligible = useIsADAvailableInState();
  const HonestyPledgeProduct = adEligible ? HonestyPledgeAD : HonestyPledgeTerm;

  const handleBack = async () => {
    const previousAction = previousStepCallback();
    previousAction();
  };

  return (
    <Layout
      as="FullWidthLayout"
      className="honesty-pledge-eligibility"
      content={{}}
      backNav={{
        hasBackNav: true,
        replaceExitInJourneyWithBack: true,
        onClick: handleBack,
      }}>
      {adEligible !== null ? <HonestyPledgeProduct /> : null}
    </Layout>
  );
};

export default HonestyPledgeEligibility;
