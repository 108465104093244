import { IProductDetail } from "config/types";
import { ICoverageEditorPremium, IEstimate } from "config/types/store.types";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { adjustEstimate, getCurrentUserEstimate, updateEstimate } from "store/actions/estimate";
import { getConstellationMembership } from "utils/constellation";

import { createReducer, isAnyOf, isAsyncThunkAction } from "@reduxjs/toolkit";
import { find } from "lodash-es";

import { getPreVerifiedInitialData } from "./../actions/appLoad";

/* ------------------    TYPES    ------------------ */
export interface IEstimateState extends IEstimate, ICoverageEditorPremium {
  loading: boolean;
  productDetail: IProductDetail;
}

/* ------------------    ADD MATCHERS     ------------------ */
const isEstimateFulfilled = isAnyOf(
  getDashboardData.fulfilled,
  getCurrentUserEstimate.fulfilled,
  getPreVerifiedInitialData.fulfilled
);

const isEstimatePremium = isAnyOf(adjustEstimate.fulfilled, updateEstimate.fulfilled);

const isEstimateThunkAction = isAsyncThunkAction(
  getDashboardData,
  getCurrentUserEstimate,
  adjustEstimate,
  updateEstimate
);

/* ------------------    REDUCER     ------------------ */
const defaultState: IEstimateState = {
  id: "",
  amount: 0,
  termDuration: 0,
  termUnit: "month",
  insuredsAgeAtTerm: 0,
  health: "average",
  tobacco: false,
  annualPremium: 0,
  monthlyPremium: 0,
  allocationsCoveredPercentage: 0,
  premiums: [],
  packages: [],
  productDetail: {
    id: "",
    name: "",
    slug: "",
    status: "",
    description: "",
  },
  loading: false,
};

const estimate = createReducer(defaultState, builder => {
  builder
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const estimate = action.payload?.latestEstimateDatum;

      return { ...state, ...estimate };
    })
    .addCase(getCurrentUserEstimate.fulfilled, (state, action) => {
      const estimate = action.payload;

      return { ...state, ...estimate };
    })
    .addCase(getPreVerifiedInitialData.fulfilled, (state, action) => {
      const membership = getConstellationMembership(
        action.payload.me.activeConstellations,
        "wysh-insurance"
      );
      const estimate = membership?.latestEstimateDatum || defaultState;

      return { ...state, ...estimate };
    })
    .addCase(logout, () => defaultState)
    .addMatcher(isEstimateFulfilled, state => {
      const premiums = state.premiums;
      const annualPremium = find(premiums, { billingFrequency: "annually" });
      const monthlyPremium = find(premiums, { billingFrequency: "monthly" });

      return {
        ...state,
        annualPremium: annualPremium?.amount || 0,
        monthlyPremium: monthlyPremium?.amount || 0,
      };
    })
    .addMatcher(isEstimatePremium, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addMatcher(isEstimateThunkAction, (state, action) => {
      if (action.meta.requestStatus === "pending") {
        state.loading = true;
      } else {
        state.loading = false;
      }
    });
});

export default estimate;
