import "./WishesIntro.scss";

import { FC, MouseEvent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import WishesIntroBg from "assets/images/illustrations/wishes-intro/wishes-intro.svg";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { AnalyticsIds } from "config/analytics";
import { Layout } from "modules";
import { useJourney, useModal, useNavigate } from "modules/hooks";
import { useTranslation } from "modules/hooks/useTranslation";
import { RootState } from "store";
import {
  getWyshAllocations,
  getWyshAllocationSummary,
  getWyshes,
  getWyshesCategories,
} from "store/actions/wyshes";

import { push } from "connected-react-router";
import { get } from "lodash-es";

const WishesIntro: FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { anyJourneysActive, nextStepCallback } = useJourney();
  const navigate = useNavigate();
  const wyshNowAnalyticsId = get(AnalyticsIds, "estimation.makeWyshNow", "");
  const wyshLaterAnalyticsId = get(AnalyticsIds, "estimation.makeWyshLater", "");

  const { allocated } = useSelector((state: RootState) => state.wyshAllocations);

  const hasBackNav = true;

  const content = {
    imgSrc: WishesIntroBg,
    offsetTopImage: hasBackNav ? 0 : -45,
  };

  const startCoverageCollectionFlow = () => {
    const callback = () => dispatch(push("/flow/coverage-collection"));
    const nextStep = nextStepCallback(callback, false, true);
    nextStep();
  };

  const openModal = useModal({ startCoverageCollectionFlow });

  const onSkipWishes = () => {
    if (allocated.length) {
      openModal("CancelAddedWyshesModal");
    } else {
      startCoverageCollectionFlow();
    }
  };

  const makeWyshOnClick = (event: MouseEvent) => {
    event.preventDefault();

    navigate("/wysh-builder/list");
  };

  const fetchWyshesItems = useCallback(() => {
    dispatch(getWyshes());
    dispatch(getWyshesCategories());
    dispatch(getWyshAllocations());
    dispatch(getWyshAllocationSummary());
  }, [dispatch]);

  useEffect(() => {
    fetchWyshesItems();
  }, [fetchWyshesItems]);

  return (
    <Layout
      as="TwoColumnLayout"
      className="wishes-intro__container"
      content={content}
      backNav={{ hasBackNav, pathname: "/" }}>
      <Text
        className="wishes-intro__header"
        tag="h3"
        text={t("wyshes.intro.header", "Need help figuring out your coverage needs?")}
      />
      <Text
        className="wishes-intro__body-text"
        tag="p3"
        text={t(
          "wyshes.intro.body",
          "Wyshes will help you identify all the things you want covered if you die, from paying off your home to donating to your fav charity. You can see everything, down to the dollar, so you only pay for what you need."
        )}
      />

      <div className="wishes-intro__button-wrapper" data-testid="wysh-setup__intro">
        <Button
          className="wishes-intro__button"
          subtype="primary"
          text={t("wyshes.intro.cta.primary", "YES, SET UP WYSHES")}
          onClick={makeWyshOnClick}
          dataAnalyticsId={wyshNowAnalyticsId}
          dataTestId="wyshes-intro__setup-wyshes-btn"
        />

        {anyJourneysActive && (
          <Button
            onClick={onSkipWishes}
            className="wishes-intro__button"
            subtype="text-dark-border"
            text={t("wyshes.intro.cta.secondary", "NO, I KNOW HOW MUCH I WANT")}
            dataAnalyticsId={wyshLaterAnalyticsId}
            dataTestId="wyshes-intro__skip-wyshes-btn"
          />
        )}
      </div>
    </Layout>
  );
};

export default WishesIntro;
