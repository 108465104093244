import { FC, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router";

import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import CustomerSupport from "containers/CustomerSupport/CustomerSupport";
import Disclosure, { buildDisclosureTitle } from "containers/Disclosure/Disclosure";
import { JourneyRouteManager, ProtectedRoute, Route } from "modules";
import ScrollToTop from "modules/partials/ScrollToTop";
import FlowRoute, { buildFlowTitle } from "router/FlowRoute/FlowRoute";
import NotFoundRoute from "router/NotFoundRoute/NotFoundRoute";
import { RootState } from "store";

/* ------------------------    IMPORT ROUTES - LAZY LOADED    ------------------------------ */

const AboutADRoute = lazy(() => import("router/AboutADRoute/AboutADRoute"));
const AccountLocked = lazy(() => import("components/AccountLocked/AccountLocked"));
const AccountRoutes = lazy(() => import("router/AccountRoutes/AccountRoutes"));
const BeneficiaryRoute = lazy(() => import("router/BeneficiaryRoute/BeneficiaryRoute"));
const CoverageDeniedRoute = lazy(() => import("router/CoverageDeniedRoute/CoverageDeniedRoute"));
const CoverageEditorRoute = lazy(() => import("router/CoverageEditorRoute/CoverageEditorRoute"));
const CoveragePreviewRoute = lazy(() => import("router/CoverageEditorRoute/CoveragePreviewRoute"));
const CreateNewPasswordRoute = lazy(() =>
  import("router/CreateNewPasswordRoute/CreateNewPasswordRoute")
);
const CreatePhoneRoute = lazy(() => import("router/CreatePhoneRoute/CreatePhoneRoute"));
const DashboardRoute = lazy(() => import("router/DashboardRoute/DashboardRoute"));
const FulfillmentRoute = lazy(() => import("router/FulfillmentRoute/FulfillmentRoute"));
const IneligibleWarningRoute = lazy(() =>
  import("router/IneligibleWarningRoute/IneligibleWarningRoute")
);
const LoginRoute = lazy(() => import("router/LoginRoute/LoginRoute"));
const PreAuthRoutes = lazy(() => import("router/PreAuthRoutes/PreAuthRoutes"));
const ProductsRoutes = lazy(() => import("router/ProductsRoutes"));
const QuoteRoute = lazy(() => import("router/QuoteRoute/QuoteRoute"));
const RecommendationRoute = lazy(() => import("router/RecommendationRoute/RecommendationRoute"));
const ResetPasswordRoute = lazy(() => import("router/ResetPasswordRoute/ResetPasswordRoute"));
const WyshBuilderLiteRoute = lazy(() => import("router/WyshBuilderLiteRoute/WyshBuilderLiteRoute"));
const WyshesRoute = lazy(() => import("router/WyshesRoute/WyshesRoute"));

/* ------------------------    ROUTE    ------------------------------ */
const Routes: FC = () => {
  const locked = useSelector((state: RootState) => state.account.locked);

  return locked ? (
    <AccountLocked />
  ) : (
    <ScrollToTop>
      <PreAuthRoutes>
        <Suspense fallback={<ScreenLoader />}>
          <Switch>
            <Route path="/products" component={ProductsRoutes} />
            <Route exact={true} path="/login" component={LoginRoute} title="Login" />
            <Route
              exact={true}
              path="/forgot-password"
              component={ResetPasswordRoute}
              title="Forgot Password"
            />
            <Route
              exact={true}
              path="/create-new-password/:token"
              component={CreateNewPasswordRoute}
              title="Create New Password"
            />
            <Route path="/disclosure/:slug" component={Disclosure} title={buildDisclosureTitle} />
            <Redirect from="/signup" to="/products/insurance/welcome" />
            <Route
              path="/ineligible/:reason?"
              component={IneligibleWarningRoute}
              title="Ineligible Coverage"
            />
            <Route
              path="/fulfillment/:action/:policyId?"
              component={FulfillmentRoute}
              title="Fulfillment"
            />
            <Route path="/create-phone" component={CreatePhoneRoute} title="Create Phone" />

            <Route>
              <JourneyRouteManager>
                <Switch>
                  <ProtectedRoute
                    path="/"
                    component={DashboardRoute}
                    exact
                    minimumAuthenticationLevel="identified"
                    title="Dashboard"
                  />
                  <ProtectedRoute
                    path="/beneficiary/:lineOfBusiness"
                    title="Beneficiary Overview"
                    exact
                    component={BeneficiaryRoute}
                  />
                  <ProtectedRoute
                    path="/flow/:slug"
                    exact
                    component={FlowRoute}
                    minimumAuthenticationLevel="identified"
                    title={buildFlowTitle}
                  />
                  <ProtectedRoute path="/account" component={AccountRoutes} title="Account" />
                  <ProtectedRoute
                    path="/wysh-builder"
                    component={WyshBuilderLiteRoute}
                    minimumAuthenticationLevel="identified"
                  />
                  <ProtectedRoute path="/wyshes" component={WyshesRoute} />

                  {/* Routes that will move to insurance namespace in future app architecture work */}
                  <ProtectedRoute
                    path="/about-ad"
                    component={AboutADRoute}
                    conditions={["memberships.hasInsurance"]}
                  />
                  <ProtectedRoute
                    path="/coverage-denied/:type?"
                    component={CoverageDeniedRoute}
                    conditions={["memberships.hasInsurance"]}
                    minimumAuthenticationLevel="identified"
                    title="Coverage Denied"
                  />
                  <ProtectedRoute
                    path="/coverage-preview"
                    component={CoveragePreviewRoute}
                    conditions={["memberships.hasInsurance"]}
                    exact
                    minimumAuthenticationLevel="identified"
                    title="Coverage Preview"
                  />
                  <ProtectedRoute
                    path="/edit-coverage/:type/:billingFrequency?"
                    component={CoverageEditorRoute}
                    conditions={["memberships.hasInsurance"]}
                    exact
                    minimumAuthenticationLevel="identified"
                    title="Edit Coverage"
                  />
                  <ProtectedRoute
                    path="/recommendations"
                    component={RecommendationRoute}
                    conditions={["memberships.hasInsurance"]}
                    exact
                    title="Recommendations"
                  />
                  <ProtectedRoute
                    path="/review-quote"
                    component={QuoteRoute}
                    conditions={["memberships.hasInsurance"]}
                    exact
                    title="Quote Review"
                  />
                  <ProtectedRoute path="/customer-support" component={CustomerSupport} exact />

                  {/* Redirects from old removed routes to new routes */}
                  <Redirect from="/coverage*" to="/products/insurance*" />
                  <Redirect from="/settings/billing*" to="/products/insurance/billing*" />
                  <Redirect from="/settings*" to="/account*" />

                  <Route component={NotFoundRoute} />
                </Switch>
              </JourneyRouteManager>
            </Route>
          </Switch>
        </Suspense>
      </PreAuthRoutes>
    </ScrollToTop>
  );
};

export default Routes;
