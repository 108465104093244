import {
  DynamicFormInputItems,
  DynamicFormItems,
  EnumDynamicFormNonInputItemTypes,
  IFlowQuestion,
  IFormInput,
  IFormInputs,
  OrUndefined,
} from "config/types";

import { defaultTo, every, flatten, keyBy, mapValues, values } from "lodash-es";

// resetValue should be object with keys=fieldId values=valueToResetTo
// eg. { firstName: "", joinNewsletter: true }
export interface IResetValues {
  [key: string]: any;
}

// Reset form. Provide desired reset value for each input
export const resetFormInputs = (inputs: IFormInputs, resetValues: IResetValues): IFormInputs => {
  return mapValues(inputs, input => ({ ...input, value: resetValues[input.id], error: [] }));
};

// Build IFormInputs object from IFormInput[]
export const buildFormInputs = (inputs: Partial<IFormInput>[]): IFormInputs => {
  const formInputs: IFormInputs = {};
  const allFieldsRequired = every(inputs, ["required", true]);

  inputs.forEach(input => {
    const {
      id,
      key,
      value,
      label,
      placeholder,
      validations,
      required,
      error,
      touched,
      metadata,
      inputType,
      ...rest
    } = input;

    const lbl = label && required && !allFieldsRequired ? `${label} *` : label;

    const ph = placeholder && required && !allFieldsRequired ? `${placeholder} *` : placeholder;

    const inputId = defaultTo(id, "");
    const initialInput: IFormInput = {
      ...rest,
      id: inputId,
      key: defaultTo(key, ""),
      pristineValue: defaultTo(value, ""),
      value: defaultTo(value, ""),
      validations: defaultTo(validations, []),
      error: defaultTo(error, []),
      touched: defaultTo(touched, false),
      dirty: false,
      required: defaultTo(required, false),
      metadata: defaultTo(metadata, {}),
      inputType: defaultTo(inputType, ""),
      label: defaultTo(lbl, ""),
      placeholder: defaultTo(ph, ""),
    };

    formInputs[inputId] = initialInput;
  });

  return formInputs;
};

export const buildFormInputsFromQuestions = (
  questions: IFlowQuestion[] = [],
  inputs: IFormInputs = {}
) => {
  const fields = flatten(questions.map(question => question.fields));

  const formInputData: Partial<IFormInput>[] = fields.map(field => {
    const inputType = field.inputType;
    const validations = [];

    if (inputType === "date") {
      validations.push("validateDateFormat");
      validations.push({
        name: "validateDateRange",
        min: field.metadata.min,
        max: field.metadata.max,
      });
    }

    if (inputType === "numeric") {
      validations.push({ name: "inRange", min: field.metadata.min, max: field.metadata.max });
    }

    if (inputType === "tax_id") {
      if (!field.metadata?.disabled && !field.metadata?.value) {
        validations.push("ssnTin");
      }
    }

    if (inputType === "unitized" && field.required) {
      validations.push("validateUnitizedRequired");
    }

    const inputValue = inputs[field.id]?.value || field.metadata.value;

    return {
      id: field.id,
      key: field.key,
      value: inputValue,
      required: field.required,
      metadata: {
        flowInputType: field.inputType,
      },
      validations,
    };
  });

  const formInputs = buildFormInputs(formInputData);
  return formInputs;
};

export const buildFormInputsFromFormItems = (items: DynamicFormItems[]) => {
  const filteredInputFormIitems = items.filter(
    item => !(values(EnumDynamicFormNonInputItemTypes) as unknown[]).includes(item.type)
  ) as DynamicFormInputItems[];

  const formInputData: IFormInput[] = filteredInputFormIitems.map(item => {
    const validations = [];
    let label: OrUndefined<string>;

    if (item.type === "inputText" && item.content.element === "email") {
      validations.push("email");
    }

    if (item.type === "inputDate") {
      validations.push("validateDateFormat");
      validations.push({
        name: "validateDateRange",
        min: item.content.min,
        max: item.content.max,
      });
    }

    if (item.type === "inputNumeric") {
      validations.push({ name: "inRange", min: item.content.min, max: item.content.max });
    }

    if (item.type === "inputTaxId") {
      if (!item.content?.disabled && !item.content?.value) {
        validations.push("ssnTin");
      }
    }

    if (item.type === "inputUnitized" && item.content.required) {
      validations.push("validateUnitizedRequired");
    }

    switch (item.type) {
      case "inputDate":
      case "inputNumeric":
      case "inputSingleSelect":
      case "inputTaxId":
      case "inputText":
        label = item.content.hint;
        break;

      default:
        label = item.content.label;
        break;
    }

    return {
      ...item.content,
      disabled: item.content.disabled,
      dirty: false,
      error: [],
      id: item.key,
      inputType: item.type,
      key: item.key,
      label,
      metadata: {
        flowQuestionId: item.content?.id,
        flowInputType: item.type,
      },
      pristineValue: defaultTo(item.content.value, ""),
      required: item.content.required,
      touched: false,
      validations,
      value: defaultTo(item.content.value, ""),
    };
  });

  return keyBy(formInputData, "id");
};
