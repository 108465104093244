import { INotificationState } from "config";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { getNotifications, updateNotification } from "store/actions/notifications";

import { createReducer } from "@reduxjs/toolkit";
import { find } from "lodash-es";

/* ------------    DEFAULT STATE     ------------------ */
const defaultState: INotificationState = {
  notifications: [],
  readNotifications: [],
};

/* ------------    REDUCER     ------------------ */
const notifications = createReducer(defaultState, builder => {
  builder
    .addCase(getDashboardData.fulfilled, (state, action) => {
      state.notifications = action.payload?.notifications || [];
    })
    .addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload || [];
    })
    .addCase(updateNotification.pending, (state, action) => {
      const notificationId = action.meta.arg;
      const foundNotification = find(state.notifications, { id: notificationId });

      if (foundNotification) {
        state.readNotifications.push(foundNotification);
      }
    })
    .addCase(logout, () => defaultState);
});

export default notifications;
