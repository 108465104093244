import { coverageBreakdownQuery } from "graphql/queries/coverageBreakdown";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCoverageBreakdown = createAsyncThunk(
  "fetchCoverageBreakdown",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await coverageBreakdownQuery();

      return data.me.activeConstellations;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
