import { RootState } from "store";
import { IEventFiredPayload } from "store/actions/analytics";
import * as actionType from "store/constants";
import { getInsuranceMembership } from "store/selectors";

import { createAction } from "@reduxjs/toolkit";
import { EventDefinition } from "redux-beacon";
import { sha1 as SHA1 } from "sha.js";

import {
  getDefaultAttrs,
  getExternalId,
  getProductOfferingAttr,
  getProductSelectedAttr,
} from "./defaults";

/* ------------------------    EVENT ACTION     ------------------------------ */

// Client application receives the next question for a user in their disclosure interview from AURA
export const disclosureQuestionReceivedEvent = createAction(
  actionType.DISCLOSURE_QUESTION_RECEIEVED
);

// Client application submits a user answer to a disclosure question to AURA
export const disclosureAnswerSubmittedEvent = createAction(actionType.DISCLOSURE_ANSWER_SUBMITTED);

// User clicks to purchase the policy on the Quote Review screen
export const quoteNextCtaEvent = createAction(actionType.QUOTE_NEXT_CTA);

// User accepts quote/product-offer
export const fulfillmentQuoteAcceptedEvent = createAction(actionType.FULFILLMENT_QUOTE_ACCEPTED);

// Policy is created during fulfillment journey
export const fulfillmentPolicyEvent = createAction(actionType.FULFILLMENT_POLICY_CREATED);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const disclosureQuestionEvent: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (action, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const flowFormSlug = prevState.formFlow.slug;
  const externalId = getExternalId(prevState);
  const eventName =
    action.type === actionType.DISCLOSURE_ANSWER_SUBMITTED
      ? "fulfillment_answer_submitted"
      : "fulfillment_question_received";

  // First condition checks for "evidence-underwriting-aura" and "evidence-underwriting-aura-review"
  if (!flowFormSlug.includes("evidence-underwriting-aura") || !action.type) {
    return null;
  }

  return {
    event: eventName,
    attribute: {
      ...defaultAttrs,
      externalId,
    },
  };
};

const quoteNextCta: EventDefinition<
  IEventFiredPayload | null,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");

  return {
    event: "fulfillment_quote_next",
    attribute: {
      ...defaultAttrs,
    },
  };
};

const fulfillmentPolicyCreated: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "ONBOARDING");
  const policy = prevState.policy;
  const account = getInsuranceMembership(prevState);
  const termInMonths = policy.termDuration * 12;
  const activeEmail =
    prevState.profile.activeEmail?.email || prevState.profile.pendingEmail?.email || "";
  const shaEmail = activeEmail ? new SHA1().update(activeEmail).digest("hex") : "";
  const productSelected = getProductSelectedAttr(prevState);

  return {
    event: "fulfillment_policy_created",
    attribute: {
      policyPremium: policy.activePlan.amount,
      policyAmount: policy.amount,
      policyTerm: termInMonths,
      premiumClass: policy.classification.name,
      productSelected: productSelected,
      accountId: account?.id || "",
      userEmail: shaEmail,
      policyId: policy.id,
      ...defaultAttrs,
    },
  };
};

const fulfillmentQuoteAccepted: EventDefinition<
  IEventFiredPayload,
  { [key: string]: any },
  RootState
> = (_, prevState: RootState) => {
  const defaultAttrs = getDefaultAttrs(prevState, "FULFILLMENT");
  const quote = prevState.quote;
  const termInMonths = quote.termDuration * 12;
  const productOfferAttribute = getProductOfferingAttr(prevState);
  const productSelected = getProductSelectedAttr(prevState);

  return {
    event: "fulfillment_quote_accepted",
    attribute: {
      quotePremium: quote?.monthlyPremium,
      quoteAmount: quote?.amount,
      quoteTerm: termInMonths,
      premiumClass: quote?.classification.name,
      applicationId: prevState.insuranceApplication.id,
      productOffering: productOfferAttribute,
      productSelected: productSelected,
      ...defaultAttrs,
    },
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const fulfillmentEventsMap = {
  [actionType.DISCLOSURE_QUESTION_RECEIEVED]: disclosureQuestionEvent,
  [actionType.DISCLOSURE_ANSWER_SUBMITTED]: disclosureQuestionEvent,
  [actionType.QUOTE_NEXT_CTA]: quoteNextCta,
  [actionType.FULFILLMENT_POLICY_CREATED]: fulfillmentPolicyCreated,
  [actionType.FULFILLMENT_QUOTE_ACCEPTED]: fulfillmentQuoteAccepted,
};
