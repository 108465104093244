import "./AddWyshModal.scss";

import { FC, MouseEvent, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Button/Button";
import CurrencyInput, { INumberInputEvent } from "components/CurrencyInput/CurrencyInput";
import FormError from "components/FormError/FormError";
import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import Text from "components/Text/Text";
import TruncateText from "components/TruncateText/TruncateText";
import { IFormInput } from "config";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { ConditionalWrapper, Layout, useForm } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { closeModal } from "store/actions/modal";
import { createAndUpdateWyshAllocation } from "store/actions/wyshes";
import { setWyshesAdded } from "store/actions/wyshesFlow";
import { getThunkError } from "utils/error";

import { defaultTo, filter, find, get, map, sortBy, toString } from "lodash-es";

const AddWyshModal: FC<IModalProps> = () => {
  //  Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wyshData = useSelector((state: RootState) => state.modal.data);
  const { wyshesItems } = useSelector((state: RootState) => state.wyshes);

  // Local State
  const [error, setError] = useState("");

  // Derived Local Values
  const wysh = useMemo(() => find(wyshesItems, ["id", wyshData?.wyshId]), [
    wyshData?.wyshId,
    wyshesItems,
  ]);
  const isSimpleForm = !filter(wysh?.wyshSuggestions, "enabled").length;
  const wyshSuggestions = useMemo(() => sortBy(wysh?.wyshSuggestions, "order"), [
    wysh?.wyshSuggestions,
  ]);

  const initialInputValue = wysh?.amountPlaceholder ? "" : wysh?.defaultAmount;
  const initialInputData: Partial<IFormInput>[] = [
    {
      id: "wyshAmount",
      value: initialInputValue,
      required: true,
    },
  ];

  const { form, inputs } = useForm(initialInputData, {
    showInputErrorMessages: true,
  });

  const amountInput = inputs["wyshAmount"];

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const handleClose = () => {
    dispatch(
      eventFired({
        event: "wysh_builder_navigation_dismissed_wysh",
        experienceLocation: "ONBOARDING",
        attribute: {
          wyshType: get(wysh, "defaultName"),
          wyshCategory: get(wysh, "wyshCategory.name"),
        },
      })
    );
    closeModalWrapper();
  };

  const onChange = (event: INumberInputEvent) => {
    form.onChange(event);
    setError("");
  };

  const optionOnClick = (amount: number) => {
    const optionAmount = amount === amountInput.value ? "" : amount;

    dispatch(
      eventFired({
        event: "wysh_builder_navigation_suggested_amount",
        experienceLocation: "ONBOARDING",
        attribute: {
          wyshType: get(wysh, "defaultName"),
          wyshCategory: get(wysh, "wyshCategory.name"),
        },
      })
    );

    const event = {
      target: {
        value: optionAmount,
        id: "wyshAmount",
      },
    };

    form.onChange(event);
    setError("");
  };

  const handleAddWysh = async (event: MouseEvent) => {
    event.preventDefault();

    if (!wysh) return;

    try {
      form.setLoading(true);

      const response = await dispatch(
        createAndUpdateWyshAllocation({
          wyshId: wysh?.id,
          amount: +amountInput.value,
        })
      );

      const createError = getThunkError(response);
      if (createError) {
        throw createError;
      }

      dispatch(setWyshesAdded(true));

      closeModalWrapper();
    } catch (err) {
      setError(defaultTo(err.fullMessage, err.code));
    } finally {
      form.setLoading(false);
    }
  };

  const inputPlaceholderText = wysh?.amountPlaceholder || toString(wysh?.defaultAmount);

  const headerText =
    wysh?.amountPrompt ||
    t("wysh.setup.amount.suggestion.title", "Ok, how much do you want to cover?");

  const content = {
    header: headerText,
    onClose: handleClose,
  };

  if (!wysh?.id) {
    return <ScreenLoader />;
  }

  return (
    <Layout as="ModalLayout" content={content} className="add-wysh-modal__container">
      <div className="add-wysh-modal__body">
        <TruncateText
          tag="p3"
          text={wysh?.description}
          className="add-wysh-modal__description-text"
        />
      </div>

      <ConditionalWrapper condition={!isSimpleForm}>
        <div className="add-wysh-modal__suggestion-options-wrapper">
          <div className="add-wysh-modal__suggestion">
            <Text
              className="add-wysh-modal__suggestion-text"
              tag="c6"
              text={t("add.wysh.our.suggestion", "OUR SUGGESTIONS")}
            />
          </div>

          {map(
            wyshSuggestions,
            suggestion =>
              suggestion.enabled && (
                <Button
                  className={`add-wysh-modal__suggestion-option ${amountInput.value ===
                    suggestion.amount && "active"}`}
                  subtype="text-dark-border"
                  text={suggestion.title}
                  tag="c6"
                  onClick={() => optionOnClick(suggestion.amount)}
                  key={suggestion.id}
                />
              )
          )}
        </div>
      </ConditionalWrapper>

      <CurrencyInput
        className="add-wysh-modal__number-input"
        id="wyshAmount"
        value={amountInput.value}
        placeholder={inputPlaceholderText}
        onChange={onChange}
        error={form.getInputError(amountInput.id)}
        errorMessage={form.getInputErrorMessage(amountInput.id)}
        dataTestId="add-wysh-modal__amount-input"
      />

      <ConditionalWrapper condition={!!error}>
        <FormError className="add-wysh-modal__number-input--error" error={error} />
      </ConditionalWrapper>

      <ConditionalWrapper condition={isSimpleForm && !!wysh?.amountGuidance}>
        <Text
          className="add-wysh-modal__text--protip"
          tag="c5"
          text={t("add.wysh.protip", "PRO TIP")}
        />
        <Text className="add-wysh-modal__text--guidance" tag="p6" text={wysh?.amountGuidance} />
      </ConditionalWrapper>

      <Button
        className="add-wysh-modal__btn--submit"
        text={t("add.wysh.cta.add_wysh", "ADD WYSH")}
        disabled={form.getSubmitBtnDisabled()}
        isLoading={form.loading}
        onClick={handleAddWysh}
        dataTestId="add-wysh-modal__submit"
      />
    </Layout>
  );
};

export default AddWyshModal;
