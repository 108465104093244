import React from "react";

import ListItem from "components/ListItem/ListItem";
import VariableText from "components/VariableText/VariableText";
import { useTranslation } from "modules";

import { join } from "lodash-es";

interface SuggestionsItemProps {
  item: any;
  index: number;
  highlighted: boolean;
  search?: string;
  getItemProps: (options: any) => any;
}

const SuggestionsItem: React.FC<SuggestionsItemProps> = props => {
  const { item, index, search, highlighted, getItemProps } = props;
  const { t } = useTranslation();

  const formatSuggestionItem = (suggestion: any) => {
    const street = suggestion.streetLine ? `${suggestion.streetLine}` : "";
    const entries = suggestion?.entries > 1 ? `(${suggestion.entries}),` : "";
    const secondary = suggestion?.secondary ? `${suggestion.secondary}${entries ? "" : ","}` : "";
    const city = suggestion?.city ? `${suggestion.city},` : "";
    const state = suggestion?.state ? `${suggestion.state},` : "";
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : "";

    return join([street, secondary, entries, city, state, zip], " ");
  };

  const formattedText = formatSuggestionItem(item);

  const { ref, ...itemProps } = getItemProps({ item, index });
  const hasEntries = item.entries > 1;
  const entriesText = t(
    "address_verification_input.suggestion.entries_label",
    "%<entries>s entries available",
    { entries: item.entries }
  );

  const textContent = search ? (
    <VariableText text={formattedText} target={search} />
  ) : (
    formattedText
  );

  return (
    <ListItem
      {...itemProps}
      dataTestId={`address-suggestion-item-${index}`}
      elementRef={ref}
      className={["address-suggestion-item", "max-width", { highlighted }]}
      text={textContent}
      active={highlighted}
      secondaryText={hasEntries ? entriesText : undefined}
    />
  );
};

export default SuggestionsItem;
