import "./Checkbox.scss";

import React, { CSSProperties, FC } from "react";

import Icon from "components/Icon/Icon";
import { ClassName, OrString } from "config/types";

import classNames from "classnames";

interface IProps {
  label: OrString<JSX.Element>;
  value: any;
  checkboxRef?: React.RefObject<any>;
  checked?: boolean;
  className?: ClassName;
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  style?: CSSProperties;
  onChange?(event: ICheckboxEvent): void;
}

export interface ICheckboxEvent {
  target: {
    value: boolean;
    id: string;
  };
}

const Checkbox: FC<IProps> = props => {
  const {
    checkboxRef,
    checked,
    className,
    dataTestId,
    disabled = false,
    id,
    label,
    name,
    onChange,
    style,
    value,
  } = props;

  const classes = classNames(
    "checkbox p3-tag__text",
    { "checkbox--disabled": disabled },
    className
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const changeEvent = {
      target: { value: event.target.checked, id: id || "" },
    };
    onChange && onChange(changeEvent);
  };

  return (
    <label style={style} className={classes}>
      <input
        checked={checked}
        data-testid={dataTestId || value}
        disabled={disabled}
        id={id}
        name={name}
        onChange={handleOnChange}
        ref={checkboxRef}
        type="checkbox"
        value={value}
      />
      <div className="checkbox__label-text">{label}</div>

      <div className={`checkbox__checkmark ${checked ? "checked" : ""}`}>
        <Icon size="xs" src="CheckboxSelected" className="checkbox__checkmark--icon" />
      </div>
    </label>
  );
};

export default Checkbox;
