import { fetchDashboardHomeQuery } from "graphql/queries/dashboard";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

/* ------------       THUNKS      ------------------ */

export const getDashboardData = createAsyncThunk(
  "dashboard/getDashboardData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchDashboardHomeQuery();

      const activeAccount = getActiveAccount(data.me);

      return activeAccount;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
