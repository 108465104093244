import { FC } from "react";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";

import { delay } from "lodash-es";

export const SUCCESS_MODAL_DURATION = 2000;

const Success: FC<IModalProps> = ({ closeModal, context, data }) => {
  const { t } = useTranslation();

  const onInitialRender = () => {
    const callback = () => {
      closeModal();
      context?.callbacks?.onClose && context?.callbacks?.onClose();
    };
    const closeAfterTimer = delay(callback, SUCCESS_MODAL_DURATION);
    return () => clearTimeout(closeAfterTimer);
  };

  const content = {
    header: data?.text || t("beneficiary.success_modal.title", "Success!"),
    icon: "BlobCheck",
    onInitialRender,
  };

  return <Layout as="ModalStatusLayout" content={content} />;
};

export default Success;
