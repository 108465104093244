import "./ResendCodeModal.scss";

import { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";

import Text from "components/Text/Text";
import { PhoneVerificationMode } from "config";
import { IModalProps } from "config/init/providers";
import { Layout, useModal } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { RootState, useAppDispatch } from "store";
import { requestOtpCode } from "store/actions/authentication";
import { getVerificationPhoneNumber } from "utils/phoneNumber";

import { get } from "lodash-es";

const ResendCodeModal: FC<IModalProps> = ({ closeModal, context, data }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const phoneNumbers = useSelector((state: RootState) => state.profile.phoneNumbers);
  const phoneNumberId = getVerificationPhoneNumber(phoneNumbers)?.id;
  const openModal = useModal();
  const changeMode = context.callbacks?.onModeChange;
  const onError = context.callbacks?.onError;

  const [loading, setLoading] = useState<PhoneVerificationMode | false>(false);
  const isCodeExpired =
    useSelector((state: RootState) => state.modal.data?.errorCode) === "ACCESS_CODE_EXPIRED";
  const mode = useRef<PhoneVerificationMode>((data?.mode || "sms") as PhoneVerificationMode);

  const openHelpModal = () => {
    openModal("CustomerSupportModal");
  };

  const handleSubmitResendCode = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: PhoneVerificationMode = "sms"
  ) => {
    try {
      event.preventDefault();
      setLoading(type);
      await dispatch(requestOtpCode({ phoneNumberId: phoneNumberId, type })).unwrap();
      changeMode && changeMode(type);
    } catch (error) {
      console.log(error);
      onError && onError(get(error, "code", ""), get(error, "fullMessage", ""));
    } finally {
      closeModal();
      setLoading(false);
    }
  };

  const buttonCallback = (type: PhoneVerificationMode) => {
    return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      handleSubmitResendCode(event, type);
  };

  const ctaButtons = {
    sms: [
      {
        text: t("session_verify.resend_code_modal.button_sms.label", "Text a new code"),
        isLoading: loading === "sms",
        onClick: buttonCallback("sms"),
        disabled: loading !== false,
        className: "resend-code-btn",
      },
      {
        subType: "text-dark",
        text: t("session_verify.resend_code_modal.button_voice.alt_label", "Call me instead"),
        isLoading: loading === "voice",
        onClick: buttonCallback("voice"),
        disabled: loading !== false,
        className: "resend-code-btn",
      },
    ],
    voice: [
      {
        text: t("session_verify.resend_code_modal.button_voice.label", "Call me again"),
        isLoading: loading === "voice",
        onClick: buttonCallback("voice"),
        disabled: loading !== false,
        className: "resend-code-btn",
      },
      {
        subType: "text-dark",
        text: t("session_verify.resend_code_modal.button_sms.alt_label", "Text me instead"),
        isLoading: loading === "sms",
        onClick: buttonCallback("sms"),
        disabled: loading !== false,
        className: "resend-code-btn",
      },
    ],
  };

  const expiredButtons = [
    {
      text: t("session_verify.resend_code_modal.expired.resend_button.label", "Re-send code"),
      isLoading: loading === mode.current,
      onClick: buttonCallback(mode.current),
      disabled: loading !== false,
      className: "resend-code-btn",
    },
    {
      subType: "text-dark",
      text: t("session_verify.resend_code_modal.expired.help_button.label", "Get help"),
      onClick: openHelpModal,
      disabled: loading !== false,
      className: "resend-code-btn",
    },
  ];

  const subtitle = {
    sms: t(
      "session_verify.resend_code_modal.subtitle.sms",
      "Sometimes these things take a couple mins. Still missing? We can text you a new one."
    ),
    voice: t(
      "session_verify.resend_code_modal.subtitle.voice",
      "Sometimes these things take a couple mins. Still missing? We can call you again."
    ),
  };

  const content = {
    header: isCodeExpired
      ? t("session_verify.resend_code_modal.expired.title", "Your code's expired")
      : t("session_verify.resend_code_modal.title", "Didn't get your code?"),
    subheader: isCodeExpired
      ? t(
          "session_verify.resend_code_modal.expired.subtitle",
          "No biggie, just hit the resend button below and we'll send you a new one."
        )
      : subtitle[mode.current],
    onClose: closeModal,
    button: isCodeExpired ? expiredButtons : ctaButtons[mode.current],
    icon: "BlobPlane",
  };

  return (
    <Layout as="ModalLayout" className="resend-code-modal" content={content}>
      {mode.current !== "sms" && !isCodeExpired && (
        <Text key="subtitle2" tag="p6" className="text-left verify-phone__terms-disclaimer">
          {t(
            "session_verify.resend_code_modal.disclaimer.text",
            "By selecting 'Text me instead' you agree to receive one text message per login. Message and data rates may apply. Reply HELP for help or STOP to cancel."
          )}
          <br />
          <a
            href="https://www.wysh.com/sms-terms"
            target="_blank"
            rel="noopener noreferrer"
            className="link-text">
            <b>
              {t(
                "session_verify.resend_code_modal.disclaimer.link_text",
                "Mobile Terms of Service; Privacy Policy"
              )}
            </b>
          </a>
        </Text>
      )}
    </Layout>
  );
};

export default ResendCodeModal;
