import "./AddedWishesCard.scss";

import { FC } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ICardProps } from "modules/partials/Card";
import { IWyshesAllocationState } from "store/reducers/wyshes";

interface IContent extends IWyshesAllocationState {
  onClick(allocationId: string): void;
}

export const AddedWishesCard: FC<ICardProps> = props => {
  const content = props.content as IContent;
  const { name, amount, wysh, covered, icon, id, onClick } = content;
  const color = wysh?.wyshCategory?.color || "";

  return (
    <div
      onClick={() => onClick(id)}
      className="card-content__body-container card-container card-container__added-wishes bgcolor-secondary-dark">
      <div className="card-content__icon-text-wrapper flex--center-space-between">
        <Text tag="c5" text={covered.toString()} className="card-content__icon-text" />
        <div className="card-content__icon-wrapper" style={{ background: color }}>
          <Icon className="card-content__icon" src={icon} size="lg" />
        </div>
      </div>

      <div className="card-content">
        <div className="card-content__body-text__wrapper">
          <Text tag="l4" text={name} className="card-content__body-text" />
          <Text tag="p6" text={amount.toString()} className="card-content__body-text" />
        </div>
      </div>
    </div>
  );
};
