import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

const getIsAuthenticated = (state: RootState) => state.authentication.authenticated;
export const getIsSessionVerified = (state: RootState) => state.authentication.sessionVerified;
const getIsAuthenticating = (state: RootState) => state.authentication.authenticating;

export const getIsLoggedIn = createSelector(
  getIsAuthenticated,
  getIsSessionVerified,
  (isAuthenticated, isSessionVerified) => isAuthenticated && isSessionVerified
);

export const getHasFulfilledLogIn = createSelector(
  getIsLoggedIn,
  getIsAuthenticating,
  (isLoggedIn, isAuthenticating) => isLoggedIn && !isAuthenticating
);
