import { BranchAPIKey } from "config";
import { CaseResult } from "config/types";

import Branch from "branch-sdk";

type BranchEventName = "COMPLETE_REGISTRATION" | "PURCHASE" | "VIEW_ITEM";
type BranchEventTitle = "Register User" | "Policy Purchase" | "Underwriting Result";

interface IBranchRegisterContentItems {
  $og_title: BranchEventTitle;
  $canonical_identifier: string;
}

interface IBranchPaymentContentItems {
  $og_title: BranchEventTitle;
  $canonical_identifier: string;
  $price: number;
  $term: number;
}

interface IBranchUnderwritingFailureContentItems {
  $og_title: BranchEventTitle;
}

interface IBranchRegisterCustomData {
  title: string;
  canonical_identifier: string;
}

interface IBranchPurchaseCustomData {
  title: string;
  canonical_identifier: string;
  price: number;
  term: number;
}

interface IBranchUnderwritingFailureCustomData {
  title: BranchEventTitle;
  type: CaseResult;
}

export const logBranchEvent = (
  name: BranchEventName,
  eventAndCustomData:
    | IBranchRegisterCustomData
    | IBranchPurchaseCustomData
    | IBranchUnderwritingFailureCustomData,
  contentItems:
    | IBranchRegisterContentItems[]
    | IBranchPaymentContentItems[]
    | IBranchUnderwritingFailureContentItems[]
) => {
  if (!BranchAPIKey) {
    return;
  }
  Branch.init(BranchAPIKey);

  const CustomerEventAlias = "";

  Branch.logEvent(name, eventAndCustomData, contentItems, CustomerEventAlias, function(err) {
    if (err) {
      console.error(err);
    }
  });
};

export const logBranchRegisterEvent = (userId: string) => {
  if (!userId) {
    return;
  }
  const eventAndCustomData: IBranchRegisterCustomData = {
    title: "Register User",
    canonical_identifier: userId,
  };
  const contentItems: IBranchRegisterContentItems[] = [
    { $og_title: "Register User", $canonical_identifier: userId },
  ];
  logBranchEvent("COMPLETE_REGISTRATION", eventAndCustomData, contentItems);
};

export const logBranchPurchaseEvent = (policyId: string, price: number, term: number) => {
  if (!policyId) {
    return;
  }
  const eventAndCustomData: IBranchPurchaseCustomData = {
    title: "Policy Purchase",
    canonical_identifier: policyId,
    term,
    price,
  };
  const contentItems: IBranchPaymentContentItems[] = [
    { $og_title: "Policy Purchase", $canonical_identifier: policyId, $price: price, $term: term },
  ];
  logBranchEvent("PURCHASE", eventAndCustomData, contentItems);
};

export const logBranchUnderwritingFailureEvent = (type: CaseResult) => {
  if (!type) {
    return;
  }
  const eventAndCustomData: IBranchUnderwritingFailureCustomData = {
    title: "Underwriting Result",
    type,
  };
  const contentItems: IBranchUnderwritingFailureContentItems[] = [
    { $og_title: "Underwriting Result" },
  ];
  logBranchEvent("VIEW_ITEM", eventAndCustomData, contentItems);
};
