import { ICounter } from "config/types";

import { createReducer } from "@reduxjs/toolkit";

const defaultState: ICounter = {
  timer: {},
};

const counter = createReducer(defaultState, {
  SET_TIMER: (state: ICounter, action) => {
    const name = action.payload.name;
    const value = action.payload.value;
    return {
      ...defaultState,
      timer: {
        ...state.timer,
        [name]: value,
      },
    };
  },
});
export default counter;
