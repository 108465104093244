import { IPlan, IPolicy } from "config/types";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { getPendingPolicy } from "store/actions/pendingPolicy";
import { toFixed } from "utils/toFixed";

import { createReducer } from "@reduxjs/toolkit";

export interface IPendingPolicyState extends Omit<IPolicy, "classification" | "lapsesAt"> {
  pendingPlan: IPlan;
}

const defaultState: IPendingPolicyState = {
  id: "",
  status: "",
  amount: 0,
  termDuration: 0,
  termUnit: "month",
  insuredsAgeAtTerm: 0,
  premiums: [],
  product: undefined,
  effectiveAt: "",
  pausedAt: "",
  terminatesAt: "",
  effectiveUntil: "",
  documents: [],
  pendingPlan: {
    id: "",
    amount: 0,
    billingFrequency: "monthly",
    nextPayment: {
      date: "",
      estimated: false,
      invoiceId: "",
    },
  },
};

const pendingPolicy = createReducer(defaultState, {
  [getPendingPolicy.fulfilled.type]: (state, action) => {
    const pendingPolicy = action.payload;
    const defaultPlan = pendingPolicy?.product?.pendingPlan || state.pendingPlan;
    const planAmount = toFixed(defaultPlan?.amount || 0);
    const pendingPlan = { ...defaultPlan, amount: planAmount };

    return { ...state, ...pendingPolicy, pendingPlan };
  },
  [getDashboardData.fulfilled.type]: (state, action) => {
    const pendingPolicy = action.payload?.pendingPolicy || defaultState;
    const activePlanPayload = pendingPolicy?.product?.activePlan || state.pendingPlan;
    const planAmount = toFixed(activePlanPayload?.amount || state.pendingPlan.amount);
    const pendingPlan = { ...state.pendingPlan, ...activePlanPayload, amount: planAmount };

    return { ...state, ...pendingPolicy, pendingPlan };
  },
  [logout.type]: () => defaultState,
});

export default pendingPolicy;
