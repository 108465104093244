import "./HomeProductCard.scss";

import { MouseEvent, ReactNode, useRef } from "react";

import { TextLink } from "components/Buttons";
import Divider from "components/Divider/Divider";
import Icon from "components/Icon/Icon";
import Loader from "components/Loader/Loader";
import Text from "components/Text/Text";
import { ConditionalWrapper } from "modules";
import { useAppDispatch } from "store";

import classNames from "classnames";
import { push } from "connected-react-router";

export interface IHomeProductCard {
  children: ReactNode;
  icon: string;
  link: string;
  title: string;
  text: string;
  className?: string;
  isLoading?: boolean;
}

function HomeProductCardHeader(props: Partial<IHomeProductCard>) {
  const { icon, title } = props;

  return (
    <div className="home-product-card-header">
      <div className="home-product-card-header__title">
        <Icon className="home-product-card-header__icon" src={icon} size="sm" />
        <Text tag="l5-label" className="home-product-card-header__title">
          {title}
        </Text>
      </div>
      <Icon src="CaratRight" size="xs" />
    </div>
  );
}

function HomeProductCardContainer(props: Partial<IHomeProductCard>) {
  const { className, children, link } = props;
  const dispatch = useAppDispatch();
  const currentTargetRef = useRef<MouseEvent["target"] | null>(null);

  const classes = classNames("home-product-card", className);

  const redirectToProductRoute = (event: MouseEvent) => {
    if (link && currentTargetRef.current === event.target) {
      dispatch(push(link));
    }
  };

  const setCurrentTarget = (event: MouseEvent) => {
    currentTargetRef.current = event.target;
  };

  return (
    <div className={classes} onClick={redirectToProductRoute} onMouseDown={setCurrentTarget}>
      {children}
    </div>
  );
}

function HomeProductCardDivider() {
  return <Divider className="home-product-card__divider" color="silver" />;
}

function HomeProductCardBody(props: Partial<IHomeProductCard>) {
  const { children, isLoading = false } = props;

  return (
    <div className="home-product-card__body">
      <ConditionalWrapper condition={isLoading} props={{ size: "md" }} wrapper={Loader}>
        {children}
      </ConditionalWrapper>
    </div>
  );
}

function HomeProductCardTextLink(props: Pick<IHomeProductCard, "link" | "text">) {
  const { link, text } = props;
  const preventHomeCardRedirect = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return <TextLink text={text} to={link} onClick={preventHomeCardRedirect} />;
}

export {
  HomeProductCardBody,
  HomeProductCardContainer,
  HomeProductCardDivider,
  HomeProductCardHeader,
  HomeProductCardTextLink,
};
