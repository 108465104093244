import { IWyshAllocationHistory } from "config/types";
import { getAllWyshAllocationHistories } from "store/actions/allocationHistory";
import { logout } from "store/actions/authentication";

import { createReducer } from "@reduxjs/toolkit";

const defaultState: IWyshAllocationHistory[] = [];

const allocationHistory = createReducer(defaultState, {
  [getAllWyshAllocationHistories.fulfilled.type]: (state, action) => {
    return action.payload;
  },
  [logout.type]: () => defaultState,
});

export default allocationHistory;
