import "./AuthLayout.scss";

import { FC } from "react";

import Button from "components/Button/Button";
import CornerBlob from "components/CornerBlob/CornerBlob";
import Icon from "components/Icon/Icon";
import Text, { TextType } from "components/Text/Text";
import { ButtonSubType, ValueOrArray } from "config/types";
import Container from "containers/Container/Container";
import { ILayoutProps } from "modules";

import classNames from "classnames";
import { flatten, map } from "lodash-es";

type ButtonType = {
  subType: ButtonSubType;
  text: string;
  isLoading: boolean;
  disabled: boolean;
  onClick: () => void;
  className?: string;
  dataTestId?: string;
};

type ContentType = {
  header: string;
  subheader: ValueOrArray<string>;
  button: string;
  buttonType?: ButtonSubType;
  buttonTag?: TextType;
  buttonClassName?: string;
  buttons?: ButtonType[];
  headerClassName?: string;
  icon?: string;
  cornerBlob?: boolean;
  onButtonClick?: () => void;
};

const AuthLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const header = content?.header as string;
  const subheader = content?.subheader;
  const buttonContent = content?.button;
  const onButtonClick = content?.onButtonClick;
  const buttonType = content?.buttonType;
  const buttonTag = content?.buttonTag;
  const buttonClassName = content?.buttonClassName;
  const buttons = content?.buttons || [];
  const headerClassName = content?.headerClassName;
  const icon = content?.icon;
  const hasCornerBlob = content?.cornerBlob;
  const subheaderList = flatten([subheader]);

  const classes = classNames("flex-column--center flex-stretch auth-layout", className);
  const headerContainerClasses = classNames("login-header", {
    "login-header--has-subheader": subheader,
  });
  const headerClasses = classNames("login-header__title h3", headerClassName);

  return (
    <Container className={classes}>
      {hasCornerBlob && <CornerBlob />}
      <div className={headerContainerClasses}>
        {icon ? <Icon className="login-header__icon" src={icon} size="xl" /> : null}
        <Text className={headerClasses} tag="h1" text={header} />
        {map(subheaderList, (item: string, index: number) => (
          <Text tag="p3" text={item} key={index} />
        ))}
        {buttonContent && (
          <Button
            subtype={buttonType || "primary"}
            onClick={onButtonClick}
            text={buttonContent}
            tag={buttonTag}
            className={buttonClassName}
          />
        )}
        {map(buttons, (item: ButtonType, index: number) => (
          <Button
            key={index}
            subtype={item.subType || "primary"}
            text={item.text}
            onClick={item.onClick}
            isLoading={item.isLoading}
            disabled={item.disabled}
            className={item.className}
            dataTestId={item.dataTestId}
          />
        ))}
      </div>
      {children}
    </Container>
  );
};

export default AuthLayout;
