import "./FlowReviewField.scss";

import { ComponentProps } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { IFlowField } from "config";

import classNames from "classnames";

import { FlowReviewAnswerList, FlowReviewAnswerText } from "./FlowReviewAnswers";

interface IProps {
  inputType: IFlowField["inputType"];
  isEditDisabled: boolean;
  label: IFlowField["label"];
  value: string | string[];
  options?: {
    label: string;
    value: string;
  }[];
  isLegacy?: boolean;
  unit?: string;
  onEdit(): void;
}

type TAnswerType =
  | ComponentProps<typeof FlowReviewAnswerText>["type"]
  | ComponentProps<typeof FlowReviewAnswerList>["type"]
  | null;

function getAnswerType(inputType: IFlowField["inputType"]): TAnswerType {
  switch (inputType) {
    case "blobs":
      return "boolean";

    case "multi_select":
    case "radio_group":
      return "list";

    case "long_text":
    case "numeric":
    case "unitized":
      return inputType;

    default:
      return null;
  }
}

const FlowReviewField = (props: IProps) => {
  const { inputType, isLegacy, label, isEditDisabled, onEdit, options = [], unit, value } = props;

  const answerType = getAnswerType(inputType);
  const iconClasses = classNames("flow-review-field__edit-btn", {
    "flow-review-field__edit-btn--disabled": isEditDisabled,
  });

  return (
    <div className="flow-review-field">
      <div className="flow-review-field__header">
        <Text className="flow-review-field__label" tag="l6" text={label} />
        <Icon className={iconClasses} onClick={onEdit} size="sm" src="edit" />
      </div>

      {answerType === "boolean" || answerType === "list" ? (
        <FlowReviewAnswerList options={options} type={answerType} value={value} />
      ) : null}

      {answerType === "long_text" || answerType === "numeric" || answerType === "unitized" ? (
        <FlowReviewAnswerText
          // TODO: isLegacy can be removed once DF3K is everywhere
          isSelected={!isLegacy}
          options={options}
          type={answerType}
          unit={unit}
          value={value}
        />
      ) : null}
    </div>
  );
};

export default FlowReviewField;
