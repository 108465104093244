import "./ImageItem.scss";
import { CSSProperties } from "react";

import Icon from "components/Icon/Icon";
import { IDynamicFormItemProps } from "containers/DynamicForm/DynamicFormItems";

import classNames from "classnames";
import { camelCase, kebabCase } from "lodash-es";

export function ImageItem({ item, className }: IDynamicFormItemProps<"image">) {
  const { alignment, fillColor, height, name, width } = item.content;

  const fillColorClassName = fillColor ? `fill-${kebabCase(fillColor)}\!` : "";
  const classes = classNames(
    "dynamic-form-item form-item--image form-item-image",
    `form-item-image--${alignment}`,
    fillColorClassName,
    className
  );

  const iconStyles: Partial<CSSProperties> = {};
  const svgStyles: Partial<CSSProperties> = {};

  if (height && width) {
    iconStyles.maxHeight = `${height}px`;
    iconStyles.width = `${width}px`;
    svgStyles.height = `${height}px`;
    svgStyles.width = `${width}px`;
  }

  return <Icon className={classes} src={camelCase(name)} style={iconStyles} svgStyle={svgStyles} />;
}
