import { MouseEvent, useMemo } from "react";
import { useSelector } from "react-redux";

import { IMenuItem } from "config/types";
import { useCondition, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { logout } from "store/actions/authentication";
import { setModal } from "store/actions/modal";
import { getFeatureFlagByName, getIsIdentified, useParamSelector } from "store/selectors";

import { push } from "connected-react-router";

export const useMenu = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hasActiveJourney } = useCondition({
    hasActiveJourney: "journey.hasActiveJourney",
  });

  const savingsEnabled = useParamSelector(getFeatureFlagByName, "savings_enabled");
  const isIdentified = useSelector(getIsIdentified);

  const handleLogout = () => {
    if (hasActiveJourney) {
      dispatch(setModal("JourneyExit", "", { logOutUser: true }));
    } else if (isIdentified) {
      dispatch(setModal("IdentifiedExitModal"));
    } else {
      dispatch(logout());
      dispatch(push("/login"));
    }
  };

  const headerLinksMenu = useMemo(
    () =>
      [
        { icon: "Home", text: t("tab_nav.item.1.title", "Home"), linkTo: "/", exact: true },
        {
          icon: "Insurance",
          text: t("tab_nav.item.3.title", "Insurance"),
          linkTo: "/products/insurance",
        },
        savingsEnabled && {
          icon: "SavingsPlus",
          text: t("tab_nav.item.2.title", "Savings"),
          linkTo: "/products/savings",
        },
        !isIdentified
          ? { icon: "Wyshes", text: t("tab_nav.item.4.title", "Wyshes"), linkTo: "/wyshes" }
          : undefined,
        {
          text: t("tab_nav.item.6.title", "Support"),
          icon: "ChatAgent",
          onClick: (event: MouseEvent) => {
            event.preventDefault();

            dispatch(setModal("CustomerSupportModal"));
          },
        },
        !isIdentified
          ? { icon: "Account", text: t("tab_nav.item.5.title", "Account"), linkTo: "/account" }
          : undefined,
      ].filter(link => !!link) as IMenuItem[],
    [isIdentified, savingsEnabled, t, dispatch]
  );

  const headerUnregisteredMenu = isIdentified
    ? ([
        {
          text: t("exit", "EXIT"),
          icon: "Logout",
          onClick: handleLogout,
        },
      ] as IMenuItem[])
    : undefined;

  return {
    headerLinksMenu,
    headerUnregisteredMenu,
  };
};
