import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCondition } from "modules";
import { JourneyManager } from "modules/managers/JourneyManager";
import { RootState } from "store";
import { restoreJourney } from "store/actions/journey";
import { setModal } from "store/actions/modal";

import { first, get, isEmpty, isEqual, some } from "lodash-es";
import { local } from "store2";

export const JourneyRouteManager: FC = props => {
  const dispatch = useDispatch();
  const collection = useSelector((store: RootState) => store.journey.collection);
  const userId = useSelector((store: RootState) => store.user.id);
  const currentModal = useSelector((store: RootState) => store.modal.currentModal);
  const sessionVerified = useSelector((store: RootState) => store.authentication.sessionVerified);
  const { userHasValidPhone } = useCondition({ userHasValidPhone: "user.hasVerifiedPhoneNumber" });

  const storage = local.namespace(userId);
  const journeyProgress = isEmpty(userId)
    ? {}
    : (storage.get("journeyProgress") as Partial<RootState["journey"]>);

  const isAnyJourneyActive = some(collection, item => item.active);
  const journeyProgressActive = first(get(journeyProgress, "activeJourneys")) || "";
  const isJourneyProgressOnboarding = isEqual(
    get(journeyProgress, ["collection", journeyProgressActive, "context"]),
    "onboarding"
  );

  if (!isEmpty(journeyProgress) && !isAnyJourneyActive) {
    if (isJourneyProgressOnboarding && (!userHasValidPhone || sessionVerified)) {
      dispatch(restoreJourney(journeyProgress));
    }

    if (!isEqual(currentModal, "JourneyProgress") && !isJourneyProgressOnboarding) {
      dispatch(setModal("JourneyProgress"));
    }
  }

  return isAnyJourneyActive ? <JourneyManager /> : <>{props.children}</>;
};
