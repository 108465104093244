// DEPRECATED - Use https://www.npmjs.com/package/classnames
import { isString } from "util";

import { ClassName } from "config/types";

import {
  concat,
  defaultTo,
  flattenDeep,
  join,
  pickBy,
  reduce,
  split,
  toPairs,
  trim,
  trimEnd,
  uniq,
} from "lodash-es";

/**
 * Generates a valid class list.
 * Duplicates will be removed and the result will be sorted based on javscript's string sort.
 * @param {string|array} classNames - A class name or list of class names.
 * @return {string} A class list
 */
export function classNames(...p: ClassName[]) {
  return trim(
    reduce(
      p,
      (r: any, o: any) => {
        if (isString(o)) {
          return trimEnd(join(concat(defaultTo(split(r, " "), []), [o]), " "));
        }
        const v = toPairs(pickBy(o, b => b === true));
        return trimEnd(
          uniq(flattenDeep(v))
            .filter(c => c)
            .filter(d => d !== true)
            .sort()
            .join(" ")
            .concat(" ", r)
        );
      },
      ""
    )
  );
}

export default classNames;
