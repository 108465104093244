import { IModalResource } from "config/init/providers/ModalsProvider";

import { get, isEmpty, isString, map, reduce } from "lodash-es";

import modals from "./modals";

const collection: IModalResource[] = map(modals, (value, key) => ({
  component: get(value, "component", value),
  displayName: get(value, "displayName", key),
}));

const Modals = reduce(
  collection,
  (result: Record<string, IModalResource["component"]>, value) => {
    const { component, displayName } = value;

    const payload =
      isString(displayName) && !isEmpty(displayName) ? { [displayName]: component } : {};

    return { ...result, ...payload };
  },
  {}
);

export default Modals;
