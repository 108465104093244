import "./FormDecorator.scss";

import { ComponentProps, FC } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";

type FormDecoratorProps = {
  description: ComponentProps<typeof Text>["text"];
  icon: ComponentProps<typeof Icon>;
  title: ComponentProps<typeof Text>["text"];
};

interface IProps {
  content: FormDecoratorProps;
}

const FormDecorator: FC<IProps> = props => {
  const { content } = props;
  return (
    <div className="form-content-decorator__container">
      {content.icon && (
        <div className="form-content-decorator__icon">
          <Icon size="sm" {...content.icon} />
        </div>
      )}
      <div className="form-content-decorator__content">
        <Text tag="p4" text={content.title} />
        <Text tag="b4" text={content.description} />
      </div>
    </div>
  );
};

export default FormDecorator;
