import "./SelectInput.scss";

import { CSSProperties, FC } from "react";
import { BrowserView, isTablet, MobileView } from "react-device-detect";

import CustomSelect from "components/SelectInput/CustomSelect";
import NativeSelect from "components/SelectInput/NativeSelect";
import { ClassName, ValueOrArray } from "config/types";

export interface ISelectOption {
  label: string;
  value: string;
  key?: string | null;
}

export interface ISelectEvent {
  target: { value: string; id: string };
}

export interface ISelectProps {
  id: string;
  autoCompleteOverride?: string;
  className?: ClassName;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  label?: string;
  options: ISelectOption[];
  prompt?: string;
  style?: CSSProperties;
  value?: string;
  dataTestId?: string;
  highlightSearchBuffer?: boolean;
  onChange?(event: ISelectEvent): void;
}

const SelectInput: FC<ISelectProps> = props => {
  return (
    <>
      {!isTablet && (
        <BrowserView viewClassName="select-input__view--browser">
          <CustomSelect {...props} />
        </BrowserView>
      )}
      <MobileView viewClassName="select-input__view--mobile">
        <NativeSelect {...props} />
      </MobileView>
    </>
  );
};

export default SelectInput;
