import "./FormValueProps.scss";

import { ComponentProps, FC } from "react";

import Text from "components/Text/Text";

type FormValueProps = {
  description: ComponentProps<typeof Text>["text"];
  imgSrc: string;
  title: ComponentProps<typeof Text>["text"];
};

interface IProps {
  content: FormValueProps[];
}

const FormValueProps: FC<IProps> = ({ content }) => {
  return (
    <div className="form-value-props">
      {content.map(({ description, imgSrc, title }, index) => (
        <div className="form-value-prop" key={index}>
          <img className="form-value-prop__img" alt="" src={imgSrc} />
          <Text className="form-value-prop__title" tag="c5" text={title} />
          <Text className="form-value-prop__description" tag="p5" text={description} />
        </div>
      ))}
    </div>
  );
};

export default FormValueProps;
