import { FC, FocusEvent, useCallback, useMemo, useState } from "react";

import { IFlowQuestionAdapterProps } from "components/FlowQuestions/FlowQuestion";
import { useFlowFieldAnalyticEvents } from "components/FlowQuestions/useFlowFieldAnalyticEvents";
import { ISelectOption } from "components/SelectInput/SelectInput";
import Text from "components/Text/Text";
import { IFlowField } from "config";
import { ConditionalWrapper, useModal } from "modules";

import { every, filter, find, findKey, forEach, get, has, mapValues, reduce } from "lodash-es";

import AddressInput, { Address, defaultAddress, IChangeEvent } from "./AddressInput";

type IProps = IFlowQuestionAdapterProps;

type AddressElement = "street_1" | "street_2" | "city" | "state" | "postal_code" | "country";

interface ParsedAddressElement {
  id: string;
  inputType: IFlowField["inputType"];
  key: IFlowField["key"];
  value: any;
  element: AddressElement;
  options?: ISelectOption[];
  required?: boolean;
  metadata?: Record<string, any>;
}

interface ParsedAddress {
  address1: ParsedAddressElement;
  address2: ParsedAddressElement;
  city: ParsedAddressElement;
  state: ParsedAddressElement;
  zipCode: ParsedAddressElement;
  country: ParsedAddressElement;
}

const AddressInputFlowQuestion: FC<IProps> = props => {
  const { question, inputs, fieldDisclosures, fieldTitles, form, children } = props;
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const openModal = useModal();
  const fieldKey = question.fields[0].key;
  const fieldTitle = fieldTitles[fieldKey];
  const fieldDisclosure = fieldDisclosures[fieldKey];

  const { flowFieldTextTypeEvent, flowFieldSelectTypeEvent } = useFlowFieldAnalyticEvents();

  const fieldMap: Record<keyof typeof defaultAddress, { element: string }> = {
    address1: { element: "street_1" },
    address2: { element: "street_2" },
    city: { element: "city" },
    state: { element: "state" },
    zipCode: { element: "postal_code" },
    country: { element: "country" },
  };

  const parseAddressFromFields = (fields: IFlowField[], inputs: IProps["inputs"]) => {
    return reduce(
      fields,
      (acc, field: IFlowField) => {
        const { id, inputType, key, metadata, required } = field;
        const fieldElement = metadata.element;
        const options = metadata.options ? { options: metadata.options } : {};

        const input = inputs[id];
        const inputValue = input?.value || "";
        const value = inputValue || metadata.value || "";

        const element = findKey(fieldMap, { element: fieldElement });
        const isAddressField = !!element;

        return {
          ...acc,
          ...(isAddressField && element
            ? {
                [element]: {
                  ...options,
                  id,
                  inputType,
                  key,
                  value,
                  required,
                  metadata,
                  element: fieldElement,
                },
              }
            : {}),
        };
      },
      defaultAddress
    );
  };

  const parsedAddress = (parseAddressFromFields(
    question.fields,
    inputs
  ) as unknown) as ParsedAddress;

  const handleSelectedItemChange = ({ address }: { address: Address }) => {
    forEach(parsedAddress, (input, key) => {
      if (input.id) {
        updateField(input.id, address[key as keyof Address] || "");
      }
    });
  };

  const updateField = (fieldId: string, value: string | boolean) => {
    form.onChange({ target: { id: fieldId, value } });
  };

  const onChange = (e: IChangeEvent) => {
    const key = e.target.key;
    const input = parsedAddress[key as keyof ParsedAddress];
    const changeValue = e.target.value;

    if (input?.id) {
      form.onChange({ target: { id: input.id, value: changeValue } });
    }

    flowFieldSelectTypeEvent({ event: e, field: input });
  };

  const handleClear = () => {
    forEach(parsedAddress, (input, key) => {
      if (input.id) {
        updateField(input.id, (defaultAddress as Address)[key as keyof Address] || "");
      }
    });
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    form.onBlur(event);

    const inputKey = event.target?.id;
    const input = parsedAddress[inputKey as keyof ParsedAddress];
    const errorMessages = form.getInputErrorMessage(input.id);

    flowFieldTextTypeEvent({ errorMessages, event, field: input });
  };

  const onUnsupportedState = useCallback(() => {
    openModal("UnsupportedStateModal");
  }, [openModal]);

  const onApiError = useCallback((error: string) => {
    setApiErrors((apiErrors: string[]) => [...apiErrors, error]);
  }, []);

  const parsedAddressValue: Address = mapValues(parsedAddress, address =>
    get(address, "value", address)
  );

  // Check if street1 address element is disabled or form is loading
  const isDisabled = parsedAddress?.address1?.metadata?.disabled === true || form.loading;
  const allFieldsRequired = every(inputs, { required: true });
  const isRequired = parsedAddress?.address1?.required === true && !allFieldsRequired;

  const initialInputProps = !!parsedAddressValue.address1
    ? { initialValue: parsedAddressValue }
    : {};

  const statesOptions = filter(parsedAddress.state?.options || [], option => option.key !== "");
  const preferredState = useMemo(() => {
    const preferredState = parsedAddress.state?.value || "";
    const preferredStateOption = find(statesOptions, { value: preferredState });

    return preferredStateOption?.key;
  }, [statesOptions, parsedAddress.state?.value]);

  const preferences =
    preferredState && !parsedAddressValue?.address1
      ? { preferences: { states: [preferredState] } }
      : {};

  if (!question || !inputs || !has(inputs, question.fields[0].id)) {
    return null;
  }

  if (apiErrors.length > 2) {
    return <>{children}</>;
  }

  return (
    <>
      <ConditionalWrapper condition={!!fieldTitle}>
        <Text tag="l6" text={fieldTitle} className="flow__field-title_text" />
      </ConditionalWrapper>
      <AddressInput
        {...initialInputProps}
        {...preferences}
        disabled={isDisabled}
        required={isRequired}
        states={statesOptions}
        onBlur={onBlur}
        onChange={onChange}
        onSelectedItemChange={handleSelectedItemChange}
        onClear={handleClear}
        onErrors={{ onUnsupportedState, onApiError }}
      />
      <ConditionalWrapper condition={!!fieldDisclosure}>
        <Text tag="b6" text={fieldDisclosure} className="flow__field-disclosure_text" />
      </ConditionalWrapper>
    </>
  );
};

export default AddressInputFlowQuestion;
