import "./ContactService.scss";

import {
  CustomerSupportCall,
  CustomerSupportChat,
  CustomerSupportFAQ,
} from "components/CustomerSupportTiles/CustomerSupportTiles";
import Text from "components/Text/Text";
import { useTranslation } from "modules";

export function ContactService() {
  const { t } = useTranslation();

  return (
    <div className="contact-service content-container">
      <div className="contact-service__column content-wrapper contact-service__customer-support">
        <Text tag="h4" text={t("home.tab.customer_service.header", "Need Help?")} />

        <div className="contact-service__tiles content-wrapper">
          <CustomerSupportChat />
          <CustomerSupportCall />
          <CustomerSupportFAQ />
        </div>
      </div>
    </div>
  );
}
