import "./PolicyUpdatesBanner.scss";

import { FC, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { ConditionalLink, useAccountData, useTranslation } from "modules";
import { RootState } from "store";

const PolicyUpdatesBanner: FC = () => {
  const { t } = useTranslation();
  const { pendingQuote, userHasPolicy, userHasRUWCase } = useAccountData();
  const pendingPolicyId = useSelector((state: RootState) => state.pendingPolicy.id);
  const quoteId = pendingQuote?.id;
  const hasPolicy = userHasPolicy;
  const hasRUWCase = userHasRUWCase;

  const [message, setMessage] = useState("");
  const [ctaText, setCtaText] = useState("");
  const [ctaLink, setCtaLink] = useState("");

  useLayoutEffect(() => {
    switch (true) {
      case hasPolicy && !!pendingPolicyId:
        setMessage(t("dashboard.policy.has_policy.pending_banner.title", "UPDATES PENDING"));
        setCtaText(t("dashboard.policy.has_policy.pending_banner.cta", "SEE DETAILS"));
        setCtaLink(`/products/insurance/${pendingPolicyId}/pending-details`);
        break;
      case hasPolicy && hasRUWCase:
        setMessage(t("pending_banner.policy.ruw.message", "UPDATES PENDING"));
        setCtaText(t("pending_banner.policy.ruw.cta", "SEE DETAILS"));
        setCtaLink("/products/insurance/manual-review");
        break;
      case hasPolicy && !!quoteId:
        setMessage(t("pending_banner.policy.pending_quote.message", "PENDING YOUR APPROVAL"));
        setCtaText(t("pending_banner.policy.pending_quote.cta", "REVIEW"));
        setCtaLink("/products/insurance/confirm-update");
        break;
      default:
        setMessage("");
        setCtaText("");
        setCtaLink("");
        break;
    }
  }, [hasPolicy, hasRUWCase, pendingPolicyId, quoteId, t]);

  if (message) {
    return (
      <div className="policy-updates-banner__container" data-testid="policy-updates-banner">
        <div className="policy-updates-banner__wrapper">
          <div className="policy-updates-banner__message">
            <Text tag="c5" text={message} />
          </div>
          <div className="policy-updates-banner__cta">
            <ConditionalLink condition={!!ctaLink} to={ctaLink}>
              <Button className="updates-banner__button" subtype="secondary" text={ctaText} />
            </ConditionalLink>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PolicyUpdatesBanner;
