import { START_REQUEST, END_REQUEST } from "store/constants";

import { createAction } from "@reduxjs/toolkit";

/* ------------    ACTIONS     ------------------ */

export const startRequest = createAction(START_REQUEST, (resource: string) => ({
  payload: { resource },
}));

export const endRequest = createAction(END_REQUEST, (resource: string) => ({
  payload: { resource },
}));

/* ------------       THUNKS      ------------------ */
