import "./ScreenLoader.scss";

import { FC } from "react";

import lottieImage from "assets/lottie/screen_loader.json";
import Lottie from "components/Lottie/Lottie";

const ScreenLoader: FC = () => {
  return (
    <div className="screen-loader-container">
      <div className="screen-loader-wrapper">
        <div className="screen-loader__image--wrapper">
          <Lottie className="screen-loader__image" lottieData={lottieImage} />
        </div>
      </div>
    </div>
  );
};

export default ScreenLoader;
