import { EncryptionKeys, PlatformApiUrl } from "config";

import { isFunction } from "lodash-es";

import { isLocal } from "./isLocal";

const getEncryptKey = (platformEndpoint: string, local: boolean) => {
  try {
    if (!platformEndpoint) return EncryptionKeys.default;
    const endpoint = new URL(platformEndpoint);
    const { hostname } = endpoint;

    if (local && hostname.includes(".staging.backend.")) return EncryptionKeys.staging;
    if ((!local && hostname === "localhost") || hostname.endsWith(".test"))
      return EncryptionKeys.local;

    return EncryptionKeys.default;
  } catch (error) {
    return EncryptionKeys.default;
  }
};

const local = isLocal();
const defaultEncryptKey = getEncryptKey(PlatformApiUrl, local);

export const encrypt = (message: string, key = defaultEncryptKey) => {
  const { JSEncrypt } = window as JSEncrypt.WindowWithJSEncrypt;

  if (isFunction(JSEncrypt)) {
    const jsEncrypt = new (JSEncrypt as any)();
    jsEncrypt.setPublicKey(key);
    return jsEncrypt.encrypt(message);
  } else {
    return "";
  }
};
