import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";

import Button from "components/Button/Button";
import { ClassName } from "config/types";
import { useJourney, useNavigate, useTranslation } from "modules";
import { RootState } from "store";
import { eventFired } from "store/actions/analytics";
import { getCanSeeDashboard, getIsIdentified } from "store/selectors";
import formatCommas from "utils/formatCommas";

import classNames from "classnames";

interface IProps {
  type: "header" | "footer" | "wysh-list" | "drawer";
  dataTestId?: string;
  className?: ClassName;
}

const WyshBuilderLiteCta = (props: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { nextStepCallback } = useJourney();
  const dispatch = useDispatch();
  const { dataTestId, className, type } = props;
  const wyshBuilderFlow = useSelector((state: RootState) => state.wyshesFlow.wyshBuilderFlowType);
  const isIdentifiedUser = useSelector(getIsIdentified);
  const isRegisteredUser = useSelector(getCanSeeDashboard);
  const { activeCount, totalAmount, percentCovered } = useSelector(
    (state: RootState) => state.allocationSummary
  );
  const isOnboardingUser =
    isIdentifiedUser ||
    !isRegisteredUser ||
    matchPath(location.pathname, {
      path: "/products/insurance/wysh-builder/list",
      exact: true,
    });
  const formattedAllocationsAmount = t("dollar.amount", "$%<amount>s", {
    amount: totalAmount ? formatCommas(totalAmount) : "--",
  });
  const noAmountWyshesText = t("dollar.amount", "$%<amount>s", { amount: 0 });

  const policyId = useSelector((state: RootState) => state.policy.id);

  const amountText = activeCount && !totalAmount ? noAmountWyshesText : formattedAllocationsAmount;

  const onClick = () => {
    dispatch(
      eventFired({
        event: "wysh_builder_complete",
        experienceLocation: "ONBOARDING",
        attribute: {
          wyshCount: activeCount,
          coverageAmount: totalAmount,
          ctaType: type,
        },
      })
    );

    const hasNoPolicy = !policyId;
    const hasFullCoverage = !!policyId && percentCovered >= 100;
    const hasOneOrLessAllocations = activeCount < 2;
    const skipPrioritization = hasOneOrLessAllocations || hasFullCoverage || hasNoPolicy;

    const landingPage = wyshBuilderFlow === "dashboard" ? "/" : "/wyshes";
    const nextWyshPath = skipPrioritization ? landingPage : "/wyshes/prioritize";
    const onboardingPath = isOnboardingUser ? "/coverage-preview" : nextWyshPath;

    const callback = nextStepCallback(
      () => navigate(onboardingPath, { state: { enablePrioritizeCta: true } }),
      false,
      true
    );
    callback();
  };

  const classes = classNames("wysh-builder__cta", className);

  return (
    <Button
      className={classes}
      onClick={onClick}
      disabled={!activeCount}
      text={t("wysh_builder.onboarding.cta", "CONTINUE WITH %<amount>s", {
        amount: amountText,
      })}
      dataTestId={dataTestId}
    />
  );
};

export default WyshBuilderLiteCta;
