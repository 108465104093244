import { ICloseFinanceAccountInput } from "config";
import {
  closeFinanceAccountMutation,
  createFinanceCustomerMutation,
  initiateFinanceVerificationMutation,
  uploadDocumentMutation,
} from "graphql/mutations";
import { financeCustomerQuery } from "graphql/queries/financeCustomer";
import { RootState } from "store";
import { getFinanceCustomerResultScreenPath, getFinanceMembershipId } from "store/selectors";
import { getAppError } from "utils/error";
import { socureDeviceSessionId } from "utils/socureInitialization";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { replace } from "connected-react-router";

/* ------------       THUNKS      ------------------ */

export const createFinanceCustomer = createAsyncThunk(
  "financeCustomer/createFinanceCustomer",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const membershipId = getFinanceMembershipId(state) || "";

      const { data } = await createFinanceCustomerMutation(membershipId);

      return data.createFinanceCustomer.customer;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const initiateFinanceVerification = createAsyncThunk(
  "financeCustomer/initiateFinanceVerification",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { financeCustomer } = getState() as RootState;
      const customerId = financeCustomer.id;
      const deviceSessionId = await socureDeviceSessionId();

      const { data } = await initiateFinanceVerificationMutation({
        customerId,
        ...(!!deviceSessionId && { deviceSessionId }),
      });
      return data.initiateFinanceVerification.customer;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const createAndInitiateFinanceCustomer = createAsyncThunk(
  "financeCustomer/createAndInitiateFinanceCustomer",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(createFinanceCustomer()).unwrap();
      return await dispatch(initiateFinanceVerification()).unwrap();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const predictiveDocumentVerification = createAsyncThunk(
  "financeCustomer/documentVerification",
  async (documentUuid: string, { getState, rejectWithValue }) => {
    try {
      const { financeCustomer } = getState() as RootState;
      const customerId = financeCustomer.id;

      const { data } = await uploadDocumentMutation({
        customerId,
        documentUuid: documentUuid,
      });

      return data.uploadDocument.customer;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const redirectToVerificationResult = createAsyncThunk(
  "financeCustomer/redirectToVerificationResult",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const resultRoutePath = getFinanceCustomerResultScreenPath(state);

      return dispatch(replace(resultRoutePath));
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const closeFinanceAccount = createAsyncThunk(
  "financeCustomer/closeFinanceAccount",
  async (input: ICloseFinanceAccountInput, { rejectWithValue }) => {
    try {
      const { data } = await closeFinanceAccountMutation(input);

      return data.closeFinanceAccount.response.success;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const fetchFinanceCustomer = createAsyncThunk(
  "financeCustomer/fetchFinanceCustomer",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await financeCustomerQuery();

      return data.me.financeCustomer;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
