import "./PaymentConfirmation.scss";

import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { billingCompletedConfirmEvent, billingCompleteEvent } from "analytics/billingEvents";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import List from "components/List/List";
import ListItem from "components/ListItem/ListItem";
import Text from "components/Text/Text";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { RootState } from "store";
import { finishJourney } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { resolveMUWNotification } from "store/actions/notifications";
import formatCommas from "utils/formatCommas";

import { push } from "connected-react-router";

type ProductSpecificContent = {
  header: string;
  productDetailItems: {
    header: string;
    value: string;
  }[];
};

const PaymentConfirmation: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const policy = useSelector((state: RootState) => state.policy);

  const endJourney = () => {
    dispatch(billingCompletedConfirmEvent());
    dispatch(finishJourney("fulfillment"));
    dispatch(push("/"));
  };

  const handleOnClick = () => {
    dispatch(setModal("CustomerSupportModal"));
  };

  useEffect(() => {
    // Check for Underwriting Notifications
    dispatch(resolveMUWNotification());
    dispatch(billingCompleteEvent());
  }, [dispatch]);

  const productSpecificContentMap: Record<string, ProductSpecificContent> = {
    "wysh-accidental-death": {
      header: t("payment_confirmation.header.ad", "Congrats on your Accidental Death policy!"),
      productDetailItems: [
        {
          header: t("payment_confirmation.policy_details.coverage_amount.title", "Coverage Amount"),
          value: t(
            "payment_confirmation.policy_details.coverage_amount.value.ad",
            "up to $%<coverageAmount>s",
            { coverageAmount: formatCommas(policy.amount) }
          ),
        },
        {
          header: t(
            "payment_confirmation.policy_details.coverage_term.title.ad",
            "Guaranteed Coverage"
          ),
          value: t(
            "payment_confirmation.policy_details.coverage_term.value.ad",
            "Until you’re %<insuredsAgeAtTerm>s y/o",
            {
              insuredsAgeAtTerm: policy.insuredsAgeAtTerm,
            }
          ),
        },
      ],
    },

    "wysh-term-life": {
      header: t("payment_confirmation.header.tl", "Congrats on your Term Life insurance policy!"),
      productDetailItems: [
        {
          header: t("payment_confirmation.policy_details.coverage_amount.title", "Coverage Amount"),
          value: `$${formatCommas(policy.amount)}`,
        },
        {
          header: t(
            "payment_confirmation.policy_details.coverage_term.title.tl",
            "Covered Until You’re"
          ),
          value: t(
            "payment_confirmation.policy_details.coverage_term.value.tl",
            "%<insuredsAgeAtTerm>s y/o",
            {
              insuredsAgeAtTerm: policy.insuredsAgeAtTerm,
            }
          ),
        },
      ],
    },
  };

  const productSpecificContent =
    productSpecificContentMap[
      policy.productDetail.slug as "wysh-accidental-death" | "wysh-term-life"
    ];

  return (
    <Layout as="FullWidthLayout" className="payment-confirmation">
      <div className="payment-confirmation-congratulations">
        <Text tag="c1" text={t("payment_confirmation.salutation", "WELCOME!")} />
        <Text
          className="payment-confirmation-congratulations__header"
          tag="h4"
          text={productSpecificContent.header}
        />

        <Text
          className="payment-confirmation-congratulations__welcome-message"
          tag="p2"
          text={t(
            "payment.confirmed.message",
            "You’re now the newest member of the Wysh fam (and one step closer to that financial security you crave). Now jump in to finish some final setup stuff."
          )}
        />
      </div>

      <div className="payment-confirmation-policy-details">
        <Text
          className="payment-confirmation-policy-details__header"
          tag="l1"
          text={t("payment_confirmation.policy_details.header", "Policy Details")}
        />

        <List className="payment-confirmation-policy-details__list">
          {productSpecificContent.productDetailItems.map(
            ({ header, value }: { header: string; value: string }) => {
              return (
                <ListItem
                  className="payment-confirmation-policy-details__list-item"
                  disabled
                  key={header}
                  text={header}
                  rightText={value}
                />
              );
            }
          )}
        </List>

        <Button
          className="payment-confirmation-policy-details__cta"
          dataTestId="payment-confirmation__cta"
          onClick={endJourney}
          subtype="primary"
          text={t("payment_confirmation.cta", "CONTINUE")}
        />
      </div>

      <div className="payment-confirmation-customer-support">
        <Text
          className="payment-confirmation-customer-support__header"
          tag="h5"
          text={t("payment_confirmation.cs.header", "Questions about your policy?")}
        />

        <button className="payment-confirmation-customer-support__cta" onClick={handleOnClick}>
          <Icon src="BlobFAQ" size="md" />
          <Text tag="c5" text={t("payment_confirmation.cs.chat.cta", "CHAT WITH US")} />
        </button>

        <Text
          className="payment-confirmation-customer-support__additional-info"
          tag="p3"
          text={t(
            "payment_confirmation.cs.chat.info",
            "Live chat support is available Monday-Friday during business hours (9am-5pm EST)."
          )}
        />
      </div>
    </Layout>
  );
};

export default PaymentConfirmation;
