import "./ProductOffering.scss";

import { ReactNode } from "react";

import { ClassName } from "config";

import classNames from "classnames";

interface IProductOfferingContainerOrColumn {
  children: ReactNode;
  className?: ClassName;
}

export function ProductOfferingContainer(props: IProductOfferingContainerOrColumn) {
  const { children, className } = props;

  return <div className={classNames("product-offering-container", className)}>{children}</div>;
}

export function ProductOfferingColumn({ children, className }: IProductOfferingContainerOrColumn) {
  return <div className={classNames("product-offering-column", className)}>{children}</div>;
}

export * from "./ProductOfferingDetails";
export * from "./ProductOfferingHeaders";
export * from "./ProductOfferingPremiumCalculation";
export * from "./ProductOfferingPremiumInfo";
export * from "./ProductOfferingValueProps";
