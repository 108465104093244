import { RootState } from "store";

import { filter, reduce } from "lodash-es";

export const getWyshTermLifeOffering = (state: RootState) => {
  const termOfferings = filter(state.productOffering.byId, ["productSlug", "wysh-term-life"]);
  const latestTermOffer = reduce(termOfferings, (latestOffer, offering) => {
    return latestOffer?.createdAt > offering.createdAt ? latestOffer : offering;
  });

  return latestTermOffer?.status === "expired" ? undefined : latestTermOffer;
};

export const getWyshADOffering = (state: RootState) => {
  const ADOfferings = filter(state.productOffering.byId, ["productSlug", "wysh-accidental-death"]);
  const latestADOffer = reduce(ADOfferings, (latestOffer, offering) => {
    return latestOffer?.createdAt > offering.createdAt ? latestOffer : offering;
  });

  return latestADOffer;
};

export const getCurrentProductOffer = (state: RootState) => {
  const termOffer = getWyshTermLifeOffering(state);
  const adOffer = getWyshADOffering(state);

  return termOffer || adOffer;
};

// USE FOLLOW MOCK TO RETURN AN AD OFFERING
// CAN BE REMOVED WHEN BACKEND PROVIDES AN AD RESULT SELECTOR

// import { IProductOffering } from "config";

// const mockOffering: IProductOffering = {
//   id: "1",
//   amount: 650000,
//   productSlug: "wysh-accidental-death",
//   premiums: [{ id: "12", billingFrequency: "monthly", amount: 8.99 }],
//   expiresAt: "2022-08-26T23:59:59-04:00",
//   insuredsAgeAtTerm: 65,
//   allocationsCoveredPercentage: 90,
//   monthlyPremium: 99,
//   annualPremium: 1000,
//   termDuration: 30,
//   termUnit: "year",
// };

// export const getWyshADOffering = () => {
//   return mockOffering;
// };
