import { FC } from "react";

import { RouteHistory } from "config/types";
import { Store } from "store";

import { ConnectedRouter } from "connected-react-router";

import BranchProvider from "./BranchProvider";
import BrazeProvider from "./BrazeProvider";
import { MetadataProvider } from "./MetadataProvider";
import { ModalsProvider } from "./ModalsProvider";
import { OAuthProvider } from "./OAuthProvider";
import { PlatformProvider } from "./PlatformProvider";
import RefreshTokenProvider from "./RefreshTokenProvider";
import StripeProvider from "./StripeProvider";

interface IProps {
  history: RouteHistory;
  store: Store;
}

// const AllowRefreshToken: FC = ({ children }) => {
//   const identityPayload = session.get("AuthIdentity");
//   const isDevOrTestingEnv = includes(["development", "testing"], env);

//   const allowFromIdentityPayload = JSON.parse(get(identityPayload, "bypassVerification", false));
//   const allow = (allowTokenRefresh || allowFromIdentityPayload) && isDevOrTestingEnv;

//   return allow ? <RefreshTokenProvider>{children}</RefreshTokenProvider> : <>{children}</>;
// };

const AppProvider: FC<IProps> = props => {
  const { children, history, store } = props;

  return (
    <ConnectedRouter history={history}>
      <PlatformProvider store={store}>
        <MetadataProvider>
          <OAuthProvider>
            <RefreshTokenProvider>
              <BranchProvider>
                <StripeProvider>
                  <BrazeProvider>
                    <ModalsProvider />
                    {children}
                  </BrazeProvider>
                </StripeProvider>
              </BranchProvider>
            </RefreshTokenProvider>
          </OAuthProvider>
        </MetadataProvider>
      </PlatformProvider>
    </ConnectedRouter>
  );
};

export default AppProvider;

export * from "./MetadataProvider";
export * from "./ModalsProvider";
export * from "./OAuthProvider";
export * from "./PlatformProvider";
