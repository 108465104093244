import "./PremiumCalculationModal.scss";

import { FC } from "react";
import { useSelector } from "react-redux";

import { BlobList, BlobListItem } from "components/List/BlobList/BlobList";
import Text from "components/Text/Text";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";
import { getWyshTermLifeOffering } from "store/selectors/getProductOffering";

const PremiumCalculationModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const termOffering = useSelector(getWyshTermLifeOffering);
  const closeModalWrapper = () => dispatch(closeModal());

  const onClickConfirm = () => {
    closeModalWrapper();
  };

  const content = {
    header: t(
      "offerings_review.premium_calculation.modal.title.new",
      "What factors go into your monthly premiums?"
    ),
    subheader: (
      <>
        <Text
          className="premium-calculation-modal__subtitle"
          tag="p3"
          text={
            !!termOffering
              ? t(
                  "offerings_review.premium_calculation.modal.subtitle.new",
                  "Insurance premiums (aka what you pay each month for coverage) are based on four factors:"
                )
              : t(
                  "offerings_review.premium_calculation.modal.ad.subtitle.new",
                  "Accidental Death Insurance premiums (aka what you pay each month for coverage) are based on two factors:"
                )
          }
        />

        <BlobList className="premium-calculation-modal__subtitle-list">
          <BlobListItem>
            {t(
              "offerings_review.premium_calculation.modal.item1",
              "Your Coverage Amount (Death Benefit)"
            )}
          </BlobListItem>
          {!!termOffering && (
            <BlobListItem>
              {t("offerings_review.premium_calculation.modal.item2", "Your Term Length")}
            </BlobListItem>
          )}
          <BlobListItem>
            {t("offerings_review.premium_calculation.modal.item3", "Your Risk Class*")}
          </BlobListItem>
          {!!termOffering && (
            <BlobListItem>
              {t("offerings_review.premium_calculation.modal.item4", "Your Age")}
            </BlobListItem>
          )}
        </BlobList>

        <Text
          className="premium-calculation-modal__footnote"
          tag="p6"
          text={t(
            "offerings_review.premium_calculation.modal.legal",
            "*Your risk class is determined by the underwriting process."
          )}
        />
      </>
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("offerings_review.premium_calculation.modal.cta.new", "OKAY"),
        subType: "primary",
        onClick: onClickConfirm,
      },
    ],
    onButtonClick: closeModalWrapper,
    icon: "BlobPolicy",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} className="premium-calculation-modal" />;
};

export default PremiumCalculationModal;
