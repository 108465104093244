import { ComponentProps, FC, isValidElement, useEffect } from "react";

import { ClassName, IDynamicForm } from "config/types";
import NotFound from "containers/NotFound/NotFound";
import { useAppDispatch } from "store";
import { setJourneyProgressPercentOverride } from "store/actions/journey";

import classNames from "classnames";
import { defaultTo, get, isString } from "lodash-es";

import AuthLayout from "./AuthLayout/AuthLayout";
import CenteredLayout from "./CenteredLayout/CenteredLayout";
import DisclosureLayout from "./DisclosureLayout/DisclosureLayout";
import FullScreenModalLayout from "./FullScreenModalLayout/FullScreenModalLayout";
import FullWidthLayout from "./FullWidthLayout/FullWidthLayout";
import ImageBackgroundLayout from "./ImageBackgroundLayout/ImageBackgroundLayout";
import MessageLayout from "./MessageLayout/MessageLayout";
import ModalLayout from "./ModalLayout/ModalLayout";
import ModalStatusLayout from "./ModalStatusLayout/ModalStatusLayout";
import { NavigationSection } from "./NavigationSection/NavigationSection";
import OverlayLayout from "./OverlayLayout/OverlayLayout";
import ADProductOfferingLayout from "./ProductOfferingLayouts/ADProductOfferingLayout/ADProductOfferingLayout";
import TLProductOfferingLayout from "./ProductOfferingLayouts/TLProductOfferingLayout/TLProductOfferingLayout";
import SidebarMenuLayout from "./SidebarMenuLayout/SidebarMenuLayout";
import SplashMessage from "./SplashMessage/SplashMessage";
import ScrollingTabsLayout from "./TabsLayouts/ScrollingTabsLayout/ScrollingTabsLayout";
import SidebarTabsLayout from "./TabsLayouts/SidebarTabsLayout/SidebarTabsLayout";
import TriviaLayout from "./TriviaLayout/TriviaLayout";
import TwoColumnLayout from "./TwoColumnLayout/TwoColumnLayout";

export const layouts = {
  ADProductOfferingLayout,
  AuthLayout,
  CenteredLayout,
  DisclosureLayout,
  FullScreenModalLayout,
  FullWidthLayout,
  ImageBackgroundLayout,
  MessageLayout,
  ModalLayout,
  ModalStatusLayout,
  OverlayLayout,
  ScrollingTabsLayout,
  SidebarTabsLayout,
  SidebarMenuLayout,
  SplashMessage,
  TLProductOfferingLayout,
  TriviaLayout,
  TwoColumnLayout,
};

interface IProps {
  as: keyof typeof layouts;
  content?: NonNullable<ComponentProps<typeof layouts[IProps["as"]]>["content"]>;
  className?: ClassName;
  renderFallback?: boolean;
  fallback?: keyof typeof layouts | FC | any;
  finalScreen?: boolean;
  backNav?: {
    hasBackNav?: boolean;
    leadingNavigationButton?: IDynamicForm["leadingNavigationButton"];
    pathname?: string;
    icon?: string;
    text?: string;
    replaceExitInJourneyWithBack?: boolean;
    overrideDefaultBehavior?: boolean;
    onClick?(): void;
  };
  nextNav?: {
    hasNextNav?: boolean;
    pathname: string;
    icon?: string;
    text: string;
    onClick?(): void;
  };
}

export interface ILayoutProps<Content> {
  className?: ClassName;
  content?: Content | Record<string, unknown>;
}

export const Layout: FC<IProps> = props => {
  const dispatch = useAppDispatch();
  const {
    as,
    className,
    children,
    content,
    backNav,
    nextNav,
    fallback = NotFound,
    finalScreen,
    renderFallback,
  } = props;
  const Element = get(layouts, as);
  const hasBackNav = backNav?.hasBackNav;

  useEffect(() => {
    if (finalScreen) {
      dispatch(setJourneyProgressPercentOverride(100));
    }
  }, [dispatch, finalScreen]);

  const layoutProps = {
    className: classNames(className, { "has-backnav": hasBackNav }),
    content,
  };

  if (renderFallback) {
    const Fallback = isString(fallback)
      ? get(layouts, fallback, defaultTo(fallback, null))
      : fallback;

    return isValidElement(fallback) ? fallback : <Fallback {...layoutProps} />;
  }

  return (
    <>
      <NavigationSection as={as} backNav={backNav} nextNav={nextNav} />
      <Element {...layoutProps}>{children}</Element>
    </>
  );
};

export default Layout;
