import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  billingCCFailureEvent,
  billingCCSubmitEvent,
  paymentPolicyDisplayedEvent,
  policyDocClickEvent,
} from "analytics/billingEvents";
import { fulfillmentPolicyEvent } from "analytics/fulfillmentEvents";
import PolicyAcknowledgements from "components/PurchasePolicy/PolicyAcknowledgements";
import PolicyCoverages from "components/PurchasePolicy/PolicyCoverages";
import PolicyLegalese from "components/PurchasePolicy/PolicyLegalese";
import PurchasePolicy from "components/PurchasePolicy/PurchasePolicy";
import { IInvoice, IPolicy } from "config";
import { AnalyticsIds } from "config/analytics";
import { useCreateCCPayment, useJourney } from "modules/hooks";
import { RootState, useAppDispatch } from "store";
import { createPurchaseAcknowledgements } from "store/actions/acknowledgement";
import { getDisclosure } from "store/actions/disclosure";
import { setShowJourneyProgress } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { createPayment } from "store/actions/payment";
import { getCurrentUserPolicy } from "store/actions/policy";
import { getQuote, updateQuote } from "store/actions/quote";
import { getLatestPayableInvoice, selectReviewDisclosures } from "store/selectors";
import formatCommas from "utils/formatCommas";
import { poll } from "utils/poll";

import { filter, find, get } from "lodash-es";

type PolicyPollContext = {
  invoice: IInvoice | undefined;
  policy: IPolicy | undefined;
};

const PurchasePolicyContainer: FC = () => {
  //  Hooks
  const dispatch = useAppDispatch();
  const { exitJourney, nextStepCallback } = useJourney();
  const { clearCCPaymentFields, createCCPayment } = useCreateCCPayment();

  const quote = useSelector((state: RootState) => state.quote);
  const documentsAndDisclosures = useSelector(selectReviewDisclosures);
  const hasPolicy = useSelector((state: RootState) => !!state.policy.id && !!state.policy.status);
  const latestPayableInvoice = useSelector(getLatestPayableInvoice);

  // Local State
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [adCheckbox, setADCheckbox] = useState(false);
  const [quoteData, setQuoteData] = useState({
    amount: "0",
    coveragePremium: "0",
    term: 0,
    isLoaded: false,
  });

  // Local Methods
  const acceptQuoteAndGetInvoice = async () => {
    // If there is already an invoice, the user has already had acknowledgements
    // created and has accepted the quote, so just return the latest
    // payable invoice
    if (latestPayableInvoice) {
      return latestPayableInvoice;
    }

    await createAcknowledgements();

    await dispatch(
      updateQuote({
        quoteId: quote.id,
        status: "accepted",
        billingFrequency: "monthly",
      })
    ).unwrap();

    // Start the polling for the policy data
    const policyResponse = await poll<PolicyPollContext>(fetchPolicyData);

    await dispatch(fulfillmentPolicyEvent());

    return policyResponse?.context.invoice;
  };

  // Form onSubmit handler
  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);

      await createCCPayment();

      const invoice = await acceptQuoteAndGetInvoice();

      if (!invoice) {
        throw new Error();
      }

      const {
        processed: [processedInvoice],
      } = await submitPayment(invoice);

      if (processedInvoice.transactionResult === "failed") {
        throw {
          fullMessage: processedInvoice.reason,
        };
      }

      dispatch(billingCCSubmitEvent());

      dispatch(setShowJourneyProgress(false));
      const callback = nextStepCallback(undefined, false);
      callback();
    } catch (error) {
      dispatch(setModal("PaymentFailed"));
      clearCCPaymentFields();
      dispatch(billingCCFailureEvent({ reason: error.fullMessage }));
    } finally {
      setLoading(false);
    }
  };

  const submitPayment = async (invoice: IInvoice) => {
    return await dispatch(
      createPayment({
        invoiceId: invoice.id,
        purchasePolicy: true,
      })
    ).unwrap();
  };

  const createAcknowledgements = async () => {
    const acknowledgementsPayload = filter(documentsAndDisclosures, record => {
      if (record?.slug === "replace-accidental-death" && !adCheckbox) {
        return false;
      } else {
        return true;
      }
    });

    return await dispatch(createPurchaseAcknowledgements(acknowledgementsPayload)).unwrap();
  };

  const fetchPolicyData = useCallback(async () => {
    const response = await dispatch(getCurrentUserPolicy()).unwrap();

    const activePolicy = response.activePolicy;
    const activePlan = activePolicy?.product?.activePlan;
    const latestPayableInvoice = response.latestPayableInvoice;

    // Poll for activePolicy until an active plan with an invoice exists
    // or activePolicy is already in "paid" status
    const resolved = !!activePlan && !!latestPayableInvoice?.status;

    const context = {
      invoice: latestPayableInvoice,
      policy: activePolicy,
    };
    return { resolved: resolved, context };
  }, [dispatch]);

  // Derived Local Values
  const policyDocHref = find(quote.documents, { slug: "quote" })?.html?.url;
  const soghDocHref = find(quote.documents, { slug: "statement-of-good-health" })?.html?.url;
  const isQuoteAccidentalDeath = quote.product?.slug === "wysh-accidental-death";
  const purchaseAnalyticsId = get(AnalyticsIds, "quote.purchasePolicy", "");

  // Effects
  useEffect(() => {
    if (hasPolicy) {
      exitJourney();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchDocumentData = async () => {
      setLoadingData(true);

      try {
        await Promise.all([
          dispatch(getQuote()),
          dispatch(getDisclosure({ slug: "replace-accidental-death" })),
        ]);

        dispatch(paymentPolicyDisplayedEvent());
      } catch (error) {
        return error;
      } finally {
        setLoadingData(false);
      }
    };

    fetchDocumentData();
  }, [dispatch]);

  // Set quoteData on API fetch and persist in useState
  useEffect(() => {
    if (quote.id && !quoteData.isLoaded) {
      const coverageAmount = formatCommas(quote.amount);
      const coveragePremium = formatCommas(quote.monthlyPremium);
      const quoteTerm = isQuoteAccidentalDeath ? 65 : quote.termDuration;

      setQuoteData({
        amount: coverageAmount,
        coveragePremium,
        term: quoteTerm,
        isLoaded: true,
      });
    }
  }, [quote, isQuoteAccidentalDeath, quoteData.isLoaded]);

  return (
    <PurchasePolicy
      adCards={["30-day-guarantee", "trusted-payments"]}
      analyticsId={purchaseAnalyticsId}
      loadingData={loadingData}
      onSubmit={handleFormSubmit}
      renderAcknowledgements={() => (
        <PolicyAcknowledgements
          adCheckbox={adCheckbox}
          isQuoteAccidentalDeath={isQuoteAccidentalDeath}
          setADCheckbox={setADCheckbox}
        />
      )}
      renderCoverages={() => (
        <PolicyCoverages
          amount={quoteData.amount}
          isQuoteAccidentalDeath={isQuoteAccidentalDeath}
          term={quoteData.term}
        />
      )}
      renderLegalese={() => (
        <PolicyLegalese
          coveragePremium={quoteData.coveragePremium}
          isQuoteAccidentalDeath={isQuoteAccidentalDeath}
          onClickPolicyDoc={() => dispatch(policyDocClickEvent())}
          policyDocHref={policyDocHref}
          soghDocHref={soghDocHref}
        />
      )}
      submitting={loading}
    />
  );
};

export default PurchasePolicyContainer;
