import { ChangeEvent, KeyboardEvent, MutableRefObject, useCallback } from "react";

import {
  FormatFunction,
  onChange as _onChange,
  onKeyDown as _onKeyDown,
  ParseFunction,
} from "input-format";

interface IOptions {
  format: FormatFunction;
  parse: ParseFunction;
  ref: MutableRefObject<any>;
  onChange(value: string): void;
}

export const useFormattedInput = ({ format, onChange, parse, ref }: IOptions) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      _onChange(event, ref.current, parse, format, onChange),
    [format, onChange, parse, ref]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) =>
      _onKeyDown(event, ref.current, parse, format, onChange),
    [format, onChange, parse, ref]
  );

  return {
    onChange: handleChange,
    onKeyDown: handleKeyDown,
  };
};
