import { FaqUrl } from "config";

import { isFunction } from "lodash-es";

interface WindowWithCSTools extends Window {
  zoomCampaignSdk?: typeof zoomCampaignSdk;
  zE?: Zendesk.ZendeskWidget;
}

/*
Due to closeChat() being assigned to onClick prop at App.tsx component level, usages of openChat() 
or toggleCustomerSupportChat() will often have to be preceded with an event.stopPropagation() method.
*/

let isOpen = false;

export const openChat = () => {
  const { zoomCampaignSdk, zE } = window as WindowWithCSTools;

  try {
    if (zoomCampaignSdk && isFunction(zoomCampaignSdk.open)) {
      zoomCampaignSdk.open();

      isOpen = true;
    } else if (isFunction(zE)) {
      zE("messenger", "open");

      isOpen = true;
    }
  } catch (error) {
    console.log(error.message);
    return error;
  }
};

// Note - function is assigned to onClick on App.tsx component level to close widget on outside click
export const closeChat = () => {
  const { zoomCampaignSdk, zE } = window as WindowWithCSTools;

  try {
    if (zoomCampaignSdk && isFunction(zoomCampaignSdk.close)) {
      zoomCampaignSdk.close();

      isOpen = false;
    } else if (isFunction(zE)) {
      zE("messenger", "close");

      isOpen = false;
    }
  } catch (error) {
    return error;
  }
};

// Used to toggle Solvvy/Zendesk Web Widgets
export const toggleCustomerSupportChat = () => {
  if (isOpen) {
    return closeChat();
  } else if (!isOpen) {
    return openChat();
  }
};

export const openFaqLink = () => {
  window.open(FaqUrl, "_blank");
};
