import { logger } from "utils/logger";

import { defaultTo, forOwn, get, isEmpty } from "lodash-es";

interface IOptions {
  dictionary?: { [key: string]: string };
  replacements?: { [key: string]: string | number };
  prefix?: string;
  suffix?: string;
}

const interpolate = (
  term: string,
  replacements: { [key: string]: string | number },
  prefix: string,
  suffix: string
) => {
  let interpolated = term;

  forOwn(replacements, (value, key) => {
    const regex = new RegExp(`${prefix}${key}${suffix}`, "g");
    interpolated = interpolated.replace(regex, value?.toString());
  });

  return interpolated;
};

export const translate = (key: string, fallbackTerm: string, options: IOptions = {}) => {
  const { dictionary = {}, replacements = {}, prefix = "%<", suffix = ">s" } = options;

  const termFromDict = get(dictionary, key, null);

  if (!termFromDict) {
    logger(`Key not found: ${key}`, true);
  }

  const term = defaultTo(termFromDict, fallbackTerm).replace("\\n", "\n");

  return isEmpty(replacements) ? term : interpolate(term, replacements, prefix, suffix);
};
