import { useContext } from "react";

import { IDynamicFormItemProps } from ".";

import { DevTaxInputActionItem } from "components/DevComponents/DevTaxInputItem/DevTaxInputActionItem";
import TINInput from "components/TINInput/TINInput";

import { DynamicFormContext } from "../DynamicFormContext";

export function TaxIdInputItem({ item }: IDynamicFormItemProps<"inputTaxId">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const { disabled } = content;
  const { label, value } = form.getFormInput(key);

  return (
    <>
      <TINInput
        className="dynamic-form-item form-item--input-tax-id"
        disabled={disabled}
        error={form.getInputError(key)}
        errorMessage={form.getInputErrorMessage(key)}
        id={key}
        label={label}
        onBlur={form.onBlur}
        onChange={form.onChange}
        value={value}
      />
      <DevTaxInputActionItem id={key} inputValue={value} onChange={form.onChange} />
    </>
  );
}
