import "./DynamicFormItems.scss";
import { ComponentProps } from "react";

import { ClassName, DynamicFormItems, DynamicFormItemType } from "config";

import { ButtonItem } from "./ButtonItem";
import { CheckboxInputItem } from "./CheckboxInputItem";
import { DateInputItem } from "./DateInputItem";
import DecorationItem from "./DecorationItem";
import { ImageItem } from "./ImageItem";
import { LeadingNavigationButton } from "./LeadingNavigationButton";
import { ListRowItem } from "./ListRowItem";
import { MultiSelectInputItem } from "./MultiSelectInputItem";
import { NumericInputItem } from "./NumericInputItem";
import { SingleSelectInputItem } from "./SingleSelectInputItem";
import { SliderInputItem } from "./SliderInputItem";
import { SpacerItem } from "./SpacerItem";
import { TaxIdInputItem } from "./TaxIdInputItem";
import { TextInputItem } from "./TextInputItem";
import { TextItem } from "./TextItem";
import { UnitizedInputItem } from "./UnitizedInputItem";

export type IDynamicFormItemProps<T extends DynamicFormItemType> = {
  item: Extract<DynamicFormItems, { type: T }>;
  className?: ClassName;
};

type DynamicFormItemComponentMap = {
  button: typeof ButtonItem;
  decoration: typeof DecorationItem;
  image: typeof ImageItem;
  inputCheckbox: typeof CheckboxInputItem;
  inputDate: typeof DateInputItem;
  inputMultiSelect: typeof MultiSelectInputItem;
  inputNumeric: typeof NumericInputItem;
  inputSingleSelect: typeof SingleSelectInputItem;
  inputSlider: typeof SliderInputItem;
  inputTaxId: typeof TaxIdInputItem;
  inputText: typeof TextInputItem;
  inputUnitized: typeof UnitizedInputItem;
  listRow: typeof ListRowItem;
  space: typeof SpacerItem;
  text: typeof TextItem;
};

export type ComponentDynamicFormItem = ComponentProps<
  DynamicFormItemComponentMap[keyof DynamicFormItemComponentMap]
>["item"];

interface DynamicFormItemComponentProps {
  item: ComponentDynamicFormItem;
  className?: ClassName;
}

export const DynamicFormItemComponent = ({ item, className }: DynamicFormItemComponentProps) => {
  switch (item.type) {
    case "button":
      return <ButtonItem item={item} />;

    case "decoration":
      return <DecorationItem item={item} />;

    case "image":
      return <ImageItem item={item} />;

    case "inputCheckbox":
      return <CheckboxInputItem item={item} />;

    case "inputDate":
      return <DateInputItem item={item} />;

    case "inputMultiSelect":
      return <MultiSelectInputItem item={item} />;

    case "inputNumeric":
      return <NumericInputItem item={item} />;

    case "inputSingleSelect":
      return <SingleSelectInputItem item={item} />;

    case "inputSlider":
      return <SliderInputItem item={item} />;

    case "inputTaxId":
      return <TaxIdInputItem item={item} />;

    case "inputText":
      return <TextInputItem item={item} />;

    case "inputUnitized":
      return <UnitizedInputItem item={item} />;

    case "listRow":
      return <ListRowItem item={item} className={className} />;

    case "space":
      return <SpacerItem item={item} />;

    case "text":
      return <TextItem item={item} />;
  }
};

export {
  ButtonItem,
  CheckboxInputItem,
  DateInputItem,
  DecorationItem,
  ImageItem,
  LeadingNavigationButton,
  ListRowItem,
  MultiSelectInputItem,
  NumericInputItem,
  SingleSelectInputItem,
  SliderInputItem,
  SpacerItem,
  TaxIdInputItem,
  TextInputItem,
  TextItem,
  UnitizedInputItem,
};
