import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { initApolloClient } from "config/apollo";
import AuthRequest from "config/AuthRequest";
import {
  PlatformApiFallbackUrl,
  PlatformApiHealthEndpoint,
  PlatformApiUrl,
} from "config/constants";
import { OrBoolean } from "config/types";
import { Store } from "store";
import { fetchGlobalFlags } from "store/actions";
import { setDeviceFingerprint } from "store/actions/device-fingerprint";
import { fetchTranslations } from "store/actions/translations";
import { formatHealthResponse } from "utils/healthcheck";

import { get, isEqual } from "lodash-es";
import { DateTime } from "luxon";

export type PlatformContextValue = {
  endpoint: string;
  fallback: boolean;
  healthy: OrBoolean<null>;
  timestamp?: DateTime;
};

interface IProps {
  store: Store;
}

const defaultContextValue: PlatformContextValue = {
  fallback: false,
  healthy: null,
  endpoint: PlatformApiUrl,
};
export const PlatformContext = createContext<PlatformContextValue>(defaultContextValue);

export const PlatformProvider: FC<IProps> = ({ children, store }) => {
  const [state, setState] = useState(defaultContextValue);
  const [resolved, setResolved] = useState(false);
  const dispatch = useDispatch();

  const formatHealth = useCallback((response: Response) => {
    const formatted = formatHealthResponse(response);
    printStatus(response);
    return formatted;
  }, []);

  const checkHealth = useCallback(
    async (baseUrl = PlatformApiFallbackUrl, healthPath = PlatformApiHealthEndpoint) => {
      try {
        const endpoint = new URL(healthPath, baseUrl);
        const response = await fetch(endpoint.href);
        const formattedResponse = formatHealth(response);
        if (
          formattedResponse.healthy === false &&
          !isEqual(formattedResponse.url.origin, PlatformApiFallbackUrl)
        ) {
          throw Error("Current Endpoint could not be reached.");
        }
        return formattedResponse;
      } catch (error) {
        return error;
      }
    },
    [formatHealth]
  );

  const followUp = useCallback(
    async (response: ReturnType<typeof formatHealth>) => {
      const payload: PlatformContextValue = {
        fallback: true,
        healthy: response.healthy,
        timestamp: response.timestamp,
        endpoint: response.url.origin,
      };
      await dispatch(setDeviceFingerprint());
      new AuthRequest(store, response.url.origin);
      initApolloClient(store, response.url.origin);
      await Promise.all([dispatch(fetchTranslations()), dispatch(fetchGlobalFlags())]);
      setState(payload);
      setResolved(true);
    },
    [formatHealth] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const checkApiHealth = useCallback(() => {
    return checkHealth(PlatformApiUrl)
      .then(response => {
        if (response instanceof Error) {
          throw response;
        }

        return response;
      })
      .catch(error => {
        console.log(error);

        return checkHealth();
      })
      .then(response => {
        if (!(response instanceof Error)) {
          return followUp(response);
        }

        setState(prevState => {
          return {
            ...prevState,
            healthy: get(response, "healthy", false),
            timestamp: DateTime.local(),
          };
        });

        setResolved(true);
      });
  }, [checkHealth, followUp]);

  useEffect(() => {
    if (!resolved) checkApiHealth();
  }, [checkApiHealth, resolved]);

  const printStatus = (response: Response) => {
    console.log("ENDPOINT HEALTH CHECK-UP: ", response.url);
    console.log("HEALTHY: ", response.ok);
  };

  return (
    <PlatformContext.Provider value={state}>{resolved ? children : null}</PlatformContext.Provider>
  );
};
