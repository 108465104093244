import "./PhoneNumberInput.scss";

import {
  ComponentProps,
  CSSProperties,
  FC,
  FocusEvent,
  useCallback,
  useRef,
  useState,
} from "react";

import TextInput from "components/TextInput/TextInput";
import { ClassName } from "config/types";
import { useFormattedInput, useTranslation } from "modules/hooks";

import classNames from "classnames";
import { AsYouType, CountryCode, parsePhoneNumberCharacter } from "libphonenumber-js";

interface IProps extends Pick<ComponentProps<typeof TextInput>, "error" | "errorMessage"> {
  id: string;
  value: string;
  className?: ClassName;
  dataTestId?: string;
  disabled?: boolean;
  label?: string;
  style?: CSSProperties;
  onChange?(event: IUSPhoneNumberInputEvent): void;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
}

export interface IUSPhoneNumberInputEvent {
  target: {
    id: string;
    value: string;
  };
}

const USPhoneNumberInput: FC<IProps> = props => {
  const {
    className,
    dataTestId,
    disabled = false,
    error,
    errorMessage,
    id,
    label,
    onBlur,
    onChange,
    style,
    value,
  } = props;
  const [country] = useState<CountryCode>("US");
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const handlePhoneNumberChange = (phoneNumber: string) => {
    const changeEvent = {
      target: {
        id,
        value: phoneNumber,
      },
    };

    onChange && onChange(changeEvent);
  };

  const formatPhoneNumber = useCallback(
    (phoneNumber?: string) => {
      const formatter = new AsYouType(country);
      const text = phoneNumber ? formatter.input(phoneNumber) : "";
      const template = formatter.getTemplate();

      return {
        template,
        text,
      };
    },
    [country]
  );

  const { onChange: handleOnChange, onKeyDown } = useFormattedInput({
    format: formatPhoneNumber,
    onChange: handlePhoneNumberChange,
    parse: parsePhoneNumberCharacter,
    ref: inputRef,
  });

  const classes = classNames(className, "phone-number");

  return (
    <div className={classes} data-testid={dataTestId} style={style}>
      <TextInput
        activeLabel={label || t("inputs.phone_number.label", "Phone number")}
        className="phone-number__input"
        dataTestId="phone-number__number"
        disabled={disabled}
        error={error}
        errorMessage={errorMessage}
        id="phone"
        inputRef={inputRef}
        label={label || t("inputs.phone_number.label", "Phone number")}
        onBlur={onBlur}
        onChange={handleOnChange}
        onKeyDown={onKeyDown}
        type="tel"
        value={formatPhoneNumber(value).text}
      />
    </div>
  );
};
export default USPhoneNumberInput;
