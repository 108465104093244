import { IWyshAllocation } from "config/types/wysh.types";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import {
  createWyshAllocation,
  createWyshAllocationRecipient,
  destroyWyshAllocation,
  getWyshAllocations,
  removeWyshAllocationRecipient,
  updateWyshAllocation,
  updateWyshAllocationRecipient,
} from "store/actions/wyshes";
import { findWyshIconUrls } from "utils/findIconUrl";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { filter, map, sortBy } from "lodash-es";

/* ------------    TYPES    ------------------ */
export interface IWyshesAllocationState extends IWyshAllocation {
  icon: string;
  infoHeader: string;
  detailsHeader: string;
}

interface IWyshAllocationsState {
  allocated: IWyshesAllocationState[];
}

const defaultState: IWyshAllocationsState = {
  allocated: [],
};

/* ------------    ADD MATCHERS     ------------------ */
const setWyshAllocationRecipientMatcher = isAnyOf(
  createWyshAllocationRecipient.fulfilled,
  updateWyshAllocationRecipient.fulfilled
);

/* ------------    REDUCER     ------------------ */
const wyshAllocations = createReducer(defaultState, builder => {
  builder
    .addCase(getWyshAllocations.fulfilled, (state, action) => {
      const allocations = action.payload || [];

      const sortedAllocations = mapAndSortAllocations(allocations);

      state.allocated = sortedAllocations;
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      const allocations = action.payload?.account?.wyshAllocations || [];

      const sortedAllocations = mapAndSortAllocations(allocations);

      state.allocated = sortedAllocations;
    })
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const allocations = action.payload?.wyshAllocations || [];

      const sortedAllocations = mapAndSortAllocations(allocations);

      state.allocated = sortedAllocations;
    })
    .addCase(destroyWyshAllocation.fulfilled, (state, action) => {
      const wyshAllocationId = action.payload;

      const filterWyshAllocations = filter(state.allocated, allocation => {
        return allocation.id !== wyshAllocationId;
      });

      state.allocated = filterWyshAllocations;
    })
    .addCase(createWyshAllocation.fulfilled, (state, action) => {
      const wyshAllocation = action.payload;
      const wyshIcons = findWyshIconUrls(wyshAllocation.wysh);
      const defaultAmount = wyshAllocation.amount === null ? 0 : wyshAllocation.amount;

      const wyshAllocationRecord = {
        ...wyshAllocation,
        ...wyshIcons,
        amount: defaultAmount,
      };

      state.allocated = [...state.allocated, wyshAllocationRecord];
    })
    .addCase(updateWyshAllocation.fulfilled, (state, action) => {
      const wyshAllocation = action.payload;
      const updatedWyshAllocations = map(state.allocated, allocation => {
        if (allocation.id === wyshAllocation.id) {
          return { ...allocation, ...wyshAllocation };
        } else {
          return allocation;
        }
      });

      state.allocated = updatedWyshAllocations;
    })
    .addCase(removeWyshAllocationRecipient.fulfilled, (state, action) => {
      const { wyshAllocationId } = action.payload;

      const updatedWyshAllocations = map(state.allocated, allocation => {
        if (allocation.id === wyshAllocationId) {
          return { ...allocation, recipient: null };
        } else {
          return allocation;
        }
      });

      state.allocated = updatedWyshAllocations;
    })
    .addCase(logout, () => defaultState)
    .addMatcher(setWyshAllocationRecipientMatcher, (state, action) => {
      const { recipient, wyshAllocationId } = action.payload;

      const updatedWyshAllocations = map(state.allocated, allocation => {
        if (allocation.id === wyshAllocationId) {
          return { ...allocation, recipient };
        } else {
          return allocation;
        }
      });

      state.allocated = updatedWyshAllocations;
    });
});

// Unnest wysh icon urls, default allocation.amount to 0 if null, return sorted by `order` value
const mapAndSortAllocations = (allocations: IWyshAllocation[]) => {
  const mappedAllocations = map(allocations, allocation => {
    const wyshIcons = findWyshIconUrls(allocation?.wysh);
    const defaultAmount = allocation.amount === null ? 0 : allocation.amount;

    return {
      ...allocation,
      ...wyshIcons,
      amount: defaultAmount,
    };
  });

  return sortBy(mappedAllocations, "order");
};

export default wyshAllocations;
