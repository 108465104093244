import { fetchAllWyshAllocationHistories } from "graphql/queries/allocationHistory";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllWyshAllocationHistories = createAsyncThunk(
  "wyshes/allWyshAllocationHistories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAllWyshAllocationHistories();
      const activeAccount = getActiveAccount(response.data.me);

      return activeAccount?.allocationHistories || {};
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
