import "./ProductOfferingPremiumCalculation.scss";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ClassName } from "config";
import { useTranslation } from "modules";

import classNames from "classnames";

interface IProductOfferingPremiumCalculation {
  className?: ClassName;
  onClick(): void;
}

export function ProductOfferingPremiumCalculation(props: IProductOfferingPremiumCalculation) {
  const { className, onClick } = props;

  const { t } = useTranslation();

  return (
    <div
      className={classNames("product-offering-premium-calculation", className)}
      onClick={onClick}>
      <Icon src="info" size="sm" color="cta" />
      <Text
        tag="l5-label"
        text={t("review_quotev2.term.offer.info.calculation", "How we calculate premiums")}
      />
    </div>
  );
}
