import "./HeroImageHeader.scss";

import { FC } from "react";

import { ClassName } from "config/types";

import c from "classnames";

interface IProps {
  image: string;
  className?: ClassName;
}

const HeroImageHeader: FC<IProps> = ({ image, className }) => {
  const classes = c("hero-image-header", className);
  return <div className={classes} style={{ backgroundImage: `url(${image})` }} />;
};

export default HeroImageHeader;
