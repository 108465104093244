import React, { ComponentProps, useContext } from "react";

import { IDynamicForm } from "config";
import Modal from "containers/Shared/Modal/Modal";
import { Layout, useModal } from "modules";
import { useAppDispatch } from "store";
import { dismissDynamicFormModal } from "store/actions/flow";

import { DynamicFormContext } from "../DynamicFormContext";
import { MapGroupComponentToItems } from "../DynamicFormGroups";
import { DynamicFormItemComponent, LeadingNavigationButton } from "../DynamicFormItems";
import { useButtonAction } from "../useButtonAction";

interface IProps extends ComponentProps<typeof Modal> {
  groups: IDynamicForm["groups"];
  items: IDynamicForm["items"];
  leadingNavigationButton?: IDynamicForm["leadingNavigationButton"];
}

export function ModalFlowLayout(props: IProps) {
  const { groups, items, leadingNavigationButton } = props;
  const { modalOverlay } = useContext(DynamicFormContext);
  const dispatch = useAppDispatch();
  const openModal = useModal();
  const actionCommands = useButtonAction(leadingNavigationButton?.actions || []);

  // METHODS
  const handleOnClose = () => {
    dispatch(dismissDynamicFormModal());
  };

  // execute on modal close by overlay click or ESC
  const handleNonStandardDismiss = async () => {
    try {
      if (leadingNavigationButton?.style === "hidden") {
        await actionCommands();
      }
    } catch (error) {
      openModal("GeneralErrorModal", "", { error });
    }
  };

  // RENDER
  return (
    <Layout
      as="OverlayLayout"
      className="dynamic-form-layout__modal"
      content={{
        headerProps: {
          closeButton: !!leadingNavigationButton && (
            <LeadingNavigationButton content={leadingNavigationButton} isModalLayout />
          ),
        },
        onClose: handleOnClose,
        onOverlayClickAndEsc: handleNonStandardDismiss,
        shouldCloseOverlay: modalOverlay?.shouldCloseModalOverlay,
      }}>
      <MapGroupComponentToItems items={items} groups={groups}>
        {items.map(item => (
          <DynamicFormItemComponent item={item} key={item.key} />
        ))}
      </MapGroupComponentToItems>
    </Layout>
  );
}
