import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { OrBoolean, OrNull, RouteAction, RouteLocation } from "config/types";

import { UnregisterCallback } from "history";
import { defaultTo } from "lodash-es";

type BlockLocationState = {
  ignoreAction?: boolean;
};

export const usePrompt = (
  shouldRender: boolean,
  cb: (location: RouteLocation, action: RouteAction) => OrBoolean<void>
) => {
  const history = useHistory<BlockLocationState>();
  const unblock = useRef<OrNull<UnregisterCallback>>(null);

  useEffect(() => {
    if (shouldRender) {
      unblock.current = history.block((location, action) => {
        const unregister = defaultTo(cb(location, action), false);
        const skipAction = defaultTo(location.state?.ignoreAction, false);
        if (unregister === true || skipAction) return;

        return false;
      });
    } else {
      unblock.current = null;
    }

    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [history, shouldRender, cb]);
};
