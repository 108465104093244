import { FormEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PurchasePolicy from "components/PurchasePolicy/PurchasePolicy";
import ReinstatablePolicyCoverages from "components/PurchasePolicy/ReinstateablePolicyCoverages";
import ReinstateablePolicyLegalese from "components/PurchasePolicy/ReinstateablePolicyLegalese";
import { IInvoice, IReinstateablePolicy } from "config";
import { useCreateCCPayment, useJourney, usePolicyReinstatementData } from "modules";
import { RootState, useAppDispatch } from "store";
import {
  createReinstatementInvoice,
  getCurrentUserLatestPayableInvoice,
} from "store/actions/invoice";
import { setShowJourneyProgress } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { createPayment } from "store/actions/payment";
import { getCurrentUserPolicy } from "store/actions/policy";
import { getQuote, updateQuote } from "store/actions/quote";
import {
  getLatestPayableInvoice,
  selectReinstateablePolicyById,
  selectReinstatementProductOfferingByPolicyId,
  useParamSelector,
} from "store/selectors";
import { poll } from "utils/poll";

import { find } from "lodash-es";

const ELIGIBLE_STATUSES_FOR_PAYMENT = ["created", "short_paid", "failed", "retryable"];

type LatestPayableInvoice = {
  invoice: IInvoice | undefined;
};

function PurchaseReinstatedPolicy() {
  const dispatch = useAppDispatch();
  const { clearCCPaymentFields, createCCPayment } = useCreateCCPayment();
  const { exitJourney, nextStepCallback } = useJourney();
  const { refetch: getReinstateablePolicies } = usePolicyReinstatementData();

  const policyId = useSelector(
    (state: RootState) =>
      state.journey.collection.reinstatementFulfillment?.metadata
        ?.reinstateablePolicyId as IReinstateablePolicy["id"]
  );
  const policy = useParamSelector(selectReinstateablePolicyById, policyId);
  const productOffering = useParamSelector(selectReinstatementProductOfferingByPolicyId, policyId);
  const latestPayableInvoice = useSelector(getLatestPayableInvoice);
  const { id: quoteId, documents: quoteDocuments } = useSelector((state: RootState) => state.quote);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dueToday = productOffering?.dueToday || 0;
  const monthlyPremium =
    productOffering?.premiums.find(premium => premium.billingFrequency === "monthly")?.amount || 0;
  const policyDocHref = find(quoteDocuments, { slug: "quote" })?.html?.url;
  const soghDocHref = find(quoteDocuments, { slug: "statement-of-good-health" })?.html?.url;

  const fetchLatestPayableInvoice = useCallback(async () => {
    const latestPayableInvoice = await dispatch(getCurrentUserLatestPayableInvoice()).unwrap();

    return {
      context: {
        invoice: latestPayableInvoice,
      },
      resolved: !!latestPayableInvoice?.status,
    };
  }, [dispatch]);

  const fetchPolicyData = useCallback(async () => {
    const response = await dispatch(getCurrentUserPolicy()).unwrap();

    const activePolicy = response.activePolicy;
    const activePlan = activePolicy?.product?.activePlan;

    return {
      context: {},
      resolved: !!activePlan,
    };
  }, [dispatch]);

  const generateAndGetInvoice = async () => {
    if (
      latestPayableInvoice &&
      ELIGIBLE_STATUSES_FOR_PAYMENT.includes(latestPayableInvoice.status)
    ) {
      return latestPayableInvoice;
    }

    await dispatch(
      updateQuote({ quoteId, billingFrequency: "monthly", status: "accepted" })
    ).unwrap();

    const { success } = await dispatch(createReinstatementInvoice(policyId)).unwrap();

    if (!success) {
      throw new Error("Reinstatement invoice was unable to be created");
    }

    const policyResponse = await poll<LatestPayableInvoice>(fetchLatestPayableInvoice);

    return policyResponse?.context.invoice;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsSubmitting(true);

      await createCCPayment();

      const invoice = await generateAndGetInvoice();

      if (!invoice) {
        throw new Error();
      }

      const {
        processed: [processedInvoice],
      } = await dispatch(createPayment({ invoiceId: invoice.id })).unwrap();

      if (processedInvoice.transactionResult === "failed") {
        throw {
          fullMessage: processedInvoice.reason,
        };
      }

      await poll(fetchPolicyData);

      // Updates reinstateable policies on behalf of
      // useReinstateablePolicyData hook
      getReinstateablePolicies();

      dispatch(setShowJourneyProgress(false));
      nextStepCallback(undefined, false)();
    } catch (err) {
      dispatch(setModal("PaymentFailed"));
      clearCCPaymentFields();
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (policy?.status && policy.status !== "terminated") {
      exitJourney();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchReinstateablePolicy = async () => {
      try {
        setIsLoadingData(true);

        await Promise.all([
          getReinstateablePolicies(),
          dispatch(getCurrentUserLatestPayableInvoice()).unwrap(),
          dispatch(getQuote()).unwrap(),
        ]);
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!latestPayableInvoice || (policyId && !policy) || !quoteId) {
      fetchReinstateablePolicy();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PurchasePolicy
      adCards={["trusted-payments"]}
      loadingData={isLoadingData}
      onSubmit={handleSubmit}
      renderCoverages={() => (
        <ReinstatablePolicyCoverages dueToday={dueToday} monthlyPremium={monthlyPremium} />
      )}
      renderLegalese={() => (
        <ReinstateablePolicyLegalese
          dueToday={dueToday}
          monthlyPremium={monthlyPremium}
          policyDocHref={policyDocHref}
          soghDocHref={soghDocHref}
        />
      )}
      submitting={isSubmitting}
    />
  );
}

export default PurchaseReinstatedPolicy;
