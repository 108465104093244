import { FC, useContext, useEffect } from "react";

import { paymentSuccessModalDisplayedEvent } from "analytics/billingEvents";
import { IModalProps, ModalContext } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { useAppDispatch } from "store";

const SettingsBillingPaymentRetrySuccessModal: FC<IModalProps> = props => {
  const dispatch = useAppDispatch();
  const { callbacks } = useContext(ModalContext);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(paymentSuccessModalDisplayedEvent());
  }, [dispatch]);

  const closeModal = props.closeModal;
  const billingAttemptedAmount = props.data.billingAttemptedAmount as string;
  const source = props.data.source as "bank_account" | "credit_card";

  const headerTextOptions = {
    bank_account: t(
      "settings.billing.modal.payment_retry.success.title.bank_account",
      "We updated your bank account and processed your payment"
    ),
    credit_card: t(
      "settings.billing.modal.payment_retry.success.title.credit_card",
      "We updated your card and processed your payment"
    ),
  };

  const content = {
    icon: "BlobCheck",
    header: headerTextOptions[source],
    subheader: t(
      "settings.billing.modal.payment_retry.success.text",
      "Good news! We updated your payment method on file and processed your payment for $%<billingAttemptedAmount>s. You’re all caught up on billing now, so there’s no further action required. Thanks!",
      { billingAttemptedAmount }
    ),
    button: {
      onClick: () => closeModal(callbacks?.onClose),
      text: t("settings.billing.modal.payment_retry.success.cta", "OKAY, GOT IT"),
    },
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default SettingsBillingPaymentRetrySuccessModal;
