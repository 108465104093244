import "./AddressInput.scss";

import { ChangeEvent, FC, FocusEvent, useState } from "react";

import Button from "components/Button/Button";
import SelectInput, { ISelectOption } from "components/SelectInput/SelectInput";
import TextInput from "components/TextInput/TextInput";
import { ClassName } from "config";
import { useTranslation } from "modules";
import { useSmartyCore } from "modules/hooks/useSmartyAddress";

import classNames from "classnames";
import { UseComboboxStateChange, useCombobox } from "downshift";
import { find, get, isEmpty, isString, join, merge, throttle } from "lodash-es";
import { core, usAutocompletePro, usStreet } from "smartystreets-javascript-sdk";

import Suggestions from "./AddressInputSuggestions";

export type Lookup = usAutocompletePro.Lookup;
export type Suggestions = Lookup["result"];
export type Suggestion = usAutocompletePro.Suggestion;

export interface IChangeEvent {
  target: {
    value: string;
    key: string;
  };
}

export interface AddressInputErrors {
  onApiError?(error?: string): void;
  onUnsupportedState?(error?: string): void;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  verified?: boolean;
}

type AddressData = Pick<Address, "address1" | "address2" | "city" | "state" | "zipCode">;
export type AddressDataKey = keyof AddressData;
type AddressFieldKey = AddressDataKey | "search";

interface Preferences {
  states?: string[];
  cities?: string[];
  zipCodes?: string[];
  geocode?: boolean;
}

interface IProps {
  id?: string;
  className?: ClassName;
  required?: boolean | Record<AddressFieldKey, boolean>;
  dataTestId?: string;
  disabled?: boolean;
  initialValue?: Address;
  preferences?: Preferences;
  states?: ISelectOption[];
  style?: React.CSSProperties;
  errors?: Partial<Record<AddressDataKey, string | boolean>>;
  onErrors?: Partial<AddressInputErrors>;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  onChange?(event: IChangeEvent): void;
  onSelectedItemChange?(value: { suggestion: Suggestion; address: Address }): void;
  onClear?(): void;
}

export const defaultAddress = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "US",
};

const AddressInput: FC<IProps> = props => {
  const {
    children,
    className,
    onBlur,
    onChange,
    onSelectedItemChange,
    required,
    disabled,
    onClear,
    errors = {},
    onErrors = {},
    states = [],
    initialValue = defaultAddress,
  } = props;
  const { t } = useTranslation();
  const { autocomplete, street, utils } = useSmartyCore();
  const autoCompleteClient = autocomplete.client;
  const usStreetClient = street.client;
  const [suggestions, setSuggestions] = useState<Lookup | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const initialAddress: Address = merge({}, defaultAddress, initialValue);
  const [address, setAddress] = useState<Address>(initialAddress);
  const [expanded, setExpanded] = useState<boolean>(
    initialAddress.address1 !== defaultAddress.address1
  );
  const [inputValue, setInputValue] = useState<string>("");
  const [isOpenState, setIsOpenState] = useState<boolean>(false);

  const onIsOpenChange = ({ isOpen }: UseComboboxStateChange<Suggestion>) => {
    if (isOpen !== undefined && isOpenState !== isOpen) {
      setIsOpenState(isOpen);
    }
  };

  const downshift = useCombobox<Suggestion>({
    inputValue,
    onIsOpenChange,
    isOpen: isOpenState,
    defaultIsOpen: false,
    initialIsOpen: false,
    items: suggestions?.result || [],
    onSelectedItemChange: ({ selectedItem }) => selectedItem && selectSuggestion(selectedItem),
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.InputBlur:
          break;
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem && newSelectedItem.entries > 1) {
            setInputValue(formatMultiEntryInputText(newSelectedItem));
          }
          break;
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          const value = newInputValue || "";
          setInputValue(value);
          break;
        default:
          break;
      }
    },
  });

  const { isOpen, getInputProps, getItemProps, getMenuProps } = downshift;

  const formatAutocompleteSuggestion = (suggestion: Suggestion) => {
    const street = suggestion.streetLine ? `${suggestion.streetLine}` : "";
    const secondary = suggestion?.secondary ? `${suggestion.secondary}` : "";
    const entries = suggestion?.entries !== 0 ? `(${suggestion.entries})` : "";
    const city = suggestion?.city ?? "";
    const state = suggestion?.state ?? "";
    const zip = suggestion?.zipcode ?? "";

    return join([street, secondary, entries, city, state, zip], " ");
  };

  const formatMultiEntryInputText = (suggestion: Suggestion) => {
    const street = suggestion.streetLine ? `${suggestion.streetLine}` : "";
    const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : "";

    return join([street, secondary], ", ");
  };

  const updateField = (key: keyof Address, value: string) => {
    onChange && onChange({ target: { key, value } });

    setError(null);
    setAddress(prevAddress => ({
      ...prevAddress,
      [key]: value,
    }));
  };

  const queryAutocompleteForSuggestions = async (query: string, hasSecondaries = false) => {
    const lookup = new autocomplete.lookup(query);

    if (props.preferences?.states) {
      lookup.preferStates = props.preferences.states;
    }

    if (!hasSecondaries) {
      lookup.maxResults = 6;
    }

    if (hasSecondaries) {
      lookup.selected = query;
    }

    try {
      const results = await autoCompleteClient?.send(lookup);
      setSuggestions(results);
    } catch (error) {
      const errorMessage =
        (error as Response & { error?: { message?: string } })?.error?.message || (error as string);
      console.warn(errorMessage);
      triggerError("onApiError", errorMessage);
      if (isString(error)) {
        setError(error);
      } else {
        const message = t("addressInput.errors.apiError", "Looks like something went wrong.");
        setError(message);
      }
    }
  };

  const debouncedQueryAutocompleteForSuggestions = throttle(queryAutocompleteForSuggestions, 500);

  const selectSuggestion = async (suggestion: Suggestion) => {
    if (suggestion.entries > 1) {
      await queryAutocompleteForSuggestions(formatAutocompleteSuggestion(suggestion), true);
    } else {
      await getAutoCompleteSuggestion(suggestion);
      if (address.address1.length === 100000) await validateUsAddress();
    }
  };

  const getAutoCompleteSuggestion = async (suggestion: Suggestion) => {
    try {
      const stateValue = parseStateSuggestion(suggestion.state);
      if (stateValue === undefined && states.length > 0) throw new Error("unsupportedState");

      const updatedAddress = {
        ...address,
        address1: suggestion.streetLine,
        address2: suggestion.secondary,
        city: suggestion.city,
        state: stateValue || suggestion.state,
        zipCode: suggestion.zipcode,
        verified: true,
      };
      setAddress(updatedAddress);
      onSelectedItemChange && onSelectedItemChange({ address: updatedAddress, suggestion });
      setError(null);
      setExpanded(true);
    } catch (err) {
      const error = (err as Error)?.message || err;
      if (error === "unsupportedState") {
        triggerError("onUnsupportedState", error);
        const errorContent = t(
          "addressInput.errors.unsupportedState",
          "Looks like that state isn't currently supported"
        );
        setError(errorContent);
      } else {
        setError(error as string);
      }
    } finally {
      setSuggestions(undefined);
    }
  };

  const parseStateSuggestion = (stateKey: string) => {
    const state = find(states, { key: stateKey }) || find(states, { value: stateKey });
    return state?.value;
  };

  const triggerError = (key: keyof AddressInputErrors, error: string) => {
    const onError = get(onErrors, key);
    onError && onError(error);
  };

  const validateUsAddress = async () => {
    const lookup = new street.lookup();
    lookup.street = address.address1;
    lookup.street2 = address.address2;
    lookup.city = address.city;
    lookup.state = address.state;
    lookup.zipCode = address.zipCode;

    if (!!lookup.street) {
      try {
        const response = await usStreetClient?.send(lookup);
        await updateStateFromValidatedUsAddress(response, true);
      } catch (error) {
        setError(error as string);
      }
    } else {
      setError("A street address is required.");
    }
  };

  const updateStateFromValidatedUsAddress = (
    response: core.Batch<usStreet.Lookup> | undefined,
    isAutocomplete = false
  ) => {
    const lookup = response?.lookups[0];
    const newState: Partial<Address> = {};

    if (!lookup) {
      throw new Error("No address information available.");
    } else if (!utils.isValid(lookup)) {
      throw new Error("The address is invalid.");
    } else if (utils.isAmbiguous(lookup)) {
      throw new Error("The address is ambiguous.");
    } else if (utils.isMissingSecondary(lookup) && !isAutocomplete) {
      throw new Error("The address is missing a secondary number.");
    } else {
      const candidate = lookup.result[0];
      const { components } = candidate;

      newState.address1 = candidate.deliveryLine1;
      newState.address2 = candidate.deliveryLine2 || "";
      newState.city = components.cityName;
      newState.state = components.state;
      newState.zipCode = `${components.zipCode}-${components.plus4Code}`;
    }

    try {
      setAddress(prevAddress => ({
        ...prevAddress,
        ...newState,
      }));
      setError("");
    } catch (error) {
      console.error("Error updating state from validated US address:", error);
      setError("An error occurred while updating the address.");
    }
  };

  const handleOnTextInputChange = (element: keyof Address) => async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const newValue = value || "";

    updateField(element, newValue);
  };

  const onInputFocus = () => {
    if (suggestions?.result && suggestions.result.length > 0) {
      setIsOpenState(true);
    }
  };

  const handleOnSelectInputChange = (element: keyof Address) => async (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value || "";
    const target = find(states, { key: value }) || find(states, { value });
    const targetValue = target?.value || "";

    updateField(element, targetValue);
  };

  const handleClear = () => {
    setExpanded(false);
    setAddress(defaultAddress);
    setInputValue("");
    setError(null);
    onClear && onClear();
  };

  const classes = classNames("address_input__container", className);

  const isRequired = (key: AddressFieldKey): boolean => {
    if (required === true) return true;
    const requiredField = required && required[key];
    return requiredField || false;
  };

  const fieldLabel = (key: AddressFieldKey, fallback: string) => {
    const isFieldRequired = isRequired(key);

    const label = t(`addressInput.elements.${key}.label`, fallback);
    const requiredLabel = t("input.required.label", "%<text>s *", { text: label });

    return isFieldRequired ? requiredLabel : label;
  };

  const { ref, ...inputProps } = getInputProps({
    disabled,
    placeholder: t("addressInput.search.placeholder", "Start typing your address"),
    onChange: async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value.length > 2) {
        await debouncedQueryAutocompleteForSuggestions(value);
      }

      setError(null);
    },
  }) as any;

  const fieldError = (key: AddressDataKey) => {
    const errorValue = errors[key];
    if (errorValue === true) {
      return { error: errorValue };
    } else if (errorValue) {
      return { error: true, errorMessage: errorValue };
    }
    return {};
  };

  const AddressFieldInputs = (
    <div>
      <div>
        <TextInput
          id="address1"
          label={fieldLabel("address1", "Street Address")}
          type="text"
          disabled={disabled}
          value={address.address1}
          required={isRequired("address1")}
          onBlur={onBlur}
          onChange={handleOnTextInputChange("address1")}
          {...fieldError("address1")}
        />
      </div>
      <div>
        <TextInput
          id="address2"
          type="text"
          disabled={disabled}
          value={address.address2}
          placeholder={t(
            "addressInput.elements.address2.placeholder",
            "Apt/Suite/Floor (optional)"
          )}
          onBlur={onBlur}
          onChange={handleOnTextInputChange("address2")}
        />
      </div>
      <div>
        <TextInput
          id="city"
          label={t("addressInput.elements.city.label", "City")}
          type="text"
          disabled={disabled}
          value={address.city}
          required={isRequired("city")}
          onBlur={onBlur}
          onChange={handleOnTextInputChange("city")}
        />
      </div>
      <div>
        {isEmpty(states) ? (
          <TextInput
            id="state"
            label={fieldLabel("state", "State")}
            type="text"
            disabled={disabled}
            value={address.state}
            required={isRequired("state")}
            onBlur={onBlur}
            onChange={handleOnTextInputChange("state")}
          />
        ) : (
          <SelectInput
            id="state"
            label={fieldLabel("state", "State")}
            options={states}
            disabled={disabled}
            value={address.state}
            onChange={handleOnSelectInputChange("state")}
          />
        )}
      </div>
      <div>
        <TextInput
          id="zipCode"
          label={fieldLabel("zipCode", "Zip Code")}
          type="text"
          disabled={disabled}
          value={address.zipCode}
          required={isRequired("zipCode")}
          onBlur={onBlur}
          onChange={handleOnTextInputChange("zipCode")}
        />
      </div>
    </div>
  );

  const AddressFields = children ? children : AddressFieldInputs;

  return (
    <div className={classes}>
      <div className={expanded ? "appear-hidden" : ""}>
        <TextInput
          {...inputProps}
          disableAutoComplete
          error={!!error}
          errorMessage={error}
          inputRef={ref}
          onFocus={onInputFocus}
          label={fieldLabel("search", inputProps.placeholder)}
          placeholder={undefined}
        />
        <Suggestions
          isOpen={isOpen}
          highlightedIndex={downshift.highlightedIndex}
          suggestions={suggestions}
          selectSuggestion={selectSuggestion}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
        />
      </div>
      {expanded && AddressFields}
      {expanded && (
        <Button
          className="address-input__cta--clear-form"
          disabled={disabled}
          onClick={handleClear}
          style={{ display: disabled ? "none" : "inline-block" }}
          subtype="text-dark"
          type="button">
          {t("addressInput.buttons.clear.label", "Clear Address")}
        </Button>
      )}
      {error && expanded && <div className="error">{error}</div>}
    </div>
  );
};

export default AddressInput;
