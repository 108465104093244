import { useMemo } from "react";

import { FlowTypes } from "config/types";
import { useTranslation } from "modules/hooks/useTranslation";

export const useFlowFieldTitles = (slug: FlowTypes) => {
  const { t } = useTranslation();

  const fieldTitles = useMemo(() => {
    const residentialAddressContent = t(
      "savings.application_form.section.address.label",
      "Residential Address"
    );

    const ssnContent = t("savings.application_form.section.ssn", "Social Security Number");

    switch (slug) {
      case "savings-default-application":
        return {
          "savings-default-application-address": residentialAddressContent,
          "savings-default-application-ssn-tin": ssnContent,
          "savings-active-phone-application-address": residentialAddressContent,
          "savings-active-phone-application-ssn-tin": ssnContent,
          "savings-existing-phone-application-address": residentialAddressContent,
          "savings-existing-phone-application-ssn-tin": ssnContent,

          // V2 Savings+ Application
          "savings-default-application-v2-address": residentialAddressContent,
          "savings-default-application-v2-ssn-tin": ssnContent,
          "savings-active-phone-application-v2-address": residentialAddressContent,
          "savings-active-phone-application-v2-ssn-tin": ssnContent,
          "savings-existing-phone-application-v2-address": residentialAddressContent,
          "savings-existing-phone-application-v2-ssn-tin": ssnContent,
        };

      default:
        return {};
    }
  }, [slug, t]);

  return fieldTitles;
};
