import { RootState } from "store";
import * as actionType from "store/constants";
import { selectFinanceAccount } from "store/selectors";

import { createAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { getDefaultAttrs } from "../defaults";

// Analytic events for Savings+ application

/* ------------------------    EVENT ACTION     ------------------------------ */

export const savingsApplicationFormStartedEvent = createAction(actionType.OB_APP_FORM_STARTED);

export const savingsApplicationSetDOBEvent = createAction(
  actionType.OB_APP_OPENACCOUNT_FORM_FIELD_SET_DOB
);

export const savingsApplicationSetGenderEvent = createAction(
  actionType.OB_APP_OPENACCOUNT_FORM_FIELD_SET_GENDER
);

export const savingsApplicationSetStateEvent = createAction(
  actionType.OB_APP_OPENACCOUNT_FORM_FIELD_SET_STATE
);

export const savingsApplicationFormCompletedEvent = createAction(
  actionType.OB_APP_OPENACCOUNT_FORM_COMPLETED
);

export const savingsApplicationEditClickEvent = createAction(actionType.OB_APP_EDIT_CLICK);

export const savingsApplicationOutOfStatePageEvent = createAction(
  actionType.OB_APP_OUTOFSTATE_PAGEVIEW
);

export const savingsApplicationOutOfAgePageEvent = createAction(
  actionType.OB_APP_OUTOFAGE_PAGEVIEW
);

export const savingsApplicationSummaryPageEvent = createAction(actionType.OB_APP_SUMMARY_PAGEVIEW);

export const savingsApplicationCompletedEvent = createAction(actionType.OB_APP_COMPLETED);

export const savingsVerificationPlaidErrorPageEvent = createAction(
  actionType.OB_APP_PLAIDERROR_PAGEVIEW
);

export const savingsVerificationIdErrorPageEvent = createAction(actionType.OB_APP_IDERROR_PAGEVIEW);

export const savingsVerificationAcceptancePageEvent = createAction(
  actionType.OB_APP_ACCEPTANCE_PAGEVIEW
);

export const savingsVerificationAcceptanceCompletedEvent = createAction(
  actionType.OB_APP_ACCEPTANCE_COMPLETED
);

export const savingsVerificationManualReviewPageEvent = createAction(
  actionType.OB_APPLICATION_MANUALIDREVIEW_PAGEVIEW
);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const getSavingsApplicationFields = (payloadType: string, state: RootState) => {
  const responses = state.analyticsTracker.responses;

  if (payloadType === actionType.OB_APP_OPENACCOUNT_FORM_COMPLETED) {
    const birthdate =
      responses["savings-active-phone-application-dob"] ||
      responses["savings-default-application-dob"] ||
      responses["savings-existing-application-dob"];

    const gender =
      responses["savings-active-phone-application-gender"] ||
      responses["savings-default-application-gender"] ||
      responses["savings-existing-application-gender"];

    const state =
      responses["savings-active-phone-application-state"] ||
      responses["savings-default-application-state"] ||
      responses["savings-existing-application-state"];

    return {
      birthdate: DateTime.fromISO(birthdate).toFormat("yyyy-MM-dd"),
      gender,
      state,
    };
  } else if (payloadType === actionType.OB_APP_COMPLETED) {
    return {
      depositAmount: state.financeMembership.metadata.initial_funding_amount,
      financeMembershipId: state.financeMembership.id,
      savingsStatus: selectFinanceAccount(state).status,
    };
  }

  return {};
};

const getSavingsApplicationEventDefinition = (eventName: string) => {
  return (action: { [key: string]: any }, prevState: RootState, nextState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "APPLICATION");
    const applicationFields = getSavingsApplicationFields(action.type, nextState);

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        ...applicationFields,
      },
    };
  };
};

const getSavingsVerificationEventDefinition = (eventName: string) => {
  return (action: { [key: string]: any }, prevState: RootState, nextState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "VERIFICATION");
    let additionalAttrs = {};

    if (action.type === actionType.OB_APP_ACCEPTANCE_COMPLETED) {
      additionalAttrs = {
        depositAmount: nextState.financeMembership.metadata.initial_funding_amount,
        financeMembershipId: nextState.financeMembership.id,
        savingsStatus: selectFinanceAccount(nextState).status,
      };
    }

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        ...additionalAttrs,
      },
    };
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const savingsApplicationEventsMap = {
  [actionType.OB_APP_FORM_STARTED]: getSavingsApplicationEventDefinition("ob_app_form_started"),
  [actionType.OB_APP_OPENACCOUNT_FORM_FIELD_SET_DOB]: getSavingsApplicationEventDefinition(
    "ob_app_openaccount_form_field_set_DOB"
  ),
  [actionType.OB_APP_OPENACCOUNT_FORM_FIELD_SET_GENDER]: getSavingsApplicationEventDefinition(
    "ob_app_openaccount_form_field_set_gender"
  ),
  [actionType.OB_APP_OPENACCOUNT_FORM_FIELD_SET_STATE]: getSavingsApplicationEventDefinition(
    "ob_app_openaccount_form_field_set_state"
  ),
  [actionType.OB_APP_OPENACCOUNT_FORM_COMPLETED]: getSavingsApplicationEventDefinition(
    "ob_app_openaccount_form_completed"
  ),
  [actionType.OB_APP_EDIT_CLICK]: getSavingsApplicationEventDefinition("ob_app_edit_click"),
  [actionType.OB_APP_OUTOFSTATE_PAGEVIEW]: getSavingsApplicationEventDefinition(
    "ob_app_outofstate_pageview"
  ),
  [actionType.OB_APP_OUTOFAGE_PAGEVIEW]: getSavingsApplicationEventDefinition(
    "ob_app_outofage_pageview"
  ),
  [actionType.OB_APP_SUMMARY_PAGEVIEW]: getSavingsApplicationEventDefinition(
    "ob_app_summary_pageview"
  ),
  [actionType.OB_APP_COMPLETED]: getSavingsApplicationEventDefinition("ob_app_completed"),
  [actionType.OB_APP_PLAIDERROR_PAGEVIEW]: getSavingsVerificationEventDefinition(
    "ob_app_plaiderror_pageview"
  ),
  [actionType.OB_APP_IDERROR_PAGEVIEW]: getSavingsVerificationEventDefinition(
    "ob_app_iderror_pageview"
  ),
  [actionType.OB_APP_ACCEPTANCE_PAGEVIEW]: getSavingsVerificationEventDefinition(
    "ob_app_acceptance_pageview"
  ),
  [actionType.OB_APP_ACCEPTANCE_COMPLETED]: getSavingsVerificationEventDefinition(
    "ob_app_acceptance_completed"
  ),
  [actionType.OB_APP_AGE_BLOCKER_PAGEVIEW]: getSavingsVerificationEventDefinition(
    "ob_app_age_blocker_pageview"
  ),
  [actionType.OB_APP_MISMATCHERROR_PAGEVIEW]: getSavingsVerificationEventDefinition(
    "ob_app_mismatcherror_pageview"
  ),
  [actionType.OB_APPLICATION_MANUALIDREVIEW_PAGEVIEW]: getSavingsVerificationEventDefinition(
    "ob_application_manualidreview_pageview"
  ),
};
