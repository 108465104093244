import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

import { getIsIdentified } from "./getIsIdentified";
import { getIsLoggedIn } from "./getIsLoggedIn";

export const getEstimate = (state: RootState) => state.estimate;

export const getIsPastEstimation = createSelector(
  getEstimate,
  getIsIdentified,
  getIsLoggedIn,
  (estimate, isIdentified, isLoggedIn) => (isIdentified && !!estimate.id) || isLoggedIn
);
