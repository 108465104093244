import { TFeatureFlagNames } from "config";
import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";
import { find } from "lodash-es";

export const getFeatureFlags = (state: RootState) => state.featureFlags;

export const getBetaFeatureFlag = createSelector(getFeatureFlags, featureFlags => {
  const preBetaPhase = find(featureFlags, { name: "wysh-financial-global-prebeta" })?.enabled;
  const betaPhase = find(featureFlags, { name: "wysh-financial-global-beta" })?.enabled;

  switch (true) {
    case preBetaPhase && !betaPhase:
      return "preBeta";
    case !preBetaPhase && betaPhase:
      return "beta";
    case !preBetaPhase && !betaPhase:
      return "live";
    default:
      return "preBeta";
  }
});

export const getIsFinancePreBetaFlag = createSelector(getBetaFeatureFlag, financeBetaFlag => {
  return financeBetaFlag === "preBeta";
});

export const getIsFinanceBetaFlag = createSelector(getBetaFeatureFlag, financeBetaFlag => {
  return financeBetaFlag === "beta";
});

export const getIsBeneficiaryPacketEmailEnabled = createSelector(getFeatureFlags, featureFlags => {
  const beneficiaryPacketEmailFlag = find(featureFlags, {
    name: "wysh-insurance-beneficiary-packet-email",
  });

  return beneficiaryPacketEmailFlag?.enabled;
});

export const getFeatureFlagByName = createSelector(
  [getFeatureFlags, (_state: RootState, featureFlagName: TFeatureFlagNames) => featureFlagName],
  (featureFlags, featureFlagName) => {
    const foundFeatureFlag = find(featureFlags, { name: featureFlagName });
    return foundFeatureFlag?.enabled || false;
  }
);
