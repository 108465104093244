import { FC } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useJourney } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { logout } from "store/actions/authentication";
import { closeModal } from "store/actions/modal";

import { push } from "connected-react-router";

const ExistingAccountModal: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { exitJourney } = useJourney();

  const closeModalWrapper = () => {
    props.context?.callbacks?.resetForm();
    dispatch(closeModal());
  };

  const handleOnClick = async () => {
    await exitJourney();
    dispatch(logout());
    dispatch(push("/login"));
    closeModalWrapper();
  };

  const content = {
    header: t(
      "register_user.existing_account.modal.title",
      "Looks like you have an existing account."
    ),
    subheader: t(
      "register_user.existing_account.modal.body",
      "We recognize an account with that email. Do you want to login?"
    ),
    onClose: closeModalWrapper,
    button: {
      text: t("register_user.existing_account.modal.button.title", "login"),
      onClick: handleOnClick,
    },
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default ExistingAccountModal;
