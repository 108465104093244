import "./UnitizedInput.scss";

import { CSSProperties, FC } from "react";

import SelectInput, { ISelectEvent } from "components/SelectInput/SelectInput";
import Text from "components/Text/Text";
import { ClassName, ValueOrArray } from "config/types";

import classNames from "classnames";
import { defaultTo, flatten, range } from "lodash-es";

export interface IUnitizedOption {
  label: string;
  min: number;
  max: number;
}

export interface IUnitizedEvent {
  target: { value: (number | undefined)[]; id: string };
}

interface IProps {
  id: string;
  options: IUnitizedOption[];
  className?: ClassName;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  groupLabel?: string;
  style?: CSSProperties;
  dataTestId?: string;
  value?: (number | undefined)[];
  onChange(event: IUnitizedEvent): void;
}

const UnitizedInput: FC<IProps> = props => {
  const {
    id,
    options,
    className,
    error,
    errorMessage,
    groupLabel,
    style,
    dataTestId,
    value = [],
    onChange,
  } = props;

  const values = options.map((option, index) => {
    return defaultTo(value?.[index], undefined);
  });

  const handleOnChange = (newValue: string, index: number) => {
    const newValues = [...values];
    newValues[index] = parseInt(newValue);

    const event = {
      target: {
        value: newValues,
        id,
      },
    };
    onChange(event);
  };

  const errorMessages = flatten([errorMessage]);

  const classes = classNames("form__input unitized-input", className, {
    "unitized-input--error": error,
  });

  const inputs = options.map((option: IUnitizedOption, index) => {
    const optionRange = range(option.min, option.max + 1);
    const selectOptions = optionRange.map((unit: number) => {
      return { label: unit.toString(), value: unit.toString() };
    });

    return (
      <SelectInput
        error={error}
        id={option.label}
        key={option.label}
        label={option.label}
        onChange={(event: ISelectEvent) => handleOnChange(event.target.value, index)}
        options={selectOptions}
        value={values[index]?.toString()}
        className={`unitized-input__select--${index}`}
      />
    );
  });

  return (
    <div id={id} className={classes} style={style} data-testid={dataTestId}>
      {groupLabel && <Text tag="p1" text={groupLabel} />}
      {inputs}
      {error
        ? errorMessages.map(message => (
            <Text className="unitized-input__error-text" key={message} tag="p6" text={message} />
          ))
        : null}
    </div>
  );
};

export default UnitizedInput;
