import { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import CoverageDenied, { DenialTypes } from "containers/CoverageDenied/CoverageDenied";
import { RootState } from "store";

import { defaultTo } from "lodash-es";
interface IProps {
  isReinstatementJourney?: boolean;
  type?: DenialTypes;
}

interface IRouteParams {
  type: DenialTypes;
}

const CoverageDeniedRoute: FC<IProps> = ({ isReinstatementJourney, type }) => {
  const params = useParams<IRouteParams>();
  const latestCase = useSelector((state: RootState) => state.cases.latestCase);

  let denialType = defaultTo(params?.type, type) || "default";

  if (isReinstatementJourney && denialType === "rejected") {
    denialType = "rejected_reinstatement";
  }

  return <CoverageDenied type={denialType} latestCase={latestCase} />;
};

export default CoverageDeniedRoute;
