import { FC, useContext, useEffect } from "react";

import { paymentProcessingModalDisplayedEvent } from "analytics/billingEvents";
import Text from "components/Text/Text";
import { IModalProps, ModalContext } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { openChat } from "utils/customerSupport";

const SettingsBillingPaymentRetryProcessingModal: FC<IModalProps> = props => {
  const { callbacks } = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(paymentProcessingModalDisplayedEvent());
  }, [dispatch]);

  const closeModal = props.closeModal;
  const billingAttemptedAmount = props.data.billingAttemptedAmount as string;

  const content = {
    icon: "BlobUnlinkedMonitored",
    header: t(
      "settings.billing.modal.payment_retry.processing.title",
      "Hang tight, your payment is processing..."
    ),
    subheader: (
      <>
        {t(
          "settings.billing.modal.payment_retry.processing.text_1",
          "We updated your payment method on file, but your payment for $%<billingAttemptedAmount>s is still processing. Payments may take up to 5 business days to process. Please check back later to ensure your payment was successful, or contact",
          { billingAttemptedAmount }
        )}{" "}
        <Text
          as="span"
          className="modal__subheader-inline-cta"
          onClick={() => {
            openChat();
            closeModal(callbacks?.onClose);
          }}
          tag="p3"
          text={t("settings.billing.modal.payment_retry.processing.text_cs", "Customer Support")}
        />{" "}
        {t("settings.billing.modal.payment_retry.processing.text_2", "for help with billing.")}
      </>
    ),
    button: [
      {
        onClick: () => closeModal(callbacks?.onClose),
        text: t("settings.billing.modal.payment_retry.processing.cta_1", "OKAY, GOT IT"),
      },
      {
        onClick: () => {
          openChat();
          closeModal(callbacks?.onClose);
        },
        subType: "text-dark",
        text: t("settings.billing.modal.payment_retry.processing.cta_2", "GET HELP"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default SettingsBillingPaymentRetryProcessingModal;
