import { globalFeatureFlagQuery } from "graphql/queries/featureFlag";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchGlobalFlags = createAsyncThunk(
  "fetchGlobalFlags",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await globalFeatureFlagQuery();

      return data.globalFeatureFlags;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
