import "./AddWyshCounter.scss";

import { FC, MouseEvent } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ICardProps } from "modules/partials/Card";
import { IWyshState } from "store/reducers/wyshes";

import classNames from "classnames";
import { kebabCase } from "lodash-es";

interface IAddWyshCounterProps extends IWyshState {
  icons: { selected: ""; unselected: "" };
  total?: number;
  onClick?(event: MouseEvent): void;
}

export const AddWyshCounterCard: FC<ICardProps> = props => {
  const { className } = props;
  const content = props.content as IAddWyshCounterProps;
  const { prompt, icons, slug, onClick, total } = content;

  const wyshIcon = total === 0 ? icons.unselected : icons.selected;

  const classes = classNames(
    className,
    "fcard-content__body-container card-container card-container__add-wysh-counter"
  );

  return (
    <button
      className={classes}
      style={props.style}
      onClick={onClick}
      data-testid={`wysh__${kebabCase(slug)}`}>
      <div className="card-content__wrapper">
        <div className="text__wrapper">
          <Text tag="wl" text={prompt} />
        </div>

        <div className="icon-counter__wrapper">
          <div className="add-wysh-counter__icon-wrapper">
            <Icon className="add-wysh-counter__icon" src={wyshIcon} size="xl" key={wyshIcon} />
          </div>

          <div className={`add-wysh-counter__total-wrapper ${!total && "hide"}`}>
            <Text className="add-wysh-counter__total" tag="wl" text={total} />
          </div>
        </div>
      </div>
    </button>
  );
};
