import "./CustomerSupportTiles.scss";

import ArticleSrc from "assets/icons/Article/Article.svg";
import FaqSrc from "assets/icons/FAQ/FAQ.svg";
import PhoneSrc from "assets/icons/Phone/Phone_2.svg";
import Text from "components/Text/Text";
import Tile, { ITile } from "components/Tile/Tile";
import VariableText from "components/VariableText/VariableText";
import { FaqUrl } from "config";
import { useTranslation } from "modules";
import { openChat } from "utils/customerSupport";

import { parsePhoneNumber } from "libphonenumber-js";

function CustomerSupportChat() {
  const { t } = useTranslation();

  const handleChatClick: ITile["onClick"] = event => {
    event?.stopPropagation();

    openChat();
  };

  return (
    <Tile
      className="customer-support__tile"
      iconSrc={FaqSrc}
      onClick={handleChatClick}
      title={t("customer_support.chat.title", "Chat with us live")}
      body={t(
        "customer_support.chat.body",
        "Our chatbot is available 24/7, or speak with a live representative M-F, 9am-5pm EST."
      )}
    />
  );
}

function CustomerSupportCall() {
  const { t } = useTranslation();

  const customerSupportNumber = t("customer_support.modal.body.inline_cta", "+1 (844) 997-4457");

  return (
    <Tile
      className="customer-support__tile"
      iconSrc={PhoneSrc}
      src={parsePhoneNumber(customerSupportNumber)?.getURI()}
      title={t("customer_support.call.title", "Call us")}
      body={
        <VariableText
          className="customer-support__call-cta-link"
          replacementValue={<Text as="span" tag="p4-paragraph" text={customerSupportNumber} />}
          target={/(?:<a.*?>|<\/a>)/g}
          text={t(
            "customer_support.call.body",
            'Available M-F, 9am-5pm EST\n<a href="tel:+18449974457">+1 (844) 997-4457 </a>'
          )}
        />
      }
    />
  );
}

function CustomerSupportFAQ() {
  const { t } = useTranslation();

  return (
    <Tile
      className="customer-support__tile"
      iconSrc={ArticleSrc}
      showAsExternal
      src={FaqUrl}
      target="_blank"
      title={t("customer_support.help_center.title", "Help Center")}
      body={t("customer_support.help_center.body", "Find answers to FAQs here.")}
    />
  );
}

export { CustomerSupportCall, CustomerSupportChat, CustomerSupportFAQ };
