import { IInvoice } from "config/types";
import { logout } from "store/actions/authentication";
import {
  getCurrentUserLatestPayableInvoice,
  getCurrentUserPastDueInvoices,
} from "store/actions/invoice";
import { getCurrentUserPolicy } from "store/actions/policy";

import { createReducer } from "@reduxjs/toolkit";

interface IInvoiceState {
  byId: Record<string, IInvoice>;
  ids: string[];
  latestPayableId: string;
  pastDueIds: string[];
  pastDueBalance: number;
  processingAmount: number;
  processingIds: string[];
}

const defaultState: IInvoiceState = {
  byId: {},
  ids: [],
  latestPayableId: "",
  pastDueIds: [],
  processingAmount: 0,
  pastDueBalance: 0,
  processingIds: [],
};

const invoice = createReducer(defaultState, builder => {
  builder
    .addCase(getCurrentUserPastDueInvoices.fulfilled, (state, action) => {
      const {
        invoicesPastDueBalance,
        invoicesProcessingAmount,
        pastDueInvoices,
        processingInvoices,
      } = action.payload;

      // Reset past due and processing invoices state by setting the arrays to
      // be empty and removing any instances of the Invoice object that were not
      // added by another action
      state.pastDueIds.forEach(id => {
        if (state.ids.indexOf(id) === -1) {
          delete state.byId[id];
        }
      });
      state.processingIds.forEach(id => {
        if (state.ids.indexOf(id) === -1) {
          delete state.byId[id];
        }
      });

      state.pastDueIds = [];
      state.processingIds = [];

      pastDueInvoices.forEach((invoice: IInvoice) => {
        if (!state.byId[invoice.id]) {
          state.byId[invoice.id] = invoice;
        }

        if (state.pastDueIds.indexOf(invoice.id) === -1) {
          state.pastDueIds.push(invoice.id);
        }
      });
      processingInvoices.forEach((invoice: IInvoice) => {
        if (!state.byId[invoice.id]) {
          state.byId[invoice.id] = invoice;
        }

        if (state.processingIds.indexOf(invoice.id) === -1) {
          state.processingIds.push(invoice.id);
        }
      });

      state.processingAmount = invoicesProcessingAmount;
      state.pastDueBalance = invoicesPastDueBalance;
    })
    .addCase(getCurrentUserLatestPayableInvoice.fulfilled, (state, action) => {
      const latestPayableInvoice = action.payload;

      if (!latestPayableInvoice) {
        return state;
      }

      if (!state.byId[latestPayableInvoice.id]) {
        state.ids.push(latestPayableInvoice.id);
      }

      state.byId[latestPayableInvoice.id] = latestPayableInvoice;
      state.latestPayableId = latestPayableInvoice.id;
    })
    .addCase(getCurrentUserPolicy.fulfilled, (state, action) => {
      const latestPayableInvoice = action.payload.latestPayableInvoice;

      if (!latestPayableInvoice) {
        return state;
      }

      if (!state.byId[latestPayableInvoice.id]) {
        state.ids.push(latestPayableInvoice.id);
      }

      state.byId[latestPayableInvoice.id] = latestPayableInvoice;
      state.latestPayableId = latestPayableInvoice.id;
    })
    .addCase(logout, () => defaultState);
});

export default invoice;
