import "./FulfillmentOutages.scss";

import { FC } from "react";
import { useDispatch } from "react-redux";

import { Layout, useJourney } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";

import { push } from "connected-react-router";
import { DateTime } from "luxon";

enum EnumOutageType {
  scheduled_maintenance,
  unscheduled_maintenance,
}

export type OutageTypes = keyof typeof EnumOutageType;

interface IProps {
  type: OutageTypes;
  maintenanceDate?: string;
}

const FulfillmentOutages: FC<IProps> = ({ type, maintenanceDate = "" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { exitJourney } = useJourney();

  // TODO - use scheduled maintenance date from API
  const isValidDate = DateTime.fromISO(maintenanceDate).isValid;
  const formatMaintenanceDate =
    isValidDate && DateTime.fromISO(maintenanceDate).toFormat("h a ZZZZ, cccc, LLLL d");

  const redirectToHome = async () => {
    await exitJourney();

    dispatch(push("/"));
  };

  const content = {
    scheduled_maintenance: {
      header: t("scheduled.maintenance.modal.title", "We'll BRB!"),
      subheader: t(
        "scheduled.maintenance.modal.body",
        "Sorry, we're down for some scheduled maintenance right now, but we'll be back on %<date>s to get you up and running on Wysh.",
        { date: formatMaintenanceDate || "" }
      ),
      button: t("scheduled.maintenance.modal.cta", "TRY AGAIN LATER"),
      onButtonClick: redirectToHome,
      icon: "BlobAlert",
    },
    unscheduled_maintenance: {
      header: t("unscheduled.maintenance.modal.title", "Be back soon!"),
      subheader: t(
        "unscheduled.maintenance.modal.body",
        "Sorry, we're down for maintenance (polishing our tech, perfecting some new things, etc.).\n\n But don’t worry, we’ll be back as soon as we can."
      ),
      button: t("unscheduled.maintenance.modal.cta", "GOT IT"),
      onButtonClick: redirectToHome,
      icon: "BlobAlert",
    },
  };

  const outageType = maintenanceDate ? type : "unscheduled_maintenance";

  return (
    <Layout
      as="AuthLayout"
      content={content[outageType]}
      className="fulfillment-outages__container"
    />
  );
};

export default FulfillmentOutages;
