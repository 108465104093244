import { generatePath } from "react-router";

import { LinkModule } from ".";

import { BusinessSlugTypes, DynamicFormCriteriaFlowScope } from "config";

type BeneficiaryLinkState = {
  flowScope: DynamicFormCriteriaFlowScope;
};

export const beneficiaryFlowLink = (
  scope: Exclude<DynamicFormCriteriaFlowScope, "application">,
  state: Record<string, unknown> = {}
): LinkModule<BeneficiaryLinkState> => {
  const lineOfBusinessMap: Record<typeof scope, BusinessSlugTypes> = {
    policy_holder: "wysh-insurance",
    finance: "wysh-financial",
  };

  const lineOfBusiness = lineOfBusinessMap[scope];
  const route = "/beneficiary/:lineOfBusiness";
  const pathname = generatePath(route, { lineOfBusiness });

  return {
    pathname,
    state: {
      ...state,
      flowScope: scope,
    },
  };
};
