import { FC, useContext, useEffect } from "react";

import { paymentFailureModalDisplayedEvent } from "analytics/billingEvents";
import Text from "components/Text/Text";
import { IModalProps, ModalContext } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { openChat } from "utils/customerSupport";

const SettingsBillingPaymentRetryFailureModal: FC<IModalProps> = props => {
  const { callbacks } = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(paymentFailureModalDisplayedEvent());
  }, [dispatch]);

  const bankAccountMask = props.data.bankAccountMask as string;
  const bankAccountType = props.data.bankAccountType as string;
  const bankName = props.data.bankName as string;
  const billingAttemptedAmount = props.data.billingAttemptedAmount as string;
  const cardMask = props.data.cardMask as string;
  const cardType = props.data.cardType as string;
  const closeModal = props.closeModal;
  const source = props.data.source as "bank_account" | "credit_card";

  const handleCustomerServiceClick = () => {
    openChat();
    closeModal(callbacks?.onClose);
  };

  const subheaderTextOptions = {
    bank_account: t(
      "settings.billing.modal.payment_retry.error.text_1.bank_account",
      "We’re having a little trouble processing your payment for $%<billingAttemptedAmount>s with the %<bankName>s %<bankAccountType>s account ending in %<bankAccountMask>s. To keep your policy, please update your payment method and try again. If you have billing questions, please contact",
      { bankAccountMask, bankName, bankAccountType, billingAttemptedAmount }
    ),
    credit_card: t(
      "settings.billing.modal.payment_retry.error.text_1.credit_card",
      "We’re having a little trouble processing your payment for $%<billingAttemptedAmount>s with the %<cardType>s ending in %<cardMask>s. To keep your policy, please update your payment method and try again. If you have billing questions, please contact",
      { billingAttemptedAmount, cardMask, cardType }
    ),
  };

  const content = {
    icon: "BlobAlert",
    header: t(
      "settings.billing.modal.payment_retry.error.title",
      "Uh-oh, your payment didn’t go through..."
    ),
    subheader: (
      <>
        {subheaderTextOptions[source]}{" "}
        <Text
          as="span"
          className="modal__subheader-inline-cta"
          onClick={handleCustomerServiceClick}
          tag="p3"
          text={t("settings.billing.modal.payment_retry.error.text_cs", "Customer Support")}
        />{" "}
        {t("settings.billing.modal.payment_retry.error.text_2", "for help.")}
      </>
    ),
    button: [
      {
        onClick: () => closeModal(callbacks?.onClose),
        text: t("settings.billing.modal.payment_retry.error.cta_1", "UPDATE PAYMENT METHOD"),
      },
      {
        onClick: handleCustomerServiceClick,
        subType: "text-dark",
        text: t("settings.billing.modal.payment_retry.error.cta_2", "GET HELP"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default SettingsBillingPaymentRetryFailureModal;
