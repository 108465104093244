import { TApiButtonCommandFn } from ".";

import { useAppDispatch } from "store/index";

import { compact } from "lodash-es";

import { useGroupApi } from "../useGroupApi";

export const useExecuteGroupApi = () => {
  const dispatch = useAppDispatch();
  const apiGroupThunk = useGroupApi();

  const executeGroupApiCommand: TApiButtonCommandFn = async commandArgs => {
    try {
      const commandArguments = commandArgs || [];
      const groupApiThunks = compact(
        commandArguments.map(argument => apiGroupThunk(argument.source))
      );
      const dispatchedThunks = groupApiThunks.map(thunk => dispatch(thunk).unwrap());

      return await Promise.all(dispatchedThunks);
    } catch (error) {
      throw error;
    }
  };

  return executeGroupApiCommand;
};
