import { useCallback } from "react";
import { useLocation } from "react-router";

import { TApiButtonCommandFn } from ".";

import { useJourney } from "modules";
import { useAppDispatch } from "store/index";

import { replace } from "connected-react-router";

export const usePopToRoot = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { previousStepCallback } = useJourney();

  const buildBackAction = useCallback(() => {
    const locationState = (location.state || {}) as { from?: string };
    const rootPath = locationState.from || "/";

    return () => dispatch(replace(rootPath));
  }, [dispatch, location.state]);

  const popToRootCommand: TApiButtonCommandFn = useCallback(async () => {
    const goBackAction = buildBackAction();
    const callback = previousStepCallback(goBackAction);
    callback();
  }, [buildBackAction, previousStepCallback]);

  return popToRootCommand;
};
