import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { WyshesCoveredState } from "config/types";
import { RootState } from "store";
import { getCurrentUserPolicy } from "store/actions/policy";
import { getWyshAllocationSummary } from "store/actions/wyshes";

export const useWyshesCovered = () => {
  const dispatch = useDispatch();
  const policy = useSelector((state: RootState) => state.policy);
  const wyshesSummary = useSelector((state: RootState) => state.allocationSummary);

  const policyAmount = policy?.amount || 0;

  const computeCoverage = (coverage: number) => {
    if (!wyshesSummary.totalAmount) return 0;

    return Math.floor((coverage / wyshesSummary.totalAmount) * 100);
  };

  const wyshesCovered = computeCoverage(policyAmount);

  const wyshesCoveredState: WyshesCoveredState =
    wyshesCovered < 100 ? "below" : wyshesCovered === 100 ? "covered" : "over";

  useEffect(() => {
    if (!policy.id) {
      dispatch(getCurrentUserPolicy);
    }
  }, [dispatch, policy.id]);

  useEffect(() => {
    dispatch(getWyshAllocationSummary());
  }, [dispatch]);

  return { wyshesCovered, computeCoverage, wyshesCoveredState };
};
