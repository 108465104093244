import {
  DynamicFormCriteriaFlowScope,
  IDynamicForm,
  FlowTypes,
  IFlowForm,
  IFormInputs,
  IFlowSession,
} from "config";
import { logout } from "store/actions/authentication";
import {
  createFlowResponse,
  dismissDynamicFormModal,
  fetchDynamicForm,
  fetchFlow,
  initFormFlow,
  setFormFlowDirty,
  setFormFlowIndex,
} from "store/actions/flow";
import { clearJourneyActive } from "store/actions/journey";
import { FLOW_FIELD_SET } from "store/constants";

import { createReducer } from "@reduxjs/toolkit";

type IFormFlowWithMeta = (IFlowForm | IDynamicForm) & {
  arg: Parameters<typeof fetchDynamicForm>[0];
};

interface IFlowFormState {
  flowScope: DynamicFormCriteriaFlowScope | undefined;
  flowSessionId: IFlowSession["id"];
  formIndex: number;
  id: string;
  inputValues: IFormInputs;
  isFormDirtied: boolean;
  nextForm: IFormFlowWithMeta[];
  responses: Partial<Record<FlowTypes | "", Record<string, any>>>;
  slug: "" | FlowTypes;
}

const defaultState: IFlowFormState = {
  flowScope: undefined,
  flowSessionId: "",
  formIndex: 0,
  id: "",
  inputValues: {},
  isFormDirtied: false,
  nextForm: [],
  responses: { "": {} },
  slug: "",
};

const formFlow = createReducer(defaultState, builder => {
  builder
    .addCase(fetchFlow.fulfilled, (state, action) => {
      state.flowScope = undefined;
      state.flowSessionId = "";
      state.formIndex = 0;
      state.id = "";
      state.nextForm = [];
      state.slug = action.payload.slug;
    })
    .addCase(initFormFlow.fulfilled, (state, action) => {
      state.flowSessionId = action.payload.flowSession.id;
      state.slug = action.payload.flow.slug;
    })
    .addCase(fetchDynamicForm.fulfilled, (state, action) => {
      const nextFormObj = { ...action.payload, arg: action.meta.arg };

      state.flowScope = action.meta.arg.criteria?.flowScope || state.flowScope;
      state.id = nextFormObj.id;
      state.nextForm.push(nextFormObj);
      state.formIndex = state.formIndex + 1;
      state.isFormDirtied = false;
    })
    .addCase(setFormFlowIndex, (state, action) => {
      const flowFormDirection = action.payload;
      const indexValue = flowFormDirection === "back" ? -1 : +1;

      if (flowFormDirection === "back") {
        state.nextForm.pop();
      }

      state.formIndex += indexValue;
    })
    .addCase(dismissDynamicFormModal.fulfilled, state => {
      const latestFlowFormLayout = state.nextForm[state.formIndex - 1]?.layout;

      if (latestFlowFormLayout === "modal") {
        state.nextForm.pop();
        state.formIndex += -1;
      }
    })
    .addCase(createFlowResponse.fulfilled, (state, action) => {
      const responseInputs = action.meta.arg.formInputs;

      state.inputValues = { ...state.inputValues, ...responseInputs };
    })
    .addCase(FLOW_FIELD_SET, state => {
      state.isFormDirtied = true;
    })
    .addCase(setFormFlowDirty, (state, action) => {
      state.isFormDirtied = action.payload;
    })
    .addCase(clearJourneyActive, () => defaultState)
    .addCase(logout, () => defaultState);
});

export default formFlow;
