import { ADD_WYSH_FLOW_DEFAULT, IWyshesFlow } from "config/types";
import { logout } from "store/actions/authentication";

import { createReducer } from "@reduxjs/toolkit";

// addWyshFlowType - state to determine landing page when adding a wysh.
// Options: remain in Wysh Builder, redirect to /wyshes
// Used: adding wyshes during setup, adding wyshes from drawer

// backWyshDetailType - state to determine back navigation when leaving WyshDetail screen.
// Options: back to Dashboard "/", back to My Wyshes "/wyshes"
// Used: viewing WyshDetails from Dashboard wyshes list, viewing WyshDetails from My Wyshes list

// wyshBuilderFlowType - state to determine landing page when completing Wysh Builder flow.
// Options: redirect to Dashboard "/", redirect to My Wyshes "/wyshes"
// Used: adding a wysh from Dashboard when user has no allocations

// wyshesAdded - state to trigger WyshesAddedDrawer animation when wyshAllocation is created
// Used: adding a wysh during onboarding in wysh builder

const defaultState: IWyshesFlow = {
  addWyshFlowType: ADD_WYSH_FLOW_DEFAULT,
  backWyshDetailType: "wyshes",
  wyshBuilderFlowType: "wyshes",
  wyshesAdded: false,
};

const wyshesFlow = createReducer(defaultState, {
  SET_ADD_WYSH_FLOW_TYPE: (state, action) => {
    state.addWyshFlowType = action.payload.type;
  },
  SET_BACK_WYSH_DETAIL_TYPE: (state, action) => {
    state.backWyshDetailType = action.payload.type;
  },
  SET_WYSHES_BUILDER_FLOW_TYPE: (state, action) => {
    state.wyshBuilderFlowType = action.payload.type;
  },
  SET_WYSHES_ADDED: (state, action) => {
    state.wyshesAdded = action.payload;
  },
  [logout.type]: () => defaultState,
});

export default wyshesFlow;
