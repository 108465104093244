import { PlatformApiUrl } from "config";
import { Store } from "store";

import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";

import { createApolloLinks } from "./links";

export let apolloClient: ApolloClient<NormalizedCacheObject>;

export const initApolloClient = (store: Store, endpoint = PlatformApiUrl) => {
  const context = { endpoint, store };
  const links = createApolloLinks(context);

  apolloClient = new ApolloClient({
    link: links,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: { fetchPolicy: "no-cache" },
      mutate: { fetchPolicy: "no-cache" },
    },
  });
};

export const getClient = () => apolloClient as any;
