import { IFormValidations, IValidationData } from "config/types";
import { validateTIN } from "utils/tin";
import { validateDateFormat, validateDateRange } from "utils/validateDate";
import validateEmail from "utils/validateEmail";
import validatePassword from "utils/validatePassword";
import validatePhoneNumber from "utils/validatePhoneNumber";
import { validateUnitizedRequired } from "utils/validateUnitizedRequired";

import { defaultTo, get, toNumber } from "lodash-es";
import { DateTime } from "luxon";

const formValidations: IFormValidations = {
  alphabetical: {
    validation: (value: string) => {
      const letters = /^[A-Za-z]+$/;
      if (value.match(letters) || !value) {
        return true;
      } else {
        return false;
      }
    },
    errorCode: "errors.alphabetical",
    defaultError: "Must be only alphabetical characters",
  },
  beneficiaryTooYoung: {
    validation: (value: string) => {
      return DateTime.fromISO(value).diffNow("years").years < -21;
    },
    errorCode: "beneficiary.form.validation.too_young",
    defaultError: "Your beneficiary must be 21+",
  },
  email: {
    validation: validateEmail,
    errorCode: "errors.invalid_email",
    defaultError: "Invalid email",
  },
  greaterThan: {
    validation: (value: string, data: IValidationData) => {
      return toNumber(value) > data.validation.min;
    },
    errorCode: "beneficiary.allocations.error.zero_percent",
    defaultError: "Added beneficiaries cannot have 0% allocation",
  },
  inRange: {
    validation: (value: string, data: IValidationData): boolean => {
      const { validation } = data;
      const parsedValue = parseInt(value);
      const bottom = defaultTo(validation.min, Number.NEGATIVE_INFINITY);
      const top = defaultTo(validation.max, Number.POSITIVE_INFINITY);
      return parsedValue >= bottom && parsedValue <= top;
    },
    errorCode: "errors.outside_valid_range",
    defaultError: "Value is outside of the valid range",
  },
  middleInitial: {
    validation: (value: string) => {
      return value.length < 2;
    },
    errorCode: "beneficiary.form.validation.middle_initial",
    defaultError: "Middle inital must be one character",
  },
  mustMatch: {
    validation: (value: string, data: IValidationData) => {
      const { inputs, validation } = data;
      const matchValue = get(inputs, [validation.matchId, "value"]);
      return value === matchValue;
    },
    errorCode: "errors.password_match",
    defaultError: "Passwords do not match",
  },
  phoneNumber: {
    validation: (value: { phoneNumber: string; countryCode: string }, data: IValidationData) => {
      const isRequired = data.inputs[data.inputId].required;

      return validatePhoneNumber(value, isRequired);
    },
    errorCode: "",
    defaultError: "This should be a 10-digit number",
  },
  required: {
    validation: (value: string): boolean => value !== undefined && value !== null && value !== "",
    errorCode: "errors.required",
    defaultError: "Required",
  },
  ssnTin: {
    validation: (value: string) => {
      return validateTIN(value);
    },
    errorCode: "errors.invalid_ssn_tin",
    defaultError: "This should be a 9-digit number",
  },
  validateDateFormat: {
    validation: validateDateFormat,
    errorCode: "errors.invalid_date_format",
    defaultError: "Format date as mm/dd/yyyy",
  },
  validateDateRange: {
    validation: validateDateRange,
    errorCode: "errors.invalid_date_range",
    defaultError: "Date is outside of valid range",
  },
  validatePasswordLength: {
    validation: validatePassword,
    errorCode: "errors.password_12_characters",
    defaultError: "Must be 12 characters or longer",
  },
  validateUnitizedRequired: {
    validation: validateUnitizedRequired,
    errorCode: "errors.unitized_required",
    defaultError: "All fields are required",
  },
};

export default formValidations;
