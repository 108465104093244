import {
  disclosureQuery,
  disclosureWithBodyQuery,
  businessDisclosuresQuery,
} from "graphql/queries/disclosure";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDisclosure = createAsyncThunk(
  "disclosure/getDisclosure",
  async ({ slug }: { slug: string }, { rejectWithValue }) => {
    try {
      const response = await disclosureQuery(slug);

      return response.data.disclosure;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getDisclosureWithBody = createAsyncThunk(
  "disclosure/getDisclosureWithBody",
  async ({ slug }: { slug: string }, { rejectWithValue }) => {
    try {
      const response = await disclosureWithBodyQuery(slug);

      return response.data.disclosure;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const getBusinessDisclosures = createAsyncThunk(
  "disclosure/getBussinessDisclosures",
  async ({ businessSlug }: { businessSlug: string }, { rejectWithValue }) => {
    try {
      const response = await businessDisclosuresQuery(businessSlug);

      return response.data.businessDisclosures;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
