import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { BusinessSlugTypes, TFinanceCustomersStatus } from "config";
import { RootState, useAppDispatch } from "store";
import {
  getActiveMembershipBusinesses,
  getIsUserWyshFinanceEnabled,
  getMembershipMetadata,
  selectFinanceCustomer,
} from "store/selectors";

import { push } from "connected-react-router";
import { includes } from "lodash-es";

//------  TYPES  ------//
type SavingsPlusLaunchStates =
  | Exclude<TFinanceCustomersStatus, null>
  | "cdd_not_submitted"
  | "blocked"
  | "default";
type SavingsInsuranceLaunchStates = "dashboard" | "default";

//------  PRODUCT LAUNCH PATHS  ------/
export const wyshFinancialPaths: Record<SavingsPlusLaunchStates, string> = {
  automated_reviewed: "/products/savings/verification/automated_reviewed",
  blocked: "/products/savings/coming-soon",
  cdd_not_submitted: "/flow/savings-cdd",
  created: "/flow/savings-default-application",
  default: "/products/savings/welcome",
  denied: "/products/savings/verification/denied",
  manual_reviewed: "/",
  verified: "/products/savings",
};

const wyshInsuranceLaunchStates: Record<SavingsInsuranceLaunchStates, string> = {
  dashboard: "/",
  default: "/",
};

// Hook to determine launch state of user based on business type
export const useProductLaunch = (byBusiness?: BusinessSlugTypes) => {
  const dispatch = useAppDispatch();
  const financeMemberMetadata = useSelector(getMembershipMetadata);
  const userActiveMemberships = useSelector(getActiveMembershipBusinesses);
  const financeCustomer = useSelector(selectFinanceCustomer);
  const isUserFinanceEnabled = useSelector(getIsUserWyshFinanceEnabled);
  const { preVerifiedDataFetched } = useSelector((state: RootState) => state.profile);

  const screenPath = useRef("/");

  const financeCustomerStatus = financeCustomer.status || "default";
  const shouldLaunchCddForm =
    includes(["automated_reviewed", "manual_reviewed", "verified"], financeCustomerStatus) &&
    financeMemberMetadata?.cdd_submitted === false;

  useEffect(() => {
    if (userActiveMemberships["wysh-financial"] || byBusiness === "wysh-financial") {
      if (!isUserFinanceEnabled) {
        screenPath.current = wyshFinancialPaths.blocked;
      } else if (shouldLaunchCddForm) {
        screenPath.current = wyshFinancialPaths.cdd_not_submitted;
      } else {
        screenPath.current = wyshFinancialPaths[financeCustomerStatus];
      }
    } else if (userActiveMemberships["wysh-insurance"] || byBusiness === "wysh-insurance") {
      screenPath.current = wyshInsuranceLaunchStates.dashboard;
    }
  }, [
    byBusiness,
    financeCustomerStatus,
    isUserFinanceEnabled,
    shouldLaunchCddForm,
    userActiveMemberships,
  ]);

  const launchAppToProduct = () => {
    if (preVerifiedDataFetched) {
      dispatch(push(screenPath.current));
    }
  };

  return {
    launchAppToProduct,
    launchPathToProduct: screenPath.current,
    loaded: preVerifiedDataFetched,
  };
};
