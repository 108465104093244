import "./CloseAccountModal.scss";

import { FC } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { closeModal } from "store/actions/modal";
import { openChat, openFaqLink } from "utils/customerSupport";

const CloseAccountFailedModal: FC<IModalProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const onClickChat = () => {
    openChat();
    closeModalWrapper();
  };

  const onClickFaq = () => {
    openFaqLink();
    closeModalWrapper();
  };

  const content = {
    alwaysShowIcon: true,
    button: [
      {
        text: t("settings.profile.view.close.alert.modal.primary_cta", "CHAT WITH US"),
        subType: "primary",
        onClick: onClickChat,
        dataTestId: "close-account-failed-modal__chat-btn",
      },
      {
        text: t("settings.profile.view.close.alert.modal.secondary_cta", "GO TO FAQS"),
        subType: "text-dark-border",
        onClick: onClickFaq,
      },
    ],
    header: t("settings.profile.view.close.alert.modal.title", "Please contact us"),
    icon: "BlobHelp",
    onClose: closeModalWrapper,
    subheader: t(
      "settings.profile.view.close.alert.modal.body",
      "We apologize, but there was an error while closing your account. Please contact our support team and we will get this all sorted out.\n\nChat support is available Mon-Fri from 9am-5pm EST."
    ),
  };

  return <Layout as="ModalLayout" className="account-close-modal" content={content} />;
};

export default CloseAccountFailedModal;
