import { tokenSilentRefreshMinutes } from "config";
import { AuthLevel, IAccessToken } from "config/types";

import { get } from "lodash-es";
import { DateTime } from "luxon";
import store, { session } from "store2";

export const setRefreshToken = (accessToken: IAccessToken, authLevel = "unidentified") => {
  store("token", { token: accessToken.refresh_token, createdAt: DateTime.local(), authLevel });
};

export const getRefreshToken = (): { token: string; createdAt: string; authLevel: AuthLevel } => {
  const authIdentity = session.get("AuthIdentity");
  const storedToken = get(authIdentity, "token", store("token"));
  return storedToken;
};

export const shouldRefreshToken = (expiresInSeconds: number): boolean => {
  const createdAt = getRefreshToken().createdAt;
  const expiration = DateTime.fromISO(createdAt).plus({ seconds: expiresInSeconds });

  const diff = expiration.diff(DateTime.local(), "minutes");
  const minutesToExpiration = Math.floor(diff.minutes);

  return minutesToExpiration <= tokenSilentRefreshMinutes;
};
