import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { RootState } from "store";
import { closeModal } from "store/actions/modal";

const VerificationEmailSentModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pendingEmail, activeEmail, lastEmailIsPending } = useSelector(
    (state: RootState) => state.profile
  );

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const email = lastEmailIsPending ? pendingEmail : activeEmail;

  const content = {
    header: t("settings.profile.view.modal.title", "Link sent!"),
    subheader: t(
      "settings.profile.view.modal.body",
      "We’ve sent your verification email to %<email>s. Please check your inbox, thanks.",
      { email: email?.email || "" }
    ),
    onClose: closeModalWrapper,
    button: {
      text: t("settings.profile.view.modal.button.done", "DONE"),
      subType: "primary",
      onClick: closeModalWrapper,
      dataTestId: "resend-email-button",
    },

    icon: "BlobPaperPlane",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default VerificationEmailSentModal;
