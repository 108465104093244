import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { RecursiveKeyOf, RouteLocation } from "config/types";
import { Layout, useJourney, useTranslation } from "modules";
import { RootState } from "store";
import { logout } from "store/actions/authentication";
import { clearJourneyActive } from "store/actions/journey";
import { closeModal } from "store/actions/modal";
import { getIsMissingPayment } from "store/selectors";

import { push } from "connected-react-router";
import { get, isEmpty } from "lodash-es";

const JourneyExit: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { exitJourney, rootJourney } = useJourney();
  const location = props.data.location as RouteLocation;
  const contentFromProps = props.data.content;
  const journey = useSelector((state: RootState) => state.journey);
  const isJourneyEmpty = isEmpty(journey.activeJourneys);
  const isOnboardingJourney = rootJourney?.context === "onboarding";
  const isMissingPayment = useSelector(getIsMissingPayment);

  const handleExitJourney = async () => {
    if (isOnboardingJourney) {
      dispatch(logout());
      const targetPath = "/login";
      const destinationUrl = { ...location, pathname: targetPath, state: { ignoreAction: true } };
      dispatch(push(destinationUrl));
      dispatch(clearJourneyActive());
    } else {
      await exitJourney();
      if (props.data?.logOutUser) dispatch(logout());
      dispatch(push("/login"));
    }
    closeModalWrapper();
  };

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const defaultTextContent = {
    header: t("journey.journey_resume.modal.title", "Journey In Progress"),
    subheader: t(
      "journey.journey_resume.modal.body",
      "You're in the middle of a journey. Don't abandon it! Get in there!"
    ),
    buttons: {
      primary: t("journey.journey_resume.modal.button_confirm", "Exit Journey"),
      secondary: t("journey.journey_resume.modal.button_cancel", "Cancel"),
    },
  };

  const missingPaymentContent = {
    header: t("missing_payment.exit.modal.title", "Don’t leave without setting up payment!"),
    subheader: t(
      "missing_payment.exit.modal.subtitle",
      "Your account is almost ready to roll! Add a payment method to keep your premiums up to date and you can also relax knowing your policy won’t lapse."
    ),
    buttons: {
      primary: t("missing_payment.exit.modal.cta.primary", "ADD PAYMENT METHOD"),
      secondary: t("missing_payment.exit.modal.cta.secondary", "I'LL PAY LATER"),
    },
  };

  const textContent = isMissingPayment ? missingPaymentContent : defaultTextContent;

  const getContent = (path: RecursiveKeyOf<typeof textContent>) => {
    const defaultContent = get(textContent, path);
    return isMissingPayment ? defaultContent : get(contentFromProps, path, defaultContent);
  };

  const primaryCallback = isMissingPayment ? closeModalWrapper : handleExitJourney;
  const secondaryCallback = isMissingPayment ? handleExitJourney : closeModalWrapper;

  const ctaButtons = [
    {
      text: getContent("buttons.primary"),
      onClick: primaryCallback,
      dataTestId: "journey-exit-modal__confirm",
    },
    {
      text: getContent("buttons.secondary"),
      subType: "text-dark",
      onClick: secondaryCallback,
      dataTestId: "journey-exit-modal__decline",
    },
  ];

  const content = {
    header: getContent("header"),
    subheader: getContent("subheader"),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: isMissingPayment ? "BlobCreditCard" : "BlobAlert",
  };

  return (
    <Layout
      as="ModalLayout"
      content={content}
      renderFallback={isJourneyEmpty}
      fallback={Fallback}
    />
  );
};

const Fallback: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return <Redirect to="/" />;
};

export default JourneyExit;
