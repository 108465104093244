import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout, useTranslation } from "modules";
import { RootState } from "store";
import { startJourney } from "store/actions/journey";
import { closeModal } from "store/actions/modal";

import { isEmpty } from "lodash-es";

const JourneyCompleted: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());
  const isPending = useSelector((state: RootState) => state.request.inFlight);
  const journeyId = useSelector((state: RootState) => state.modal.id);

  const handleStartJourney = () => {
    if (!isEmpty(journeyId)) {
      dispatch(startJourney(journeyId));
      closeModalWrapper();
    }
  };

  const ctaButtons = [
    {
      text: t("journey.journey_completed.modal.button_cancel", "Cancel"),
      isLoading: isPending,
      onClick: closeModalWrapper,
    },
    {
      text: t("journey.journey_completed.modal.button_confirm", "Start Journey Anyway"),
      subType: "text-dark",
      onClick: handleStartJourney,
    },
  ];

  const content = {
    header: t("journey.journey_completed.modal.title", "Journey Completed"),
    subheader: t(
      "journey.journey_completed.modal.body",
      "You have already completed this journey before. I mean, you can go through it again, but why? Just why? You know what, go ahead and complete this journey again. It won't change a thing anyway."
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default JourneyCompleted;
