import { FC } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { closeModal } from "store/actions/modal";
import { openChat } from "utils/customerSupport";

const CancelPolicyModal: FC<IModalProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const closeModalWrapper = () => dispatch(closeModal());

  const onClickConfirm = () => {
    openChat();
    closeModalWrapper();
  };

  const onClickDecline = () => {
    closeModalWrapper();
  };

  const content = {
    header: t("policy.cancel.title", "Cancel Plan"),
    subheader: t("policy.cancel.body", "Please contact customer service to cancel your plan"),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("policy.cancel.cta", "OK"),
        subType: "primary",
        onClick: onClickConfirm,
      },
      {
        text: t("policy.cancel.cancel", "CANCEL"),
        subType: "text-dark-border",
        onClick: onClickDecline,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobAlert",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default CancelPolicyModal;
