import "./TriviaLayout.scss";

import { FC } from "react";

import { ILayoutProps } from "modules";

const TriviaLayout: FC<ILayoutProps<Record<string, never>>> = props => {
  const { children } = props;

  return (
    <div className="trivia__container">
      <div className="trivia__viewport">{children}</div>
    </div>
  );
};

export default TriviaLayout;
