import { IPlaidItem } from "config/types";
import { logout } from "store/actions/authentication";
import { getPlaidItems } from "store/actions/bank";

import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash-es";

/* ------------    TYPES     ------------------ */
interface IPlaidItemsState {
  ids: IPlaidItem["id"][];
  byId: Record<IPlaidItem["id"], IPlaidItem>;
}

/* ------------    PLAIDITEMS DEFAULT STATE     ------------------ */
const defaultState: IPlaidItemsState = {
  ids: [],
  byId: {},
};

/* ------------    REDUCER     ------------------ */
const plaidItems = createReducer(defaultState, builder => {
  builder
    .addCase(getPlaidItems.fulfilled, (state, action) => {
      const plaidItems = action.payload || [];

      state.ids = plaidItems.map(plaidItem => plaidItem.id);
      state.byId = keyBy(plaidItems, "id");
    })
    .addCase(logout, () => defaultState);
});

export default plaidItems;
