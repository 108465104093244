import { CaseResult, IProductOffering } from "config";
import {
  acceptProductOfferingMutation,
  IUpdateProductOfferingInput,
  updateProductOfferingMutation,
} from "graphql/mutations/product-offering";
import { IAdjustCoverageInput } from "graphql/queries/estimate";
import { adjustProductOfferingQuery, fetchProductOfferings } from "graphql/queries/productOffering";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProductOfferings = createAsyncThunk(
  "offerings/getProductOfferings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchProductOfferings();
      const activeAccount = getActiveAccount(response.data.me);
      const latestInsuranceApplication = activeAccount?.latestInsuranceApplication;
      const accountProductOfferings = activeAccount?.productsOfferings || [];
      const productOfferings: IProductOffering[] = accountProductOfferings.map(
        (offering: IProductOffering) => {
          return {
            ...offering,
            productSlug: offering.product.slug,
            allocationsCoveredPercentage: offering.allocationCoverage,
            annualPremium:
              offering.premiums.find(premium => premium.billingFrequency === "annually")?.amount ||
              0,
            monthlyPremium:
              offering.premiums.find(premium => premium.billingFrequency === "monthly")?.amount ||
              0,
          };
        }
      );

      return { productOfferings, latestInsuranceApplication };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const offeringsReviewPolling = createAsyncThunk(
  "offerings/offeringsReviewPolling",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await dispatch(getProductOfferings()).unwrap();

      const caseResult: CaseResult = response.latestInsuranceApplication?.result || "";
      const caseId = response.latestInsuranceApplication?.id || "";

      return { caseResult, caseId, productOfferings: response.productOfferings };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const acceptProductOffering = createAsyncThunk(
  "offerings/acceptProductOffering",
  async (offeringId: string, { rejectWithValue }) => {
    try {
      const { data } = await acceptProductOfferingMutation({ offeringId });

      return { success: data.acceptProductOffering.response.success };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const adjustProductOffering = createAsyncThunk(
  "offerings/adjustProductOffering",
  async (input: IAdjustCoverageInput, { rejectWithValue }) => {
    try {
      const response = await adjustProductOfferingQuery(input);

      return {
        ...response.data.adjustProductOffering,
        offeringId: input.id,
      };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const updateProductOffering = createAsyncThunk(
  "offerings/updateProductOffering",
  async (input: IUpdateProductOfferingInput, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateProductOfferingMutation(input);
      await dispatch(getProductOfferings()).unwrap();

      return { success: response.data.updateProductOffering.response.success };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
