import "./QuoteLoader.scss";

import { FC } from "react";

import lottieImage from "assets/lottie/quote_loader.json";
import Lottie from "components/Lottie/Lottie";
import Text from "components/Text/Text";
import { useTranslation } from "modules/hooks/useTranslation";

const QuoteLoader: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="quote-loader-container">
      <div className="quote-loader-wrapper flex-column--center">
        <div className="quote-loader__image--wrapper flex-column--center">
          <Lottie className="quote-loader__image" lottieData={lottieImage} />
        </div>

        <div className="quote-loader__text--body flex-column--center">
          <div className="quote-loader__text--wrapper flex-column--center">
            <Text
              className="quote-loader__text--header"
              tag="h6"
              text={t("quote.loader.title", "Building your tailored plan...")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteLoader;
