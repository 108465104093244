import "./VerifySession.scss";

import { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import VerifyPhoneImage from "assets/images/registration/VerifyPhone.svg";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { PhoneVerificationMode } from "config";
import { Layout, useJourney, useTranslation } from "modules";
import { RootState, useAppDispatch } from "store";
import { requestOtpCode } from "store/actions/authentication";
import { getActivePhoneNumber, getVerificationPhoneNumber } from "utils/phoneNumber";

import VerifySessionOTP from "./VerifySessionOTP";

interface IProps {
  backNav?: {
    hasBackNav?: boolean;
    pathname?: string;
    icon?: string;
    text?: string;
    replaceExitInJourneyWithBack?: boolean;
    overrideDefaultBehavior?: boolean;
    onClick?(): void;
  };
  shouldRedirect?: boolean;
  type?: "phone" | "session";
  onSuccess?(): void;
}

const VerifySession: FC<IProps> = props => {
  const { backNav, onSuccess, shouldRedirect = true, type = "session" } = props;
  const { t } = useTranslation();
  const { anyJourneysActive } = useJourney();
  const dispatch = useAppDispatch();
  const [verificationMode, setVerificationMode] = useState<PhoneVerificationMode | null>(null);
  const [loading, setLoading] = useState<PhoneVerificationMode | false>(false);

  const phoneNumbers = useSelector((state: RootState) => state.profile.phoneNumbers);
  const phoneNumber =
    type === "session"
      ? getActivePhoneNumber(phoneNumbers)
      : getVerificationPhoneNumber(phoneNumbers);

  const requestOTP = useCallback(
    async (type: PhoneVerificationMode = "sms") => {
      try {
        setLoading(type);
        const payload = { type, phoneNumberId: phoneNumber?.id };
        await dispatch(requestOtpCode(payload)).unwrap();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, phoneNumber]
  );

  const handleButtonClick = useCallback(
    async (mode: PhoneVerificationMode) => {
      try {
        setLoading(mode);
        await new Promise(resolve => setTimeout(resolve, 1000));
        requestOTP(mode);
        setVerificationMode(mode);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [requestOTP]
  );

  const handleTextButtonClick = () => {
    handleButtonClick("sms");
  };

  const handleCallButtonClick = () => {
    handleButtonClick("voice");
  };

  const buttonsDisabled = loading !== false;

  const content = {
    header: t("session_verify.overview.title", "2-step verification"),
    subheader: [
      t(
        "session_verify.overview.subtitle",
        "To keep your account safe, well send you a verification code via text or through an automated phone call."
      ),
      <Text key="subtitle2" tag="p6" className="verify-phone__terms-disclaimer">
        {t(
          "session_verify.overview.disclaimer.text",
          "By selecting 'Text my code' you agree to receive one text message per login. Message and data rates may apply. Reply HELP for help or STOP to cancel."
        )}
        <br />
        <a
          href="https://www.wysh.com/sms-terms"
          target="_blank"
          rel="noopener noreferrer"
          className="link-text">
          <b>
            {t(
              "session_verify.overview.disclaimer.link_text",
              "Mobile Terms of Service;  Privacy Policy"
            )}
          </b>
        </a>
      </Text>,
    ],
    imgSrc: VerifyPhoneImage,
  };

  const backNavProps = backNav || { hasBackNav: anyJourneysActive };

  if (verificationMode !== null) {
    return (
      <VerifySessionOTP
        backNav={
          backNavProps?.hasBackNav === true
            ? { ...backNavProps, onClick: () => setVerificationMode(null) }
            : undefined
        }
        changeMode={setVerificationMode}
        mode={verificationMode}
        onSuccess={onSuccess}
        phoneNumber={phoneNumber}
        shouldRedirect={shouldRedirect}
      />
    );
  }

  return (
    <Layout as="TwoColumnLayout" content={content} backNav={backNavProps}>
      <div className="settings-verify-phone__content-wrapper">
        <Button
          tag="c6"
          text={t("session_verify.overview.button.sms", "Text my code")}
          className="settings-verify-phone__resend-code-wrapper full-width"
          dataTestId="verify-session__text-button"
          isLoading={loading === "sms"}
          disabled={buttonsDisabled}
          onClick={handleTextButtonClick}
        />
        <Button
          tag="c6"
          subtype="text-dark"
          text={t("session_verify.overview.button.voice", "Call me with my code")}
          className="settings-verify-phone__resend-code-wrapper margin-auto mt-25"
          isLoading={loading === "voice"}
          disabled={buttonsDisabled}
          onClick={handleCallButtonClick}
        />
      </div>
    </Layout>
  );
};

export default VerifySession;
