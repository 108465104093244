import "./TriviaQuestion.scss";

import { FC, MouseEvent } from "react";

import Text from "components/Text/Text";
import TriviaBanner from "components/Trivia/TriviaBanner";
import { ITriviaAnswer } from "config/types";

import classNames from "classnames";

interface IProps {
  isQuoteReady: boolean;
  questionScreenDuration: number;
  answers?: ITriviaAnswer[];
  question?: string;
  questionNumber?: number;
  selectedAnswerSlug?: string;
  onComplete(event?: MouseEvent<HTMLDivElement>): void;
  onSelectAnswer(slug: string): void;
}

const TriviaQuestion: FC<IProps> = props => {
  const {
    answers = [],
    isQuoteReady,
    onComplete,
    onSelectAnswer,
    question,
    questionNumber,
    questionScreenDuration,
    selectedAnswerSlug,
  } = props;

  const answerListClasses = classNames("trivia-question__answer-list", {
    "trivia-question__answer-list--answer-selected": selectedAnswerSlug,
  });

  return (
    <div className="trivia-question__container">
      {isQuoteReady ? <TriviaBanner onComplete={onComplete} /> : null}

      <div
        className="trivia-question__background trivia-question__background--color"
        style={{
          animationDuration: `${questionScreenDuration}s`,
        }}
      />
      <div className="trivia-question__background trivia-question__background--image" />

      <Text className="trivia-question__number-indicator" tag="n4">
        {questionNumber}
      </Text>

      <Text className="trivia-question__question-text" tag="h4">
        {question}
      </Text>

      <ul className={answerListClasses}>
        {answers.map(({ correct, name, slug }: ITriviaAnswer) => {
          const isCorrectAnswer = selectedAnswerSlug && correct;
          const isIncorrectAnswer = selectedAnswerSlug === slug && !correct;

          const listItemClasses = classNames("trivia-question__answer-list-item", {
            "trivia-question__answer-list-item--correct": isCorrectAnswer,
            "trivia-question__answer-list-item--incorrect": isIncorrectAnswer,
          });

          return (
            <li className={listItemClasses} key={slug}>
              <button
                className="trivia-question__answer-list-item-button"
                onClick={() => onSelectAnswer(slug)}>
                {name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TriviaQuestion;
