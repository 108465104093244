import { useSelector } from "react-redux";

import { RootState } from "store";

import jwtDecode from "jwt-decode";

export const useDecodedAccessToken = (): any => {
  const token = useSelector((state: RootState) => state.authentication.accessToken?.access_token);
  try {
    return jwtDecode(token || "");
  } catch {
    return {};
  }
};
