import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

import { selectExternalAccount } from "./selectExternalAccounts";
import { selectFinanceBankAccount } from "./selectFinanceBankAccount";
import { selectFinanceBankTransactions } from "./selectFinanceBankTransactions";
import { hasOpenedFinanceAccount } from "./selectFinanceCustomer";
import { getMembershipMetadata } from "./selectMemberships";
import { getAutoDepositTransferSchedule } from "./selectTransferSchedules";

const getHasExternalAccountsLoaded = (state: RootState) => state.externalAccounts.hasLoaded;
const getHasFinanceBankAccountLoaded = (state: RootState) => state.financeBankAccount.hasLoaded;

export const getHasUnlinkedExternalAccount = createSelector(
  selectExternalAccount,
  getHasExternalAccountsLoaded,
  getMembershipMetadata,
  hasOpenedFinanceAccount,
  (externalAccount, hasExternalAccountsLoaded, membershipMetadata, hasOpenFinanceAccount) => {
    const hasCompletedCDDForm = membershipMetadata?.cdd_submitted === true;

    return (
      hasExternalAccountsLoaded && !externalAccount && hasCompletedCDDForm && hasOpenFinanceAccount
    );
  }
);

export const getIsFinanceAccountUnfunded = createSelector(
  selectFinanceBankAccount,
  getHasFinanceBankAccountLoaded,
  selectFinanceBankTransactions,
  getAutoDepositTransferSchedule,
  getMembershipMetadata,
  hasOpenedFinanceAccount,
  (
    financeBankAccount,
    hasLoadedFinanceBankAccount,
    bankTransactions,
    autoDepositId,
    membershipMetadata,
    hasOpenFinanceAccount
  ) => {
    const hasZeroBalance = !financeBankAccount.accountBalance && !financeBankAccount.pendingBalance;
    const hasNoInitiatedDeposit = !bankTransactions.length;
    const hasNoAutoDeposit = !autoDepositId;
    const hasCompletedCDDForm = membershipMetadata?.cdd_submitted === true;

    return (
      hasLoadedFinanceBankAccount &&
      hasZeroBalance &&
      hasNoInitiatedDeposit &&
      hasNoAutoDeposit &&
      hasCompletedCDDForm &&
      hasOpenFinanceAccount
    );
  }
);
