import "./Container.scss";

import React, { ComponentProps, HTMLProps } from "react";

import { ClassName, OrString } from "config/types";

import classNames from "classnames";

interface IProps extends Omit<HTMLProps<HTMLDivElement>, "className"> {
  children: React.ReactNode;
  className?: ClassName;
  as?: any;
  gutters?: boolean;
  fixed?: boolean;
  maxWidth?: OrString<number>;
}

const Container = React.forwardRef<HTMLDivElement, IProps & ComponentProps<IProps["as"]>>(
  function Container(props, ref) {
    const {
      className,
      as = "div",
      gutters = false,
      fixed = false,
      maxWidth = "large",
      ...other
    } = props;

    const Component = as;

    const conditionals = {
      fixed: fixed,
      "gutters-disabled": gutters,
      [`mw-${maxWidth}`]: maxWidth,
    };
    const classes = classNames(className, conditionals, "container");
    return <Component className={classes} ref={ref} {...other} />;
  }
);

export default Container;
