import { useContext } from "react";

import { TApiButtonCommandFn } from ".";

import { find } from "lodash-es";

import { DynamicFormContext } from "../DynamicFormContext";

export const useSetFormAction = () => {
  const { inputs } = useContext(DynamicFormContext);

  const setFormActionCommand: TApiButtonCommandFn = async commandArgs => {
    const actionArgument = find(commandArgs, ["name", "action"]);
    const actionValue = find(inputs, ["key", actionArgument?.source])?.value;

    return { formAction: actionValue };
  };

  return setFormActionCommand;
};
