// check if event has already been fired
// necessary for screens that poll for data
// avoids case where event could be fired twice if user refreshes page
import { IAllocationSummary } from "config/types";
import { eventFired } from "store/actions/analytics";
import { IEstimateState } from "store/reducers/estimate";

import { Dispatch } from "@reduxjs/toolkit";
import { get, has, includes, uniq } from "lodash-es";
import { local } from "store2";

export const setEventFiredOnceTracker = (event: string, id: string) => {
  const stored = local.get("eventsFired");
  const eventsFired = stored ? stored : {};

  if (has(eventsFired, event)) {
    eventsFired[event] = uniq([...eventsFired[event], id]);
  } else {
    eventsFired[event] = [id];
  }

  local.set("eventsFired", eventsFired);
};

export const getEventAlreadyFired = (event: string, id: string) => {
  const stored = local.get("eventsFired");
  if (!stored) {
    return false;
  }

  return has(stored, event) && includes(stored[event], id);
};

export const sendEstimateBuilderComplete = ({
  allocationSummary,
  dispatch,
  estimate,
  userId,
  eventName,
}: {
  allocationSummary: IAllocationSummary;
  dispatch: Dispatch<any>;
  estimate: Partial<IEstimateState>;
  userId: string;
  eventName: string;
}) => {
  if (!estimate.id || getEventAlreadyFired(eventName, userId)) {
    return;
  }

  const attribute = {
    estimatePremium: get(estimate, "monthlyPremium"),
    coverageAmount: get(estimate, "amount"),
    coverageTerm: get(estimate, "termDuration", 0) * 12,
    smoker: get(estimate, "tobacco"),
    health: get(estimate, "health"),
    // gender: "",
    // birthdate: "",
    // state: "",
    wyshCount: allocationSummary.activeCount,
    wyshTotal: allocationSummary.totalAmount,
  };

  dispatch(
    eventFired({
      event: eventName,
      experienceLocation: "ONBOARDING",
      attribute,
    })
  );
  setEventFiredOnceTracker(eventName, userId);
};
