import "./ListRowItem.scss";

import { MouseEvent } from "react";

import Divider from "components/Divider/Divider";
import { IDynamicFormItemProps } from "containers/DynamicForm/DynamicFormItems";
import { ListRowItemColumn } from "containers/DynamicForm/DynamicFormItems/ListRowItem/ListRowItemColumn";
import { useButtonAction } from "containers/DynamicForm/useButtonAction";

import classNames from "classnames";

export function ListRowItem({ item, className }: IDynamicFormItemProps<"listRow">) {
  const { leadingColumn, showsDivider, trailingColumn, verticalPadding } = item.content;
  const actions = item.content.actions || [];

  const actionCommands = useButtonAction(actions);
  const classes = classNames(
    "dynamic-form-item form-item--list-row form-item-list-row",
    className,
    {
      "form-item-list-row--clickable": actions.length,
    }
  );

  const handleOnClick = async (event: MouseEvent) => {
    if (actions.length) {
      event.stopPropagation();

      await actionCommands();
    }
  };

  return (
    <div className={classes} onClick={handleOnClick}>
      {leadingColumn && (
        <ListRowItemColumn
          column={leadingColumn}
          key={`${item.key}-leading-column`}
          type="leading"
          verticalPadding={verticalPadding}
        />
      )}

      {trailingColumn && (
        <ListRowItemColumn
          column={trailingColumn}
          key={`${item.key}-trailing-column`}
          type="trailing"
          verticalPadding={verticalPadding}
        />
      )}

      {showsDivider && <Divider className="form-item-list-row__divider" />}
    </div>
  );
}
