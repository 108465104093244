import "./AboutAd.scss";

import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { fulfillmentQuoteAcceptedEvent } from "analytics/fulfillmentEvents";
import { aboutADDisplayedEvent, productOfferEditEvent } from "analytics/productOfferEvents";
import HeroImage from "assets/images/illustrations/AboutADHero/AboutADHero.svg";
import ADFAQ from "components/ADFAQ/ADFAQ";
import ADOfferCard from "components/ADOfferCard/ADOfferCard";
import Button from "components/Button/Button";
import { Layout, useJourney, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { acceptProductOffering } from "store/actions/product-offerings";
import { getQuote } from "store/actions/quote";
import { getWyshADOffering } from "store/selectors";
import { poll } from "utils/poll";
import { scrollToTop } from "utils/scrollToTop";

import { goBack, push } from "connected-react-router";
import { find, get } from "lodash-es";

import Features from "./Features";

interface IProps {
  onClickEdit?(offeringType: "termLifeOffering" | "adOffering"): void;
  onClickBack?(): void;
}

const AboutAD: FC<IProps> = props => {
  const { onClickEdit, onClickBack } = props;
  const { t } = useTranslation();
  const adOffering = useSelector(getWyshADOffering);
  const [loading, setLoading] = useState(false);
  const { nextStepCallback, anyJourneysActive } = useJourney();

  const dispatch = useAppDispatch();

  const hasValidADProduct = adOffering && adOffering?.status !== "expired";

  const content = {
    pretitle: t("ad.about.pretitle", "INTRODUCING"),
    title: t("ad.about.title", "Accidental death insurance"),
    subtitle: t(
      "ad.about.subtitle",
      "Unlike term life insurance, that covers most cases of death, accidental death insurance is focused only on accident-related deaths. See below for more details."
    ),
    heroImage: HeroImage,
    twoOfferings: hasValidADProduct,
  };

  const getQuotePolling = async () => {
    const payload = await dispatch(getQuote()).unwrap();
    const quoteDocuments = get(payload, "documents", []);
    const targetDocument = find(quoteDocuments, document => document.slug === "quote");

    return !!targetDocument?.id;
  };

  const onClickCta = async () => {
    if (!adOffering) {
      return;
    }
    try {
      setLoading(true);
      await dispatch(acceptProductOffering(adOffering.id)).unwrap();

      await poll(getQuotePolling);
      dispatch(fulfillmentQuoteAcceptedEvent());

      setLoading(false);
      nextStepCallback()();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleEditClick = () => {
    if (onClickEdit) {
      onClickEdit("adOffering");
    } else {
      dispatch(push("/edit-coverage/adOffering"));
    }
    dispatch(productOfferEditEvent("ad"));
  };

  const handleBackClick = () => {
    if (onClickBack) {
      onClickBack();
    } else {
      dispatch(goBack());
    }
  };

  const handleApplyNow = () => {
    dispatch(push("/fulfillment/start"));
  };

  useEffect(() => {
    dispatch(aboutADDisplayedEvent());
    scrollToTop({ behavior: "auto" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout
        className="about-ad"
        as="ADProductOfferingLayout"
        content={content}
        backNav={{
          hasBackNav: true,
          replaceExitInJourneyWithBack: anyJourneysActive,
          onClick: handleBackClick,
        }}>
        {!hasValidADProduct && (
          <Button
            text={t("ad.about.no_quote.cta", "APPLY NOW")}
            onClick={handleApplyNow}
            className="about-ad__cta--apply"
          />
        )}
        <Features />
        {adOffering && adOffering.status !== "expired" && (
          <ADOfferCard
            mode="purchase"
            productOffering={adOffering}
            onClickCta={onClickCta}
            onClickEdit={handleEditClick}
            loading={loading}
          />
        )}
      </Layout>
      <div className="result-ad__faq">
        <ADFAQ />
      </div>
    </>
  );
};

export default AboutAD;
