import { FC } from "react";

import Text, { TextType } from "components/Text/Text";
import { ISO8601Date } from "config";
import { ConditionalWrapper } from "modules";

import { DateTime } from "luxon";

interface IProps {
  date: ISO8601Date;
  format?: string; // using Luxon token-based formatting
  textTag?: TextType;
}

const DateLabel: FC<IProps> = ({ date, format = "MM/dd/yyyy", textTag = "span" }) => {
  const dateTimeFromISO = DateTime.fromISO(date);
  const isValidDateTime = dateTimeFromISO.isValid;
  const formattedDate = dateTimeFromISO.toFormat(format);

  return (
    <ConditionalWrapper condition={!!textTag} props={{ tag: textTag }} wrapper={Text}>
      {isValidDateTime ? formattedDate : "--/--/----"}
    </ConditionalWrapper>
  );
};

export default DateLabel;
