import { getHasValidReinstatementProductOfferingByPolicyId } from "store/selectors/getHasValidReinstatementProductOffering";
import { isValidPolicy } from "utils/policy";

import { every, find, isEmpty, isEqual } from "lodash-es";

import { RootState } from "../store";
import { IReinstateablePolicy, RecursiveKeyOf } from "./types";

export type ConditionKey = RecursiveKeyOf<ReturnType<typeof buildConditions>>;

export const buildConditions = (state: RootState) => {
  return {
    auth: {
      authenticated: !!state.authentication.authenticated,
      loggedIn: !!state.authentication.authenticated,
      sessionVerified: !!state.authentication.sessionVerified,
    },
    featureFlags: {
      skipPhoneNumber: !!find(state.featureFlags, {
        name: "wysh-insurance-skip-client-phone-screen",
        enabled: true,
      }),
    },
    finance: {
      hasNotCompletedOnboarding:
        !state.financeCustomer.status || state.financeCustomer.status === "created",
    },
    user: {
      dataFetched: !!state.user.id,
      doesNotHavePolicyOrPayment: !(
        isValidPolicy(state.policy) || state.payment.status === "active"
      ),
      hasEmail: !isEmpty(state.profile.emails),
      hasEstimate: !isEmpty(state.estimate.id),
      hasPolicy: !isEmpty(state.policy.id),
      hasQuote: !isEmpty(state.quote.id),
      hasPhoneNumber: !isEmpty(state.profile.phoneNumbers),
      hasVerifiedPhoneNumber: !!state.profile.activePhoneNumber?.id,
      hasQuoteOrPolicy: !isEmpty(state.quote.id) || !isEmpty(state.policy.id),
      hasQuoteOrValidPolicy: !isEmpty(state.quote.id) || isValidPolicy(state.policy),
      hasPolicyOrPayment: isValidPolicy(state.policy) || state.payment.status === "active",
      hasProductOffering: !isEmpty(state.productOffering.ids),
      hasValidProductOffers:
        !isEmpty(state.productOffering.ids) &&
        !every(state.productOffering.byId, {
          status: "expired",
        }),
      hasValidReinstatementProductOffer: !!(
        state.journey.collection.reinstatementFulfillment?.metadata?.reinstateablePolicyId &&
        getHasValidReinstatementProductOfferingByPolicyId(
          state,
          state.journey.collection.reinstatementFulfillment.metadata
            .reinstateablePolicyId as IReinstateablePolicy["id"]
        )
      ),
    },
    wyshes: {
      hasAllocations: !isEmpty(state.wyshAllocations.allocated),
    },
    payment: {
      active: state.payment.status === "active",
    },
    policy: {
      valid: isValidPolicy(state.policy),
      terminated: state.policy.status === "terminated",
    },
    quote: {
      accepted:
        isEqual(state.quote.status, "accepted") || isEqual(state.quote.caseResult, "accepted"),
    },
    journey: {
      hasActiveJourney: !isEmpty(state.journey.activeJourneys),
    },
    memberships: {
      hasFinance: !!state.financeMembership.id,
      hasInsurance: !!state.insuranceMembership.id,
    },
  };
};
