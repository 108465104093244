import "./FlowQuestions.scss";

import { FC, FormEvent, useEffect } from "react";
import { useSelector } from "react-redux";

import Button from "components/Button/Button";
import FormError from "components/FormError/FormError";
import { FlowTypes, IFlowForm } from "config";
import { AnalyticsIds } from "config/analytics";
import { IFlowQuestion, IForm, IFormInputs } from "config/types";
import { useFlowAdContent } from "modules/hooks/useFlowAdContent";
import { useTranslation } from "modules/hooks/useTranslation";
import { RootState } from "store";
import { buildFormInputsFromQuestions } from "utils/form";

import { get, isEmpty, map } from "lodash-es";

import { FlowQuestion } from "./FlowQuestion";

interface IProps {
  questions: IFlowQuestion[];
  inputs: IFormInputs;
  form: IForm;
  formId: IFlowForm["id"];
  slug?: FlowTypes;
  formErrorMessage?: string;
  terminalForm?: boolean;
  formError?: string;
  ctaText?: string;
  fieldTitles?: Record<string, string | undefined>;
  fieldDisclosures?: Record<string, string | undefined>;
  onSubmit(event: FormEvent<HTMLFormElement>): void;
}

const FlowQuestions: FC<IProps> = props => {
  const {
    questions,
    inputs,
    formError,
    formErrorMessage = "",
    formId,
    form,
    slug,
    terminalForm = false,
    fieldTitles = {},
    fieldDisclosures = {},
    ctaText = "",
    onSubmit,
  } = props;
  const { t } = useTranslation();
  // Although FlowQuestions supports multiple questions and questions fields, all known use cases are a single question and single field
  // Analytics expects the analytics id from a single field question to be available on the submit button
  // We will default to using first field from first question
  // For Savings-Plus work, we'll need to use the `savings-application` instead.
  // TODO: Determine the assignment of these keys based on specs and have a programmatic system in place to track these keys.
  const firstFieldKey = questions[0]?.fields[0]?.key;
  const analyticsId = get(AnalyticsIds, `flowQuestions.${firstFieldKey}`, "");
  const adContentKey =
    slug === "savings-default-application" ? "savings-application" : firstFieldKey;
  const { flowAdContentComponent } = useFlowAdContent(adContentKey);

  const inputValues = useSelector((state: RootState) => state.formFlow.inputValues);

  useEffect(() => {
    const formInputs = buildFormInputsFromQuestions(questions, inputValues);
    form.initializeFormState(formInputs);
  }, [formId, questions]); //eslint-disable-line react-hooks/exhaustive-deps

  if (!questions || isEmpty(inputs)) {
    return null;
  }

  return (
    <form noValidate onSubmit={onSubmit} className="flow__question-form-container">
      {map(questions, (question: IFlowQuestion) => {
        return (
          <FlowQuestion
            {...props}
            key={question.id}
            slug={slug}
            question={question}
            inputs={inputs}
            terminalForm={terminalForm}
            fieldTitles={fieldTitles}
            fieldDisclosures={fieldDisclosures}
          />
        );
      })}
      <FormError error={formErrorMessage} dataTestId="question__form-error" />
      {adContentKey === "savings-application" && flowAdContentComponent}
      <Button
        id="flow-questions__submit"
        className="flow__submit-button two-col-layout__next"
        subtype="primary"
        text={ctaText || t("question_container.form.button", "NEXT")}
        onClick={() => form.validateAllFields()}
        disabled={form.getSubmitBtnDisabled()}
        isLoading={form.loading}
        type="submit"
        dataTestId="question__submit-button"
        dataAnalyticsId={analyticsId}
      />
      {formError && <FormError error={formError} dataTestId="flow__form-error" />}
      {adContentKey !== "savings-application" && flowAdContentComponent}
    </form>
  );
};

export default FlowQuestions;
