import { IFinanceBankTransaction } from "config/types";
import { cancelBankTransactionMutation } from "graphql/mutations";
import {
  financeBankAccountQuery,
  financeBankTransactionsQuery,
} from "graphql/queries/financeBankAccount";
import { RootState } from "store";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFinanceBankAccount = createAsyncThunk(
  "financeBankAccount/getFinanceBankAccount",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { financeCustomer } = getState() as RootState;
      const financeAccountId = financeCustomer.accounts[0]?.id;

      const { data } = await financeBankAccountQuery(financeAccountId);
      return data.bankAccount;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  },
  {
    condition: (_, { getState }) => {
      const { financeCustomer } = getState() as RootState;
      const financeAccount = financeCustomer.accounts[0];

      if (!financeAccount?.id || !financeAccount?.openedAt) {
        return false;
      }
    },
  }
);

export const fetchFinanceBankTransactions = createAsyncThunk(
  "financeBankTransactions/fetchFinanceBankTransactions",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { financeCustomer } = getState() as RootState;
      const beginDate = financeCustomer.createdAt;
      const endDate = new Date().toISOString();
      const financeAccountId = financeCustomer.accounts[0]?.id;

      const input = {
        beginDate,
        endDate,
        financeAccountId,
      };

      const { data } = await financeBankTransactionsQuery(input);
      return data.financeBankTransactions;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  },
  {
    condition: (_, { getState }) => {
      const { financeCustomer } = getState() as RootState;
      const financeAccount = financeCustomer.accounts[0];

      if (!financeCustomer.createdAt || !financeCustomer.id || !financeAccount?.openedAt) {
        return false;
      }
    },
  }
);

export const cancelBankTransaction = createAsyncThunk(
  "cancelBankTransaction",
  async (bankAccountId: IFinanceBankTransaction["externalId"], { dispatch, rejectWithValue }) => {
    try {
      const { data } = await cancelBankTransactionMutation(bankAccountId);
      await dispatch(fetchFinanceBankTransactions());

      return data.cancelBankTransaction.response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
