import "./CoverageDenied.scss";

import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  manualUWDashboardEvent,
  manualUWFAQEvent,
  manualUWStartEvent,
} from "analytics/underwritingEvents";
import VariableText from "components/VariableText/VariableText";
import { UWFaqUrl } from "config";
import { ICase } from "config/types";
import { Layout, useJourney, useNavigate } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { logout } from "store/actions/authentication";
import { finishJourney } from "store/actions/journey";
import { getHasValidPolicy } from "store/selectors";

enum EnumDenialType {
  location,
  dob,
  default,
  rejected,
  rejected_reinstatement,
  failed,
  referred_to_underwriter,
  state_ineligible,
}

export type DenialTypes = keyof typeof EnumDenialType;

interface IProps {
  type: DenialTypes;
  latestCase: ICase;
}

const CoverageDenied: FC<IProps> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { startJourney } = useJourney();
  const navigate = useNavigate();

  const hasExistingValidPolicy = useSelector(getHasValidPolicy);

  const redirectToHome = () => {
    dispatch(finishJourney("fulfillment"));
    navigate("/");
    ctaAnalyticsEvent();
  };
  const redirectToWishBuilder = () => {
    navigate("/wysh-builder");
  };

  const startFulfillment = () => {
    startJourney("fulfillment");
  };

  const handleDefaultCtaClick = () => {
    dispatch(logout());
  };

  const denialType: DenialTypes = [
    "location",
    "dob",
    "rejected",
    "rejected_reinstatement",
    "failed",
    "referred_to_underwriter",
    "state_ineligible",
  ].includes(type)
    ? type
    : "default";

  const underwritingSubheader = t(
    "coverage.result.ruw.body",
    "The robots did their best, but your application needs a human touch. Our underwriting team will soon run a manual review of your info, so sit back and relax. You should hear from us in about 48 working hours.\n\nLearn more about manual underwriting"
  );
  const underwritingLinkText = t("coverage.result.ruw.link", "here");
  const underwritingSubheaderText = `${underwritingSubheader} ${underwritingLinkText}`;

  useEffect(() => {
    if (type === "referred_to_underwriter") {
      dispatch(manualUWStartEvent());
    }
  }, [dispatch, type]);

  const uwFAQonClick = () => {
    dispatch(manualUWFAQEvent());
  };

  const ctaAnalyticsEvent = () => {
    if (type === "referred_to_underwriter") {
      dispatch(manualUWDashboardEvent());
    }
  };

  const subHeaderWithLink = (
    <VariableText
      target={underwritingLinkText}
      text={underwritingSubheaderText}
      replacementValue={
        <a
          className="custom-link--inline link p3-tag__text"
          href={UWFaqUrl}
          target="_blank"
          rel="noreferrer"
          onClick={uwFAQonClick}>
          {underwritingLinkText}
        </a>
      }
    />
  );

  const content = {
    default: {
      header: t("coverage_denied.default.title", "Sorry, friend :("),
      subheader: t(
        "coverage_denied.default.subtitle",
        "It truly pains us that we can't insure you—we crunched the numbers, and sadly, it just didn't work out.\n\n With that said, we believe it's better to have tried than live life wondering what would have happened... So, great job on starting the journey to financial security.\n\n Happy trails!"
      ),
      button: t("coverage_denied.default.button", "GOT IT"),
      onButtonClick: handleDefaultCtaClick,
      icon: "BlobSorry",
    },
    dob: {
      header: t("coverage_denied.dob.title", "Shoot! It looks like we can’t insure you"),
      subheader: t(
        "coverage_denied.dob.subtitle",
        "Unfortunately, Wysh can only satisfy those aged between 18–45. \n\nWith that said, we believe it's better to have tried than live life wondering what would have happened... So, great job on starting the journey to financial security.\n\nHappy trails!"
      ),
      button: t("coverage_denied.dob.button", "GOT IT"),
      onButtonClick: redirectToHome,
      icon: "BlobAlert",
    },
    location: {
      header: t(
        "coverage_denied.location.title",
        "Sorry, our trail hasn’t led us to your state yet"
      ),
      subheader: t(
        "coverage_denied.location.subtitle",
        "But we’re headed there soon!\n\nIn the meantime, we can still help you calculate exactly how much coverage you might need (no matter where you get your plan).\n\nWe do it with a little something we like to call the Wish Builder."
      ),
      buttons: [
        {
          text: t("coverage_denied.location.button_1", "OK, LET'S DO IT"),
          subType: "primary",
          onClick: redirectToWishBuilder,
        },
        {
          text: t("location.denial.button_2", "TRY AGAIN LATER"),
          subType: "text-dark-border",
          onClick: redirectToHome,
        },
      ],
      icon: "BlobSorry",
    },
    state_ineligible: {
      header: t("location.denial.title", "Sorry, we're not in your state yet"),
      subheader: [
        t(
          "location.denial.body.message",
          "We're currently only able to offer Wysh memberships to people with residential addresses in the following states:"
        ),
        t(
          "location.denial.body.eligible_states",
          "Arizona, Georgia, Illinois, Michigan, North Carolina, Ohio, Oregon, Pennsylvania, Wisconsin"
        ),
        t(
          "location.denial.body.fallback",
          "If you do happen to live in one of these states, feel free to continue."
        ),
      ],
      buttons: [
        {
          text: t("location.denial.cta.primary", "CONTINUE"),
          subType: "primary",
          onClick: startFulfillment,
        },
        {
          text: t("location.denial.cta.secondary", "TRY AGAIN LATER"),
          subType: "text-dark-border",
          onClick: redirectToHome,
        },
      ],
      icon: "BlobSorry",
    },
    rejected: {
      header: t("coverage_denied.default.title", "Sorry, friend :("),
      subheader: t(
        "coverage_denied.default.subtitle",
        "It truly pains us that we can't insure you—we crunched the numbers, and sadly, it just didn't work out.\n\n With that said, we believe it's better to have tried than live life wondering what would have happened... So, great job on starting the journey to financial security.\n\n Happy trails!"
      ),
      button: t("coverage_denied.default.button", "GOT IT"),
      onButtonClick: redirectToHome,
      icon: "BlobSorry",
    },
    rejected_reinstatement: {
      icon: "BlobSorry",
      header: t(
        "coverage_denied.reinstatement_rejected.title",
        "Sorry, you aren’t eligible for reinstatement"
      ),
      subheader: t(
        "coverage_denied.reinstatement_rejected.subtitle",
        "Unfortunately, due to a change in your health or other underwriting criteria, we can no longer offer you this policy.\n\nNever fear, though, you can still apply for a new policy, which often can be cheaper anyway because you don’t have to pay missed premiums."
      ),
      buttons: [
        !hasExistingValidPolicy
          ? {
              text: t(
                "coverage_denied.reinstatement_rejected.cta.new_policy",
                "APPLY FOR A NEW POLICY"
              ),
              subType: "primary",
              onClick: async () => {
                await dispatch(finishJourney("reinstatementFulfillment"));
                startFulfillment();
              },
            }
          : null,
        {
          text: t("coverage_denied.reinstatement_rejected.cta.close", "CLOSE"),
          subType: "text-dark-border",
          onClick: async () => {
            await dispatch(finishJourney("reinstatementFulfillment"));
            await navigate("/");
          },
        },
      ].filter(Boolean),
    },
    failed: {
      header: t("quote_review.time_out", "Operation has timed out"),
      subheader: t(
        "quote_review.failed",
        "A technical error has occurred. Please try again later."
      ),
      button: t("coverage_denied.failed.button", "GOT IT"),
      onButtonClick: redirectToHome,
      icon: "BlobAlert",
    },
    referred_to_underwriter: {
      header: t("coverage.result.ruw.title", "We need to take a closer look at your application"),
      subheader: subHeaderWithLink,
      buttons: [
        {
          text: t("coverage.result.ruw.cta", "GO TO DASHBOARD"),
          onClick: redirectToHome,
          dataTestId: "coverage-denied-ruw_cta",
        },
      ],
      icon: "BlobPolicy",
    },
  };

  return (
    <Layout
      as="AuthLayout"
      content={content[denialType]}
      className="coverage-denied__container"
      finalScreen={true}
    />
  );
};

export default CoverageDenied;
