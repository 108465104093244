import { FC } from "react";
import { useDispatch } from "react-redux";

import Text from "components/Text/Text";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { openChat } from "utils/customerSupport";

import { push } from "connected-react-router";

const PastDueBalanceModal: FC<IModalProps> = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = props.closeModal;
  const lapsesAt = props.data.lapsesAt as string;

  const content = {
    icon: "BlobAlert",
    header: t("policy.billing.past_due.modal.header", "You have a past due balance"),
    subheader: (
      <>
        {t(
          "policy.billing.past_due.modal.subheader_1",
          "To keep your policy, you may need to retry your payment or update your payment method. Unfortunately, we will have to cancel your policy if the past due amount is not received by %<lapsesAt>s. If you have billing questions, please contact",
          { lapsesAt }
        )}{" "}
        <Text
          as="span"
          className="modal__subheader-inline-cta"
          onClick={() => {
            openChat();
            closeModal();
          }}
          tag="p3"
          text={t("policy.billing.past_due.modal.subheader_cs", "Customer Service")}
        />{" "}
        {t("policy.billing.past_due.modal.subheader_2", "for help.")}
      </>
    ),
    button: [
      {
        onClick: () => {
          dispatch(push("/products/insurance/billing"));
          closeModal();
        },
        text: t("policy.billing.past_due.modal.cta_billing", "GO TO BILLING"),
      },
      {
        onClick: () => {
          openChat();
          closeModal();
        },
        subType: "text-dark",
        text: t("policy.billing.past_due.modal.cta_help", "GET HELP"),
      },
    ],
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default PastDueBalanceModal;
