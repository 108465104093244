import { FC } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { FlowTypes } from "config/types";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { closeModal } from "store/actions/modal";
import { resetQuote } from "store/actions/quote";

import { push } from "connected-react-router";

const UnderwritingRequiredModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const underwritingSlug: FlowTypes = "aura-interview";

  const closeModalWrapper = () => dispatch(closeModal());

  const redirectToUnderwriting = () => {
    closeModalWrapper();
    dispatch(resetQuote());
    dispatch(push(`/flow/${underwritingSlug}`));
  };

  const content = {
    header: t("policy.main.checkup.confirm.title", "Hey, good-looking! It's checkup time"),
    subheader: t(
      "policy.main.checkup.confirm.body",
      "It's that time again where we go over your current health status to make sure your plan is up-to-date. It won't hurt, promise!"
    ),
    onClose: closeModalWrapper,
    button: [
      {
        text: t("policy.main.checkup.confirm.cta", "LET'S DO IT"),
        subType: "primary",
        onClick: redirectToUnderwriting,
      },
    ],
    onButtonClick: closeModal,
    icon: "BlobFavorite",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default UnderwritingRequiredModal;
