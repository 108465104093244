import { useContext } from "react";

import { IFormInputs } from "config";
import { createFlowResponse } from "store/actions/flow";
import { useAppDispatch, useAppSelector } from "store/index";

import { DynamicFormContext } from "../DynamicFormContext";

export const useCreateResponse = () => {
  const dispatch = useAppDispatch();
  const flowSessionId = useAppSelector(state => state.formFlow.flowSessionId);
  const { inputs } = useContext(DynamicFormContext);

  const createResponseCommand = async () => {
    return await dispatch(
      createFlowResponse({ formInputs: formatInputValues(inputs), flowSessionId })
    ).unwrap();
  };

  return createResponseCommand;
};

const formatInputValues = (inputs: IFormInputs) => {
  return Object.keys(inputs).reduce((memo: IFormInputs, key: string) => {
    const input = inputs[key];
    let value = input.value;

    switch (input.inputType) {
      case "inputNumeric":
        if (input.precision && input.precision > 0) {
          value = parseFloat(input.value);
        } else {
          value = parseInt(input.value, 10);
        }
        break;
    }

    memo[key] = {
      ...input,
      value,
    };

    return memo;
  }, {});
};
