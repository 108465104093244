import { useContext } from "react";

import { DynamicFormContext } from "containers/DynamicForm/DynamicFormContext";

export const useDismiss = () => {
  const { modalOverlay } = useContext(DynamicFormContext);

  const dismissCommand = () => {
    modalOverlay?.closeModalOverlay && modalOverlay?.closeModalOverlay();
  };

  return dismissCommand;
};
