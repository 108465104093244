import { FC } from "react";
import { useSelector } from "react-redux";

import { IAppError } from "config";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { RootState } from "store";

type ErrorObjectType = IAppError & InstanceType<ErrorConstructor>;

const GeneralErrorModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { error = {} } = useSelector((state: RootState) => state.modal.data);
  const { code = "", fullMessage = "", message = "" } = error as ErrorObjectType;

  const content = {
    icon: "BlobAlert",
    header: t("errors.graphql_error", "Oops. There was an error."),
    subheader: fullMessage || code || message,
    button: {
      onClick: () => closeModal(),
      text: t("close", "CLOSE"),
    },
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default GeneralErrorModal;
