import "./RadioInput.scss";

import { ChangeEvent, CSSProperties, FC } from "react";

import Icon from "components/Icon/Icon";
import { ClassName } from "config/types";

import classNames from "classnames";

interface IProps {
  checked: boolean;
  name: string;
  value: string;
  bordered?: boolean;
  className?: ClassName;
  dataTestId?: string;
  disabled?: boolean;
  label?: string;
  style?: CSSProperties;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput: FC<IProps> = props => {
  const {
    bordered,
    checked,
    className,
    dataTestId,
    disabled = false,
    label,
    name,
    onChange,
    style,
    value,
  } = props;

  const classes = classNames(className, "radio p3-tag__text", {
    "radio--bordered": bordered,
    "radio--bordered-inactive": bordered && !checked,
    "radio--disabled": disabled,
  });

  const checkMarkClasses = classNames("radio__checkmark", {
    checked: checked,
  });

  return (
    <label style={style} className={classes}>
      <input
        data-testid={dataTestId || "radio__input"}
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={onChange}
        readOnly={!onChange}
        type="radio"
        value={value}
      />
      <div className="radio__label-text">{label}</div>
      <div className={checkMarkClasses}>
        <Icon src="RadioSelected" />
      </div>
    </label>
  );
};

export default RadioInput;
