import { IValidationData } from "config/types";

import { DateTime } from "luxon";

export const validateDateFormat = (iso: string): boolean => {
  const dateTime = DateTime.fromISO(iso);

  return dateTime.isValid;
};

export const validateDateRange = (iso: string, data: IValidationData) => {
  const { validation } = data;
  const dateTime = DateTime.fromISO(iso);

  if (!dateTime.isValid) {
    return false;
  }

  const isAboveMinDate = dateTime >= DateTime.fromISO(validation.min);
  const isBelowMaxDate = dateTime <= DateTime.fromISO(validation.max);

  switch (true) {
    case !validation.max && !validation.min:
      return true;

    case validation.max:
      return isBelowMaxDate;

    case validation.min:
      return isAboveMinDate;

    default:
      return isAboveMinDate && isBelowMaxDate;
  }
};
