import { defaultPasswordLength } from "config";

import { get, isString } from "lodash-es";

const validatePassword = (password: string, options?: { passwordLength: number }): boolean => {
  const length = get(options, "passwordLength", defaultPasswordLength);
  return isString(password) && password.length >= length;
};

export default validatePassword;
