import { IPhoneNumber } from "config/types";

import { parsePhoneNumber } from "libphonenumber-js";
import { defaultTo, find, get } from "lodash-es";

export const getVerificationPhoneNumber = (phoneNumbers: IPhoneNumber[]) => {
  phoneNumbers = phoneNumbers.slice().sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  const pendingNumber = find(phoneNumbers, ["status", "pending"]);
  const activeNumber = find(phoneNumbers, ["status", "active"]);
  return defaultTo(pendingNumber, activeNumber);
};

export const getActivePhoneNumber = (phoneNumbers: IPhoneNumber[]) => {
  return find(phoneNumbers, ["status", "active"]);
};

export const formatPhoneNumber = (
  phoneNumber: IPhoneNumber | { countryCode: string; phoneNumber: string },
  { hyphenated } = { hyphenated: false }
) => {
  try {
    if (!phoneNumber.phoneNumber) {
      return "";
    }
    // TODO when backend starts storing country code, pull value from phone number record
    const countryCode = get(phoneNumber, "countryCode", 1) || "";
    const phoneNumberNational = parsePhoneNumber(
      `+${countryCode}${phoneNumber.phoneNumber}`
    ).formatNational();

    // Formatted as 555-555-1234
    if (hyphenated) {
      return phoneNumberNational.replace(/\(|\)/g, "").replace(" ", "-");
    }
    // Formated as (555) 555-1234
    else {
      return phoneNumberNational;
    }
  } catch (error) {
    return phoneNumber.phoneNumber ? phoneNumber.phoneNumber : "";
  }
};
