import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ModalKey } from "containers/Modals/modals";
import { useModal } from "modules";
import { useAppDispatch } from "store";
import { getCurrentUserPastDueInvoices } from "store/actions/invoice";
import { createMultplePayments, getActivePaymentMethod } from "store/actions/payment";
import { getPastDueInvoiceData } from "store/selectors";

import { defaultTo, noop, upperFirst } from "lodash-es";
import { DateTime } from "luxon";

interface IUsePastDueInvoiceDataOptions {
  onModalClose?(): void;
}

interface IRetryPayment {
  location: "policy" | "settings";
}

export const usePastDueInvoiceData = (options?: IUsePastDueInvoiceDataOptions) => {
  const dispatch = useAppDispatch();
  const openModal = useModal({ onClose: options?.onModalClose || noop });

  const [error, setError] = useState("");
  const [pending, setPending] = useState(true);
  const [retrying, setRetrying] = useState(false);
  const pastDueInvoiceData = useSelector(getPastDueInvoiceData);

  const fetchData = useCallback(async () => {
    let response;

    try {
      setPending(true);
      response = await dispatch(getCurrentUserPastDueInvoices()).unwrap();
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
    } finally {
      setPending(false);
    }

    return response;
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const retryPayment = async ({ location }: IRetryPayment) => {
    const formattedLocation = upperFirst(location);
    let modalName: ModalKey = `${formattedLocation}BillingPaymentRetrySuccessModal` as ModalKey;
    const modalData: Record<string, unknown> = {
      lapsesAt: DateTime.fromISO(pastDueInvoiceData.lapsesAt).toFormat("MM/dd/yy"),
    };

    try {
      setRetrying(true);

      const { billingAttemptedAmount, processed } = await dispatch(
        createMultplePayments({ invoiceIds: pastDueInvoiceData.pastDueInvoiceIds })
      ).unwrap();

      const status = processed.reduce((memo, { transactionResult }) => {
        if (transactionResult === "failed" || memo === "failed") {
          return "failed";
        } else if (transactionResult === "processing" || memo === "processing") {
          return "processing";
        } else {
          return memo;
        }
      }, "success");

      modalData.billingAttemptedAmount = billingAttemptedAmount;

      if (status === "failed") {
        modalName = `${formattedLocation}BillingPaymentRetryFailureModal` as ModalKey;
      } else if (status === "processing") {
        modalName = `${formattedLocation}BillingPaymentRetryProcessingModal` as ModalKey;
      }
    } catch {
      modalName = "PaymentFailed";
    }

    try {
      const activePaymentMethod = await dispatch(getActivePaymentMethod()).unwrap();

      modalData.source = activePaymentMethod.source;

      if (activePaymentMethod.source === "credit_card") {
        modalData.cardMask = activePaymentMethod.metadata.card?.last4;
        modalData.cardType = upperFirst(activePaymentMethod.metadata.card?.brand);
      } else if (activePaymentMethod.source === "bank_account") {
        modalData.bankAccountMask = activePaymentMethod.plaidAccount.mask;
        modalData.bankName = activePaymentMethod.plaidAccount.plaidItem?.plaidInstitution?.name;
        modalData.bankAccountType = activePaymentMethod.plaidAccount?.accountType;
      }
    } catch (error) {
      console.log("error fetching payment method data", error);
    }

    try {
      await fetchData();
    } catch (error) {
      console.log("error refetching invoice data", error);
    }

    setRetrying(false);
    openModal(modalName, "", modalData);
  };

  return {
    ...pastDueInvoiceData,
    retryPayment,
    metadata: {
      error,
      pending,
      retrying,
    },
    refreshPastDueInvoiceData: fetchData,
  };
};
