import { ICountry } from "config/types/store.types";
import { logout } from "store/actions/authentication";
import { getCountries } from "store/actions/country";

import { createReducer } from "@reduxjs/toolkit";

interface ICountryState {
  countries: ICountry[];
}

const defaultState: ICountryState = {
  countries: [],
};

const country = createReducer(defaultState, builder => {
  builder
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    })
    .addCase(logout, () => defaultState);
});

export default country;
