import { IDynamicFormItemProps } from ".";

import c from "classnames";

export function SpacerItem({ item }: IDynamicFormItemProps<"space">) {
  const { min, mode } = item.content;

  const spaceClassName = `mb-${min}`;
  const classes = c("dynamic-form-item form-item--space", spaceClassName, {
    "space-flexible": mode === "flexible",
  });

  return <div className={classes} />;
}
