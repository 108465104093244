import { IFinanceTransferSchedule } from "config";
import { fetchFinanceScheduledTransfers } from "store/actions";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { keyBy } from "lodash-es";

interface ITransferScheduleState {
  ids: IFinanceTransferSchedule["id"][];
  byId: Record<IFinanceTransferSchedule["id"], IFinanceTransferSchedule>;
}

/* ------------    TRANSFER_SCHEDULES STATE     ------------------ */
const defaultState: ITransferScheduleState = {
  ids: [],
  byId: {},
};

/* ------------    MATCHERS     ------------------ */
const isTransferScheduleFulfilled = isAnyOf(
  getVerifiedInitialData.fulfilled,
  fetchFinanceScheduledTransfers.fulfilled
);

/* ------------    REDUCER     ------------------ */
const transferSchedules = createReducer(defaultState, builder => {
  builder
    .addCase(logout, () => defaultState)
    .addMatcher(isTransferScheduleFulfilled, (state, action) => {
      const financeCustomer = action.payload.me.financeCustomer;
      const financeAccounts = financeCustomer?.accounts || [];
      const transferSchedules = financeAccounts.flatMap(account => [...account.transferSchedules]);

      state.ids = transferSchedules.map(transfer => transfer.id);
      state.byId = keyBy(transferSchedules, "id");
    });
});

export default transferSchedules;
