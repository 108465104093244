import { createContext, FC } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";

import modals from "containers/Modals";
import { RootState, useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";

import { AnimatePresence } from "framer-motion";
import { get, has, isEmpty, isFunction } from "lodash-es";

export type ModalContextValue = {
  callbacks?: { [key: string]: (...args: unknown[]) => void };
};

export interface IModalMethods {
  closeModal(onClose?: (data: IModalData) => void): void;
}

export interface IModalData {
  data: Record<string, unknown>;
  id: string;
}
export interface IModalProps extends IModalMethods, IModalData {
  context: ModalContextValue;
}

export interface IModalResource {
  component: FC<IModalProps>;
  displayName: string;
}

const defaultContextValue: ModalContextValue = {};

const nulled = () => null;
export const ModalContext = createContext<ModalContextValue>(defaultContextValue);

// Tests fail without this check
if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

export const getCurrentModal = (currentModal?: string): FC<IModalProps> | (() => null) => {
  if (currentModal === undefined || isEmpty(currentModal) || !has(modals, currentModal)) {
    return () => null;
  }
  return get(modals, currentModal, nulled) as FC<IModalProps> | (() => null);
};

export const ModalsProvider: FC = () => {
  const dispatch = useAppDispatch();
  const modalState = useSelector((state: RootState) => state.modal);
  const { currentModal, data, id } = modalState;
  const modalProps = { data, id };

  const Modal = getCurrentModal(currentModal);

  const handleClose: IModalMethods["closeModal"] = onClose => {
    if (onClose && isFunction(onClose)) {
      onClose(modalProps);
    }

    dispatch(closeModal());
  };

  const modalMethods = { closeModal: handleClose };

  const ModalConsumer = (
    <ModalContext.Consumer>
      {context => (
        <AnimatePresence exitBeforeEnter>
          {currentModal && <Modal context={context} {...modalProps} {...modalMethods} />}
        </AnimatePresence>
      )}
    </ModalContext.Consumer>
  );

  return ModalConsumer;
};
