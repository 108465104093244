import { FC } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { closeModal } from "store/actions/modal";

import { delay } from "lodash-es";

const SettingsSuccess: FC<IModalProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onInitialRender = () => {
    const callback = () => {
      dispatch(closeModal());
      props.context?.callbacks?.onClose && props.context?.callbacks?.onClose();
    };
    const closeAfterTimer = delay(callback, 2000);
    return () => clearTimeout(closeAfterTimer);
  };

  const content = {
    header: t("settings.success_modal.title", "Updated!"),
    icon: "BlobCheck",
    onInitialRender,
  };

  return <Layout as="ModalStatusLayout" content={content} />;
};

export default SettingsSuccess;
