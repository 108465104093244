import "./AddressGroupComponent.scss";

import { ComponentProps, FC, useContext, useMemo, useState } from "react";

import { DynamicFormGroupComponentProps } from ".";

import AddressInput, { AddressDataKey, defaultAddress } from "components/AddressInput/AddressInput";
import { SingleSelectInputFormItem, TextInputFormItem, ValueOrArray } from "config";
import { useModal } from "modules";

import { compact, every, forEach, includes, mapValues, reduce, some } from "lodash-es";

import { DynamicFormContext } from "../DynamicFormContext";

type AddressInputComponentProps = ComponentProps<typeof AddressInput>;
type AddressElements = "street_1" | "street_2" | "city" | "state" | "postal_code" | "postal-code";
type DynamicFormAddressInputs = SingleSelectInputFormItem | TextInputFormItem;

const AddressDataElementMap: Record<AddressDataKey, ValueOrArray<AddressElements>> = {
  address1: "street_1",
  address2: "street_2",
  city: "city",
  state: "state",
  zipCode: ["postal_code", "postal-code"],
};

interface IProps extends DynamicFormGroupComponentProps {
  items: DynamicFormAddressInputs[];
}

export const AddressGroupComponent: FC<IProps> = ({ children, group, items = [] }) => {
  // HOOKS
  const openModal = useModal();
  const { form, inputs } = useContext(DynamicFormContext);

  // LOCAL STATE
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  // LOCAL DERIVED VARIABLES
  const hasReachedErrorLimitOnApi = apiErrors.length > 2;
  const mapItemElementToAddressKey = mapValues(AddressDataElementMap, addressElement => {
    return items.find(formItem =>
      includes(addressElement, formItem.content.element)
    ) as DynamicFormAddressInputs;
  });
  const stateInputItem = mapItemElementToAddressKey.state as SingleSelectInputFormItem;
  const allFieldsRequired = every(mapItemElementToAddressKey, { content: { required: true } });
  const isRequired = mapItemElementToAddressKey["address1"]?.content.required && !allFieldsRequired;

  const initialAddressValue = useMemo(
    () =>
      reduce(
        mapItemElementToAddressKey,
        (addressData, dynamicFormItem, addressKey) => {
          const dynamicFormItemValue = dynamicFormItem?.content.value || "";
          addressData = { ...addressData, [addressKey]: dynamicFormItemValue };

          return addressData;
        },
        defaultAddress
      ),
    [group] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const preferredState = useMemo(() => {
    if (stateInputItem && stateInputItem.content.value) {
      return stateInputItem.content.options.find(
        option => option.value === stateInputItem.content.value
      );
    }
  }, [stateInputItem]);

  const preferences = useMemo<AddressInputComponentProps["preferences"]>(
    () => ({
      states: compact([preferredState?.key]),
    }),
    [preferredState]
  );

  // LOCAL METHODS
  const handleOnClearAddressClick = () => {
    forEach(group.detail.itemKeys, itemKey => {
      const formInput = inputs[itemKey];

      if (formInput) {
        const changeEvent = {
          target: {
            id: itemKey,
            value: "",
          },
        };

        form.onChange(changeEvent);
      }
    });
  };

  const handleOnSelectSuggestedAddress: AddressInputComponentProps["onSelectedItemChange"] = ({
    address,
  }) => {
    forEach(mapItemElementToAddressKey, (formItem, addressField) => {
      const formInput = inputs[formItem.key];
      const suggestedAddressValue = address[addressField as AddressDataKey];

      if (formInput) {
        const changeEvent = {
          target: {
            id: formInput.id,
            value: suggestedAddressValue,
          },
        };

        form.onChange(changeEvent);
      }
    });
  };

  const onApiError: Exclude<
    AddressInputComponentProps["onErrors"],
    undefined
  >["onApiError"] = error => {
    if (error) {
      setApiErrors(apiErrors => [...apiErrors, error]);
    }
  };

  const onUnsupportedState = () => {
    openModal("UnsupportedStateModal");
  };

  // RENDER
  if (hasReachedErrorLimitOnApi) {
    return <>{children}</>;
  }

  return (
    <AddressInput
      className="dynamic-form-group form-group--input-address"
      disabled={some(mapItemElementToAddressKey, item => item?.content?.disabled)}
      initialValue={initialAddressValue}
      onClear={handleOnClearAddressClick}
      onErrors={{ onApiError, onUnsupportedState }}
      onSelectedItemChange={handleOnSelectSuggestedAddress}
      required={isRequired}
      preferences={preferences}
      states={stateInputItem?.content?.options}>
      <>{children}</>
    </AddressInput>
  );
};
