import { IPayoutDetails, IProductDetail, IQuote } from "config/types";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { adjustQuote, getQuote, resetQuote } from "store/actions/quote";
import { toFixed } from "utils/toFixed";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { find } from "lodash-es";

export interface IQuoteState extends IQuote {
  productDetail: IProductDetail;
  payoutDetails: IPayoutDetails;
  annualPremium: string;
  monthlyPremium: string;
  allocationsCoveredPercentage: number;
}

/* ------------    QUOTE STATE     ------------------ */
const defaultState: IQuoteState = {
  id: "",
  amount: 0,
  termDuration: 0,
  termUnit: "month",
  allocationCoverage: 0,
  monthlyPremium: "0.00",
  annualPremium: "0.00",
  allocationsCoveredPercentage: 0,
  insuredsAgeAtTerm: 0,
  status: "pending",
  expiresAt: "",
  premiums: [],
  caseResult: "",
  packages: [],
  documents: [],
  extensionAvailable: false,
  classification: {
    id: "",
    name: "",
    gender: "",
  },
  productDetail: {
    id: "",
    name: "",
    slug: "",
    status: "",
    description: "",
  },
  payoutDetails: {
    autoPedestrian: 0,
    generalAccident: 0,
    publicTransportation: 0,
  },
  insuranceApplication: {
    id: "",
    result: "",
    version: 0,
  },
};

/* ------------    ADD MATCHERS     ------------------ */
const setPremiumMatcher = isAnyOf(getDashboardData.fulfilled, getQuote.fulfilled);
const setPayoutMatcher = isAnyOf(getDashboardData.fulfilled, getQuote.fulfilled);

/* ------------    REDUCER     ------------------ */
const quote = createReducer(defaultState, builder => {
  builder
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const pendingQuote = action.payload?.pendingQuote || defaultState;
      const productDetail = pendingQuote?.product || state.productDetail;

      return {
        ...state,
        ...pendingQuote,
        productDetail,
      };
    })
    .addCase(getQuote.fulfilled, (state, action) => {
      const pendingQuote = !!action.payload?.id ? action.payload : defaultState;
      const productDetail = pendingQuote?.product || state.productDetail;

      return {
        ...state,
        ...pendingQuote,
        productDetail,
      };
    })
    .addCase(adjustQuote.fulfilled, (state, action) => {
      const premium = action.payload;
      const annualPremium = toFixed(premium.annualPremium);
      const monthlyPremium = toFixed(premium.monthlyPremium);

      state.annualPremium = annualPremium;
      state.monthlyPremium = monthlyPremium;
      state.allocationsCoveredPercentage = premium.allocationsCoveredPercentage;
    })
    .addCase(resetQuote, () => defaultState)
    .addCase(logout, () => defaultState)
    .addMatcher(setPremiumMatcher, state => {
      const premiums = state.premiums;
      const annualPremium = find(premiums, ["billingFrequency", "annually"])?.amount || 0;
      const monthlyPremium = find(premiums, ["billingFrequency", "monthly"])?.amount || 0;

      state.monthlyPremium = toFixed(monthlyPremium);
      state.annualPremium = toFixed(annualPremium);
    })
    .addMatcher(setPayoutMatcher, state => {
      const payoutDetails = state.payoutDetails || defaultState.payoutDetails;

      state.payoutDetails = payoutDetails;
    });
});

export default quote;
