import "./CenteredLayout.scss";

import { FC } from "react";
import { Prompt } from "react-router";

import Text from "components/Text/Text";
import { RouteAction, RouteLocation } from "config/types";
import Container from "containers/Container/Container";
import { ILayoutProps, useModal } from "modules";

import classNames from "classnames";

type ContentType = {
  title?: string;
  promptWhen: boolean;
  onDiscardChanges(): void;
};

const CenteredLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;

  const content = props.content as ContentType;
  const title = content.title;
  const promptWhen = content.promptWhen;
  const onDiscardChanges = content.onDiscardChanges;

  const openModal = useModal({ onDiscardChanges });

  const promptExitModal = (location: RouteLocation, action: RouteAction) => {
    openModal("ExitWithoutSavingModal");
    return action !== "POP" ? false : true;
  };

  const classes = classNames("centered-layout__container", className);

  return (
    <Container className={classes}>
      <Prompt when={promptWhen} message={promptExitModal} />
      <div className="centered-layout__content">
        {title && <Text tag="h4" className="centered-layout__title" text={title} />}
        {children}
      </div>
    </Container>
  );
};

export default CenteredLayout;
