import { useContext } from "react";

import { IDynamicFormItemProps } from ".";

import USPhoneNumberInput from "components/PhoneNumberInput/USPhoneNumberInput";
import { TextAreaInput } from "components/TextAreaInput";
import TextInput from "components/TextInput/TextInput";

import c from "classnames";

import { DynamicFormContext } from "../DynamicFormContext";

export function TextInputItem({ item }: IDynamicFormItemProps<"inputText">) {
  const { form } = useContext(DynamicFormContext);

  const { content, key } = item;
  const { characterMax, disabled, element, format, hint } = content;
  const { label, required, value } = form.getFormInput(key);

  const classes = c("dynamic-form-item form-item--input-text");

  if (format === "multiLine") {
    const textCharacterLimit = characterMax ? { character: { max: characterMax } } : {};

    return (
      <TextAreaInput
        className={classes}
        dataTestId={key}
        disabled={disabled}
        error={form.getInputError(key)}
        errorMessage={form.getInputErrorMessage(key)}
        id={key}
        limit={textCharacterLimit}
        onBlur={form.onBlur}
        onChange={form.onChange}
        placeholder={hint}
        required={required}
        value={value}
      />
    );
  } else if (element && (element === "phone_number" || element === "phone-number")) {
    return (
      <USPhoneNumberInput
        className={classes}
        dataTestId={key}
        disabled={disabled}
        error={form.getInputError(key)}
        errorMessage={form.getInputErrorMessage(key)}
        id={key}
        label={label}
        onBlur={form.onBlur}
        onChange={form.onChange}
        value={value}
      />
    );
  } else {
    return (
      <TextInput
        className={classes}
        dataTestId={key}
        disabled={disabled}
        error={form.getInputError(key)}
        errorMessage={form.getInputErrorMessage(key)}
        id={key}
        label={label}
        maxCharacterLength={characterMax || undefined}
        onBlur={form.onBlur}
        onChange={form.onChange}
        required={required}
        value={value}
      />
    );
  }
}
