import { FC } from "react";
import { useSelector } from "react-redux";

import EncourageDepositImage from "assets/images/illustrations/encourage_deposit.svg";
import Text from "components/Text/Text";
import VariableText from "components/VariableText/VariableText";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useLocalNotificationsRead, useTranslation } from "modules";
import { useAppDispatch } from "store";
import { selectExternalAccount } from "store/selectors";

import { push } from "connected-react-router";

const EncourageDepositModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setLocalStorageFlagAsRead } = useLocalNotificationsRead("hasReadFundAccountModal");
  const externalAccount = useSelector(selectExternalAccount);

  const isUnverified = externalAccount?.status === "Unverified";

  const onCloseModal = () => {
    setLocalStorageFlagAsRead();
  };

  const onClickMakeDeposit = () => {
    dispatch(
      push(
        isUnverified
          ? "/products/savings/micro-deposits/prompt"
          : "/products/savings/transfer/deposit"
      )
    );
    onCloseModal();
  };

  const content = {
    image: EncourageDepositImage,
    header: t("enc_deposit.modal.title", "Make a deposit to kickstart your savings today!"),
    subheader: (
      <Text className="text-left" tag="p3">
        <VariableText
          target={/(?:<strong.*?>|<\/strong>)/g}
          text={t(
            "enc_deposit.modal.description",
            "Get <strong>free life insurance</strong> and make the most of your Savings+ account."
          )}
          replacementElement="strong"
        />
      </Text>
    ),
    button: {
      onClick: onClickMakeDeposit,
      text: t("enc_deposit.modal.cta", "MAKE A DEPOSIT"),
    },
    onClose: onCloseModal,
  };

  return (
    <Layout as="FullScreenModalLayout" className="encourage-deposit__modal" content={content} />
  );
};

export default EncourageDepositModal;
