import { useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "store";
import { createFlowResponse, fetchDynamicForm } from "store/actions/flow";
import { setJourneyProgressPercentOverride } from "store/actions/journey";
import { setModal } from "store/actions/modal";
import { getJourneyProgress, selectDynamicForm } from "store/selectors";
import { buildFormInputs } from "utils/form";

export const useEditResponse = () => {
  const flowSessionId = useAppSelector(state => state.formFlow.flowSessionId);
  const [isRequestingEdit, setIsRequestingEdit] = useState(false);
  const { percentage: journeyProgressPercentage } = useSelector(getJourneyProgress);

  const dispatch = useAppDispatch();

  const { id: currentFormIdentifier } = useAppSelector(selectDynamicForm);

  const editResponseCommand = async (itemId: string) => {
    setIsRequestingEdit(true);

    try {
      if (journeyProgressPercentage) {
        dispatch(setJourneyProgressPercentOverride(journeyProgressPercentage));
      }

      await dispatch(
        createFlowResponse({
          flowSessionId,
          formInputs: buildFormInputs([
            {
              id: "review",
              metadata: { flowInputType: "radio_group" },
              value: itemId,
            },
          ]),
        })
      ).unwrap();

      await dispatch(
        fetchDynamicForm({
          currentFormIdentifier,
          flowSessionId,
          direction: "next",
        })
      );
    } catch (error) {
      dispatch(setModal("GeneralErrorModal", "", { error }));
    } finally {
      setIsRequestingEdit(false);
    }
  };

  return { isRequestingEdit, editResponse: editResponseCommand };
};
