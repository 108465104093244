import { RootState } from "store";
import * as actionType from "store/constants";

import { createAction } from "@reduxjs/toolkit";

import { getDefaultAttrs } from "../defaults";

// Analytic events for Savings+ Life Benefit

type CoverageDetailCtaActionTypes =
  | typeof actionType.MS_GAP_NOPOLICY_CTA_CLICK
  | typeof actionType.MS_GAP_POLICY_CTA_CLICK
  | typeof actionType.MS_NOGAP_NOWYSHES_CTA_CLICK
  | typeof actionType.MS_NOGAP_WYSHES_CTA_CLICK;

/* ------------------------    EVENT ACTION     ------------------------------ */
// View page with expanded coverage breakdown
export const savingsCoverageDetailPageEvent = createAction(actionType.MS_COVERAGE_PAGEVIEW);

// View page with life insurance payout description
export const savingsBenefitInsurancePageEvent = createAction(actionType.MS_BENINSURANCE_PAGEVIEW);

// User clicks on the hyperlink to learn more about the insurance benefit
export const savingsBenefitInsuranceFAQClickEvent = createAction(
  actionType.MS_BENINSURANCE_FAQ_CLICK
);

// User clicks to deposit more into their savings+ account
export const savingsBenefitInsuranceDepositClickEvent = createAction(
  actionType.MS_BENINSURANCE_DEPOSIT_CLICK
);

// User clicks the CTA when they currently have a policy but still have a coverage gap.
// User clicks the CTA to get a free quote from coverage breakdown screen when they currently do not have a policy
// User clicks the CTA when no longer has a coverage gap and also has wyshes.
// User clicks the CTA when no longer has a coverage gap and also does not have wyshes.
export const savingsCoverageDetailCtaClickEvent = createAction<CoverageDetailCtaActionTypes>(
  actionType.MS_COVERAGE_DETAIL_CTA_CLICK
);

/* ------------------------    EVENT DEFINITION     ------------------------------ */

const getSavingsLifeBenefitEventDefinition = (eventName: string) => {
  return (action: { [key: string]: any }, prevState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "MYSAVINGS");
    const payload = action.payload || {};

    return {
      event: eventName,
      attribute: {
        ...defaultAttrs,
        ...payload,
      },
    };
  };
};

const getSavingsCoverageDetailCtaEventDefinition = () => {
  return (action: { [key: string]: CoverageDetailCtaActionTypes }, prevState: RootState) => {
    const defaultAttrs = getDefaultAttrs(prevState, "MYSAVINGS");
    const payload = action.payload;

    const eventNameMap: Record<CoverageDetailCtaActionTypes, string> = {
      MS_GAP_NOPOLICY_CTA_CLICK: "ms_gap_nopolicy_cta_click",
      MS_GAP_POLICY_CTA_CLICK: "ms_gap_policy_cta_click",
      MS_NOGAP_NOWYSHES_CTA_CLICK: "ms_nogap_nowyshes_cta_click",
      MS_NOGAP_WYSHES_CTA_CLICK: "ms_nogap_wyshes_cta_click",
    };

    return {
      event: eventNameMap[payload],
      attribute: {
        ...defaultAttrs,
      },
    };
  };
};

/* ------------------------    EVENT MAP    ------------------------------ */

export const savingsLifeBenefitEventsMap = {
  [actionType.MS_COVERAGE_PAGEVIEW]: getSavingsLifeBenefitEventDefinition("ms_coverage_pageview"),
  [actionType.MS_BENINSURANCE_PAGEVIEW]: getSavingsLifeBenefitEventDefinition(
    "ms_beninsurance_pageview"
  ),
  [actionType.MS_BENINSURANCE_FAQ_CLICK]: getSavingsLifeBenefitEventDefinition(
    "ms_beninsurance_faq_click"
  ),
  [actionType.MS_BENINSURANCE_DEPOSIT_CLICK]: getSavingsLifeBenefitEventDefinition(
    "ms_beninsurance_deposit_click"
  ),
  [actionType.MS_COVERAGE_DETAIL_CTA_CLICK]: getSavingsCoverageDetailCtaEventDefinition(),
};
