import { IFeatureFlags } from "config/types/featureFlag.types";
import { fetchGlobalFlags } from "store/actions";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";

/* ------------    FEATURE FLAGS DEFAULT STATE     ------------------ */
const defaultState: IFeatureFlags[] = [];

/* ------------    REDUCER     ------------------ */
const featureFlags = createReducer(defaultState, builder => {
  builder
    .addCase(fetchGlobalFlags.fulfilled, (state, action) => {
      const featureFlags = action.payload || [];

      // Set Locally Managed Feature Flags
      featureFlags.push({ name: "coverage-breakdown-chart", enabled: true });

      return featureFlags;
    })
    .addMatcher(isAnyOf(fetchGlobalFlags.fulfilled, fetchGlobalFlags.rejected), state => {
      const featureFlags = state;

      // Set flags that should always be one value, regardless of what the BE returns
      const skipPhoneFlag = featureFlags.find(
        ({ name }) => name === "wysh-insurance-skip-client-phone-screen"
      );

      if (skipPhoneFlag) {
        skipPhoneFlag.enabled = true;
      } else {
        featureFlags.push({ name: "wysh-insurance-skip-client-phone-screen", enabled: true });
      }
    });
});

export default featureFlags;
