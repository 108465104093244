import { useSelector } from "react-redux";

import { IRecommendation, RecommendationOptions } from "config/types";
import { useTranslation } from "modules";
import { RootState } from "store";

import { keyBy, some } from "lodash-es";

// Hook to return select recommendation cards based on `type` passed
// Example - used to render specific recommendations based on a user's coverage value (over/under 100)

// Add to RecommendationTypes when new recommendation sets are created
type RecommendationTypes = RecommendationOptions | "";

export const useRecommendations = (type: RecommendationTypes) => {
  const { t } = useTranslation();

  const { wyshesItems } = useSelector((state: RootState) => state.wyshes);
  const { allocated } = useSelector((state: RootState) => state.wyshAllocations);

  // Check if user has wysh allocation that is monitorable
  const wyshesById = keyBy(wyshesItems, "id");
  const hasMonitorableWyshes = some(allocated, allocation => {
    const wyshItem = allocation.wysh?.id && wyshesById[allocation.wysh?.id];

    return wyshItem && wyshItem?.isMonitorable;
  });

  // Current non-api recommendations:
  const recommendationCards = {
    trackWyshes: [
      // TODO - removed recommendation until bulk monitoring developed
      // {
      //   title: t("wysh.mywyshes.under.link.account.title", "Keep an eye on your debts"),
      //   icon: "BlobBank",
      //   text: t(
      //     "wysh.mywyshes.under.link.account.subtitle",
      //     "Link wyshes to real-time accounts with wysh tracking! As you pay down debts, we'll move those dollars to cover your other priorities."
      //   ),
      //   link: {
      //     text: t("wysh.mywyshes.under.link.account.button", "TRACK WYSHES"),
      //     // TODO - update placeholder route to monitor wyshes
      //     url: "/wyshes/monitoring",
      //   },
      // },
    ],
    under100Coverage: [
      {
        title: t("wysh.mywyshes.under.more.coverage.title", "Add a little bit more"),
        icon: "BlobAddCoverage",
        text: t(
          "wysh.mywyshes.under.more.coverage.subtitle",
          "Focus on what's truly important & adjust your coverage to match—because investing in a lil more is always a wise idea."
        ),
        link: {
          text: t("wysh.mywyshes.under.more.coverage.button", "GET MORE COVERAGE"),
          url: "/products/insurance?edit=open",
        },
      },
    ],
    over100Coverage: [
      {
        title: t("wysh.mywyshes.over.review.title", "Review your wysh list"),
        icon: "BlobReview",
        text: t(
          "wysh.mywyshes.over.review.subtitle",
          "Everything still A-OK? As life changes, so do your needs. Take a min to see if your wysh list is up-to-date."
        ),
        link: {
          text: t("wysh.mywyshes.over.review.button", "CHECK ON WYSHES"),
          url: "/wyshes",
        },
      },
      {
        title: t("wysh.mywyshes.over.save.title", "See if you can save some moolah"),
        icon: "BlobSave",
        text: t(
          "wysh.mywyshes.over.save.subtitle",
          `"No thanks, I have entirely too much money," said no one ever. Check if you can reduce your coverage to match your Wyshes.`
        ),
        link: {
          text: t("wysh.mywyshes.over.save.button", "DOUBLE CHECK COVERAGE"),
          url: "/products/insurance?edit=open",
        },
      },
    ],
  };

  const recommendationsByType = () => {
    if (type === "under-100") {
      const trackWyshRecommendations = hasMonitorableWyshes ? recommendationCards.trackWyshes : [];
      const recommendations = recommendationCards.under100Coverage;

      return [...trackWyshRecommendations, ...recommendations];
    } else if (type === "over-100") {
      const recommendations = recommendationCards.over100Coverage;

      return [...recommendations];
    } else {
      return [];
    }
  };

  const recommendations: IRecommendation[] = recommendationsByType();

  return { recommendations };
};
