import { isFormDynamicForm } from "config";
import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";
import { clamp } from "lodash-es";

const selectNextForms = (state: RootState) => state.formFlow.nextForm;
const selectFormIndex = (state: RootState) => state.formFlow.formIndex;

export const selectActiveFlowForm = createSelector(
  selectNextForms,
  selectFormIndex,
  (nextForms, formIndex) => {
    const activeForm = nextForms[formIndex - 1];

    if (isFormDynamicForm(activeForm)) {
      return activeForm;
    } else {
      return activeForm;
    }
  }
);

export const selectPreviousDynamicForm = createSelector(
  selectNextForms,
  selectFormIndex,
  (nextForms, formIndex) => {
    const previousFormIndex = formIndex - 2;
    const lowestFormIndex = clamp(previousFormIndex, 0, 1000);
    const previousForm = nextForms[lowestFormIndex];

    return previousForm;
  }
);

export const selectPreviousDynamicFormInputPayload = createSelector(
  selectPreviousDynamicForm,
  previousFlowForm => {
    /* retrieve previous DynamicForm query input data  */
    const previousFormArgs = previousFlowForm?.arg || {};

    return previousFormArgs;
  }
);
