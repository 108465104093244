import { CSSProperties, FC, useCallback, useEffect, useRef } from "react";

import { ClassName } from "config/types";

import classNames from "classnames";
import lottie from "lottie-web/build/player/lottie_light";

interface IProps {
  lottieData: any;
  loop?: boolean;
  style?: CSSProperties;
  className?: ClassName;
}

const Lottie: FC<IProps> = props => {
  const { style, className, lottieData, loop } = props;
  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  const loadLottie = useCallback(() => {
    return lottie.loadAnimation({
      container: ref.current,
      animationData: lottieData,
      loop: loop,
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const lottieInstance = loadLottie();

    return () => {
      lottieInstance.destroy();
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const classes = classNames("lottie-container", className);

  return (
    <div className={classes} style={style}>
      <div id="#lottie-data" ref={ref} />
    </div>
  );
};

export default Lottie;
