import { exitPathOverrides } from "store/mock/journey.mock";
import { IJourneyWithActive, JourneyState } from "store/reducers/journey";
import { IWyshesState } from "store/reducers/wyshes";

import { find, get, isFunction } from "lodash-es";

export const isOnboarding = (journey: JourneyState) => {
  return !!find(journey.collection, item => item?.parent === undefined && item.active === true);
};

export const getJourneyExitPath = (journey?: IJourneyWithActive, wyshes?: IWyshesState) => {
  const slug = journey?.slug || "";

  const exitPathOverride = get(exitPathOverrides, slug, undefined);
  const exitPath = isFunction(exitPathOverride)
    ? exitPathOverride({ wyshes })
    : journey?.paths?.exit;

  return exitPath;
};
