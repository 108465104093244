import { useEffect, useRef, useState } from "react";

import TriviaIntro from "components/Trivia/TriviaIntro";
import { TriviaIntroType } from "config/types";

const TEXT_ANIMATION_DURATION = 2;

const TriviaIntroContainer = () => {
  const [introSection, setIntroSection] = useState<TriviaIntroType>("inProgress");
  const triviaIntroSectionTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    switch (introSection) {
      case "inProgress":
        triviaIntroSectionTimeout.current = setTimeout(() => {
          setIntroSection("letsPlay");
        }, TEXT_ANIMATION_DURATION * 1000);
        break;

      case "letsPlay":
        triviaIntroSectionTimeout.current = setTimeout(() => {
          setIntroSection("rocketship");
        }, TEXT_ANIMATION_DURATION * 1000);
        break;
    }

    return () =>
      triviaIntroSectionTimeout.current && clearTimeout(triviaIntroSectionTimeout.current);
  }, [introSection]);

  return <TriviaIntro introSection={introSection} />;
};

export default TriviaIntroContainer;
