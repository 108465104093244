import "./CustomerSupportCard.scss";

import { FC } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { useTranslation } from "modules/hooks/useTranslation";
import { useAppDispatch } from "store";
import { eventFired } from "store/actions/analytics";
import { toggleCustomerSupportChat } from "utils/customerSupport";

const CustomerSupportCard: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const content = {
    title: t("offerings_review.customer_support.title", "Need some help?"),
    body: t(
      "offerings_review.customer_support.hours",
      "Live chat support is available Monday-Friday during business hours (9am-5pm EST)."
    ),
    ctaText: t("offerings_review.customer_support.link.chat", "CHAT WITH US"),
  };

  const contactSupportOnClick = (event: any) => {
    event.stopPropagation();

    dispatch(
      eventFired({
        event: "fulfillment_quote_displayed_help",
        experienceLocation: "ONBOARDING",
        attribute: {
          faqname: "Chat with us",
        },
      })
    );

    toggleCustomerSupportChat();
  };

  return (
    <div className="customer-support-content__wrapper">
      <Text className="customer-support-content__title" tag="h4" text={content.title} />
      <div className="customer-support-content__cta" onClick={contactSupportOnClick}>
        <Icon className="customer-support-content__icon" size="md" src="BlobFAQ" />
        <Text className="customer-support-content__text" text={content.ctaText} tag="c5" />
      </div>
      <Text className="customer-support-content__body" tag="p3" text={content.body} />
    </div>
  );
};

export default CustomerSupportCard;
