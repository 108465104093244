import { RootState } from "store";
import { IWyshesAllocationState } from "store/reducers/wyshes";
import { isOnboarding } from "utils/journey";

import { get } from "lodash-es";
import { DateTime } from "luxon";

import { getDefaultAttrs } from "./defaults";

export const createWyshAllocationEventDefinition = (
  action: { [key: string]: IWyshesAllocationState },
  prevState: RootState,
  nextState: RootState
) => {
  const onboarding = isOnboarding(prevState.journey);

  const defaultAttrs = getDefaultAttrs(nextState, onboarding ? "ONBOARDING" : "DASHBOARD");

  const wysh = action.payload.wysh;
  return {
    event: "wysh_builder_wysh_created",
    attribute: {
      wyshType: get(wysh, "defaultName"),
      wyshCategory: get(wysh, "wyshCategory.name"),
      ...defaultAttrs,
    },
  };
};

export const updateWyshAllocationEventDefinition = (
  action: { [key: string]: IWyshesAllocationState },
  prevState: RootState,
  nextState: RootState
) => {
  const onboarding = isOnboarding(prevState.journey);

  const defaultAttrs = getDefaultAttrs(nextState, onboarding ? "ONBOARDING" : "DASHBOARD");

  const allocation = action.payload;
  const wysh = allocation.wysh;
  let termInMonths = 0;
  try {
    const termDate = DateTime.fromISO(get(allocation, "terminatesAt"));
    termInMonths = Math.round(termDate.diffNow("months").months);
  } catch {}

  return {
    event: "wysh_builder_wysh_updated",
    attribute: {
      wyshType: get(wysh, "defaultName", ""),
      wyshCategory: get(wysh, "wyshCategory.name", ""),
      wyshAmount: get(allocation, "amount", 0),
      wyshTerm: termInMonths,
      wyshMonitored: get(allocation, "monitored", false),
      ...defaultAttrs,
    },
  };
};
