import { TApiButtonCommandFn } from ".";

import { IApiArgument } from "config";
import { SUCCESS_MODAL_DURATION } from "containers/Modals/Success/Success";
import { useModal } from "modules";
import { delay } from "utils/delay";

// IApiArgument.name to find to display `source` value in overlay
const OVERLAY_ARGUMENT_TEXT_NAME: IApiArgument["name"] = "text";

export const useShowSuccessOverlay = () => {
  const openModal = useModal();

  const showSuccessOverlayCommand: TApiButtonCommandFn = async commandArgs => {
    const overlayText = commandArgs?.find(argument => argument.name === OVERLAY_ARGUMENT_TEXT_NAME)
      ?.source;

    openModal("Success", "", { text: overlayText });

    await delay(SUCCESS_MODAL_DURATION);
  };

  return showSuccessOverlayCommand;
};
