import "./WyshesCardSlider.scss";

import { FC, useState } from "react";
import { Link } from "react-router-dom";

import { Carousel } from "components/Carousel/Carousel";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { useTranslation } from "modules";
import { useAppDispatch } from "store";
import { IWyshesAllocationState } from "store/reducers/wyshes";

import { push } from "connected-react-router";

interface IProps {
  wyshes: IWyshesAllocationState[];
}

const WyshCardSlide = ({ id, name, icon }: IWyshesAllocationState) => {
  const dispatch = useAppDispatch();
  const [isDragging, setIsDragging] = useState(false);

  const redirectToWyshDetail = () => {
    if (!isDragging) {
      dispatch(push(`/wyshes/${id}/details`));
    }

    setIsDragging(false);
  };

  return (
    <div
      className="wyshes-card bgcolor-sand"
      onClick={redirectToWyshDetail}
      onMouseDown={() => setIsDragging(false)}
      onMouseMove={() => setIsDragging(true)}>
      <Text tag="l6-label" text={name} />
      <Icon src={icon} size="md" />
    </div>
  );
};

export const WyshesCardSlider: FC<IProps> = ({ wyshes }) => {
  const { t } = useTranslation();

  return (
    <div className="wyshes-card-slider">
      <Carousel slidesToShow={{ small: 1, medium: 1, large: 1 }} swipe>
        {wyshes.map(WyshCardSlide)}
      </Carousel>
      <Link className="wyshes-card-slider__cta bgcolor-white" to="/wyshes">
        <Text
          className="color-primary-dark"
          tag="l6-label"
          text={t("product.owned.wyshes.all", "See all")}
        />
      </Link>
    </div>
  );
};
