import { useContext } from "react";

import { useAppSelector, useAppDispatch } from "store";
import { selectDynamicFormCheckboxItems } from "store/selectors";

import { compact, flatten } from "lodash-es";

import { DynamicFormContext } from "../DynamicFormContext";
import { useGroupApi } from "../useGroupApi";

export const useCreateAcknowledgements = () => {
  const dispatch = useAppDispatch();
  const inputCheckboxItems = useAppSelector(selectDynamicFormCheckboxItems);
  const apiGroupThunk = useGroupApi();
  const { inputs } = useContext(DynamicFormContext);

  const createAcknowledgementsCommand = async () => {
    try {
      // filter to Form.items where DynamicFormItem.type === inputCheckbox AND the checkbox is checked (value === true)
      const inputCheckboxItemsAndCheckedValue = inputCheckboxItems.filter(
        inputItem => inputs[inputItem.key]?.value === true
      );

      // return flattened array of CheckboxInputFormItem.acknowledgementGroupKeys
      const acknowledgementGroupKeys = flatten(
        compact(
          inputCheckboxItemsAndCheckedValue.map(
            checkboxItem => checkboxItem.content.acknowledgementGroupKeys
          )
        )
      );

      const acknowledgementThunksByGroupKeys = compact(
        acknowledgementGroupKeys.map(acknowledgementKey => apiGroupThunk(acknowledgementKey))
      );

      const dispatchedThunks = acknowledgementThunksByGroupKeys.map(thunk =>
        dispatch(thunk).unwrap()
      );

      return await Promise.all(dispatchedThunks);
    } catch (error) {
      throw error;
    }
  };

  return createAcknowledgementsCommand;
};
