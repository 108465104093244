import "./MultipleCheckboxInput.scss";

import { createRef, FC, RefObject } from "react";

import Checkbox, { ICheckboxEvent } from "components/Checkbox/Checkbox";
import Text from "components/Text/Text";
import { ClassName, OrString } from "config/types";

import c from "classnames";
import { defaultTo, map } from "lodash-es";

export interface IMultiCheckboxOption {
  id: string;
  value: any;
  label: OrString<JSX.Element>;
}

export interface IMultiCheckboxEvent {
  target: { value: string[]; id: string };
}

interface IProps {
  id: string;
  options: IMultiCheckboxOption[];
  value?: string[];
  groupLabel?: string;
  name?: string;
  className?: ClassName;
  classNames?: ClassName;
  horizontal?: boolean;
  dividers?: boolean;
  dataTestId?: boolean;
  onChange(event: IMultiCheckboxEvent): void;
}

const noneCode = "NONE_OF_ABOVE_CHOICE_CODE";

const MultipleCheckboxInput: FC<IProps> = props => {
  const {
    options,
    onChange,
    name,
    id,
    groupLabel,
    value,
    className,
    classNames,
    horizontal,
    dividers,
    dataTestId,
  } = props;

  const refs: { [key: string]: RefObject<any> } = {};

  const getOrCreateRef = (value: string) => {
    if (!refs.hasOwnProperty(value)) {
      refs[value] = createRef();
    }
    return refs[value];
  };

  const handleOnChange = (changeEvent: ICheckboxEvent) => {
    let value: string[] = [];
    const noneSelected = changeEvent.target.id === noneCode;

    if (noneSelected) {
      value = [noneCode];
    } else {
      value = options
        .filter(option => {
          const current = refs[option.value].current;
          return current.checked && current.value !== noneCode;
        })
        .map(option => option.value);
    }

    const event = {
      target: {
        value,
        id,
      },
    };
    onChange(event);
  };

  const values = defaultTo(value, []);

  const checkboxClasses = c(classNames, { "multiple-choice-input__divider": dividers });

  const checkboxes = map(options, option => {
    return (
      <Checkbox
        id={option.id || option.value}
        className={checkboxClasses}
        checkboxRef={getOrCreateRef(option.value)}
        value={option.value}
        name={name}
        checked={values.includes(option.value)}
        label={option.label}
        key={option.value}
        onChange={handleOnChange}
      />
    );
  });

  const classes = c("flex--center", className, "multiple-choice-input-container", {
    "flex-column--start": !horizontal,
  });

  return (
    <div id={id} className={classes} data-testid={dataTestId || "multiple-checkbox__input"}>
      {groupLabel && <Text tag="p1" text={groupLabel} />}
      {checkboxes}
    </div>
  );
};

export default MultipleCheckboxInput;
