import { useSelector } from "react-redux";

import {
  HomeCardMarketingRecapture,
  HomeCardWyshesOwned,
  HomeProductCardBody,
  HomeProductCardContainer,
  HomeProductCardDivider,
  HomeProductCardHeader,
  HomeProductCardTextLink,
  IHomeProductCard,
} from "components/Cards/HomeProduct";
import { useTranslation } from "modules/hooks";
import { getWyshesProductState } from "store/selectors";

export function WyshesProductBlock() {
  const { t } = useTranslation();
  const wyshesProductState = useSelector(getWyshesProductState);

  const linkPath = "/wyshes";

  const cardBodyComponents = {
    marketing: WyshesMarketingBlockBody,
    owned: HomeCardWyshesOwned,
  };

  const CardBody = cardBodyComponents[wyshesProductState];

  return (
    <HomeProductCardContainer link={linkPath}>
      <HomeProductCardHeader icon="Wyshes" title={t("product.header.wyshes", "Wyshes")} />
      <HomeProductCardDivider />
      <HomeProductCardBody>
        <CardBody link={linkPath} />
      </HomeProductCardBody>
    </HomeProductCardContainer>
  );
}

function WyshesMarketingBlockBody({ link }: Pick<IHomeProductCard, "link">) {
  const { t } = useTranslation();

  return (
    <>
      <HomeCardMarketingRecapture
        bodyText={t(
          "product.marketing.body.wyshes",
          "A free feature to easily manage and customize your coverage needs."
        )}
      />
      <HomeProductCardTextLink link={link} text={t("product.link.learn_more", "Learn More")} />
    </>
  );
}
