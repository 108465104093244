import { IPlaidItem } from "config/types";
import { logout } from "store/actions/authentication";
import { getPlaidItems } from "store/actions/bank";

import { createReducer } from "@reduxjs/toolkit";

const defaultState: IPlaidItem[] = [];

const bank = createReducer(defaultState, {
  [getPlaidItems.fulfilled.type]: (state, action) => {
    return action.payload || defaultState;
  },
  [logout.type]: () => defaultState,
});

export default bank;
