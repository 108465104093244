import "./CardButton.scss";

import { FC } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";

import classNames from "classnames";
import { defaultTo } from "lodash-es";

interface IProps {
  header?: string;
  label?: string;
  subtext?: string;
  icon?: string;
  iconText?: string;
  subHeader?: string;
  className?: string;
  side?: "left" | "right";
  filled?: boolean;
  textCentered?: boolean;
  iconBg?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  onClick?(event: React.FormEvent): void;
}

const CardButton: FC<IProps> = (props: IProps) => {
  const {
    label,
    className,
    onClick,
    icon,
    iconText,
    side,
    filled,
    subtext,
    header,
    subHeader,
    textCentered,
    iconBg,
    dataTestId,
    disabled,
  } = props;

  const blobSide = defaultTo(side, "left");
  const blobSideClass = `card-btn--${blobSide}`;
  const blobBgClass = `${blobSideClass}-${filled ? "filled" : "empty"}`;

  const conditionalClasses = {
    disabled,
    [`${blobSideClass}-centered`]: textCentered,
    "btn-type--label": !icon && label,
  };

  const classes = classNames("card-btn", className, blobBgClass, blobSideClass, conditionalClasses);
  const headerClass = classNames("card-btn__header-container", className, {
    "icon-bg": iconBg,
    "mb-10": !header,
  });
  const bodyClasses = classNames("card-btn__body-wrapper", className, {
    "justify-start": label && subtext,
    "justify-center": !icon && label,
  });

  return (
    <button
      className={classes}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
      type="button">
      <div className={headerClass}>
        {icon && (
          <div className="card-btn__icon-container flex--center">
            <div className="card-btn__icon-wrapper">
              <Icon className="card-btn__icon" src={icon} size="lg" />
            </div>
            {iconText && <Text className="card-btn__icon-text" tag="c5" text={iconText} />}
          </div>
        )}

        <div className="card-btn__header-wrapper">
          <Text className="card-btn__header" tag="n4" text={header} />
          <Text className="card-btn__subheader" tag="l4" text={subHeader} />
        </div>
      </div>

      <div className={bodyClasses}>
        <Text className="card-btn__text" tag="c5" text={label} />
        <Text tag="p5" text={subtext} />
      </div>
    </button>
  );
};

export default CardButton;
