import "./TriviaBanner.scss";

import { FC, MouseEvent } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { useTranslation } from "modules";

interface IProps {
  onComplete(event?: MouseEvent<HTMLDivElement>): void;
}

const TriviaBanner: FC<IProps> = ({ onComplete }) => {
  const { t } = useTranslation();

  return (
    <div className="trivia-banner__container" onClick={onComplete} tabIndex={1}>
      <Text className="trivia-banner__cta-text" tag="c5">
        {t("trivia.banner.results", "your results are ready!")}
      </Text>

      <Icon className="trivia-banner__icon" src="Next" />
    </div>
  );
};

export default TriviaBanner;
