import { CaseResult } from "config";
import { IQuote } from "config/types";
import { IUpdateQuoteInput, updateQuoteMutation } from "graphql/mutations/quote";
import { IAdjustCoverageInput } from "graphql/queries/estimate";
import { adjustQuoteQuery, getQuoteQuery } from "graphql/queries/quote";
import store from "store";
import { SET_QUOTE, SET_QUOTE_EXP } from "store/constants";
import { getActiveAccount } from "utils/account";
import { getAppError } from "utils/error";

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

/* ------------       ACTIONS     ------------------ */

export const setTermExpiration = createAction(SET_QUOTE_EXP, (termDuration: number) => {
  const reduxStore = store.getState();
  const userAge = reduxStore.user.age || 0;

  return {
    type: SET_QUOTE_EXP,
    payload: termDuration + userAge,
  };
});

export const resetQuote = createAction("quote/resetQuote");

/* ------------       THUNKS      ------------------ */

export const getQuote = createAsyncThunk(SET_QUOTE, async (_, { rejectWithValue }) => {
  try {
    const response = await getQuoteQuery();
    const activeAccount = getActiveAccount(response.data.me);
    const latestInsuranceApplication = activeAccount?.latestInsuranceApplication;

    const quote = activeAccount?.pendingQuote || ({} as IQuote);
    const caseResult: CaseResult = latestInsuranceApplication?.result || "";
    const caseId = latestInsuranceApplication?.id || "";

    return { ...quote, caseResult, caseId };
  } catch (err) {
    const errorCode = getAppError(err);

    return rejectWithValue(errorCode);
  }
});

export const updateQuote = createAsyncThunk(
  "quote/updateQuote",
  async (quote: IUpdateQuoteInput, { dispatch, rejectWithValue }) => {
    try {
      await updateQuoteMutation(quote);
      await dispatch(getQuote());

      return quote;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const adjustQuote = createAsyncThunk(
  "quote/adjustQuote",
  async (input: IAdjustCoverageInput, { rejectWithValue }) => {
    try {
      const response = await adjustQuoteQuery(input);

      return response.data.adjustQuote;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
