import "./BlobList.scss";

import { ReactNode } from "react";

import List from "components/List/List";
import Text from "components/Text/Text";
import { ClassName } from "config/types";

import classNames from "classnames";

interface IBlobListProps {
  children: ReactNode;
  className?: ClassName;
}

interface IBlobListItemProps {
  children: ReactNode;
  className?: ClassName;
}

export function BlobList({ children, className }: IBlobListProps) {
  return (
    <List className={classNames("blob-list__container", className)} tag="ol">
      {children}
    </List>
  );
}

export function BlobListItem({ children, className }: IBlobListItemProps) {
  return (
    <li className={classNames("blob-list__item", className)}>
      <Text as="span" tag="l5">
        {children}
      </Text>
    </li>
  );
}
