import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  env,
  EstimateFormSlug,
  IneligibleCoverageReasonsType,
  MediaAlphaIneligibleAgeId,
  MediaAlphaIneligibleStateId,
} from "config";
import { RootState } from "store";

import { find } from "lodash-es";
import { DateTime } from "luxon";
import queryString from "query-string";
import { v4 } from "uuid";

const IFRAME_URL = "/mediaalpha-affiliate-integration.html";

interface IUseAffiliateIntegrationOptions {
  ineligibilityReason: IneligibleCoverageReasonsType | "general";
}

export const useAffiliateIntegration = ({
  ineligibilityReason,
}: IUseAffiliateIntegrationOptions) => {
  const iframeId = useRef(v4());
  const [iframeHeight, setIframeHeight] = useState(null);

  const birthDate = useSelector((state: RootState) => {
    const dobInputValue = find(state.formFlow.inputValues, {
      key: `${EstimateFormSlug}-dob`,
    });
    return dobInputValue?.value && DateTime.fromISO(dobInputValue?.value).toFormat("yyyy-LL-dd");
  });
  const coverageAmount = useSelector(
    (state: RootState) =>
      find(state.formFlow.inputValues, { key: `${EstimateFormSlug}-coverage` })?.value
  );
  const gender = useSelector(
    (state: RootState) =>
      find(state.formFlow.inputValues, { key: `${EstimateFormSlug}-gender` })?.value
  );

  let iframePlacementId = "";

  if (ineligibilityReason === "age_of_insured") {
    iframePlacementId = MediaAlphaIneligibleAgeId;
  } else if (ineligibilityReason === "state_of_residence") {
    iframePlacementId = MediaAlphaIneligibleStateId;
  }

  const iFrameMessageListener = useCallback(({ data }) => {
    const { payload, source } = data;

    if (source !== `${iframeId.current}-iframe`) {
      return;
    }

    switch (payload.eventName) {
      case "setHeight":
        setIframeHeight(payload.data);
        break;
    }
  }, []);

  const iframeSrc = useMemo(
    () =>
      iframePlacementId
        ? queryString.stringifyUrl({
            url: IFRAME_URL,
            query: {
              birthDate,
              coverageAmount,
              gender,
              environment: env,
              id: iframeId.current,
              placementId: iframePlacementId,
            },
          })
        : undefined,
    [birthDate, coverageAmount, gender, iframePlacementId]
  );

  useEffect(() => {
    window.addEventListener("message", iFrameMessageListener);

    return () => window.removeEventListener("message", iFrameMessageListener);
  }, [iFrameMessageListener]);

  return {
    iframeSrc,
    iframeHeight: iframeHeight || "auto",
    iframeId: iframeId.current,
  };
};
