import { RootState } from "store";
import { createTransactionsFilterOptions } from "utils/createTransactionsFilterOptions";

import { createSelector } from "@reduxjs/toolkit";

export const selectFinanceCustomer = (state: RootState) => state.financeCustomer;

export const hasOpenedFinanceAccount = createSelector(selectFinanceCustomer, financeCustomer => {
  return financeCustomer.accounts.some(account => account.openedAt || account.status === "opened");
});

export const getFinanceCustomerResultScreenPath = createSelector(
  selectFinanceCustomer,
  financeCustomer => {
    const customerStatus = financeCustomer.status;
    const accountOpenedAt = financeCustomer.accounts[0]?.openedAt;

    if (customerStatus === "verified") {
      return "/products/savings/verification/verified";
    } else if (!accountOpenedAt && customerStatus === "automated_reviewed") {
      return "/products/savings/verification/automated_reviewed";
    } else if (!accountOpenedAt && customerStatus === "manual_reviewed") {
      return "/products/savings/verification/manual_reviewed";
    } else if (!accountOpenedAt && customerStatus === "denied") {
      return "/products/savings/verification/denied";
    } else {
      return "/products/savings";
    }
  }
);

export const getActivityHistoryOptions = createSelector(selectFinanceCustomer, financeCustomer => {
  return createTransactionsFilterOptions(financeCustomer.createdAt, new Date().toISOString());
});

export const selectFinanceAccount = createSelector(selectFinanceCustomer, financeCustomer => {
  return financeCustomer.accounts[0];
});
