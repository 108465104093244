import { ChangeEvent, FC, FocusEvent, useState } from "react";

import TextInput from "components/TextInput/TextInput";
import { ValueOrArray } from "config/types";
import { useTranslation } from "modules/hooks/useTranslation";
import validateEmail from "utils/validateEmail";

import classNames from "classnames";

interface IProps {
  id: string;
  value: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ValueOrArray<string>;
  disableErrorState?: boolean;
  required?: boolean;
  validations?: any[];
  label?: string;
  activeLabel?: string;
  dataTestId?: string;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
}

const EmailInput: FC<IProps> = (props: IProps) => {
  const {
    className,
    disabled,
    disableErrorState,
    value,
    error,
    errorMessage,
    id,
    onChange,
    onBlur,
    onFocus,
    label,
    activeLabel,
    dataTestId,
  } = props;
  const [emailError, setEmailError] = useState(false);
  const { t } = useTranslation();

  const validateEmailInput = () => {
    return value && !validateEmail(value) ? setEmailError(true) : setEmailError(false);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);

    validateEmailInput();
  };

  const classes = classNames("form__input", className);

  return (
    <TextInput
      className={classes}
      type="email"
      value={value}
      id={id || "email"}
      label={label ? label : t("inputs.email.label", "Email")}
      activeLabel={activeLabel ? activeLabel : t("inputs.email.label", "Email")}
      error={!disableErrorState && (error || emailError)}
      errorMessage={errorMessage}
      onChange={onChange}
      onBlur={handleOnBlur}
      onFocus={onFocus}
      disabled={disabled}
      dataTestId={dataTestId}
    />
  );
};

export default EmailInput;
