import "./AccordionPanel.scss";

import { FC } from "react";

import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";

import c from "classnames";

export interface IAccordionPanel {
  id: string;
  label: string;
  content: string;
  expanded: boolean;
  onClick(id: string): void;
}

const AccordionPanel: FC<IAccordionPanel> = props => {
  const { id, label, content, expanded, onClick } = props;

  const classes = c("accordion-panel", { "accordion-panel--expanded": expanded });

  return (
    <div className={classes}>
      <button className="accordion-panel__btn" onClick={() => onClick(id)}>
        <Text className="accordion-panel__question" tag="l1" text={label} />
        <Icon
          className={c("accordion-panel__carat", { "accordion-panel__carat--expanded": expanded })}
          src="CaratDown"
          size="sm"
        />
      </button>
      <div className="accordion-panel__content">
        <Text className="accordion-panel__text" tag="p3" text={content} />
      </div>
    </div>
  );
};

export default AccordionPanel;
