import "./RadioInputGroup.scss";

import { ChangeEvent, CSSProperties, FC } from "react";

import RadioInput from "components/RadioInput/RadioInput";
import Text from "components/Text/Text";
import { ClassName } from "config/types";

import classNames from "classnames";

interface IProps {
  id: string;
  name: string;
  options: IRadioOption[];
  borderBottom?: boolean;
  className?: ClassName;
  dataTestId?: boolean;
  disabled?: boolean;
  groupLabel?: string;
  style?: CSSProperties;
  value?: string;
  vertical?: boolean;
  onChange?(event: IRadioInputGroupEvent): void;
}

export interface IRadioOption {
  value: string;
  label: string;
}

export interface IRadioInputGroupEvent {
  target: {
    value: string;
    id: string;
  };
}

const RadioInputGroup: FC<IProps> = props => {
  const {
    borderBottom,
    className,
    dataTestId,
    disabled,
    groupLabel,
    id,
    name,
    onChange,
    options,
    style,
    value,
    vertical,
  } = props;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changeEvent = {
      target: {
        value: event.target.value,
        id,
      },
    };
    onChange && onChange(changeEvent);
  };

  const conditionalClassNames = {
    "radio__group__inputs--vertical": vertical,
    "radio__group__inputs--border-bottom": borderBottom,
  };

  const classes = classNames(className, "radio__group__inputs", conditionalClassNames);

  const inputs = options.map((option, index) => {
    return (
      <RadioInput
        checked={option.value === value}
        dataTestId={option.value}
        disabled={disabled}
        key={index}
        label={option.label}
        name={name}
        onChange={handleOnChange}
        value={option.value}
      />
    );
  });

  return (
    <>
      {groupLabel && <Text tag="p1" text={groupLabel} />}
      <div style={style} className={classes} data-testid={dataTestId || "radio-group__input"}>
        {inputs}
      </div>
    </>
  );
};

export default RadioInputGroup;
