import "./BinarySelectInput.scss";

import { FC, MouseEvent } from "react";

import Text from "components/Text/Text";

import c from "classnames";

interface IBinaryOptions {
  label: string;
  value: string;
  iconType?: string;
  header?: string;
  subtext?: string;
}

interface IProps {
  id: string;
  dataTestId?: string;
  disabled?: boolean;
  error?: string | boolean;
  leftDataTestId?: string;
  options: IBinaryOptions[];
  rightDataTestId?: string;
  value?: string;
  onChange(event: IBinarySelectButtonEvent): void;
}

interface IBinarySelectButtonEvent {
  target: { value: string; id: string };
}

const BinarySelectInput: FC<IProps> = props => {
  const {
    id,
    dataTestId,
    disabled = false,
    error,
    leftDataTestId,
    onChange,
    options,
    rightDataTestId,
    value,
  } = props;

  const leftOption = options[0];
  const rightOption = options[1];

  const leftSelected = leftOption.value === value;
  const rightSelected = rightOption.value === value;

  const inputClasses = c("binary-select-input", {
    "binary-select-input--disabled": disabled,
    "input-filled": leftSelected || rightSelected,
    error: !!error,
  });

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const changeEvent = {
      target: { value: event.currentTarget.value, id: event.currentTarget.id },
    };

    onChange && onChange(changeEvent);
  };

  return (
    <div className={inputClasses} data-testid={dataTestId || "binary-select-input"}>
      <button
        id={id}
        className={c("binary-select-input__button", { selected: leftSelected })}
        aria-pressed={leftSelected}
        disabled={disabled}
        data-testid={leftDataTestId || `binary-select-input__button:${leftOption.value}`}
        onClick={handleOnClick}
        type="button"
        value={leftOption.value}>
        <Text className="binary-select-input__text" tag="p3" text={leftOption.label} />
      </button>

      <button
        id={id}
        aria-pressed={rightSelected}
        className={c("binary-select-input__button", { selected: rightSelected })}
        data-testid={rightDataTestId || `binary-select-input__button:${rightOption.value}`}
        disabled={disabled}
        onClick={handleOnClick}
        type="button"
        value={rightOption.value}>
        <Text className="binary-select-input__text" tag="p3" text={rightOption.label} />
      </button>
    </div>
  );
};

export default BinarySelectInput;
