import { IUserAccount } from "config";
import { getPreVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { createIdentifiedUser, setupUserForNewLineOfBusiness } from "store/actions/user";
import { getConstellationMembership } from "utils/constellation";

import { createReducer, isAnyOf } from "@reduxjs/toolkit";

type IActiveInsuranceMembershipsState = Pick<
  IUserAccount,
  "id" | "eligibleForCoverage" | "eligibleForMonitoring" | "eligibleForUnderwriting"
>;

/* ------------    INSURANCE MEMBERSHIPS STATE     ------------------ */
const defaultState: IActiveInsuranceMembershipsState = {
  id: "",
  eligibleForCoverage: true,
  eligibleForMonitoring: true,
  eligibleForUnderwriting: true,
};

const isNewMemberActionFulfilled = isAnyOf(
  createIdentifiedUser.fulfilled,
  setupUserForNewLineOfBusiness.fulfilled
);

/* ------------    REDUCER     ------------------ */
const insuranceMembership = createReducer(defaultState, builder => {
  builder
    .addCase(getPreVerifiedInitialData.fulfilled, (state, action) => {
      const membership = getConstellationMembership(
        action.payload.me.activeConstellations,
        "wysh-insurance"
      );

      state.id = membership?.id || defaultState.id;
      state.eligibleForCoverage =
        membership?.eligibleForCoverage || defaultState.eligibleForCoverage;
      state.eligibleForMonitoring =
        membership?.eligibleForMonitoring || defaultState.eligibleForMonitoring;
      state.eligibleForUnderwriting =
        membership?.eligibleForUnderwriting || defaultState.eligibleForUnderwriting;
    })
    .addCase(logout, () => defaultState)
    .addMatcher(isNewMemberActionFulfilled, (state, action) => {
      const membership = getConstellationMembership(
        action.payload.activeConstellations,
        "wysh-insurance"
      );

      state.id = membership?.id || "";
    });
});

export default insuranceMembership;
