import { LinkContext } from ".";

import { XClientFingerprint } from "config/constants";
import { Store } from "store";

import { ApolloLink, RequestHandler } from "@apollo/client";
import { defaultTo } from "lodash-es";

type CallbackWrapper = (state: Store) => RequestHandler;

const callback: CallbackWrapper = store => {
  return (operation, forward) => {
    const state = store.getState();
    const { deviceFingerprint, constellation } = state;
    const { fingerprint } = deviceFingerprint;
    const constellationId = constellation?.id;
    const context = operation.getContext();
    const contextHeaders = defaultTo(context.headers, {});
    const baseHeaders = {
      "X-Device-Fingerprint": fingerprint,
      "X-Client-Fingerprint": XClientFingerprint,
      ...(!!constellationId && { "X-Current-Constellation": constellationId }),
    };

    const headers = { ...baseHeaders, ...contextHeaders };
    operation.setContext({ headers });
    return forward(operation);
  };
};

const callbackWrapper = (context: LinkContext) => callback(context.store);

export const fingerprintLink = (context: LinkContext) => new ApolloLink(callbackWrapper(context));
