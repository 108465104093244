import "./ProductOfferingHeaders.scss";

import { ReactNode } from "react";

import Text from "components/Text/Text";
import { ClassName } from "config";

import classNames from "classnames";

interface IProductOfferingHeaders {
  children: ReactNode;
  className?: ClassName;
}

export function ProductOfferingHeader({ children, className }: IProductOfferingHeaders) {
  return (
    <Text className={classNames("product-offering-header", className)} tag="h2-header">
      {children}
    </Text>
  );
}

export function ProductOfferingSubheader({ children, className }: IProductOfferingHeaders) {
  return (
    <Text className={classNames("product-offering-subheader", className)} tag="p4-paragraph">
      {children}
    </Text>
  );
}
