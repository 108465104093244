import { useContext } from "react";

import { IDynamicFormItemProps } from ".";

import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import NumberInput from "components/NumberInput/NumberInput";

import c from "classnames";

import { DynamicFormContext } from "../DynamicFormContext";

export function NumericInputItem({ item }: IDynamicFormItemProps<"inputNumeric">) {
  const { content, key } = item;
  const { currency, disabled, max, min, precision, unit } = content;

  // HOOKS
  const { form } = useContext(DynamicFormContext);
  const { label, value } = form.getFormInput(key);

  const classes = c("dynamic-form-item form-item--input-numeric");

  // RENDER
  if (currency) {
    return (
      <CurrencyInput
        className={classes}
        error={form.getInputError(key)}
        errorMessage={form.getInputErrorMessage(key)}
        id={key}
        label={label}
        onBlur={form.onBlur}
        onChange={form.onChange}
        value={value}
      />
    );
  } else {
    return (
      <NumberInput
        className={classes}
        disabled={disabled}
        error={form.getInputError(key)}
        errorMessage={form.getInputErrorMessage(key)}
        id={key}
        label={label}
        max={max}
        min={min}
        onBlur={form.onBlur}
        onChange={form.onChange}
        precision={precision}
        unit={unit}
        value={value}
      />
    );
  }
}
