import "./HeaderExitButton.scss";

import Icon from "components/Icon/Icon";
import { ClassName } from "config";
import { useAppDispatch } from "store";
import { setModal } from "store/actions/modal";

import classNames from "classnames";

interface IHeaderExitButton {
  className: ClassName;
}

function HeaderExitButton({ className }: IHeaderExitButton) {
  const dispatch = useAppDispatch();

  const promptIdentifiedExit = () => {
    dispatch(setModal("IdentifiedExitModal"));
  };

  return (
    <div className={classNames("header-exit", className)} onClick={promptIdentifiedExit}>
      <Icon size="sm" src="Logout" />
    </div>
  );
}

export default HeaderExitButton;
