import { FC, useCallback } from "react";

import Button from "components/Button/Button";
import { DevPartial } from "components/DevPartial/DevPartial";
import { ITINInputEvent } from "components/TINInput/TINInput";
import { formatTIN } from "utils/tin";

interface IProps {
  id: string;
  inputValue: string | undefined;
  onChange?(event: ITINInputEvent): void;
}

const DEFAULT_DEV_VALUE = formatTIN("000000000");

export const DevTaxInputActionItem: FC<IProps> = props => {
  const { id, inputValue, onChange } = props;

  // Build event value for onChange event handler
  const buildEventValue = useCallback((value: string) => ({ target: { value, id } }), [id]);

  // Callback to fill the input with default value
  const handleClick = useCallback(() => {
    if (onChange && !inputValue) {
      const value = buildEventValue(DEFAULT_DEV_VALUE);
      onChange(value);
    }
  }, [buildEventValue, inputValue, onChange]);

  return (
    <DevPartial>
      {!inputValue && (
        <Button
          className="form__input--dev-default p-0 text-left"
          text="Use Default Value"
          size="sm"
          tag="c6"
          subtype="text-dark"
          onClick={handleClick}
        />
      )}
    </DevPartial>
  );
};
