import { FC, lazy } from "react";

import { IModalProps, IModalResource } from "config/init/providers/ModalsProvider";
import { Environment, OR } from "config/types";

import AddAnotherBeneficiaryModal from "./AddAnotherBeneficiaryModal/AddAnotherBeneficiaryModal";
import AddWyshModal from "./AddWyshModal/AddWyshModal";
import BankAccountVerificationError from "./BankAccountVerificationError/BankAccountVerificationError";
import BankAccountVerificationFailed from "./BankAccountVerificationFailed/BankAccountVerificationFailed";
import BankAccountVerificationSuccess from "./BankAccountVerificationSuccess/BankAccountVerificationSuccess";
import BillingChargeFrequencyModal from "./BillingChargeFrequencyModal/BillingChargeFrequencyModal";
import CancelAddedWyshesModal from "./CancelAddedWyshesModal/CancelAddedWyshesModal";
import CancelAutoDepositModal from "./CancelAutoDepositModal/CancelAutoDepositModal";
import CancelFinancialTransactionModal from "./CancelFinancialTransactionModal/CancelFinancialTransactionModal";
import CancelPendingUpdatesModal from "./CancelPendingUpdatesModal/CancelPendingUpdatesModal";
import CancelPolicyModal from "./CancelPolicyModal/CancelPolicyModal";
import CantEditMonitoredModal from "./CantEditMonitoredModal/CantEditMonitoredModal";
import CantFindBankModal from "./CantFindBankModal/CantFindBankModal";
import CloseAccountFailedModal from "./CloseAccountModals/CloseAccountFailedModal";
import CloseAccountModal from "./CloseAccountModals/CloseAccountModal";
import ConfirmBeneficiaryChangesModal from "./ConfirmBeneficiaryChangesModal/ConfirmBeneficiaryChangesModal";
import CustomerSupportModal from "./CustomerSupportModal/CustomerSupportModal";
import EncourageDepositModal from "./EncourageDepositModal/EncourageDepositModal";
import EndOfMonthErrorModal from "./EndOfMonthErrorModal/EndOfMonthErrorModal";
import ExistingAccountModal from "./ExistingAccountModal/ExistingAccountModal";
import ExitFlowWarningModal from "./ExitFlowWarningModal/ExitFlowWarningModal";
import ExitRegistrationModal from "./ExitRegistrationModal/ExitRegistrationModal";
import ExitWithoutSavingModal from "./ExitWithoutSavingModal/ExitWithoutSavingModal";
import GeneralErrorModal from "./GeneralErrorModal/GeneralErrorModal";
import IdentifiedExitModal from "./IdentifiedExitModal/IdentifiedExitModal";
import JourneyCompleted from "./JourneyCompleted/JourneyCompleted";
import JourneyExit from "./JourneyExit/JourneyExit";
import JourneyProgress from "./JourneyProgress/JourneyProgress";
import LoginAttemptWarningModal from "./LoginAttemptWarningModal/LoginAttemptWarningModal";
import ManualUWModal from "./ManualUWModal/ManualUWModal";
import MicrodepositBankErrorModal from "./MicrodepositBankErrorModal/MicrodepositBankErrorModal";
import MismatchFundingSourceModal from "./MismatchFundingSourceModal/MismatchFundingSourceModal";
import NoWyshesAdded from "./NoWyshesAdded/NoWyshesAdded";
import PastDueBalanceModal from "./PastDueBalanceModal/PastDueBalanceModal";
import PaymentFailed from "./PaymentFailed/PaymentFailed";
import PayoutDetailsModal from "./PayoutDetailsModal/PayoutDetailsModal";
import PendingTransferModal from "./PendingTransferModal/PendingTransferModal";
import PolicyBillingPaymentRetryFailureModal from "./PolicyBillingPaymentRetryFailureModal/PolicyBillingPaymentRetryFailureModal";
import PolicyBillingPaymentRetryProcessingModal from "./PolicyBillingPaymentRetryProcessingModal/PolicyBillingPaymentRetryProcessingModal";
import PolicyBillingPaymentRetrySuccessModal from "./PolicyBillingPaymentRetrySuccessModal/PolicyBillingPaymentRetrySuccessModal";
import PremiumCalculationModal from "./PremiumCalculationModal/PremiumCalculationModal";
import ReinstatementActivePolicyModal from "./ReinstatementActivePolicyModal/ReinstatementActivePolicyModal";
import RemoveBeneficiaryModal from "./RemoveBeneficiaryModal/RemoveBeneficiaryModal";
import RemoveLastBeneficiaryModal from "./RemoveLastBeneficiaryModal/RemoveLastBeneficiaryModal";
import RemoveLinkedAccountModal from "./RemoveLinkedAccountModal/RemoveLinkedAccountModal";
import RemoveWyshModal from "./RemoveWyshModal/RemoveWyshModal";
import ReplaceLinkedAccountModal from "./ReplaceLinkedAccountModal/ReplaceLinkedAccountModal";
import ResendCodeModal from "./ResendCodeModal/ResendCodeModal";
import ResetPasswordModal from "./ResetPasswordModal/ResetPasswordModal";
import SaveChangesModal from "./SaveChangesModal/SaveChangesModal";
import SendBeneficiaryPacketModal from "./SendBeneficiaryPacketModal/SendBeneficiaryPacketModal";
import SettingsBillingPaymentRetryFailureModal from "./SettingsBillingPaymentRetryFailureModal/SettingsBillingPaymentRetryFailureModal";
import SettingsBillingPaymentRetryProcessingModal from "./SettingsBillingPaymentRetryProcessingModal/SettingsBillingPaymentRetryProcessingModal";
import SettingsBillingPaymentRetrySuccessModal from "./SettingsBillingPaymentRetrySuccessModal/SettingsBillingPaymentRetrySuccessModal";
import SettingsSuccess from "./SettingsSuccess/SettingsSuccess";
import SliderErrorMessageModal from "./SliderErrorMessageModal/SliderErrorMessageModal";
import Success from "./Success/Success";
import TestModal from "./TestModal/TestModal";
import TransferFundSuccess from "./TransferFundSuccess/TransferFundSuccess";
import UnderwritingDecisionModal from "./UnderwritingDecisionModal/UnderwritingDecisionModal";
import UnderwritingRejectionModal from "./UnderwritingRejectionModal/UnderwritingRejectionModal";
import UnderwritingRequiredModal from "./UnderwritingRequiredModal/UnderwritingRequiredModal";
import UnlinkBankModal from "./UnlinkBankModal/UnlinkBankModal";
import UnlinkBankPaymentErrorModal from "./UnlinkBankPaymentErrorModal/UnlinkBankPaymentErrorModal";
import UnlinkBankWyshErrorModal from "./UnlinkBankWyshErrorModal/UnlinkBankWyshErrorModal";
import UnmonitorWyshModal from "./UnmonitorWyshModal/UnmonitorWyshModal";
import UnsupportedStateModal from "./UnsupportedStateModal/UnsupportedStateModal";
import VerificationEmailSendModal from "./VerificationEmailSendModal/VerificationEmailSendModal";

type CollectionItem = OR<IModalResource, IModalResource["component"]>;
export type ModalKey = keyof typeof modals;

const noopModal: FC<IModalProps> = () => null;

let DevModals = {
  DevPlatformConstantModal: noopModal,
  DevPlatformEndpointModal: noopModal,
  DevResetDataModal: noopModal,
};

if (
  (process.env.REACT_APP_ENV as Environment) === "development" ||
  (process.env.REACT_APP_ENV as Environment) === "staging"
) {
  DevModals = {
    DevPlatformEndpointModal: lazy(() =>
      import("./DevPlatformEndpointModal/DevPlatformEndpointModal")
    ),
    DevPlatformConstantModal: lazy(() =>
      import("./DevPlatformConstantModal/DevPlatformConstantModal")
    ),
    DevResetDataModal: lazy(() => import("./DevResetDataModal/DevResetDataModal")),
  };
}

const modals = {
  AddWyshModal,
  AddAnotherBeneficiaryModal,
  BankAccountVerificationError,
  BankAccountVerificationFailed,
  BankAccountVerificationSuccess,
  BillingChargeFrequencyModal,
  CancelAddedWyshesModal,
  CancelAutoDepositModal,
  CancelFinancialTransactionModal,
  CancelPendingUpdatesModal,
  CancelPolicyModal,
  CantEditMonitoredModal,
  CantFindBankModal,
  CloseAccountModal,
  CloseAccountFailedModal,
  ConfirmBeneficiaryChangesModal,
  CustomerSupportModal,
  ...DevModals,
  EncourageDepositModal,
  EndOfMonthErrorModal,
  ExistingAccountModal,
  ExitFlowWarningModal,
  ExitRegistrationModal,
  ExitWithoutSavingModal,
  GeneralErrorModal,
  IdentifiedExitModal,
  JourneyCompleted,
  JourneyExit,
  JourneyProgress,
  LoginAttemptWarningModal,
  ManualUWModal,
  MicrodepositBankErrorModal,
  MismatchFundingSourceModal,
  NoWyshesAdded,
  PastDueBalanceModal,
  PaymentFailed,
  PayoutDetailsModal,
  PolicyBillingPaymentRetryFailureModal,
  PolicyBillingPaymentRetryProcessingModal,
  PolicyBillingPaymentRetrySuccessModal,
  PendingTransferModal,
  PremiumCalculationModal,
  ReinstatementActivePolicyModal,
  RemoveBeneficiaryModal,
  RemoveLastBeneficiaryModal,
  RemoveLinkedAccountModal,
  RemoveWyshModal,
  ReplaceLinkedAccountModal,
  ResendCodeModal,
  ResetPasswordModal,
  SaveChangesModal,
  SendBeneficiaryPacketModal,
  SettingsBillingPaymentRetryFailureModal,
  SettingsBillingPaymentRetryProcessingModal,
  SettingsBillingPaymentRetrySuccessModal,
  SettingsSuccess,
  SliderErrorMessageModal,
  Success,
  TestModal,
  TransferFundSuccess,
  VerificationEmailSendModal,
  UnderwritingDecisionModal,
  UnderwritingRejectionModal,
  UnderwritingRequiredModal,
  UnlinkBankModal,
  UnlinkBankWyshErrorModal,
  UnlinkBankPaymentErrorModal,
  UnmonitorWyshModal,
  UnsupportedStateModal,
} as const;

export default modals as Record<string, CollectionItem>;
