import "./PinInput.scss";

import { FC } from "react";
import PinInput from "react-pin-input";

import Loader from "components/Loader/Loader";
import Text from "components/Text/Text";
import { ClassName } from "config/types";

import classNames from "classnames";
import { defaultTo } from "lodash-es";

interface IProps {
  className?: ClassName;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  focus?: boolean;
  initialValue?: string;
  length?: number;
  message?: string;
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
  secret?: boolean;
}

const Pin: FC<IProps> = props => {
  const {
    className,
    disabled,
    loading,
    error,
    focus,
    initialValue,
    length,
    message,
    onChange,
    onComplete,
    secret,
  } = props;

  const classes = classNames(className, "pin", { "pin--error": error, disabled: disabled });

  return (
    <div className={classes}>
      <PinInput
        length={defaultTo(length, 5)}
        focus={focus}
        initialValue={initialValue}
        secret={secret}
        type="numeric"
        inputMode="numeric"
        onChange={onChange}
        onComplete={onComplete}
        disabled={disabled}
      />
      {message && <Text tag="p3" text={message} className="pin__message" />}
      {loading ? <Loader /> : null}
    </div>
  );
};

export default Pin;
