import { FC } from "react";

import { journeyConfig } from "config/journey";
import { IJourneyStep, Section } from "config/types";
import JourneySection from "containers/JourneySection/JourneySection";

import { defaultTo, get, isEmpty, isEqual } from "lodash-es";

import Journey from "./Journey";

interface IProps {
  step: IJourneyStep;
  active?: boolean;
  metadata?: Record<string, unknown>;
}

interface IJourneySectionWrapperProps {
  id: string;
  active: boolean;
  step: string;
  slug: string;
}

const JourneySectionWrapper: FC<IJourneySectionWrapperProps> = props => {
  const sections = get(journeyConfig, props.step, []) as Section[];
  if (isEmpty(sections)) return null;

  return <JourneySection sections={sections} {...props} />;
};

const JourneyStep: FC<IProps> = ({ step, active, metadata }) => {
  const stepValue = get(journeyConfig, step.key, null);

  if (isEqual(step.type, "journey")) {
    return <Journey id={step.key} active={active} metadata={metadata} />;
  }

  const Step = defaultTo(stepValue, null);
  const stepProps = {
    id: defaultTo(step.id, ""),
    slug: step.key,
    active: !!active,
    step: step.key,
    metadata,
  };

  if (isEqual(Step, null) || active !== true) {
    return null;
  }

  if (isEqual(step.type, "section")) {
    return <JourneySectionWrapper {...stepProps} />;
  }

  const StepElement = Step as FC<typeof stepProps>;
  return <StepElement {...stepProps} />;
};

export default JourneyStep;
