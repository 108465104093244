import { useContext } from "react";

import { IDynamicFormItemProps } from ".";

import BinarySelectInput from "components/BinarySelectInput/BinarySelectInput";
import CardButtonInput from "components/CardButtonInput/CardButtonInput";
import RadioInputGroup from "components/RadioInputGroup/RadioInputGroup";
import SelectInput from "components/SelectInput/SelectInput";

import c from "classnames";

import { DynamicFormContext } from "../DynamicFormContext";

export function SingleSelectInputItem({ item }: IDynamicFormItemProps<"inputSingleSelect">) {
  const { content, key } = item;
  const { disabled, format, options } = content;

  // HOOKS
  const { form } = useContext(DynamicFormContext);
  const { label, value } = form.getFormInput(key);

  const classes = c("dynamic-form-item form-item--input-single-select");

  // RENDER
  if (format === "blobs") {
    return (
      <CardButtonInput
        dataTestId={key}
        className={classes}
        disabled={disabled}
        id={key}
        onChange={form.onChange}
        options={options}
        textCentered
        value={value}
      />
    );
  } else if (format === "dropdown") {
    return (
      <SelectInput
        className={classes}
        dataTestId={key}
        disabled={disabled}
        error={form.getInputError(key)}
        errorMessage={form.getInputErrorMessage(key)}
        id={key}
        onChange={form.onChange}
        options={options}
        label={label}
        value={value}
      />
    );
  } else if (format === "radio") {
    return (
      <RadioInputGroup
        borderBottom
        dataTestId={true}
        disabled={disabled}
        id={key}
        name={label || ""}
        onChange={form.onChange}
        options={options}
        vertical
        value={value}
      />
    );
  } else if (format === "rectangles") {
    return (
      <BinarySelectInput
        disabled={disabled}
        error={form.getInputError(key)}
        id={key}
        onChange={form.onChange}
        options={options}
        value={value}
      />
    );
  } else {
    return null;
  }
}
