/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPolicyRider } from "config/types";
import { logout } from "store/actions/authentication";
import { getPolicyRiders } from "store/actions/policy";

import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash-es";

export interface IPolicyRidersState {
  ids: string[];
  byId: Record<IPolicyRider["id"], IPolicyRider>;
}

const defaultState: IPolicyRidersState = {
  ids: [],
  byId: {},
};

/* ------------    REDUCER     ------------------ */
const policyRiders = createReducer(defaultState, builder => {
  builder
    .addCase(getPolicyRiders.fulfilled, (state, action) => {
      const policyRiders = action.payload;

      state.ids = policyRiders.map(rider => rider.id);
      state.byId = keyBy(policyRiders, "id");
    })
    .addCase(logout, () => defaultState);
});

export default policyRiders;
