import { createContext, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { ConditionKey } from "config/conditions";
import { OR, Overwrite, Section, ValueOrArray } from "config/types";
import { useCondition } from "modules";
import { setJourneyActiveSection } from "store/actions/journey";

import {
  add,
  defaultTo,
  every,
  filter,
  find,
  findIndex,
  first,
  flatten,
  flattenDeep,
  get,
  isEmpty,
  isEqual,
  last,
  map,
  noop,
  size,
  uniq,
} from "lodash-es";

type SectionWithConditions = Overwrite<Section, { conditions: boolean | undefined }>;

export type SectionContextValue = {
  sections: SectionWithConditions[];
  sectionMetadata: Record<string, unknown>;
  active: string;
  isLastSectionActive: boolean;
  isFirstSectionActive: boolean;
  setSections(sections: Section[]): void;
  setActive(activeKey: string, metadata?: SectionContextValue["sectionMetadata"]): void;
  activateNextSection(): { success: boolean };
};

const defaultContextValue: SectionContextValue = {
  sections: [],
  sectionMetadata: {},
  active: "",
  isLastSectionActive: false,
  isFirstSectionActive: false,
  setActive: noop,
  setSections: noop,
  activateNextSection: () => ({ success: false }),
};

export const JourneySectionContext = createContext<SectionContextValue>(defaultContextValue);

const JourneySectionProvider: FC = props => {
  const dispatch = useDispatch();
  const [sections, setSectionsState] = useState<Section[]>([]);
  const [sectionMetadata, setSectionMetadata] = useState<SectionContextValue["sectionMetadata"]>(
    defaultContextValue.sectionMetadata
  );

  const [activeState, setActiveState] = useState("");

  const firstInSection = first(sections);
  const lastInSection = last(sections);

  const isFirstSectionActive = isEqual(activeState, firstInSection?.key);
  const isLastSectionActive = isEqual(activeState, lastInSection?.key);

  const sectionConditions = flattenDeep(
    defaultTo(map(sections, "conditions"), []) as ValueOrArray<string>
  );
  const filteredConditions = filter(sectionConditions, v => !isEmpty(v));
  const uniqConditions = uniq(filteredConditions) as ConditionKey[];
  const conditions = useCondition(isEmpty(uniqConditions) ? undefined : uniqConditions);

  const conditionsIterator = useCallback(
    (section: Section) => {
      const empty = isEmpty(section?.conditions) || every(section?.conditions, isEmpty);
      if (empty) return { ...section, conditions: undefined };

      const conditionsList = flatten([section.conditions]);
      const computedCondition = every(conditionsList, value => {
        if (isEmpty(value)) return true;
        return defaultTo(conditions[value as string], false);
      });
      return { ...section, conditions: computedCondition };
    },
    [conditions]
  );

  const sectionsWithCompConditions = map(sections, conditionsIterator);

  const setActive = useCallback(
    (key: string, metadata: Record<string, unknown> = {}) => {
      if (!isEqual(key, activeState) || isEmpty(key)) {
        dispatch(setJourneyActiveSection(key));
        setActiveState(key);
      }

      if (!isEqual(metadata, sectionMetadata)) {
        setSectionMetadata(metadata);
      }
    },
    [activeState, dispatch, sectionMetadata]
  );

  const findActiveSection = (section: Section) => {
    const condition = section.conditions;
    const conditionsNotMet = isEqual(condition, false) || isEqual(condition, undefined);
    return !!section.active || conditionsNotMet;
  };

  const setSections = useCallback(
    (collection: Section[]) => {
      const getKeys = (items: OR<Section, SectionWithConditions>[]) => map(items, "key");
      if (isEmpty(collection)) {
        return setSectionsState(collection);
      }

      if (!isEqual(getKeys(sections), getKeys(collection))) {
        const collectionWithConditions = map(collection, conditionsIterator);
        const filteredCollection = filter(
          collectionWithConditions,
          item => !isEqual(item.conditions, true)
        );

        const firstInCollection = first(filteredCollection);
        const activeSection = find(collectionWithConditions, findActiveSection);
        const currentActiveInCollection = defaultTo(activeSection, firstInCollection);
        const activeKey = get(currentActiveInCollection, "key", "");

        setSectionsState(collection);
        if (every(collection, item => !isEqual(item.key, activeState))) {
          const incompleteConditions = isEmpty(conditions) && !isEmpty(sectionConditions);
          setActive(incompleteConditions ? "" : activeKey);
        }
      }
    },
    [activeState, conditions, conditionsIterator, sectionConditions, sections, setActive]
  );

  const activateNextSection = useCallback(() => {
    const currentActiveIndex = findIndex(sections, item => {
      return !!item.active || item.key === activeState;
    });

    const clampedIndex = Math.min(add(currentActiveIndex, 1), size(sections) - 1);

    const nextIndexIterator = (section: SectionWithConditions) => {
      const conditionsNotMet = !isEqual(section.conditions, true);
      return conditionsNotMet;
    };

    const nextIndex = findIndex(sectionsWithCompConditions, nextIndexIterator, clampedIndex);

    if (nextIndex > -1 && !isEqual(currentActiveIndex, nextIndex)) {
      const nextItem = sections[nextIndex];
      setActive(nextItem.key);
      return { success: true };
    } else {
      return { success: false };
    }
  }, [activeState, sections, sectionsWithCompConditions, setActive]);

  const sectionsWithActive = map(sectionsWithCompConditions, section => {
    return { ...section, active: isEqual(section.key, activeState) };
  });

  useEffect(() => {
    const activeSection = find(sectionsWithCompConditions, findActiveSection);
    if (!isEqual(get(activeSection, "key"), activeState) && isEmpty(activeState)) {
      setActive(get(activeSection, "key"));
    }
  }, [activeState, sectionsWithCompConditions, setActive]);

  const contextValue = useMemo(
    () => ({
      sectionMetadata,
      sections: sectionsWithActive,
      active: activeState,
      setSections,
      setActive,
      isLastSectionActive,
      isFirstSectionActive,
      activateNextSection,
    }),
    [
      sectionMetadata,
      sectionsWithActive,
      activeState,
      setSections,
      setActive,
      isLastSectionActive,
      isFirstSectionActive,
      activateNextSection,
    ]
  );

  return (
    <JourneySectionContext.Provider value={contextValue}>
      {props.children}
    </JourneySectionContext.Provider>
  );
};

export default JourneySectionProvider;
