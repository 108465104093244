import { EVENT_FIRED } from "store/constants";

import { createAction } from "@reduxjs/toolkit";

export interface IEventFiredPayload {
  event: string;
  experienceLocation?: "ONBOARDING" | "DASHBOARD";
  attribute?: { [key: string]: any };
}

/* ------------    ACTIONS     ------------------ */

export const eventFired = createAction<IEventFiredPayload>(EVENT_FIRED);
