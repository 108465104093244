import "./Footer.scss";

import { FC } from "react";

import Icon from "components/Icon/Icon";
import Logo from "components/Logo/Logo";
import Text from "components/Text/Text";
import { FacebookURL, InstagramURL, LinkedInURL, TwitterURL } from "config";
import { useImmersiveMode } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";

import classNames from "classnames";

const Footer: FC = () => {
  const { t } = useTranslation();
  const immersiveMode = useImmersiveMode();
  const classes = classNames("footer-container footer__v2", {
    "appear-hidden": immersiveMode,
  });

  return (
    <footer className={classes}>
      <div className="footer-wrapper">
        <div className="footer__section">
          <div className="footer__icons-wrapper">
            <div className="footer__icons--social-media-wrapper">
              <a href={FacebookURL} target="_blank" rel="noreferrer">
                <Icon className="footer__social-media-icon" src="Facebook" />
              </a>
              <a href={InstagramURL} target="_blank" rel="noreferrer">
                <Icon className="footer__social-media-icon" src="Instagram" />
              </a>
              <a href={TwitterURL} target="_blank" rel="noreferrer">
                <Icon className="footer__social-media-icon" src="Twitter" />
              </a>
              <a href={LinkedInURL} target="_blank" rel="noreferrer">
                <Icon className="footer__social-media-icon" src="LinkedIn" />
              </a>
            </div>
          </div>
          <div className="footer__content-wrapper">
            <Text
              className="footer__content--copyright"
              text={t(
                "footer.copyright",
                "Copyright © 2021 Wysh Life and Health Insurance Company. All rights reserved."
              )}
              tag="p6"
            />
          </div>
        </div>
        <div className="footer__section">
          <div className="footer__logo-wrapper appear-hidden@medium">
            <Logo className="footer__logo" type="WyshboxSmall" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
