import "./Divider.scss";

import { CSSProperties, FC } from "react";

import { ClassName } from "config/types";

import classNames from "classnames";
import { defaultTo, get } from "lodash-es";

export const elementTypes = {
  hr: "hr",
  div: "div",
  span: "span",
  li: "li",
};

type DividerColors = "satin" | "silver";

export type ElementType = keyof typeof elementTypes;

type IProps = {
  tag?: ElementType;
  className?: ClassName;
  style?: CSSProperties;
  vertical?: boolean;
  absolute?: boolean;
  bold?: boolean;
  color?: DividerColors;
};

const Divider: FC<IProps> = (props: IProps) => {
  const { tag, className, style, vertical, absolute, bold, color = "satin" } = props;

  const defaultTag = defaultTo(tag, "hr");

  const Element: any = get(elementTypes, defaultTag);

  const classes = classNames(
    "divider",
    className,
    {
      "divider--vertical": vertical,
      "divider--absolute": absolute,
      "divider--bold": bold,
    },
    `divider--${color}`
  );

  return <Element style={style} className={classes} />;
};

export default Divider;
