import { createSelector } from "@reduxjs/toolkit";

import { selectReinstateablePolicyById } from "./selectReinstateablePolicies";

export const getHasValidReinstatementProductOfferingByPolicyId = createSelector(
  selectReinstateablePolicyById,
  policy => {
    return (
      !!policy &&
      !!policy.reinstatementRequest &&
      !policy.reinstatementRequest.underwritingRequired &&
      !!policy.reinstatementRequest.productsOffering &&
      !!policy.reinstatementRequest.productsOffering.status &&
      ["accepted", "pending"].includes(policy.reinstatementRequest.productsOffering.status)
    );
  }
);
