import { useMemo } from "react";

import { FlowTypes } from "config/types";
import { useTranslation } from "modules/hooks/useTranslation";

export const useFlowFieldDisclosures = (slug: FlowTypes) => {
  const { t } = useTranslation();

  const fieldDisclosures = useMemo(() => {
    const genderDisclosure = t(
      "savings.application_form.disclosure.gender",
      "Please note, we gather sex information for insurance needs, and not for banking purposes."
    );

    switch (slug) {
      case "savings-default-application":
        return {
          "savings-default-application-gender": genderDisclosure,
          "savings-active-phone-application-gender": genderDisclosure,

          // V2 Savings+ Application
          "savings-default-application-v2-gender": genderDisclosure,
          "savings-active-phone-application-v2-gender": genderDisclosure,
        };

      default:
        return {};
    }
  }, [slug, t]);

  return fieldDisclosures;
};
