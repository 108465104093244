import React, { FC } from "react";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { useAppDispatch } from "store";

import { push } from "connected-react-router";

const MicrodepositBankErrorModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClickChat = () => {
    dispatch(push("/products/savings/micro-deposits/prompt"));
    closeModal();
  };

  const content = {
    button: [
      {
        text: t("savings.verify_block.modal.cta", "VERIFY MY BANK"),
        subType: "primary",
        onClick: onClickChat,
      },
      {
        text: t("savings.verify_block.modal.cta.secondary", "NEVERMIND"),
        subType: "text-dark",
        onClick: () => closeModal(),
      },
    ],
    header: t(
      "savings.verify_block.modal.title",
      "Please verify your bank before making a transfer"
    ),
    subheader: t(
      "savings.verify_block.modal.text",
      "Your bank has not been verified yet. This must be done before you can request any deposits or withdrawals."
    ),
    icon: "BlobBank",
    alwaysShowIcon: true,
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default MicrodepositBankErrorModal;
