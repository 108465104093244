import { IFinanceScheduledTransfer } from "config/types/financeCustomer.types";
import { logout } from "store/actions/authentication";
import { fetchFinanceAutoDeposits } from "store/actions/scheduledTransfers";

import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash-es";

interface IPendingScheduledTransfersState {
  ids: IFinanceScheduledTransfer["id"][];
  byId: Record<IFinanceScheduledTransfer["id"], IFinanceScheduledTransfer>;
}

/* ------------    SCHEDULED_TRANSFERS STATE     ------------------ */
const defaultState: IPendingScheduledTransfersState = {
  ids: [],
  byId: {},
};

/* ------------    REDUCER     ------------------ */
const pendingAutoTransfers = createReducer(defaultState, builder => {
  builder
    .addCase(fetchFinanceAutoDeposits.fulfilled, (state, action) => {
      const pendingTransfers = action.payload;
      state.ids = pendingTransfers.map(transfer => transfer.id);
      state.byId = keyBy(pendingTransfers, "id");
    })
    .addCase(logout, () => defaultState);
});

export default pendingAutoTransfers;
