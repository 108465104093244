import React, { FC, useEffect } from "react";
import { useLocation } from "react-router";

import { initialDepositFundingMismatchEvent } from "analytics/savings";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";
import { useAppDispatch } from "store";
import { closeModal } from "store/actions/modal";

import c from "classnames";
import { push } from "connected-react-router";

const MismatchFundingSourceModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(initialDepositFundingMismatchEvent());
  }, [dispatch]);

  const closeModalWrapper = () => dispatch(closeModal());

  const content = {
    header: t(
      "savings.initial_deposit.mismatch.funding.source.title",
      "Heads up!\nThis may update other transfers."
    ),
    subheader: t(
      "savings.mismatch_funding_source.modal.body",
      "We currently allow only one linked bank account. This means that changing it here will also update other scheduled deposits and withdrawals."
    ),
    button: [
      {
        onClick: () => dispatch(push("/products/savings/deposit/get-started", { from: pathname })),
        text: t("savings.mismatch_funding_source.modal.cta.primary", "UPDATE LINKED BANK"),
      },
      {
        onClick: closeModalWrapper,
        subType: "text-dark",
        text: t("savings.mismatch_funding_source.modal.cta.secondary", "NEVER MIND"),
      },
    ],
    onClose: closeModalWrapper,
    onButtonClick: closeModal,
    icon: "BlobAlert",
    alwaysShowIcon: true,
  };

  const classes = c("mismatch-funding-source__modal");

  return <Layout as="ModalLayout" className={classes} content={content} />;
};

export default MismatchFundingSourceModal;
