import "./DynamicForm.scss";

import { useEffect } from "react";

import FormError from "components/FormError/FormError";
import { IDynamicForm } from "config/types";
import { useForm, useScroll } from "modules";
import { buildFormInputsFromFormItems } from "utils/form";

import { DynamicFormProvider } from "./DynamicFormContext";
import { DynamicFormLayout } from "./DynamicFormLayouts";

/**
 * Controls form state and form submission
 * - renders DynamicFormLayout based on IDynamicForm.layout type
 * - renders DynamicFormItems based on IDynamicForm.items data
 * @param {Object} dynamicForm - IDynamicForm data
 * @param {String} flowSlug - flow slug to be used as form slug
 * @param {Object} onSubmitCallback - submit callback event to be called after form submit event
 * @return {React.ReactElement} - DynamicFormLayout component to generate UI layout
 */

export function DynamicForm(props: IProps) {
  const { dynamicForm, flowSlug } = props;
  const { scrollToTop } = useScroll({ duration: 0.35 });

  // METHODS
  const handleOnSubmit = () => {
    if (dynamicForm.layout !== "modal") {
      scrollToTop();
    }
  };

  // HOOKS
  const { form, inputs } = useForm([], {
    showInputErrorMessages: true,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    if (dynamicForm?.items) {
      const formInputs = buildFormInputsFromFormItems(dynamicForm.items);
      form.initializeFormState(formInputs);
    }
  }, [dynamicForm?.items]); //eslint-disable-line react-hooks/exhaustive-deps

  // RENDER
  if (!form.isFormInitialized || !dynamicForm) {
    return null;
  }

  return (
    <div
      className={`dynamic-form dynamic-form__container dynamic-form--${dynamicForm.layout}`}
      data-testid={`flow-form__${flowSlug}`}
      key={dynamicForm.id}>
      <DynamicFormProvider form={{ ...form, onSubmit: handleOnSubmit }} inputs={inputs}>
        <form
          className="dynamic-form__form"
          data-testid={`dynamic-form__${dynamicForm.slug}`}
          noValidate>
          <fieldset disabled={form.loading}>
            <DynamicFormLayout
              groups={dynamicForm.groups}
              items={dynamicForm.items}
              layout={dynamicForm.layout}
              leadingNavigationButton={dynamicForm.leadingNavigationButton}
              slug={dynamicForm.slug || flowSlug}
            />
          </fieldset>

          <FormError className="dynamic-form__error-message" error={form.formErrorMessage} />
        </form>
      </DynamicFormProvider>
    </div>
  );
}

/* ------------------    TYPES    ------------------ */
interface IProps {
  dynamicForm: IDynamicForm;
  flowSlug: IDynamicForm["slug"];
}
