import { countriesQuery } from "graphql/queries/countries";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

/* ------------       THUNKS      ------------------ */

export const getCountries = createAsyncThunk(
  "countries/getCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await countriesQuery();

      return response.data?.countries || [];
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
