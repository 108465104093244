import "./DashboardCard.scss";

import { FC, MouseEvent } from "react";
import { Link } from "react-router-dom";

import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ConditionalLink } from "modules";
import { ICardProps } from "modules/partials/Card";

import classNames from "classnames";

type Content = {
  body: string;
  header: {
    cta: {
      icon: string;
      url: string;
    };
    title: string;
  };
  cta?: {
    text: string;
    url: string;
    onClick?(event: MouseEvent): void;
  };
};

export const DashboardCard: FC<ICardProps> = props => {
  const content = props.content as Content;
  const { body, cta, header } = content;

  const classes = classNames("card--dashboard", props.className);

  return (
    <div className={classes}>
      <div className="card__header">
        <Text tag="c4">{header.title}</Text>

        {header.cta ? (
          <Link className="card__header-cta" to={header.cta.url}>
            <Icon className="card__icon" src={header.cta.icon} />
          </Link>
        ) : null}
      </div>

      <div className="card__body">{body}</div>

      {cta ? (
        <ConditionalLink condition={!!cta.url} to={cta.url}>
          <Button className="card__cta-btn" onClick={cta?.onClick} text={cta.text} />
        </ConditionalLink>
      ) : null}
    </div>
  );
};
