import "./MarketingCard.scss";

import { FC } from "react";
import { Link } from "react-router-dom";

import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ICardProps } from "modules/partials/Card";

import classNames from "classnames";
import { isString } from "lodash-es";

type Content = {
  body: string;
  bubble: string;
  cta: {
    url: string;
    text: string;
  };
  header: string;
  icon?: string;
};

export const MarketingCard: FC<ICardProps> = props => {
  const content = props.content as Content;
  const { body, bubble, cta, header, icon } = content;

  const classes = classNames("card--marketing", props.className);

  return (
    <div className={classes}>
      <Text className="card__bubble" tag="l3">
        {bubble}
      </Text>

      {icon ? <Icon className="card__icon" src={icon} /> : null}

      <Text className="card__header" tag="h4">
        {header}
      </Text>

      <div className="card__body">{isString(body) ? <Text tag="p5">{body}</Text> : body}</div>

      <Link className="card__cta" to={cta.url}>
        <Button className="card__cta-btn" subtype="secondary" text={cta.text} />
      </Link>
    </div>
  );
};
