import "./DisclosureLayout.scss";

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import Text from "components/Text/Text";
import Container from "containers/Container/Container";
import { ILayoutProps } from "modules";
import { useTranslation } from "modules/hooks/useTranslation";

import classNames from "classnames";
import { DateTime } from "luxon";

type ContentType = {
  name: string;
  body: string;
  version: string;
  createdAt: string;
  slug: string;
};
const DisclosureLayout: FC<ILayoutProps<ContentType>> = props => {
  const { children, className } = props;
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const content = props.content as ContentType;
  const name = content.name;
  const body = content.body;
  const createdAt = content.createdAt;
  const slug = content.slug;

  const classes = classNames("disclosure flex-column--center", className);

  const formattedCreatedAt = DateTime.fromISO(createdAt).toLocaleString();

  const [documentHeight, setDocumentHeight] = useState(null);

  const iFrameMessageListener = useCallback(
    ({ data }) => {
      if (!slug) {
        return;
      }

      const { payload, source } = data;

      if (source !== `${slug}-iframe`) {
        return;
      }

      switch (payload.eventName) {
        case "setHeight":
          setDocumentHeight(payload.data);
          break;

        case "scrollTop": {
          const htmlEl = document.querySelector("html");
          const wrapperOffsetTop = wrapperRef?.current?.offsetTop || 0;

          if (htmlEl) {
            htmlEl.scrollTop = payload.data + wrapperOffsetTop;
          }

          break;
        }
      }
    },
    [slug]
  );

  const setIFrameHeightScript = useMemo(
    () => `
      <script>
        window.wyshware = window.wyshware || {};

        window.wyshware.sendHeight = function() {
          window.parent.postMessage({
            source: "${slug}-iframe",
            payload: {
              eventName: "setHeight",
              data: document.documentElement.offsetHeight,
            },
          });
        };

        window.addEventListener("resize", window.wyshware.sendHeight);
        window.addEventListener("load", window.wyshware.sendHeight);
      </script>
    `,
    [slug]
  );

  const handleAnchorScript = useMemo(
    () => `
      <script>
        window.wyshware = window.wyshware || {};

        window.wyshware.sendAnchorPosition = function(event) {
          var targetAnchorName = event.target.hash.replace("#", "");
          var targetAnchor = document.querySelector('a[name="' + targetAnchorName + '"]');

          if (!targetAnchor) {
            return;
          }

          event.preventDefault();

          window.parent.postMessage({
            source: "${slug}-iframe",
            payload: {
              eventName: "scrollTop",
              data: targetAnchor.getBoundingClientRect().y,
            },
          });
        };

        Array.prototype.forEach.call(document.querySelectorAll('a[href^="#"]'), function(anchor) {
          anchor.addEventListener("click", window.wyshware.sendAnchorPosition);
        });
      </script>
    `,
    [slug]
  );

  useEffect(() => {
    window.addEventListener("message", iFrameMessageListener);

    return () => window.removeEventListener("message", iFrameMessageListener);
  }, [iFrameMessageListener]);

  return (
    <Container className={classes}>
      <div className="disclosure__wrapper" ref={wrapperRef}>
        <Text className="disclosure__name" tag="h1" text={name} />
        {createdAt && (
          <Text
            className="disclosure__updated"
            tag="p6"
            text={t("last_updated", `Last updated ${formattedCreatedAt}`, {
              date: formattedCreatedAt,
            })}
          />
        )}
        <iframe
          className="disclosure__body"
          id={slug}
          srcDoc={body + setIFrameHeightScript + handleAnchorScript}
          style={{ height: documentHeight || "auto" }}
          title={name}
        />
        {children}
      </div>
    </Container>
  );
};

export default DisclosureLayout;
