import { BusinessSlugTypes, IPlaidAccount, IPlaidInstitution } from "config/types";
import { logout } from "store/actions/authentication";
import { getPlaidItems } from "store/actions/bank";

import { createReducer } from "@reduxjs/toolkit";
import { keyBy } from "lodash-es";

interface IPlaidAccountExtended extends IPlaidAccount {
  businessSlug: BusinessSlugTypes;
  plaidInstitution: IPlaidInstitution;
}

/* ------------    TYPES     ------------------ */
interface IPlaidAccountsState {
  ids: IPlaidAccount["id"][];
  byId: Record<IPlaidAccount["id"], IPlaidAccountExtended>;
}

/* ------------    PLAIDACCOUNTS DEFAULT STATE     ------------------ */
const defaultState: IPlaidAccountsState = {
  ids: [],
  byId: {},
};

/* ------------    REDUCER     ------------------ */
const plaidAccounts = createReducer(defaultState, builder => {
  builder
    .addCase(getPlaidItems.fulfilled, (state, action) => {
      const plaidItems = action.payload || [];
      const flattenPlaidAccounts = plaidItems.flatMap(item =>
        item.plaidAccounts.map(account => ({
          ...account,
          businessSlug: item.business.slug,
          plaidInstitution: item.plaidInstitution,
        }))
      );
      const plaidAccountIds = flattenPlaidAccounts.map(plaidAccount => plaidAccount.id);

      state.ids = plaidAccountIds;
      state.byId = keyBy(flattenPlaidAccounts, "id");
    })
    .addCase(logout, () => defaultState);
});

export default plaidAccounts;
