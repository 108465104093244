import { IDeviceFingerprint } from "config/types";
import { setDeviceFingerprint } from "store/actions/device-fingerprint";

import { createReducer } from "@reduxjs/toolkit";

const defaultState: IDeviceFingerprint = {
  fingerprint: "",
  loading: false,
  error: null,
  loaded: false,
};

const deviceFingerprint = createReducer(defaultState, {
  [setDeviceFingerprint.pending.type]: state => {
    state.loading = true;
    state.loaded = false;
  },
  [setDeviceFingerprint.fulfilled.type]: (state, action) => ({
    fingerprint: action.payload,
    loading: false,
    error: null,
    loaded: true,
  }),
  [setDeviceFingerprint.rejected.type]: (state, action) => ({
    fingerprint: "",
    loading: false,
    error: action.payload,
    loaded: false,
  }),
});

export default deviceFingerprint;
