import { TApiButtonCommandFn } from ".";

import { useModal } from "modules";

import { findIndex } from "lodash-es";

import { BREAK_ACTIONS } from "../useButtonAction";

export const useExitFlowWarning = () => {
  const openModal = useModal();

  const exitFlowWarning: TApiButtonCommandFn = (_, { actions = [] }) => {
    const indexOfCurrentAction = findIndex(
      actions,
      action => action.command.destination === "exitFlowWarning"
    );
    const remainingButtonActions = actions.slice(indexOfCurrentAction + 1);

    openModal("ExitFlowWarningModal", "", { actions: remainingButtonActions });

    return BREAK_ACTIONS;
  };

  return exitFlowWarning;
};
