import "./SliderInput.scss";

import {
  FC,
  FocusEvent,
  PointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Text from "components/Text/Text";
import { ClassName } from "config/types";
import { outputValue, sliderValueFromTick, tickValueFromSlider } from "utils/tickCalculator";

import classNames from "classnames";
import { kebabCase, map, reduce } from "lodash-es";

interface IProps {
  id: string;
  style?: React.CSSProperties;
  className?: ClassName;
  value: number;
  label?: string;
  inactive?: boolean;
  metadata: ISliderMetaData;
  dataTestId?: string;
  onChange?(event: ISliderInputEvent): void;
  onFocus?(event: FocusEvent<HTMLInputElement>): void;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
}

export interface ISliderInputEvent {
  target: {
    value: number;
    id: string;
  };
}

export interface ISliderMetaData {
  value: number;
  ticks: ISliderTick[];
  textEditable: boolean;
  maxErrorMessage: string;
  minErrorMessage: string;
  characterMax?: number | null;
  valueBase?: number | null;
  leftTextLabel?: string | null;
  rightTextLabel?: string | null;
  increment?: number | null;
}

export interface ISliderTick {
  value: number;
  label: string;
}

const SliderInput: FC<IProps> = props => {
  const {
    className,
    style,
    onChange,
    onFocus,
    onBlur,
    id,
    dataTestId,
    metadata,
    inactive,
    label,
    value,
  } = props;

  const [sliderValue, setSliderValue] = useState(value || 0);
  const [internalValue, setInternalValue] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  const ref = useRef<HTMLInputElement>(document.createElement("input"));
  const sliderThumbRef = useRef<HTMLDivElement>(document.createElement("div"));

  // Metadata
  const ticksList = useMemo(() => metadata.ticks || [], [metadata]);
  const { min, max } = reduce(
    ticksList,
    (result: Record<string, number>, tick: ISliderTick) => {
      const newResult = { ...result };
      if (!result.min || result.min > tick.value) newResult.min = tick.value;
      if (!result.max || result.max < tick.value) newResult.max = tick.value;
      return newResult;
    },
    { min: 0, max: 0 }
  );
  const increment = metadata?.increment || 1;

  const percentValue = internalValue * 100;

  const handleSliderOnChange = (internalValue: number) => {
    if (sliderThumbRef.current !== null) {
      const tickValue = tickValueFromSlider(internalValue, ticksList, min, max);
      const outPutVal = outputValue(tickValue, increment);
      const newInternal = sliderValueFromTick(tickValue, ticksList, min, max);

      const changeEvent = {
        target: {
          value: outPutVal,
          id: id,
        },
      };

      setSliderValue(outPutVal);
      setInternalValue(newInternal);

      onChange && onChange(changeEvent);
    }
  };

  const handleSliderValue = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSliderValue(+value);
  };

  const updateSlider = useCallback(
    (value: number) => {
      const newInternal = sliderValueFromTick(value, ticksList, min, max);

      setInternalValue(newInternal);
      setSliderValue(value);
    },
    [min, max, ticksList]
  );

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
  };

  const onClickSliderBar = (event: PointerEvent<HTMLDivElement>) => {
    const clientX = event.clientX;
    const inputOffsetLeft = ref.current.getBoundingClientRect().left;
    const inputOffsetRight = ref.current.getBoundingClientRect().right;

    const internalValue = (clientX - inputOffsetLeft) / (inputOffsetRight - inputOffsetLeft);
    const tickValue = tickValueFromSlider(internalValue, ticksList, min, max);
    const outPutVal = outputValue(tickValue, increment);

    const changeEvent = {
      target: {
        value: outPutVal,
        id: id,
      },
    };

    setInternalValue(internalValue);
    props.onChange && props.onChange(changeEvent);
  };

  const pointerDown = (event: PointerEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setIsSliding(true);
  };

  const pointerUp = () => {
    setIsSliding(false);
  };

  const pointerMove = (event: any) => {
    event.preventDefault();

    const currentClientX = event.clientX;
    const startClientX = ref.current.getBoundingClientRect().left;
    const sliderWidth = ref.current.clientWidth;

    let newInternal = (currentClientX - startClientX) / sliderWidth;
    if (newInternal <= 0) newInternal = 0;
    if (newInternal >= 1) newInternal = 1;

    handleSliderOnChange(newInternal);
  };

  const toggleSlider = (isSliding: boolean) => {
    if (isSliding) {
      window.addEventListener("pointermove", pointerMove);
      window.addEventListener("pointerup", pointerUp);
    } else {
      window.removeEventListener("pointermove", pointerMove);
    }
  };

  const mapTickList = () => {
    return map(ticksList, (tickItem: ISliderTick) => {
      return (
        <div className="datalist-option c6-tag__text" key={tickItem.value}>
          {tickItem.label}
        </div>
      );
    });
  };

  useEffect(() => {
    updateSlider(metadata.value);
  }, [metadata, updateSlider]);

  useEffect(() => {
    toggleSlider(isSliding);

    if (!isSliding) updateSlider(value);

    return () => {
      window.removeEventListener("pointermove", pointerMove);
      window.removeEventListener("pointerup", pointerUp);
    };
  }, [isSliding, internalValue, value]); //eslint-disable-line react-hooks/exhaustive-deps

  const classes = classNames("slider-input__container", className);

  return (
    <div className={classes} style={style}>
      <div className={`slider-input__wrapper ${inactive ? "inactive" : ""}`}>
        <Text className="slider-input__label" tag="l2" text={label} />
        <div className="slider-input__slider" onClick={onClickSliderBar}>
          <span className="slider-input__bar" style={{ width: `${percentValue}%` }} />
          <div
            className="slider-input__thumb"
            style={{ left: `${percentValue}%` }}
            onPointerDown={pointerDown}
            ref={sliderThumbRef}
          />
          <input
            id={id}
            className="slider-input text-input_input"
            type="number"
            name="slider"
            min={min}
            max={max}
            value={sliderValue || metadata.value || min || 0}
            step={50000}
            onChange={handleSliderValue}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            role="slider-input"
            data-testid={dataTestId || `slider-input__${kebabCase(label)}`}
            ref={ref}
          />
        </div>
        <div className="slider-input__datalist">{mapTickList()}</div>
      </div>
    </div>
  );
};

export default SliderInput;
