import { useCallback, useContext, useEffect, useRef } from "react";
import { matchPath } from "react-router";

import { OrNumber, RouteDestination } from "config/types";
import {
  JourneySectionContext,
  SectionContextValue,
} from "modules/managers/JourneySectionProvider";
import { history } from "store";
import { resolvePath } from "utils/resolvePath";
import { scrollToTop } from "utils/scrollToTop";

import { defaultTo, findLast, isEmpty, map } from "lodash-es";

import { useJourney } from "./useJourney";

type State = Record<string, unknown>;

export interface NavigateOptions {
  replace?: boolean;
  state?: State;
}

export interface NavigateFunction {
  (to: RouteDestination, options?: NavigateOptions): void;
  (delta: number): void;
}

export interface NavigateHookOptions {
  context?: { journeySection?: SectionContextValue };
}

export const useNavigate = (baseState?: State, cxt?: NavigateHookOptions) => {
  const navigator = history;
  const { anyJourneysActive } = useJourney();

  const context = defaultTo(cxt?.context?.journeySection, useContext(JourneySectionContext));
  const { sections, setActive } = context;
  const activeRef = useRef(false);
  useEffect(() => {
    activeRef.current = true;
  });

  const buildMatchProps = (pathname: string) => {
    return {
      path: pathname,
      exact: true,
      strict: false,
    };
  };

  const navigate: NavigateFunction = useCallback(
    (to: OrNumber<RouteDestination>, options: NavigateOptions = {}) => {
      const path = resolvePath(to as RouteDestination, "/");
      if (anyJourneysActive) {
        const sectionsWithMatches = map(sections, section => {
          const match = matchPath(to as string, buildMatchProps(section.route as string));
          return { ...section, match };
        });

        const matchingTargetSection = findLast(sectionsWithMatches, section => {
          const hasRoute = !isEmpty(section.route);
          const hasMatch = section.match !== null;
          return hasRoute && hasMatch;
        });

        if (matchingTargetSection?.key && matchingTargetSection?.match) {
          const match = matchingTargetSection.match;
          const targetSectionKey = matchingTargetSection.key;
          const payload = { ...baseState, ...options?.state, params: match?.params };

          if (!isEmpty(targetSectionKey)) {
            setActive(targetSectionKey as string, payload);
            scrollToTop({ behavior: "auto" });
          }
          return;
        }
      }

      if (activeRef.current) {
        if (typeof to === "number") {
          navigator.go(to);
        } else {
          (!!options.replace ? navigator.replace : navigator.push)(path.pathname, {
            ...baseState,
            ...options.state,
          });
        }
      }
    },
    [anyJourneysActive, baseState, navigator, sections, setActive]
  );

  return navigate;
};
