import { financeProgramDetailsQuery } from "graphql/queries/financeProgramDetails";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFinanceProgramDetails = createAsyncThunk(
  "financeProgramDetails/getDetails",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await financeProgramDetailsQuery();
      return data.financeProgramDetails;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
