import { ProducerManagementReferralCode } from "config";
import { createApplicationReferralMutation } from "graphql/mutations/producerManagement";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const createApplicationReferral = createAsyncThunk(
  "producerManagement/createApplicationReferral",
  async (referralCode: ProducerManagementReferralCode, { rejectWithValue }) => {
    try {
      const {
        data: {
          createApplicationReferral: { response },
        },
      } = await createApplicationReferralMutation(referralCode);

      return response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
