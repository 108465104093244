import { updateNotificationMutation } from "graphql/mutations/notifications";
import { accountNotificationsQuery } from "graphql/queries/notifications";
import { RootState } from "store";
import { getActiveAccount } from "utils/account";
import { asyncEach } from "utils/asyncEach";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";

/* ------------       THUNKS      ------------------ */

export const getNotifications = createAsyncThunk(
  "notifications/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountNotificationsQuery();
      const activeAccount = getActiveAccount(response.data.me);
      const notifications = activeAccount?.notifications;

      return notifications || [];
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

// Updates notification.read value to true; removes notification from query
export const updateNotification = createAsyncThunk(
  "notifications/update",
  async (notificationId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateNotificationMutation(notificationId);
      await dispatch(getNotifications());

      return response;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const resolveMUWNotification = createAsyncThunk(
  "notifications/resolve-muw",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      await dispatch(getNotifications());
      const { notifications } = getState() as RootState;
      const muwNotifications = notifications.notifications.filter(notification => {
        return notification?.metadata?.type === "manual_underwriting_decision";
      });

      const responses = await asyncEach(muwNotifications, async ({ id }) => {
        return await updateNotificationMutation(id);
      });

      if (responses.length > 0) {
        await dispatch(getNotifications());
      }
      return responses;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
