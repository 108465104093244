import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "store";
import { fetchExternalAccounts } from "store/actions/externalAccounts";
import { getFinanceBankAccount } from "store/actions/financeBankAccount";
import { setModal } from "store/actions/modal";
import {
  getHasUnlinkedExternalAccount,
  getIsFinanceAccountUnfunded,
  getUser,
} from "store/selectors";

import { local } from "store2";

// Hook functionalities:
// 1. Checks local storage for notifications read by user
// 2. Sets flag in local storage as "read"

type NotificationFlagTypes = "hasReadFundAccountModal" | string;

export const useLocalNotificationsRead = (notificationFlag: NotificationFlagTypes) => {
  // HOOKS
  const dispatch = useAppDispatch();
  const { id } = useSelector(getUser);
  const isUnfundedAccount = useSelector(getIsFinanceAccountUnfunded);
  const hasUnlinkedExternalAccount = useSelector(getHasUnlinkedExternalAccount);

  // METHODS
  const getLocalStorageFlag = () => {
    return local.namespace(id).get(notificationFlag);
  };

  const setLocalStorageFlagAsRead = () => {
    local.namespace(id).set(notificationFlag, true);
  };

  // NOTIFICATION ACTIONS
  const renderEncourageDepositModal = () => {
    if (isUnfundedAccount || hasUnlinkedExternalAccount) {
      dispatch(setModal("EncourageDepositModal"));
    }
  };

  const isLocalNotificationRead = getLocalStorageFlag();

  // FETCH DATA HOOK
  useEffect(() => {
    if (notificationFlag === "hasReadFundAccountModal" && !isLocalNotificationRead) {
      dispatch(fetchExternalAccounts());
      dispatch(getFinanceBankAccount());
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // ACTION HOOK
  useEffect(() => {
    if (notificationFlag === "hasReadFundAccountModal" && !isLocalNotificationRead) {
      renderEncourageDepositModal();
    }
  }, [isUnfundedAccount, hasUnlinkedExternalAccount]); //eslint-disable-line react-hooks/exhaustive-deps

  return { getLocalStorageFlag, setLocalStorageFlagAsRead };
};
