import { PlatformApiUrl, XClientFingerprint } from "config";
import {
  IAuthRequestErrorConstructor,
  IAuthRequestErrorResponsePayload,
  IAuthRequestPayload,
} from "config/types";
import { Store } from "store";

import { get, isEmpty, isEqual } from "lodash-es";

interface IAuthRequest {
  data: any;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  url: string;
}

class AuthRequest {
  private static baseUrl: string;
  private static instance: AuthRequest;
  private static store: Store;

  constructor(store: Store, baseUrl = PlatformApiUrl) {
    AuthRequest.baseUrl = baseUrl;
    AuthRequest.store = store;

    AuthRequest.instance = this;
  }

  public static getInstance() {
    if (!this.instance) {
      throw new Error("AuthRequest Singleton has not been initialized yet");
    }

    return this.instance;
  }

  public async request({ data, method, url }: IAuthRequest) {
    const state = AuthRequest.store.getState();
    const token = get(state, "authentication.accessToken.access_token", "");

    const fullUrl = `${AuthRequest.baseUrl}/${url}`;

    const headers = new Headers();
    if (!isEqual(token, "undefined") && !isEqual(token, undefined) && !isEmpty(token)) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    headers.append("Content-Type", "application/json");
    headers.append("X-Client-Fingerprint", XClientFingerprint);
    headers.append("X-Device-Fingerprint", get(state, "deviceFingerprint.fingerprint", ""));
    headers.append("X-Device-Identifier", navigator.userAgent);

    const dataWithToken = token ? { ...data } : data;

    const options = {
      headers,
      method,
      body: JSON.stringify(dataWithToken),
    };

    const response = await fetch(fullUrl, options);
    const responseData = await response.json();

    if (!response.ok) {
      throw new AuthRequestError({
        code: response.status,
        message: responseData.error,
        request: {
          ...options,
          url: fullUrl,
        },
        response: {
          data: responseData,
          headers: response.headers,
          status: response.status,
        },
      });
    }

    return responseData || {};
  }
}

class AuthRequestError extends Error {
  code: number;
  request: IAuthRequestPayload;
  response: IAuthRequestErrorResponsePayload;

  constructor({ code, message, request, response }: IAuthRequestErrorConstructor) {
    super(message);

    this.code = code;
    this.request = request;
    this.response = response;

    Object.setPrototypeOf(this, AuthRequestError.prototype);
  }
}

export default AuthRequest;
