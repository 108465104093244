import "./AddWyshesCard.scss";

import { FC, MouseEvent } from "react";

import WyshesMoreWyshesBlob from "assets/icons/WyshesMoreWyshesBlob/WyshesMoreWyshesBlob.svg";
import Icon from "components/Icon/Icon";
import Text from "components/Text/Text";
import { ICardProps } from "modules/partials/Card";
import { IWyshState } from "store/reducers/wyshes";

import classNames from "classnames";
import { kebabCase } from "lodash-es";

interface IAddedWyshesProps extends IWyshState {
  carouselItem?: boolean;
  total?: number;
  onClick?(event: MouseEvent): void;
}

export const AddWyshesCard: FC<ICardProps> = props => {
  const { className } = props;
  const content = props.content as IAddedWyshesProps;
  const { prompt, icon, carouselItem, slug, onClick, total = 0 } = content;

  const classes = classNames(className, "fcard-content__body-container add-wyshes__card-wrapper", {
    "carousel--item": carouselItem,
  });

  return (
    <button
      onClick={onClick}
      data-testid={`wysh__${kebabCase(slug)}`}
      className={classes}
      style={props.style}>
      <div className="add-wyshes__card-content">
        <div className="card-content--top">
          <div className={`add-wyshes__total-wrapper ${!total && "hide"}`}>
            <Text className="add-wyshes__total" tag="c4" text={total} />
          </div>

          <div className="card-content__icon-wrapper">
            <Icon className="card-content__icon" src={icon || WyshesMoreWyshesBlob} size="lg" />
          </div>
        </div>

        <div className="card-content">
          <div className="card-content__body-text__wrapper">
            <Text tag="l2" text={prompt} className="card-content__body-text" />
          </div>
        </div>
      </div>
    </button>
  );
};
