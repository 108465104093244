import { FC } from "react";
import { useDispatch } from "react-redux";

import { IModalProps } from "config/init/providers/ModalsProvider";
import { Layout, useTranslation } from "modules";
import { closeModal } from "store/actions/modal";
import { openChat } from "utils/customerSupport";

import { push } from "connected-react-router";

const BankAccountVerificationError: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeModalWrapper = () => dispatch(closeModal());

  const unlinkBankOnClick = async () => {
    dispatch(push("/"));
  };

  const onClickHelp = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    openChat();
    closeModalWrapper();
  };
  const ctaButtons = [
    {
      text: t(
        "savings.microdeposit_verify.bank.several_deposit_errors.modal.primary.cta",
        "CONTACT SUPPORT"
      ),
      onClick: onClickHelp,
    },
    {
      text: t(
        "savings.microdeposit_verify.bank.several_deposit_errors.modal.secondary.cta",
        "NEVERMIND"
      ),
      subType: "text-dark",
      onClick: unlinkBankOnClick,
    },
  ];

  const content = {
    header: t(
      "savings.microdeposit_verify.bank.several_deposit_errors.modal.header",
      "Unable to link your bank"
    ),
    subheader: t(
      "savings.microdeposit_verify.bank.several_deposit_errors.modal.subheader",
      "We are unable to verify your account due to too many incorrect attempts. Please contact customer support."
    ),
    onClose: closeModalWrapper,
    button: ctaButtons,
    icon: "BlobBank",
  };

  return <Layout as="ModalLayout" content={content} />;
};

export default BankAccountVerificationError;
