import "./CreateAccount.scss";

import { FC, FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router";

import CreateAccountImg from "assets/images/registration/CreateAccount.svg";
import Button from "components/Button/Button";
import Checkbox from "components/Checkbox/Checkbox";
import DisclosureLink from "components/DisclosureLink/DisclosureLink";
import EmailInput from "components/EmailInput/EmailInput";
import FormError from "components/FormError/FormError";
import PasswordInput from "components/PasswordInput/PasswordInput";
import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import Text from "components/Text/Text";
import { defaultPasswordLength } from "config";
import { AnalyticsIds } from "config/analytics";
import { IFormInput } from "config/types";
import { IRegisterUserInput } from "graphql/mutations/user";
import { Layout, useDecodedAccessToken } from "modules";
import { useAuthorizeUnidentifiedUser, useForm, useJourney, useModal } from "modules/hooks";
import { useTranslation } from "modules/hooks/useTranslation";
import { useAppDispatch } from "store";
import { login } from "store/actions/authentication";
import { setModal } from "store/actions/modal";
import { registerUser, setupUserForNewLineOfBusiness } from "store/actions/user";
import { getThunkError } from "utils/error";
import { IResetValues } from "utils/form";

import { replace } from "connected-react-router";
import { get, isEmpty } from "lodash-es";

const CreateAccount: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    authorizeUnidentifiedUserByBusiness,
    clientId,
    isUnidentified,
  } = useAuthorizeUnidentifiedUser();
  const [formError, setFormError] = useState("");
  const { nextStepCallback } = useJourney();
  const createAccountAnalyticsId = get(AnalyticsIds, "loginRegistration.createAccountSignUp", "");
  const decodedToken = useDecodedAccessToken();
  const isOnSavingsPlusOnboarding = useLocation().pathname === "/products/savings/create-account";

  useEffect(() => {
    const businessType = isOnSavingsPlusOnboarding ? "wysh-financial" : "wysh-insurance";
    if (isUnidentified) {
      authorizeUnidentifiedUserByBusiness(businessType);
    } else if (isOnSavingsPlusOnboarding) {
      dispatch(setupUserForNewLineOfBusiness(businessType));
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const initialInputData: Partial<IFormInput>[] = [
    {
      id: "firstName",
      value: "",
    },
    {
      id: "lastName",
      value: "",
    },
    {
      id: "email",
      value: "",
      required: true,
      validations: ["email"],
    },
    {
      id: "password",
      value: "",
      required: true,
      validations: [{ name: "validatePasswordLength", passwordLength: defaultPasswordLength }],
    },
    {
      id: "joinNewsletter",
      value: true,
    },
  ];

  const { form, inputs } = useForm(initialInputData, { showInputErrorMessages: true });

  const onHaveAccountClick = () => {
    dispatch(setModal("ExitRegistrationModal"));
  };

  const SubheaderElement = (
    <div className="create-account__subheader-wrapper">
      <Text
        tag="p3"
        className="create-account__subheader-el"
        text={t(
          "register_user.form.subheader",
          "Sign up to save your progress. It only takes a minute."
        )}
      />
      <Text
        tag="l4"
        className="create-account__subheader-el link"
        text={t("register_user.already_have_account", "I already have an account")}
        onClick={onHaveAccountClick}
      />
    </div>
  );

  const content = {
    header: t("register_user.form.header", "Time to create an account!"),
    subheaderElement: SubheaderElement,
    imgSrc: CreateAccountImg,
  };

  const resetForm = () => {
    const resetValues: IResetValues = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      joinNewsletter: true,
    };
    form.reset(resetValues);
  };

  const openModal = useModal({ resetForm });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (!form.valid) {
        return;
      }
      form.setLoading(true);

      // get identified user id and account id from access token
      const identifiedUser = get(decodedToken, "user");
      const identifiedUserId = get(identifiedUser, "user_id");

      const password = get(inputs, ["password", "value"]);
      const userInfo = {
        userId: identifiedUserId,
        firstName: get(inputs, ["firstName", "value"]),
        lastName: get(inputs, ["lastName", "value"]),
        emailAddress: get(inputs, ["email", "value"]),
        password,
        passwordConfirmation: password,
        newsletterOptIn: get(inputs, ["joinNewsletter", "value"], false),
      };

      const registerUserInput: IRegisterUserInput = {
        attributes: {
          ...userInfo,
        },
      };

      const registerResponse = await dispatch(registerUser(registerUserInput));
      const thunkError = getThunkError(registerResponse);

      const registerErrorCode = get(thunkError, "code", "");
      const registerErrorMessage = get(registerResponse, "error.message");

      if (registerErrorCode === "EMAIL_ALREADY_TAKEN") {
        openModal("ExistingAccountModal");
        return;
      } else if (registerErrorCode || registerErrorMessage) {
        throw new Error(registerErrorCode || registerErrorMessage);
      }

      // // Silently login user before proceeding
      const loginResponse = await dispatch(
        login({ email: userInfo.emailAddress, password: userInfo.password, clientId })
      );

      const loginErrorMessage = get(
        getThunkError(loginResponse),
        "fullMessage",
        get(loginResponse, "error.message", "")
      );

      if (!isEmpty(loginErrorMessage)) {
        throw new Error(loginErrorMessage);
      }

      const nextScreenPath = isOnSavingsPlusOnboarding
        ? "/flow/savings-default-application"
        : "/create-phone";
      const callback = () => dispatch(replace(nextScreenPath));
      const nextStep = nextStepCallback(callback, false);
      nextStep();
    } catch (err) {
      const errorMessage = get(err, "fullMessage", get(err, "message", err));
      setFormError(errorMessage);
    } finally {
      form.setLoading(false);
    }
  };

  const emailInput = inputs["email"];
  const passwordInput = inputs["password"];
  const joinNewsletterInput = inputs["joinNewsletter"];

  const displayError = t(formError, formError);

  return (
    <Layout
      as="TwoColumnLayout"
      content={content}
      backNav={{ hasBackNav: isOnSavingsPlusOnboarding }}
      fallback={<ScreenLoader />}
      renderFallback={isUnidentified}>
      <div className="create-account">
        <form noValidate onSubmit={handleSubmit}>
          <EmailInput
            id={emailInput.id}
            value={emailInput.value}
            onChange={form.onChange}
            onBlur={form.onBlur}
            error={form.getInputError(emailInput.id)}
            errorMessage={form.getInputErrorMessage(emailInput.id)}
            label={t("register_user.form.email", "Email")}
            dataTestId="create-account__email"
          />
          <PasswordInput
            id={passwordInput.id}
            value={passwordInput.value}
            onChange={form.onChange}
            onBlur={form.onBlur}
            error={form.getInputError(passwordInput.id)}
            errorMessage={form.getInputErrorMessage(passwordInput.id)}
            label={t("register_user.form.password", "Password (12 character min)")}
            dataTestId="create-account__password"
          />
          <Checkbox
            id={joinNewsletterInput.id}
            label={t(
              "register_user.checkbox",
              "Keep me up-to-date with all things Wysh. by joining the Newsletter!"
            )}
            value={joinNewsletterInput.value}
            onChange={form.onChange}
            checked={joinNewsletterInput.value}
            className="create-account__checkbox"
          />
          <div className="create-account__terms p6-tag__text">
            {t(
              "register_user.legal.v2",
              "By continuing, you attest that you are over 18 years old and agree to our "
            )}{" "}
            <DisclosureLink className="link p6-tag__text" slug="terms-conditions">
              {t("register_user.terms", "Terms & Conditions")}
            </DisclosureLink>{" "}
            {t("register_user.and", "and")}{" "}
            <DisclosureLink className="link p6-tag__text" slug="privacy-policy">
              {t("register_user.privacy_policy", "Privacy Policy")}
            </DisclosureLink>
          </div>
          {displayError && <FormError style={{ marginTop: 20 }} error={displayError} />}
          <Button
            id="submit"
            className="form__submit create-account__btn"
            onClick={() => form.validateAllFields()}
            disabled={form.getSubmitBtnDisabled()}
            isLoading={form.loading}
            subtype="primary"
            text={t("register_user.form.button", "CONFIRM INFO AND CONTINUE")}
            type="submit"
            dataTestId="create-account__submit"
            dataAnalyticsId={createAccountAnalyticsId}
          />
        </form>
      </div>
    </Layout>
  );
};

export default CreateAccount;
