import {
  BeneficiaryResponsibility,
  ContactType,
  IAddress,
  IContactEntity,
  IContactIndividual,
  IFormInputs,
} from "config/types";
import {
  BeneficiaryInputAttributes,
  createBeneficiaryMutation,
  destroyBeneficiaryMutation,
  ISendBeneficiaryPacketInput,
  sendBeneficiaryPacketMutation,
  updateBeneficiaryAllocationMutation,
  updateBeneficiaryMutation,
} from "graphql/mutations/beneficiary";
import { fetchBeneficiaries } from "graphql/queries/beneficiary";
import { getAppError } from "utils/error";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, mapValues, merge, omit, pick } from "lodash-es";

const buildContact = (inputs: IFormInputs) => {
  const values = mapValues(inputs, input => (input.value === "" ? null : input.value));
  const flattenedValues = merge(values, get(values, "phoneNumber", null));

  const contact: Partial<IContactEntity> | Partial<IContactIndividual> = omit(flattenedValues, [
    "street1",
    "street2",
    "city",
    "state",
    "postalCode",
  ]);

  const address: IAddress = pick(flattenedValues, [
    "street1",
    "street2",
    "city",
    "state",
    "postalCode",
  ]);

  return { contact, address };
};

export const getBeneficiaries = createAsyncThunk(
  "getBeneficiaries",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchBeneficiaries();
      const financeBeneficiaries = data.me.financeCustomer?.beneficiaries || [];
      const insuranceBeneficiaries =
        data.me.activeConstellations[0]?.activeInsuranceMembership?.activePolicy?.beneficiaries ||
        [];
      const insuranceApplicationBeneficiaries =
        data.me.activeConstellations[0]?.activeInsuranceMembership?.beneficiaries || [];

      return { financeBeneficiaries, insuranceBeneficiaries, insuranceApplicationBeneficiaries };
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

type TCreateBeneficiaryInput = Pick<
  Parameters<typeof createBeneficiaryMutation>[0],
  "assignedEntity" | "entityType" | "responsibility" | "type"
> & {
  inputs: IFormInputs;
};

export const createBeneficiary = createAsyncThunk(
  "beneficiaries/createBeneficiary",
  async (input: TCreateBeneficiaryInput, { rejectWithValue }) => {
    try {
      const { type, responsibility, assignedEntity, inputs, entityType } = input;

      const { contact, address } = buildContact(inputs);

      const requestData = {
        type,
        contact,
        entityType,
        address,
        responsibility,
        assignedEntity,
      };

      const response = await createBeneficiaryMutation(requestData);

      return response.data.createBeneficiary.beneficiary;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

export const updateBeneficiary = createAsyncThunk(
  "beneficiaries/updateBeneficiary",
  async (
    input: {
      type: ContactType;
      inputs: IFormInputs;
      beneficiaryId: string;
      taxIdDirtied: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { type, beneficiaryId, inputs, taxIdDirtied } = input;

      const { contact, address } = buildContact(inputs);

      const requestData = {
        type,
        contact,
        address,
        beneficiaryId,
      };

      if (requestData.contact.hasOwnProperty("taxId") && !taxIdDirtied) {
        delete requestData.contact.taxId;
      }

      if (requestData.contact.hasOwnProperty("trusteeTaxId") && !taxIdDirtied) {
        delete requestData.contact.trusteeTaxId;
      }

      const response = await updateBeneficiaryMutation(requestData);

      return response.data.updateBeneficiary.beneficiary;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

export const sendBeneficiaryPacket = createAsyncThunk(
  "beneficiaries/sendBeneficiaryPacket",
  async (input: ISendBeneficiaryPacketInput, { rejectWithValue }) => {
    try {
      const response = await sendBeneficiaryPacketMutation(input);

      return { success: response.data.sendBeneficiaryPacket.response.success };
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const destroyBeneficiary = createAsyncThunk(
  "beneficiaries/destroyBeneficiary",
  async (
    input: {
      beneficiaryId: string;
      responsibility: BeneficiaryResponsibility;
      promoteBackup?: boolean;
      destroyBackup?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { beneficiaryId, promoteBackup, destroyBackup, responsibility } = input;

      const requestData = {
        beneficiaryId,
        promoteBackup,
        destroyBackup,
      };

      await destroyBeneficiaryMutation(requestData);

      return { beneficiaryId, responsibility };
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

export const updateBeneficiaryAllocations = createAsyncThunk(
  "beneficiaries/updateBeneficiaryAllocation",
  async (beneficiaryAllocations: BeneficiaryInputAttributes[], { rejectWithValue }) => {
    try {
      const response = await updateBeneficiaryAllocationMutation(beneficiaryAllocations);

      return response.data.updateBeneficiaryAllocation.response.status;
    } catch (err) {
      const errorCode = getAppError(err);
      return rejectWithValue(errorCode);
    }
  }
);

/* ------------       ACTIONS      ------------------ */
