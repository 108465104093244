import { IAccountStatus } from "config/types";
import {
  disableFinanceOnboarding,
  exchangeBlackbox,
  getAccountCapabilities,
  getEligibleForCoverageUnderwriting,
  lockAccount,
  unlockAccount,
} from "store/actions/account";
import { getVerifiedInitialData } from "store/actions/appLoad";
import { logout } from "store/actions/authentication";
import { getDashboardData } from "store/actions/dashboard";
import { requestPolicyCoverageAdjustment } from "store/actions/policy";

import { createReducer } from "@reduxjs/toolkit";

const defaultState: IAccountStatus = {
  capabilities: {
    billing: false,
    termLifeExtension: false,
  },
  caseId: "",
  eligibleForCoverage: false,
  eligibleForFinanceApplication: true,
  eligibleForMonitoring: false,
  eligibleForUnderwriting: true,
  fraudulentDevice: false,
  locked: "",
  underwritingRequired: false,
};

const account = createReducer(defaultState, builder => {
  builder
    .addCase(disableFinanceOnboarding, state => {
      state.eligibleForFinanceApplication = false;
    })
    .addCase(exchangeBlackbox.fulfilled, (state, action) => {
      const { blocked, caseId } = action.payload;

      state.fraudulentDevice = blocked;
      state.locked = blocked ? "DEVICE_LOCKED" : state.locked;
      state.caseId = blocked ? caseId : state.caseId;
    })
    .addCase(getAccountCapabilities.fulfilled, (state, action) => {
      state.capabilities.billing = action.payload?.billing || defaultState.capabilities.billing;
      state.capabilities.termLifeExtension =
        action.payload?.termLifeExtension || defaultState.capabilities.termLifeExtension;
    })
    .addCase(getDashboardData.fulfilled, (state, action) => {
      state.eligibleForMonitoring = action.payload?.eligibleForMonitoring || false;
      state.eligibleForUnderwriting =
        action.payload?.eligibleForUnderwriting !== undefined
          ? action.payload?.eligibleForUnderwriting
          : state.eligibleForUnderwriting;
    })
    .addCase(getEligibleForCoverageUnderwriting.fulfilled, (state, action) => {
      state.eligibleForCoverage =
        action.payload?.eligibleForCoverage || defaultState.eligibleForUnderwriting;
      state.eligibleForUnderwriting =
        action.payload?.eligibleForUnderwriting || defaultState.eligibleForUnderwriting;
    })
    .addCase(getVerifiedInitialData.fulfilled, (state, action) => {
      state.eligibleForMonitoring = action.payload?.account?.eligibleForMonitoring || false;
      state.underwritingRequired = action.payload?.account?.underwritingRequired || false;
    })
    .addCase(lockAccount, (state, action) => {
      return {
        ...defaultState,
        locked: action.payload,
      };
    })
    .addCase(logout, () => defaultState)
    .addCase(requestPolicyCoverageAdjustment.fulfilled, (state, action) => {
      state.underwritingRequired = action.payload?.underwritingRequired || false;
    })
    .addCase(unlockAccount, () => {
      return {
        ...defaultState,
        fraudulentDevice: false,
        locked: "",
      };
    });
});

export default account;
