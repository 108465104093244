import React, { FC, Fragment, ReactNode } from "react";

import AddressInputFlowQuestion from "components/AddressInput/AddressInputFlowQuestion";
import Text from "components/Text/Text";
import VariableText from "components/VariableText/VariableText";
import {
  FlowQuestionLayout,
  FlowTypes,
  IFlowField,
  IFlowQuestion,
  IForm,
  IFormInputs,
} from "config";

import classNames from "classnames";
import { map } from "lodash-es";

import { FlowQuestionField } from "./FlowQuestionField";

export interface IFlowQuestionAdapterProps {
  question: IFlowQuestion;
  inputs: IFormInputs;
  form: IForm;
  children: ReactNode;
  terminalForm: boolean;
  fieldTitles: Record<string, string | undefined>;
  fieldDisclosures: Record<string, string | undefined>;
}

interface IProps {
  form: IForm;
  terminalForm: boolean;
  inputs: IFormInputs;
  question: IFlowQuestion;
  questions: IFlowQuestion[];
  fieldTitles: Record<string, string | undefined>;
  fieldDisclosures: Record<string, string | undefined>;
  slug?: FlowTypes;
  dataTestId?: string;
}

const FlowQuestionAdapter: FC<IFlowQuestionAdapterProps> = props => {
  const { question, terminalForm, children = null } = props;
  const { layout = FlowQuestionLayout.vertical } = question;

  if (layout === FlowQuestionLayout.address) {
    return (
      <div className="flow__field-container" data-terminal={terminalForm}>
        <AddressInputFlowQuestion {...props}>{children}</AddressInputFlowQuestion>
      </div>
    );
  }

  return <>{children}</>;
};

const HEALTH_JUSTIFICATION_STRING =
  "Excellent = You’re a picture of perfect health. Your cholesterol, blood pressure, and weight are #goals.\nGood = You’re a healthy weight and try to maintain it by making an effort to eat well and exercise from time to time.\nAverage = Your health isn’t really bad or really great. You may have a non-life threatening medical condition, but you try to eat well and exercise to keep healthy.\nDespite your health status, please let us know if you smoke cigarettes, chew tobacco, or use it any other way.\n";

export const FlowQuestion: FC<IProps> = props => {
  const { question, form, ...rest } = props;
  const flowInputClasses = classNames("flow__inputs", { disabled: form.loading });
  const fields = question.fields;
  const Question = FlowQuestionAdapter ?? Fragment;

  return (
    <div key={question.id}>
      <Text tag="h4" text={question.prompt} className="flow__question" />
      <Text tag="p3" text={question.description} className="flow__description" />
      <div className={flowInputClasses}>
        <Question
          question={question}
          form={form}
          inputs={rest.inputs}
          terminalForm={rest.terminalForm}
          fieldTitles={rest.fieldTitles}
          fieldDisclosures={rest.fieldDisclosures}>
          {map(fields || [], (field: IFlowField) => (
            <FlowQuestionField key={field.id} field={field} form={form} {...rest} />
          ))}
        </Question>
      </div>
      {question.justification && (
        <>
          <Text
            tag="c5"
            text={question.justificationHeader}
            className="flow__justification-prompt"
          />
          {question.justification.trim() === HEALTH_JUSTIFICATION_STRING.trim() ? (
            <Text tag="p6" className="flow__justification">
              <VariableText
                text={question.justification}
                target={/^(Average|Good|Excellent)/gm}
                replacementElement="strong"
              />
            </Text>
          ) : (
            <Text tag="p6" text={question.justification} className="flow__justification" />
          )}
        </>
      )}
    </div>
  );
};
