import { useSelector } from "react-redux";

import { RootState } from "store";
import { translate } from "utils/translations";

interface IReplacements {
  [key: string]: string | number;
}

export const useTranslation = () => {
  const translations = useSelector((state: RootState) => state.translations);

  const t = (key: string, fallbackTerm: string, replacements: IReplacements = {}) => {
    return translate(key, fallbackTerm, { replacements, dictionary: translations });
  };

  return { t };
};
