import { DashboardProductStates, TFinanceCustomersStatus } from "config";
import { RootState } from "store";

import { createSelector } from "@reduxjs/toolkit";

import { selectFinanceCustomer } from "./selectFinanceCustomer";
import { getMembershipMetadata } from "./selectMemberships";

const selectEstimate = (state: RootState) => state.estimate;
const selectPolicy = (state: RootState) => state.policy;
const selectProductOfferings = (state: RootState) => state.productOffering;
const selectInsuranceApplication = (state: RootState) => state.insuranceApplication;
const selectAllocationSummary = (state: RootState) => state.allocationSummary;
const selectWyshAllocations = (state: RootState) => state.wyshAllocations.allocated;

export const getInsuranceProductState = createSelector(
  selectPolicy,
  selectInsuranceApplication,
  selectEstimate,
  selectProductOfferings,
  (activePolicy, insuranceApplication, estimate, productOfferings): DashboardProductStates => {
    if (activePolicy.id) {
      return "owned";
    } else if (insuranceApplication.result === "referred_to_underwriter") {
      return "notice";
    } else if (!!productOfferings.ids.length) {
      return "quote";
    } else if (estimate.id) {
      return "recapture";
    } else {
      return "marketing";
    }
  }
);

export const getSavingsProductState = createSelector(
  selectFinanceCustomer,
  getMembershipMetadata,
  (financeCustomer, membershipMetadata): Exclude<DashboardProductStates, "quote"> => {
    const recaptureStateStatuses: Partial<TFinanceCustomersStatus>[] = [
      "automated_reviewed",
      "created",
      "verified",
    ];

    if (financeCustomer.status === "verified" && membershipMetadata?.cdd_submitted) {
      return "owned";
    } else if (financeCustomer.status === "manual_reviewed") {
      return "notice";
    } else if (recaptureStateStatuses.includes(financeCustomer.status)) {
      return "recapture";
    } else {
      return "marketing";
    }
  }
);

export const getWyshesProductState = createSelector(
  selectWyshAllocations,
  selectAllocationSummary,
  (wyshAllocations, allocationSummary): Extract<DashboardProductStates, "marketing" | "owned"> => {
    if (wyshAllocations.length || allocationSummary.activeCount) {
      return "owned";
    } else {
      return "marketing";
    }
  }
);
