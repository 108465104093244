import { Component, CSSProperties } from "react";

import SVGInjector from "@tanem/svg-injector";
import * as PropTypes from "prop-types";
import { renderToStaticMarkup } from "react-dom/server";

import shallowDiffers from "./shallow-differs";

export type OnInjected = (error: Error | null, svg: SVGSVGElement | undefined) => void;

interface IProps {
  evalScripts?: "always" | "once" | "never";
  fallback?: React.ReactType;
  loading?: React.ReactType;
  onInjected?: OnInjected;
  renumerateIRIElements?: boolean;
  src: string;
  svgClassName?: string;
  svgStyle?: CSSProperties;
  wrapper?: "div" | "span";
}

interface IState {
  hasError: boolean;
  isLoading: boolean;
}

export default class Svg extends Component<
  IProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement | HTMLDivElement>,
      HTMLSpanElement | HTMLDivElement
    >,
  IState
> {
  public static defaultProps = {
    evalScripts: "never",
    fallback: null,
    loading: null,
    onInjected: () => undefined,
    renumerateIRIElements: true,
    svgClassName: null,
    svgStyle: {},
    wrapper: "div",
  };

  public static propTypes = {
    evalScripts: PropTypes.oneOf(["always", "once", "never"]),
    fallback: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
    loading: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
    onInjected: PropTypes.func,
    renumerateIRIElements: PropTypes.bool,
    src: PropTypes.string.isRequired,
    svgClassName: PropTypes.string,
    svgStyle: PropTypes.object,
    wrapper: PropTypes.oneOf(["div", "span"]),
  };

  public initialState = {
    hasError: false,
    isLoading: true,
  };

  public state = this.initialState;

  // tslint:disable-next-line:variable-name
  public _isMounted = false;

  public container?: HTMLSpanElement | HTMLDivElement | null;

  public svgWrapper?: HTMLSpanElement | HTMLDivElement | null;

  public refCallback: any = (container: any) => {
    this.container = container;
  };

  public renderSVG() {
    if (this.container instanceof Node) {
      const { evalScripts, renumerateIRIElements, src, svgClassName, svgStyle } = this.props;
      const onInjected = this.props.onInjected!; // eslint-disable-line
      const Wrapper = this.props.wrapper!; // eslint-disable-line

      const wrapper = document.createElement(Wrapper);
      wrapper.innerHTML = renderToStaticMarkup(
        <Wrapper className="svg-wrapper">
          <Wrapper className={svgClassName} data-src={src} style={svgStyle} />
        </Wrapper>
      );

      this.svgWrapper = this.container.appendChild(
        wrapper.firstChild as HTMLSpanElement | HTMLDivElement
      );

      const each: OnInjected = (error, svg) => {
        if (error) {
          this.removeSVG();
        }

        // TODO: It'd be better to cleanly unsubscribe from SVGInjector
        // callbacks instead of tracking a property like this.
        if (this._isMounted) {
          this.setState(
            () => ({
              hasError: !!error,
              isLoading: false,
            }),
            () => {
              onInjected(error, svg);
            }
          );
        }
      };

      SVGInjector(this.svgWrapper.firstChild, {
        each,
        evalScripts,
        renumerateIRIElements,
      });
    }
  }

  public removeSVG() {
    if (this.container instanceof Node && this.svgWrapper instanceof Node) {
      this.container.removeChild(this.svgWrapper);
      this.svgWrapper = null;
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this.renderSVG();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (shallowDiffers(prevProps, this.props)) {
      this.setState(
        () => this.initialState,
        () => {
          this.removeSVG();
          this.renderSVG();
        }
      );
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    this.removeSVG();
  }

  public render() {
    /*eslint-disable*/
    const {
      fallback: Fallback,
      loading: Loading,
      wrapper,
      evalScripts,
      svgStyle,
      svgClassName,
      renumerateIRIElements,
      onInjected,
      ...rest
    } = this.props;
    const Wrapper = wrapper!;
    /*eslint-enable*/

    return (
      <Wrapper className="svg-container" {...rest} ref={this.refCallback}>
        {this.state.isLoading && Loading && <Loading />}
        {this.state.hasError && Fallback && <Fallback />}
      </Wrapper>
    );
  }
}
