import { SocureSDKKey } from "config/constants";

import { isFunction } from "lodash-es";

interface WindowWithSocure extends Window {
  devicer?: typeof SocureDeviceRisk;
  SocureInitializer?: typeof SocureInitializer;
  Socure?: typeof Socure;
}

// Initialize Socure SDK with API key to retrieve Socure module
export const socureInitialization = async () => {
  const { SocureInitializer } = window as WindowWithSocure;

  if (SocureInitializer && isFunction(SocureInitializer.init)) {
    try {
      await SocureInitializer.init(SocureSDKKey);
    } catch (error) {
      return error;
    }
  }
};

// Socure module for initiating document request verification
export const socureDocumentRequest = async (
  configCallbacks?: Partial<Socure.IConfigCallbackMethods>
) => {
  const { Socure } = window as WindowWithSocure;
  const {
    onProgress = onProgressCallback,
    onSuccess = onSuccessCallback,
    onError = onErrorCallback,
  } = configCallbacks || {};

  async function onProgressCallback(response: Socure.IProgressResponse) {
    return response;
  }

  async function onSuccessCallback(successResp: Socure.ISuccessResponse) {
    return successResp;
  }

  async function onErrorCallback(response: Socure.IProgressResponse) {
    return response;
  }

  const config = {
    onProgress,
    onSuccess,
    onError,
    qrCodeNeeded: true,
  };

  if (Socure && isFunction(Socure.init)) {
    await Socure.init(SocureSDKKey, "#socure-websdk", config);
    await Socure.start(2);
  }
};

export const socureClearSession = () => {
  const { Socure } = window as WindowWithSocure;

  if (Socure && isFunction(Socure.cleanup)) {
    Socure.cleanup();
    Socure.reset();
    Socure.unmount();
    sessionStorage.removeItem("documentVerificationToken");
    sessionStorage.removeItem("publicApiKey");
    localStorage.removeItem("devicer_id");
  }
};

export const socureDeviceSessionId = async () => {
  const { devicer } = window as WindowWithSocure;

  try {
    if (devicer && isFunction(devicer.run)) {
      const deviceFPOptions = {
        context: "signup" as const,
        publicKey: SocureSDKKey,
        userConsent: true,
      };

      const deviceSessionId = await new Promise<string>(resolve => {
        devicer.run(deviceFPOptions, response => {
          resolve(response.sessionId);
        });
      });

      return deviceSessionId;
    }
  } catch (error) {
    return null;
  }
};
