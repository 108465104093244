import "./CreatePhoneNumber.scss";

import { FC } from "react";

import CreatePhoneImg from "assets/images/registration/CreatePhone.svg";
import { DynamicFormFlow } from "containers/DynamicFormFlow/DynamicFormFlow";
import { Layout } from "modules";

const CreatePhoneNumber: FC = () => {
  const content = {
    imgSrc: CreatePhoneImg,
  };

  return (
    <Layout as="TwoColumnLayout" className="create-phone-number" content={content}>
      <DynamicFormFlow flowSlug="pre-verified-initial-data" sessionFromStart />
    </Layout>
  );
};

export default CreatePhoneNumber;
