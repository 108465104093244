import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import { fetchTrivia } from "store/actions/trivia";

import { random } from "lodash-es";

const ANSWER_DURATION = 2;
const ANSWER_SCREEN_DURATION = 7;
const DELAY_BEFORE_BANNER = 1;
const MIN_BANNER_DISPLAY_DURATION = 3;
const QUESTION_SCREEN_DURATION = 7;

export const useTrivia = () => {
  const dispatch = useDispatch();

  const questionSlugs = useSelector((state: RootState) => state.trivia.slugs);
  const questions = useSelector((state: RootState) => state.trivia.questions);

  const availableSlugs = useRef([...questionSlugs]);
  const questionNumber = useRef(0);

  const nextQuestion = () => {
    if (availableSlugs.current.length === 0) {
      return undefined;
    }

    const randomSlugIndex = random(0, availableSlugs.current.length - 1);
    const nextSlug = availableSlugs.current[randomSlugIndex];
    const nextQuestionNumber = questionNumber.current + 1;

    availableSlugs.current = availableSlugs.current.filter(slug => slug !== nextSlug);
    questionNumber.current = nextQuestionNumber;

    return {
      question: questions[nextSlug],
      questionNumber: nextQuestionNumber,
    };
  };

  const submitAnswer = (questionSlug: string, answerSlug: string) => {
    const answer = questions[questionSlug].answers.find(({ slug }) => slug === answerSlug);

    return answer?.correct;
  };

  useEffect(() => {
    if (!availableSlugs.current.length && questionSlugs.length) {
      availableSlugs.current = [...questionSlugs];
    }
  }, [questionSlugs]);

  useEffect(() => {
    if (!questionSlugs.length) {
      // INVESTIGATE: Should we call this ahead of time? Perhaps have a last grabbed value in reducer
      // to check for staleness so we can re-fetch if needed. Could either export the fetchQuestions
      // method or just call `useTrivia();` without caring about the return value to pre-fetch.
      dispatch(fetchTrivia());
    }
  }, [dispatch, questionSlugs]);

  return {
    nextQuestion,
    submitAnswer,
    answerDuration: ANSWER_DURATION,
    answerScreenDuration: ANSWER_SCREEN_DURATION,
    delayBeforeBanner: DELAY_BEFORE_BANNER,
    minBannerDisplayDuration: MIN_BANNER_DISPLAY_DURATION,
    questionScreenDuration: QUESTION_SCREEN_DURATION,
  };
};
