import api from "api/api";
import { allowTokenRefresh, IPhoneNumber, IUser, OAuthRedirectUri, OrNull } from "config";
import {
  IAccessToken,
  IAccessTokenRequestData,
  IAuthorizeRequestData,
  ICredential,
  PhoneVerificationMode,
} from "config/types";
import {
  exchangeLoginTokenMutation,
  requestOtpMutation,
  verifySessionMutation,
} from "graphql/mutations/authentication";
import { RootState } from "store";
import { exchangeBlackbox } from "store/actions/account";
import { getPreVerifiedInitialData, getVerifiedInitialData } from "store/actions/appLoad";
import { LOGOUT, SET_ACCESS_TOKEN, UPDATE_ACCESS_TOKEN } from "store/constants";
import { generateCodeChallenge, generateCodeVerifier } from "utils/authCodeChallenge";
import { getAppError } from "utils/error";
import { getRefreshToken, setRefreshToken } from "utils/refreshToken";

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { get, isEmpty } from "lodash-es";
import { session } from "store2";

// ******======== Helpers ========******

interface authCodeInput extends ICredential {
  clientId: string;
  userId?: string;
}

const getAuthorizationCode = async (input: authCodeInput, codeChallenge: string) => {
  const authorizeRequestData: IAuthorizeRequestData = {
    client_id: input.clientId,
    redirect_uri: OAuthRedirectUri,
    response_type: "code",
    scope: "api",
    code_challenge_method: "S256",
    code_challenge: codeChallenge,
    email: input.email,
    password: input.password,
    user_id: input.userId || "",
  };
  const authorizeResponse = await api.auth.authorize(authorizeRequestData);
  return authorizeResponse.redirect_uri.code;
};

const getAccessToken = async (
  authorizationCode: string,
  codeVerifier: string,
  clientId: string
) => {
  const accessTokenRequestData: IAccessTokenRequestData = {
    client_id: clientId,
    redirect_uri: OAuthRedirectUri,
    grant_type: "authorization_code",
    code: authorizationCode,
    code_verifier: codeVerifier,
  };
  return await api.auth.fetchToken(accessTokenRequestData);
};

// ******======== Actions ========******

export const logout = createAction(LOGOUT);

export const setAccessToken = createAction<{ accessToken: IAccessToken }>(SET_ACCESS_TOKEN);
export const updateAccessToken = createAction<Partial<IAccessToken>>(UPDATE_ACCESS_TOKEN);
// export const setSessionVerificationChecked = createAction<boolean>(
//   SET_SESSION_VERIFICATION_CHECKED
// );

export const setRedirectPath = createAction<OrNull<string>>("authentication/set-redirect-path");

// ******======== Thunks ========******

export const refreshToken = createAsyncThunk(
  "authentication/refresh-token",
  async (clientId: string | undefined, { rejectWithValue, dispatch, getState }) => {
    try {
      const { authentication } = getState() as RootState;
      const refreshToken = getRefreshToken();

      if (!refreshToken && !authentication.accessToken) {
        throw new Error("No available refresh token");
      }

      const identity = session.get("AuthIdentity");
      const identityToken = get(identity, "token");

      const accessToken = isEmpty(identityToken)
        ? await api.auth.refreshAccessToken(clientId)
        : identityToken;

      const jwtDecoded = jwtDecode(accessToken.access_token);
      const authLevel = get(jwtDecoded, "user.auth_level");

      setRefreshToken(accessToken, authLevel);
      dispatch(setAccessToken({ accessToken }));

      await dispatch(getPreVerifiedInitialData());

      if (authLevel === "verified") {
        await dispatch(getVerifiedInitialData());
      }

      return { accessToken };
    } catch (err) {
      const errorMessage = getAppError(err);
      return rejectWithValue(errorMessage);
    }
  }
);

export const authorizeUnidentifiedUser = createAsyncThunk(
  "authentication/authorizeUnidentifiedUser",
  async (
    { clientId, userData }: { clientId: string; userData: Partial<IUser> },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(exchangeBlackbox());
    } catch (err) {
      console.log("error", err);
    }
    try {
      const userId = userData.id;
      const codeVerifier = generateCodeVerifier(32);
      const codeChallenge = generateCodeChallenge(codeVerifier);
      const authorizationCode = await getAuthorizationCode(
        { clientId, userId: userId, email: "", password: "" },
        codeChallenge
      );
      const accessToken = await getAccessToken(authorizationCode, codeVerifier, clientId);
      const jwtDecoded = jwtDecode(accessToken.access_token);
      const authLevel = get(jwtDecoded, "user.auth_level");

      if (allowTokenRefresh) {
        setRefreshToken(accessToken, authLevel);
      }

      await dispatch(setAccessToken({ accessToken }));

      return {
        accessToken,
        user: userData,
      };
    } catch (err) {
      const errorMessage = getAppError(err);

      return rejectWithValue(errorMessage);
    }
  }
);

interface ILoginInput extends ICredential {
  clientId: string;
  exchangeBlackBox?: boolean;
}

export const login = createAsyncThunk(
  "authentication/login",
  async (input: ILoginInput, { rejectWithValue, dispatch }) => {
    if (input.exchangeBlackBox) {
      try {
        dispatch(exchangeBlackbox());
      } catch (err) {
        console.log("error", err);
      }
    }
    try {
      const codeVerifier = generateCodeVerifier(32);
      const codeChallenge = generateCodeChallenge(codeVerifier);
      const authorizationCode = await getAuthorizationCode(input, codeChallenge);
      const accessToken = await getAccessToken(authorizationCode, codeVerifier, input.clientId);

      if (allowTokenRefresh) {
        setRefreshToken(accessToken);
      }

      await dispatch(setAccessToken({ accessToken }));
      await dispatch(getPreVerifiedInitialData());
      // since we are in login, we know we already know session verified is false
      // so we manually set session verification checked to true
      // await dispatch(setSessionVerificationChecked(true));

      return accessToken;
    } catch (err) {
      const errorMessage = getAppError(err);

      return rejectWithValue(errorMessage);
    }
  }
);

interface IExchangeLoginToken {
  loginToken: string;
  exchangeBlackBox?: boolean;
}

export const exchangeLoginToken = createAsyncThunk(
  "authentication/exchangeLoginToken",
  async (input: IExchangeLoginToken, { dispatch, rejectWithValue }) => {
    await dispatch(logout());

    if (input.exchangeBlackBox) {
      try {
        dispatch(exchangeBlackbox());
      } catch (err) {
        console.log("error", err);
      }
    }

    try {
      const {
        data: {
          exchangeLoginToken: { token },
        },
      } = await exchangeLoginTokenMutation({
        loginToken: input.loginToken,
      });
      const access_token = {
        access_token: token.accessToken,
        created_at: token.createdAt,
        expires_in: token.expiresIn,
        refresh_token: token.refreshToken,
        scope: token.scope,
        token_type: token.tokenType,
      };

      if (allowTokenRefresh) {
        setRefreshToken(access_token);
      }

      await dispatch(setAccessToken({ accessToken: access_token }));
      await dispatch(getPreVerifiedInitialData());

      return access_token;
    } catch (err) {
      const errorMessage = getAppError(err);

      return rejectWithValue(errorMessage);
    }
  }
);

export const verifySession = createAsyncThunk(
  "session/verifySession",
  async (input: { code: string; phoneNumberId?: string }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await verifySessionMutation(input);
      const session = get(data, ["verifySession", "session"]);

      await dispatch(getVerifiedInitialData());

      return session;
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);

export const requestOtpCode = createAsyncThunk(
  "session/otpCode",
  async (
    { phoneNumberId, type }: { phoneNumberId?: IPhoneNumber["id"]; type?: PhoneVerificationMode },
    { getState, rejectWithValue }
  ) => {
    try {
      const reduxState = getState();
      const accessToken: IAccessToken = get(reduxState, ["authentication", "accessToken"]);
      const jwtDecoded = jwtDecode(accessToken.access_token);
      const sessionId = get(jwtDecoded, ["user", "session_id"]);
      const { data } = await requestOtpMutation({ sessionId, phoneNumberId, channel: type });

      return get(data, ["requestOtp", "response", "success"]);
    } catch (err) {
      const errorCode = getAppError(err);

      return rejectWithValue(errorCode);
    }
  }
);
