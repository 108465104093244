import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormError from "components/FormError/FormError";
import { IModalProps } from "config/init/providers/ModalsProvider";
import { BeneficiaryResponsibility, BusinessSlugTypes } from "config/types";
import { Layout, useTranslation } from "modules";
import { RootState } from "store";
import { destroyBeneficiary } from "store/actions/beneficiaries";
import { closeModal } from "store/actions/modal";
import { getThunkError } from "utils/error";

import { push } from "connected-react-router";
import { defaultTo } from "lodash-es";

const RemoveLastBeneficiaryModal: FC<IModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const modalData = useSelector((state: RootState) => state.modal.data);

  const beneficiaryId = modalData.beneficiaryId as string;
  const responsibility = modalData.responsibility as BeneficiaryResponsibility;
  const lineOfBusiness = modalData.lineOfBusiness as BusinessSlugTypes;

  const remove = async (promoteBackup: boolean, destroyBackup: boolean) => {
    try {
      const response = await dispatch(
        destroyBeneficiary({ beneficiaryId, responsibility, promoteBackup, destroyBackup })
      );
      const error = getThunkError(response);
      if (error) {
        throw error;
      }
      closeModalWrapper();
    } catch (error) {
      setError(defaultTo(error.fullMessage, error.code));
    }

    dispatch(push(`/beneficiary/${lineOfBusiness}`));
  };

  const promoteBackupOnClick = () => {
    remove(true, false);
  };

  const removeBeneficiariesOnClick = async () => {
    remove(false, true);
    // dispatch(setModal("RemoveBeneficiaryModal", "", { removeBoth: true }));
  };

  const closeModalWrapper = () => {
    dispatch(closeModal());
  };

  const content = {
    icon: "BlobAlert",
    header: t("beneficiary.remove_last.header", "You can’t have a backup without a primary"),
    subheader: t(
      "beneficiary.remove_last.body",
      "How about taking your backup beneficiary off the bench and making them your new primary beneficiary?"
    ),
    onClose: closeModalWrapper,

    button: [
      {
        subType: "primary",
        text: t("beneficiary.remove_last.cta.primary", "YES, PROMOTE BY BACKUP"),
        onClick: promoteBackupOnClick,
      },
      {
        subType: "text-dark-border",
        text: t("beneficiary.remove_last.cta.secondary", "NOPE, REMOVE BOTH"),
        onClick: removeBeneficiariesOnClick,
      },
    ],
  };

  return (
    <Layout as="ModalLayout" content={content}>
      <FormError error={error} />
    </Layout>
  );
};

export default RemoveLastBeneficiaryModal;
