import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";

import { BusinessSlugTypes } from "config";
import { OAuthContext } from "config/init/providers";
import { RootState, useAppDispatch } from "store";
import { authorizeUnidentifiedUser } from "store/actions/authentication";
import { createIdentifiedUser } from "store/actions/user";

// Hook to create new "identified" users by business type ("wysh-financial" or ""wysh-insurance"")
export const useAuthorizeUnidentifiedUser = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useContext(OAuthContext);
  const isUnidentified = useSelector(
    (state: RootState) => state.authentication.authLevel === "unidentified"
  );

  const authorizeUnidentifiedUserByBusiness = useCallback(
    async (businessSlug: BusinessSlugTypes) => {
      try {
        if (isUnidentified) {
          const userData = await dispatch(createIdentifiedUser(businessSlug)).unwrap();

          await dispatch(authorizeUnidentifiedUser({ clientId, userData }));
        }
      } catch (error) {
        return error;
      }
    },
    [clientId, dispatch, isUnidentified]
  );

  return { authorizeUnidentifiedUserByBusiness, clientId, isUnidentified };
};
